import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-restriction-note',
  templateUrl: './restriction-note.component.html',
  styleUrls: ['./restriction-note.component.scss'],
  standalone: false
})
export class RestrictionNoteComponent implements OnInit {

  @Input() hasLoanProductWriteAccess: boolean;
  @Input() restrictionNotes: string;

  @Output() saveRestrictionNote = new EventEmitter<string>();

  myFormControlFromComponent = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.myFormControlFromComponent.setValue(this.restrictionNotes);
  }

  /**
   * @description on saving a new restriction notes
   */
  saveClicked(restrictionNotes: string) {
    this.saveRestrictionNote.emit(restrictionNotes);
    this.restrictionNotes = restrictionNotes;
    this.resetValue();
  }

  resetValue() {
    this.myFormControlFromComponent.setValue(this.restrictionNotes);
  }
}
