<!-- Loading spinner -->
@if (loading) {
<app-dot-loader-scale class="offers-loader-scale"></app-dot-loader-scale>
} @else {
@if (inLenderTasksContext) {
    <lender-tasks-header [deal]="deal"
                         [borrowerName]="borrowerName">
    </lender-tasks-header>
}
<mat-tab-group [class.lender-tasks-tab-group]="inLenderTasksContext" mat-stretch-tabs="true" mat-align-tabs="start" [(selectedIndex)]="selectedIndex" animationDuration="0ms"
    (selectedTabChange)="handleSelectedTabChange($event)">
    <!-- Details tab -->
    <mat-tab>
        <ng-template mat-tab-label>Details</ng-template>
        <!-- Each content section must be an ng-template to refresh on tab switch. -->
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4">
                    <mat-card appearance="outlined">
                        <mat-card-content>
                            <app-details [applicationDetails]="applicationDetails$ | async"
                                [applicationFields]="applicationFields$ | async">
                            </app-details>
                        </mat-card-content>
                    </mat-card>
                </div>
                @if (!inLenderTasksContext) {
                    <!-- Deal sidebar -->
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>

    <!-- Documents tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
            <span *ngIf="documents$ | async as documents" class="!font-light !text-gray-400 ml-2">
                {{ documents?.length }}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" [class.bg-white]="canSeeAdvDocMgmt()" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" [class]="!canSeeAdvDocMgmt() ? 'p-4' : ''">
                    @if (canSeeAdvDocMgmt()) {
                        <app-documents-smart [canSeeAdvDocManagement]="true"
                                             [dealId]="dealId"
                                             [borrowerId]="borrowerId"
                                             [loanProductCategory]="loanProductCategory"
                                             [borrowerLenderId]="businessLenderId$ | async">
                        </app-documents-smart>
                    } @else {
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                    <app-documents-smart [canSeeAdvDocManagement]="false"
                                                         [dealId]="dealId"
                                                         [borrowerId]="borrowerId"
                                                         [borrowerLenderId]="businessLenderId$ | async">
                                    </app-documents-smart>
                            </mat-card-content>
                        </mat-card>
                    }
                </div>
                @if (!inLenderTasksContext) {
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>

    <!-- Offers tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            Offers
            <ng-container *ngIf="offers$ | async as offers">
                <span class="!font-light !text-gray-400 ml-2">
                    @if (offers.length) {
                    {{ offers.length }}
                    } @else {
                    0
                    }
                </span>
            </ng-container>
        </ng-template>
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4">
                    <app-offers-smart [dealId]="dealId" [loanProductCategory]="loanProductCategory"
                        [tenantId]="businessTenantId$ | async">
                    </app-offers-smart>
                </div>
                @if (!inLenderTasksContext) {
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>

    <!-- Approvals tab -->
    <mat-tab>
        <ng-template mat-tab-label>Approval</ng-template>
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-approval-smart [dealId]="dealId" [useStoreApprovals]="true">
                    </app-approval-smart>
                </div>
                @if (!inLenderTasksContext) {
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>

    <!-- Decisioning tab -->
    @if (hasDecisioningFeature$ | async) {
    <mat-tab>
        <ng-template mat-tab-label>Decisioning</ng-template>
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-decisioning [borrowerId]="borrowerId" [loanProductId]="loanProductId"
                        [deal]="(applicationDetails$ | async)?.deal"
                        [autoPopOverrides]="shouldAutoPopOverrides"></app-decisioning>
                </div>
                @if (!inLenderTasksContext) {
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>
    }
    <!-- Contract request tab -->
    @if (showContractRequestTab) {
    <mat-tab>
        <ng-template mat-tab-label>
            Contract request
            <span class="!font-light !text-gray-400 ml-2">
                @if (hasContractRequest) {
                1
                } @else {
                0
                }
            </span>
        </ng-template>
        <ng-template matTabContent>
            <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-contract-requests *ngIf="contractRequests$ | async as contractRequest; else loading"
                        [contractRequest]="contractRequest" [loanProductName]="loanProductTypeName ?? loanProductType"
                        [dealId]="dealId">
                    </app-contract-requests>
                    <ng-template #loading>
                        <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
                    </ng-template>
                </div>
                @if (!inLenderTasksContext) {
                    <app-deal-sidebar
                        [dealId]="dealId"
                        [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                        (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                        (overrideButtonClicked)="handleOverrideButtonClicked()"
                        (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                        (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                        (applicationDetailsTabChange)="activateTabFromName($event)">
                    </app-deal-sidebar>
                }
            </div>
        </ng-template>
    </mat-tab>
    }

    <!-- Messages tab -->
    <!-- TODO: Remove the canSeeMessagesTab$ conditional when we kill Early Access Feature "Messages Tab" -->
    @if ((isMarketplaceOrEmbeddedDeal$ | async) && (canSeeMessagesTab$ | async)) {
        <mat-tab>
            <ng-template mat-tab-label>
                Messages
                <span *ngIf="fundingDeskRequests$ | async as requests" class="!font-light !text-gray-400 ml-2">
                    {{ requests?.length }}
                </span>
            </ng-template>
            <ng-template matTabContent>
                <div [class.grid]="!inLenderTasksContext" class="grid-flow-col grid-cols-[1fr_352px]">
                    <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4 w-[734px] justify-self-center">
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                <app-funding-desk-smart [dealId]="dealId" [isSidebar]="false"></app-funding-desk-smart>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    @if (!inLenderTasksContext) {
                        <app-deal-sidebar
                            [dealId]="dealId"
                            [applicationDetailsPage]="(applicationDetails$ | async)?.page"
                            (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                            (overrideButtonClicked)="handleOverrideButtonClicked()"
                            (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                            (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                            (applicationDetailsTabChange)="activateTabFromName($event)">
                        </app-deal-sidebar>
                    }
                </div>
            </ng-template>
        </mat-tab>
    }

    <!-- Notes tab (only in Lender Tasks context) -->
    @if (inLenderTasksContext) {
        <mat-tab>
            <ng-template mat-tab-label>
                Notes
            </ng-template>
            <ng-template matTabContent>
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-deal-notes-tab [dealId]="dealId"></app-deal-notes-tab>
                    <ng-template #loading>
                        <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
                    </ng-template>
                </div>
            </ng-template>
        </mat-tab>
    }

    <!-- History tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            History
        </ng-template>
        <ng-template matTabContent>
            <div class="deal-history-content">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-deal-activities *ngIf="dealActivities$ | async as dealActivities; else loading"
                        [dealActivities]="dealActivities"
                        [dealId]="dealId">
                    </app-deal-activities>
                    <ng-template #loading>
                        <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
}
