import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { ThoughtSpotService } from '@app/app/services/thoughtspot-service';
import {
  Action,
  LiveboardEmbed,
  MessagePayload,
} from '@thoughtspot/visual-embed-sdk';

@Component({
  selector: 'app-report-single-view',
  templateUrl: './report-single-view.component.html',
  styleUrls: ['./report-single-view.component.scss'],
  standalone: false
})
export class ReportSingleViewComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild('tsEmbedElement', { static: true })
  tsEmbedElement: ElementRef<HTMLDivElement>;
  private _liveboardEmbed: LiveboardEmbed;

  constructor(
    private _route: ActivatedRoute,
    private _titleService: NavPageTitleService,
    private _router: Router,
    private _tsService: ThoughtSpotService,
    private _breadcrumbService: BreadcrumbService
  ) {}

  ngOnDestroy(): void {
    // Stop listening to embed errors when we leave the component.
    this._liveboardEmbed.off(
      this._tsService.EmbedEvent.Error,
      this._handleEmbedError
    );
    this._breadcrumbService.setBreadcrumbs([]);
  }

  ngOnInit(): void {
    this._setPageTitle();
    this._breadcrumbService.setBreadcrumbs([
      { label: 'Reports', path: '/reports' },
    ]);
  }

  ngAfterViewInit(): void {
    this._embedTSReport();
  }

  /**
   * Set page title by pulling report name from
   * state object.
   */
  private _setPageTitle(): void {
    const { reportName } = history.state;
    this._titleService.setTitle(reportName);
  }

  /**
   * Return the report id from the route.
   * @returns string
   */
  private _reportId(): string | null {
    return this._route.snapshot.paramMap.get('id');
  }

  /**
   * Embed ThoughtSpot report and handle errors.
   */
  private _embedTSReport(): void {
    const reportId = this._reportId();
    if (reportId === null) {
      this._routeTo404Page();
      return;
    }

    const thoughtSpotDiv = this.tsEmbedElement?.nativeElement;
    this._liveboardEmbed = this._tsService.embedLiveboardDefault({
      liveboardId: reportId,
      embedDiv: thoughtSpotDiv,
      shouldCallRender: false,
      visibleActions: [
        Action.Present,
        Action.Download,
        Action.DownloadAsPdf,
        Action.DownloadAsPng,
        Action.DownloadAsXlsx,
        Action.DownloadAsCsv,
        Action.ShowUnderlyingData,
        Action.Explore,
        Action.DrillDown,
      ],
    });
    this._liveboardEmbed
      .on(this._tsService.EmbedEvent.Error, this._handleEmbedError)
      .render();
  }

  /**
   * Route user to 404 page without changing URL in browser.
   */
  private _routeTo404Page(): void {
    this._router.navigateByUrl('404', { skipLocationChange: true });
  }

  /**
   * Handle error emitted by TS when embedding the report.
   * @param {MessagePayload} error
   */
  private _handleEmbedError = (error: MessagePayload) => {
    // Assume error is due to invalid liveboard/report guid and route to 404 page.
    this._routeTo404Page();

    // TODO: Examine error. If error not due to an invalid report/guid then
    // do the following:
    // 1. Report error to Rollbar?
    // 2. Display an error message or alert?
  };
}
