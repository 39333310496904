import { DocumentRequirementItem } from "@app/app/interfaces/document-requirement-item.model";
import { DocumentRequest, DocumentRequestPayload, DocumentRequirementRequest } from "@app/app/interfaces/document-requirements.model";

export const formatDocumentRequestsPayload = ({ documentRequirements, noticeToBorrower, notifyBorrower }: DocumentRequestPayload): DocumentRequirementRequest => {
    const requirements: DocumentRequest[] = documentRequirements.map(item => {
        const requirement: DocumentRequest = {
            publishTo: 'borrower',
            deliverTo: 'lender',
            categoryId: item.id,
            name: item.name,
            description: item.description
        }

        if (item.monthValues) {
            requirement.month = item.monthValues.month
            requirement.year = item.monthValues.year
        }

        if (item.yearsString) {
            requirement.year = parseInt(item.yearsString);
        }

        return requirement;
    });


    if (notifyBorrower) {
        const payload: DocumentRequirementRequest = {
            requirements,
            notifyBorrower
        }

        if (noticeToBorrower) {
            payload.noticeToBorrower = noticeToBorrower
        }

        return payload;
    }

    return { requirements };
}