import { Component } from '@angular/core';
import { Breadcrumb } from '@app/app/interfaces/breadcrumb.model';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: false
})
export class BreadcrumbComponent {

  breadCrumbs$: Observable<Breadcrumb[]> = this._breadcrumbService.getBreadcrumbs();

  constructor(
    private _breadcrumbService: BreadcrumbService
  ) {}

}
