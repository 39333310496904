import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-fail-dialog',
  templateUrl: './import-fail-dialog.component.html',
  styleUrls: ['./import-fail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})

export class ImportFailDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data.page === 'imports') {
      try {
        const importError = JSON.parse(this.data?.errorMessage);
        this.data.errorMessage = importError;
        this.data.errorFormat = 'object';
      } catch {
        this.data.errorFormat = 'string';
      }
    } else if (this.data.page === 'details') {
      try {
        const entityError = JSON.parse(this.data?.errors);
        this.data.errors = entityError;
        this.data.errorFormat = 'object';
      } catch {
        this.data.errorFormat = 'string';
      }
    }
  }
}
