import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DealStatusChangeDialogComponent,
  DealStatusChangeDialogData,
  DealStatusChangeDialogOutput,
} from './deal-status-change-dialog/deal-status-change-dialog.component';
import { Observable, catchError, map, take, tap, combineLatest, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UpdateDealStatus } from '@app/app/store/application-details/application-details.actions';
import { StatusOption } from '@app/app/interfaces/status-option';
import { DEAL_STATUS_OPTIONS_BY_STAGE } from './deal-status-options-by-stage';
import { MatButtonModule } from '@angular/material/button';
import { Deal } from '@app/app/interfaces/deal.model';
import { ConfettiService } from '@app/app/services/confetti.service';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { DealStage } from './deal-stage.enum';
import { DeadDispositionsState } from '@app/app/store/dead-dispositions/dead-dispositions.state';
import { GetInactiveReasons } from '@app/app/store/dead-dispositions/dead-dispositions.actions';
import { InactiveReason } from '@app/app/interfaces/inactive-reason.model';
import { LendioStatusDisplayComponent } from '@app/app/components/lendio-angular-material-theme/lendio-status-display/lendio-status-display.component';
import { MatchMenuWidthDirective } from '@app/app/components/lendio-angular-material-theme/match-menu-width.directive';
import { NewOfferDialogComponent } from '../new-offer-display/new-offer-dialog/new-offer-dialog.component';
import {
  AcceptOfferDialogCloseData,
  AcceptOfferDialogCloseReason,
  AcceptOfferDialogComponent,
} from '@app/app/components/deal-status-change-dropdown/accept-offer-dialog/accept-offer-dialog.component';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { Offer } from '@app/app/interfaces/offer.model';
import { TemplateDialogComponent } from '@app/app/components/templates/template.dialog.component';
import { Business } from '@app/app/interfaces/business.model';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { ConsumerCreditReport } from '@app/app/interfaces/credit-reports/credit-report.model';
import { FinancesState } from '@app/app/store/finances/finances.state';

@Component({
  selector: 'app-deal-status-change-dropdown',
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    LendioStatusDisplayComponent,
    MatchMenuWidthDirective,
  ],
  templateUrl: './deal-status-change-dropdown.component.html',
  styleUrls: ['./deal-status-change-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe]
})
export class DealStatusChangeDropdownComponent implements OnInit, OnDestroy {
  @Input({ required: true }) deal: Deal;
  business$: Observable<Business> = this._store.select( BusinessState.business );
  @Select(SaasFeaturesState.saasPermitted('canViewAANDialog')) canViewAANDialog$: Observable<boolean>;

  destroyed$ = new Subject<boolean>();


  /**
   * Reference to dropdown button so we can show confetti when a deal gets funded.
   */
  @ViewChild('dealStatusDropdownButton')
  dealStatusDropdownButton: ElementRef<HTMLElement>;

  private _newStatus: StatusOption;
  private _dealStatusChangeDialogDialogRef: MatDialogRef<
    DealStatusChangeDialogComponent,
    DealStatusChangeDialogData
  >;
  private _newOffer: Partial<Offer>;
  protected readonly _dealStatusOptionsByStage = DEAL_STATUS_OPTIONS_BY_STAGE;

  constructor(
    private _confettiService: ConfettiService,
    private _dialog: MatDialog,
    private _lendioSnackbarService: LendioSnackbarService,
    private _store: Store,
    private _titleCasePipe: TitleCasePipe,
  ) {}

  ngOnInit(): void {
    this._store.dispatch(new GetInactiveReasons());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  /**
   * Use deal’s stage/status to find predefined StatusOption
   * */
  get currentStatusOption(): StatusOption | undefined {
    // TODO: If for whatever reason we ever can’t find a match (which shouldn’t ever happen),
    // we need to handle the undefined case.
    if (DEAL_STATUS_OPTIONS_BY_STAGE.hasOwnProperty(this.deal.stage)) {
      return DEAL_STATUS_OPTIONS_BY_STAGE[this.deal.stage].find(
        (statusOption: StatusOption) => statusOption.value === this.deal.status
      );
    }
    return undefined;
  }

  /**
   * Reactively filter list of inactive reasons to only those that
   * pertain to the new deal status being set.
   */
  get inactiveReasonsForNewStatus$(): Observable<InactiveReason[]> {
    return this._store
      .select(DeadDispositionsState.inactiveReasonsByStatusFn)
      .pipe(map((filterFn) => filterFn(this._newStatus.value)));
  }

  /**
   * Open DealStatusChangeDialog with current and new statuses.
   * @param newStatus
   */
  protected _handleDealStatusMenuSelection(
    newStatus: Readonly<StatusOption>
  ): void {
    this._newStatus = newStatus;

    if (newStatus.stage === DealStage.Funded) {
      return this._handleChangeToFunded();
    }

    this._showDealStatusChangeDialog();
  }

  private _showDealStatusChangeDialog(acceptedOffer?: Partial<Offer>) {
    const dialogData: DealStatusChangeDialogData = {
      currentStatus: this.currentStatusOption!,
      newStatus: this._newStatus,
      inactiveReasons$: this.inactiveReasonsForNewStatus$,
      acceptedOffer,
    };

    this._dealStatusChangeDialogDialogRef = this._dialog.open<
      DealStatusChangeDialogComponent,
      DealStatusChangeDialogData
    >(DealStatusChangeDialogComponent, {
      data: dialogData,
      maxWidth: 702,
      minWidth: 470,
      autoFocus: false
    });

    this._subscribeToSaveChanges();
  }

  /**
   * Set up subscription to DealStatusChangeDialogComponent’s
   * saveChangesClicked event emission.
   */
  private _subscribeToSaveChanges(): void {
    this._dealStatusChangeDialogDialogRef.componentInstance.saveChangesClicked
      .pipe(take(1))
      .subscribe((dialogOutput) => this._updateDealStatus(dialogOutput));
  }

  /**
   * Dispatch UpdateDealStatus to set new deal status.
   * @param dialogOutput
   */
  private _updateDealStatus(dialogOutput: DealStatusChangeDialogOutput) {
    const isNewlyFunded =
      this._newStatus.stage === DealStage.Funded &&
      this.deal.stage !== DealStage.Funded;

    this._store
      .dispatch(
        new UpdateDealStatus(
          this.deal.id,
          this._newStatus,
          dialogOutput.reason,
          dialogOutput.otherReason
        )
      )
      .pipe(
        catchError((error) => {
          this._dealStatusChangeDialogDialogRef.close();
          throw error;
        })
      )
      .subscribe(() => {
        if (isNewlyFunded) this._blastConfetti();
        this._triggerSuccessSnackbar();
        this._dealStatusChangeDialogDialogRef.close();
      });
  }


  /**
   * Blast confetti 🎉 by triggering ConfettiService on deal status dropdown button.
   */
  private _blastConfetti() {
    this._confettiService.confetti(this.dealStatusDropdownButton?.nativeElement);
  }

  private _triggerSuccessSnackbar() {
    let message = `Deal has been moved to “${this._titleCasePipe.transform(
      this._newStatus.stage
    )}: ${this._newStatus.display}” status.`;

    if (this._newStatus.stage === DealStage.Funded) {
      message = 'Deal funded successfully!';
    }

    this._lendioSnackbarService.open({ message });
  }

  private _triggerErrorAlert() {
    this._store.dispatch(
      new CreateNewAlert({
        level: 'error',
        message: 'Unable to change deal status.',
      })
    );
  }

  private _handleChangeToFunded() {
    if (this._dealHasAcceptedOffer()) {
      return this._showDealStatusChangeDialog();
    }

    if (this._dealHasUnacceptedOffers()) {
      return this._showAcceptOfferDialog();
    }

    // Deal has no offers so prompt user to create one on the fly
    this._showCreateNewOfferDialog();
  }

  private _dealHasAcceptedOffer(): boolean {
    return this.deal.acceptedOfferId !== null;
  }

  private _dealHasUnacceptedOffers(): boolean {
    return !!this.deal.offers?.length;
  }

  private _showCreateNewOfferDialog(): void {
    const newOfferDialogRef = this._dialog.open(NewOfferDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        dealId: this.deal.id,
        borrowerName: this.deal.borrower?.name,
        isFundingInterstitial: true,
      },
    });

    newOfferDialogRef
      .afterClosed()
      .pipe(
        tap((wasCanceled) => {
          if (!wasCanceled) {
            this._showDealStatusChangeDialog(this._newOffer);
          }
        })
      )
      .subscribe();

    newOfferDialogRef.componentInstance.newOfferCreated
      .pipe(take(1))
      .subscribe((newOffer) => (this._newOffer = newOffer));
  }
  _showAcceptOfferDialog(): void {
    this._dialog
      .open(AcceptOfferDialogComponent, {
        autoFocus: false,
        disableClose: true,
        minWidth: 540,
        data: {
          offers: this.deal.offers,
        },
      })
      .afterClosed()
      .pipe(
        tap((closeData: AcceptOfferDialogCloseData) => {
          switch (closeData.reason) {
            case AcceptOfferDialogCloseReason.Error:
              this._triggerErrorAlert();
              break;
            case AcceptOfferDialogCloseReason.Default:
              break;
            case AcceptOfferDialogCloseReason.AcceptOffer:
              if (closeData.acceptedOffer) {
                this._showDealStatusChangeDialog(closeData.acceptedOffer);
              }
              break;
            case AcceptOfferDialogCloseReason.CreateNewOffer:
              this._showCreateNewOfferDialog();
              break;
          }
        })
      )
      .subscribe();
  }
}
