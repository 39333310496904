import { Component, OnInit, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { trigger, transition, style, animate } from '@angular/animations';
import { SmallChartData } from '../../../interfaces/small-chart-data.model';

@Component({
  selector: 'app-medium-line-chart',
  templateUrl: './medium-line-chart.component.html',
  styleUrls: ['./medium-line-chart.component.scss'],
  animations: [
    trigger('fadeEaseInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.1s ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.1s ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
  standalone: false
})
export class MediumLineChartComponent implements OnInit {
  @Input() chartData: SmallChartData;
  @Input() percent: boolean;
  overlay = true;

  // options
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = false;
  showXAxisLabel = false;
  xAxisLabel = 'Date';
  yAxisLabel: string;
  yScaleMax: number | null | undefined;
  yScaleMin: any = undefined;
  timeline = false;
  showGridLines = false;
  curve: any = shape.curveMonotoneX;
  colorScheme = {
    domain: [
      '#bbe6fa',
      '#4299E1',
      '#9F7AEA',
      '#ED8936',
      '#F56565',
      '#276749',
      '#81E6D9',
    ]
  };
  showRefLabels = true;
  showRefLines = true;
  refLines: object[] = [];

  constructor() { }

  ngOnInit(): void {
    this.refLines = [{ value: this.chartData.average, name: 'Average'}];
    this.yAxisLabel = this.chartData.name;
    if (this.percent) {
      this.yScaleMax = 100;
    } else if (this.chartData.average) {
      this.yScaleMax = this.chartData.average * 1.7;
    } else {
      this.yScaleMax = null;
    }
  }

}
