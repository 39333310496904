import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Document } from '@app/app/interfaces/document.model';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Select } from '@ngxs/store';
import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import { ApplicationDetails } from '@app/app/interfaces/application-details.model';
import { environment } from '@app/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { EventBusService } from '@app/app/services/event-bus.service';
import { ActivateDealPageTabEvent } from '@app/app/services/event-types';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { DocumentsService } from '@app/app/services/documents.service';

@Component({
  selector: 'app-documents-quick-view',
  templateUrl: './documents-quick-view.component.html',
  styleUrls: ['./documents-quick-view.component.scss'],
  standalone: false
})
export class DocumentsQuickViewComponent implements OnInit, OnDestroy {

  @Select( DocumentsState.documents ) documents$: Observable<Document[]>;
  @Select( AuthState.user ) currentUser$: Observable<AuthUser | undefined>;
  @Select( ApplicationDetailsState.applicationDetails ) applicationDetails$: Observable<ApplicationDetails>;

  destroyed$ = new Subject<boolean>();
  dealId: number;
  user: AuthUser | undefined;
  latest3Documents: Document[] = [];

  constructor(
    private eventBusService: EventBusService,
    private doc: DocumentsService
  ) {
  }

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;
    });
    this.applicationDetails$.pipe(
      takeUntil( this.destroyed$ )
    ).subscribe( ( applicationDetails: ApplicationDetails ) => {
      this.dealId = applicationDetails?.deal?.id
    } );
    this.documents$.subscribe((docs: Document[]) =>
      this.latest3Documents = [...docs].sort((a,b) => Number(b.created) - Number(a.created)).slice(0,3)
    );
  }

  viewDocument( document: Document ): void {
    if (document.scanStatus !== "CLEAN") {
      return;
    }
    const url = `${environment.apiUrl}/l/v2/internal/document/${ document.id }/stream?dealId=${ this.dealId }`;
    this.doc.openInNewTab( url, document.id);
  }

  viewAllDocuments() {
    this.eventBusService.publish( new ActivateDealPageTabEvent( 'documents' ) );
  }

  ngOnDestroy() {
    this.destroyed$.next( true );
    this.destroyed$.complete();
  }

}
