import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FocusOnInitDirective} from "@app/app/directives/focus-on-init.directive";
import {FormsModule} from "@angular/forms";
import {MatAnchor, MatButton} from "@angular/material/button";
import {MatFormField} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {fadeIn, fadeOut} from "ng-animate";
import {FundingDeskRequest} from "@app/app/interfaces/funding-desk-request.model";
import {Store} from "@ngxs/store";
import * as moment from "moment-timezone";
import {AuthState} from "@app/app/store/auth/auth.state";

@Component({
  selector: 'app-single-request-chains-sidebar',
  animations: [
    trigger('slideUpDown', [
      state('in', style({ height: '*' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(150, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: 0 }),
        animate(150, style({ height: '*' }))
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave', useAnimation(fadeOut, { params: { timing: 0.05 } }))
    ]),
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.3 } }))
    ])
  ],
  imports: [
    FocusOnInitDirective,
    FormsModule,
    MatAnchor,
    MatButton,
    MatFormField,
    MatIcon,
    MatInput,
    NgForOf,
    NgIf
  ],
  templateUrl: './single-request-chains-sidebar.component.html',
  styleUrl: './single-request-chains-sidebar.component.scss'
})
export class SingleRequestChainsSidebarComponent implements OnChanges, OnInit {

  @ViewChild('commentTextArea', {static: true})
  commentTextArea: ElementRef;

  @Input() public requestChain: FundingDeskRequest;
  @Input() public requestIndex: number;
  @Output() comment = new EventEmitter<{ description: string, requestId: number }>();

  addNewComment = false;
  moreComments = false;
  comments: any[];
  newComment = '';
  requestTypeList = {
    lender: 'Lender Request',
    fundingDesk: 'Funding Desk Request',
    fundingManager: 'Funding Manager Request'
  };
  addRequestTypeLabel = false;
  requestType = '';

  constructor(private store: Store) { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
    this.requestType = this.getRequestType();
    this.addRequestTypeLabel = this.isIndividualRequestType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requestChain) {
      const sorted = [
        ...changes.requestChain.currentValue.task_comments ?? []
      ].sort((a, b) => {
        return new Date(b.created).getTime()-new Date(a.created).getTime()
      });
      this.comments = sorted;
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

  createNewComment(description: string) {
    this.comment.emit({description, requestId: this.requestChain.id});
    const {first, last} = this.store.selectSnapshot(AuthState.user) ?? {}
    const newestComment = {creator_user: { first, last}, created: new Date(), description}
    this.comments = [newestComment, ...this.comments]
    this.newComment = '';
    this.addNewComment = false;
  }

  cancelComment() {
    this.newComment = '';
    this.addNewComment = false;
  }

  isIndividualRequestType() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestChain.type === 'QC Task' || this.requestTypeList.hasOwnProperty(this.requestChain.fromRole);
  }

  getRequestType() {
    if (this.requestChain.type === 'QC Task') {
      return 'QC Task';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestTypeList[this.requestChain.fromRole];
  }
}
