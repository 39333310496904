import { Inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { LenderUser } from '../../interfaces/lender-user.model';
import { RollbarService } from '../../rollbar';
import { LenderUserService } from '../../services/lender-user.service';
import { environment } from '../../../environments/environment';
import Rollbar, { LogArgument } from 'rollbar';
import { tap } from 'rxjs/operators';
import { catchError, of, throwError } from 'rxjs';
import {
  CreateLenderUser,
  DeleteLenderUser,
  GetLenderUser,
  GetLenderUsers,
  GoOffline,
  GoOnline,
  UpdateLenderUser,
  UpdatePausedWorkflow
} from './lender-user.actions';
import { insertItem, patch, updateItem, removeItem } from '@ngxs/store/operators';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";
import { AuthState } from '../auth/auth.state';

declare const FS: any;

export interface LenderUserStateModel {
  lenderUser: LenderUser;
  lenderUsers: LenderUser[];
  online: boolean;
  loading: boolean;
}

@Injectable()
@State<Partial<LenderUserStateModel>>( {
  name: 'lenderUser',
  defaults: {
    lenderUsers: [],
    online: true,
    loading: false,
  }
} )
export class LenderUserState {

  private identified = false;

  constructor(
    private lenderUserService: LenderUserService,
    private store: Store,
    private _snackbarService: LendioSnackbarService,
    @Inject( RollbarService ) private rollbar: Rollbar
  ) {
  }

  @Selector()
  static lenderUser( state: LenderUserStateModel ): LenderUser {
    return state.lenderUser;
  }

  @Selector()
  static loading( state: LenderUserStateModel ): boolean {
    return state.loading;
  }

  @Selector()
  static online( state: LenderUserStateModel ) {
    return state.online;
  }

  @Selector()
  static hasPermissions( permissionNames: string[] ) {
    return createSelector( [ LenderUserState ], ( { lenderUser } ) => {
      const permissions = lenderUser?.lenderUser?.permissions;
      return permissionNames.reduce( ( res: any, cur: any ) => {
        res[cur] = permissions?.includes( cur );
        return res;
      }, {} );
    });
  }

  @Selector()
  static hasPermission( permissionName: string ) {
    return createSelector( [ LenderUserState ], ( { lenderUser } ) => {
      const permissions = lenderUser?.lenderUser?.permissions;
      return permissions.includes( permissionName );
    } );
  }

  @Selector()
  static teamMembers( state: LenderUserStateModel ) {
    return state.lenderUsers;
  }


  /**
   * TODO: This isn't currently set up correctly. There is no lender facing endpoint to fetch the current Lender User
   */
  @Action( GetLenderUser )
  getLenderUser(
    ctx: StateContext<LenderUserStateModel>,
    {}: GetLenderUser
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.user);
    const currentState = ctx.getState();
    if(currentState?.lenderUser?.id !== currentUser?.id) {

      ctx.patchState( {
        loading: true,
      });
      return this.lenderUserService.get().pipe(
        catchError( err => {
          ctx.patchState( {
            loading: false,
          });
          return throwError(err);
        }),
        tap( lenderUser => {
          ctx.patchState( {
            loading: false,
          });
          if ( !lenderUser ) {
            return;
          }
          ctx.patchState( { lenderUser } );
          setTimeout( () => {
            this.identify( lenderUser );
          }, 1000 );
        })
      );

    }
  }

  @Action( GetLenderUsers )
  getLenderUsers(
    { patchState }: StateContext<LenderUserStateModel>,
    {}: GetLenderUsers
  ) {
    patchState( {
      loading: true,
    });

    return this.lenderUserService.getLenderUsers().pipe(
      catchError( (err: any) => {
        patchState({
          loading: false,
        });
        this.store.dispatch( new CreateNewAlert( {
          level: 'error',
          message: 'Problem fetching Team Members.'
        }));
        return throwError( err );
      }),
      tap( response => {
        patchState( {
          lenderUsers: response.data,
          loading: false,
        });
      })
    );
  }

  @Action( UpdateLenderUser )
  updateLenderUser(
    ctx: StateContext<LenderUserStateModel>,
    { userId, settings }: UpdateLenderUser
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.user);
    return this.lenderUserService.updateLenderUser( userId, settings )
      .pipe(
        catchError( err => {
          ctx.patchState({
            loading: false,
          });
          this.store.dispatch( new CreateNewAlert( {
            level: 'error',
            message: 'Problem updating User.'
          }));
          return throwError( err );
        }),
        tap( response => {
          const lenderUser = response.data as LenderUser;
          ctx.setState(
            patch( {
              lenderUsers: updateItem( user => user.id === userId, {
                ...lenderUser
              }),
              loading: false,
            })
          );

          if ( currentUser?.id === userId ) {
            ctx.patchState( {
              lenderUser
            })
          }
        }));
  }

  @Action( CreateLenderUser )
  createLenderUser(
    ctx: StateContext<LenderUserStateModel>,
    { newLenderUser }: CreateLenderUser
  ) {
    ctx.patchState({
      loading: true,
    });

    return this.lenderUserService.createLenderUser( newLenderUser )
      .pipe(
        catchError( err => {
          ctx.patchState({
            loading: false,
          });
          this.store.dispatch( new CreateNewAlert( {
            level: 'error',
            message: 'Problem creating new User.'
          }));
          return throwError( err );
        }),
        tap( response => {
          const newUser = response.data as LenderUser;
          ctx.setState(
            patch( {
              lenderUsers: insertItem( newUser ),
              loading: false,
            })
          );

          const roles = newUser?.user?.roles,
            hasAdminRole = !!roles?.find( role => role.alias === 'lpxClientAdministrator'),
            role = hasAdminRole ? 'Administrator' : roles[0]?.name;
          const addedAsString = !!role ? `as ${role}` : '';
          this._snackbarService.open({
            message: `${newUser?.fullName} added ${addedAsString}`,
            duration: 5000,
          });
        })
      )
  }

  @Action( DeleteLenderUser )
  deleteLenderUser(
    ctx: StateContext<LenderUserStateModel>,
    { userId }: DeleteLenderUser,
  ) {
    ctx.patchState({
      loading: true,
    });
    return this.lenderUserService.deleteLenderUser( userId )
      .pipe(
        catchError( err => {
          ctx.patchState({
            loading: false,
          });
          this.store.dispatch( new CreateNewAlert( {
            level: 'error',
            message: 'Problem deleting User.'
          }));
          return throwError( err );
        } ),
        tap( () => {
          ctx.setState(
            patch( {
              lenderUsers: removeItem( user => user.id === userId ),
              loading: false,
            })
          )
        })
      )
  }

  @Action( UpdatePausedWorkflow )
  updatePausedWorkflow(
    { patchState, getState }: StateContext<LenderUserStateModel>,
    { userId, meta }: UpdatePausedWorkflow
  ) {
    return this.lenderUserService.updatePausedWorkflow( userId, meta ).pipe(
      tap( lenderUser => {
        patchState( {
          lenderUser: {
            ...getState().lenderUser, ...lenderUser
          }
        });
      }));
  }

  @Action( GoOnline )
  GoOnline(
    { patchState }: StateContext<LenderUserStateModel>,
    { userId }: GoOnline
  ) {
    //this.pusherService.subscribeRaw(`presence-lendio-${environment.environment}-notification-channel`);
    //this.pusherService.subscribeRaw(`presence-lender-user-${userId}-${environment.environment}-notification-channel`);
    patchState( { online: true } );
  }

  @Action( GoOffline )
  GoOffline(
    { patchState }: StateContext<LenderUserStateModel>,
    { userId }: GoOffline
  ) {
    //this.pusherService.unsubscribeRaw(`presence-lendio-${environment.environment}-notification-channel`);
    //this.pusherService.unsubscribeRaw(`presence-lender-user-${userId}-${environment.environment}-notification-channel`);
    patchState( { online: false } );
  }

  private identify( lenderUser: LenderUser ): void {
    if ( this.identified ) {
      return;
    }
    this.identified = true;

    try {
      this.rollbarIdentify( lenderUser );
      this.fullstoryIdentify( lenderUser );
    } catch ( err ) {
      this.rollbar.error( err as LogArgument );
    }
  }

  private rollbarIdentify( lenderUser: LenderUser ): void {
    this.rollbar.configure( {
      payload: {
        person: {
          id: lenderUser.id,
          username: lenderUser.fullName,
          email: lenderUser.user.email
        }
      }
    });
  }

  private fullstoryIdentify( lenderUser: LenderUser ): void {
    if ( typeof FS === 'undefined' ) {
      return;
    }

    FS.identify( lenderUser.id, {
      displayName: lenderUser.fullName,
      email: lenderUser.user.email
    });
  }
}
