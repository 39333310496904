import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { Injectable, signal } from '@angular/core';
import { EarlyAccessFeature, EarlyAccessFeatureRequest, SaasFeatureResponse } from '@app/app/interfaces/saas-feature.model';
import { Observable } from 'rxjs';

const postUrl = `${environment.apiUrl}/l/v2/internal/early-access-features`;
const AUTO_ENROLLMENT_ALIAS = 'automaticEnrollment';
@Injectable({
  providedIn: 'root',
})

export class EarlyAccessFeatureService {
  private _features: EarlyAccessFeature[] = [];
  public earlyAccessFeatures = signal(this._features);
  public earlyAccessShowBadge = signal(false);
  public earlyAccessShowDialog = signal(false);
  public autoEnrollmentAlias = AUTO_ENROLLMENT_ALIAS;

  constructor(private _http: HttpClient) { }

  public setFeatures(features: EarlyAccessFeature[]) {
    const automaticEnrollment = features.find(feature => feature.alias === this.autoEnrollmentAlias);
    this.earlyAccessFeatures.set([ ...features, ...this._features ]);
  }

  public upsertEarlyAccessFeature(feature: EarlyAccessFeature): Observable<EarlyAccessFeature> {
    return this._http.post<EarlyAccessFeature>(`${postUrl}/${feature.id}/enroll'`, {feature});
  }

  public upsertEarlyAccessFeatures(features: EarlyAccessFeatureRequest): Observable<SaasFeatureResponse> {
    return this._http.post<SaasFeatureResponse>(`${postUrl}/enroll`, features);
  }
}
