<ng-container *ngIf="(approvals$ | async) as approvals; else NoApproval">
@if (approvals.length) {
    <div class="p-4">
        @if (locTermSbaApprovals.length) {
            <app-approvals [approvals]="locTermSbaApprovals"></app-approvals>
        }
        <mat-accordion class="approvals-accordion">
            <div *ngFor="let offer of otherApprovals; let index = index;" class="mb-2 first:pt-5">
                <app-ach-approvals
                    [initializeExpanded]="!locTermSbaApprovals.length && index === 0"
                    [readOnly]="readOnly"
                    [disabled]="offer.calculationType === 'custom' && customControlsDisabled" [offer]="offer"
                    [loading]="getLoading(offer.id)" [errored]="getErrored(offer.id)" (error)="setErrored($event)"
                    (approvalAdjusted)="handleAdjustedApproval($event)">
                </app-ach-approvals>
            </div>
        </mat-accordion>
    </div>

} @else {
    <ng-template [ngTemplateOutlet]="NoApproval"></ng-template>
}
</ng-container>
<ng-template #NoApproval>
  <mat-card class="m-4" appearance="outlined">
    <mat-card-content>
      <app-empty-state
        [type]="emptyType"
        [label]="emptyLabelContent"
        [loading]="approvalsLoading$ | async">
      </app-empty-state>
    </mat-card-content>
  </mat-card>
</ng-template>
