import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NaicsSearchService } from '@app/app/services/naics-search.service';
import { take } from 'rxjs';
import { NaicsObject } from '@app/app/interfaces/naics-object.model';
import * as _ from 'underscore';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

export interface BorrowerToAdd {
  name: string;
  fromAddBusinessForm: boolean,
  contact: {
    first: string;
    last: string;
    email: string;
    phoneNumber: string;
    phoneType: string;
    title: string;
  }
  attributes: {
    timeInBusiness: string;
    average_monthly_sales: number | null;
    creditScore: number | null;
    industry_naics: number | null;
  };
}
@Component({
  selector: 'app-add-business-dialog',
  templateUrl: './add-business-dialog.component.html',
  styleUrls: ['./add-business-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class AddBusinessDialogComponent implements OnInit {

  addBusinessForm: FormGroup;
  dialogData: any;
  industries: NaicsObject[]= [];
  borrowerToAdd: BorrowerToAdd = {
    'name': '',
    'fromAddBusinessForm': true,
    'contact': {
      'first': '',
      'last': '',
      'email': '',
      'phoneNumber': '',
      'phoneType': '',
      'title': ''
    },
    'attributes': {
      'timeInBusiness': '',
      'average_monthly_sales': null,
      'creditScore': null,
      'industry_naics': null
    }
};

  phoneTypes = [
    {key: 'Work', value: 'work'},
    {key: 'Mobile', value: 'mobile'}
  ]

  searchText: string = '';
  formSubmitted: boolean = false;
  naics_loading = true;
  errored = false;
  searching = false;
  hasSearched = false;
  naicsCode: number | null;
  hideEditModal: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddBusinessDialogComponent>,
    private router: Router,
    private naicsSearch: NaicsSearchService,
    private fb: FormBuilder,
    public confirmDialog: MatDialog,
    ) {
    if (data) {
      this.borrowerToAdd = data;
    }
      this.search = _.debounce(this.search, 600);

    }

  ngOnInit(): void {
    if (this.data) {
      this.borrowerToAdd = this.data;
    }
    this.createForm();

    this.naicsCode = parseInt(this.addBusinessForm.controls['industry_naics'].value);
    // NAICS autocomplete initialize.
    if (this.naicsCode && this.naicsCode.toString().length === 6) {
      this.naicsSearch.searchByCode(this.naicsCode).pipe(take(1)).subscribe({
        next: (res) => {
        // If we find a match, show it in the form field.
          if (res && 'data' in res && res.data.hasOwnProperty('code')) {
            this.addBusinessForm.controls['industry_naics'].setValue(res.data.title);
          }
          this.naics_loading = false;
          this.errored = false;
        },
        error: () => {
          // If the request errors, just let the text be blank
          this.naics_loading = false;
          this.errored = true;
        }
      });
    } else {
      // Invalid code.
      this.naics_loading = false;
    }
  }

  createForm(){
    this.addBusinessForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      businessStartDate: new FormControl(null, [Validators.required]),
      average_monthly_sales: new FormControl(''),
      industry_naics: new FormControl(''),
      first: new FormControl('', [Validators.required]),
      last: new FormControl('', [Validators.required]),
      title: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(''),
      phoneType: new FormControl(''),
      creditScore: new FormControl('',[Validators.required, Validators.pattern('^[0-9]{3}$')])
    });

    this.addBusinessForm.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

  get businessStartDateControl(): AbstractControl | null {
    return this.addBusinessForm.get('businessStartDate');
  }

  get businessStartDate() {
    return this.businessStartDateControl?.value;
  }

  onValueChanged(data?: any) {
    if(!this.addBusinessForm) { return; }

    if(data?.industry_naics) {
      this.searchText = data.industry_naics;
    }
  }

  addBusiness() {
    this.formSubmitted = true;

    this.assignFormValuesToObject();

    this.dialogRef.close(this.borrowerToAdd);
  }

  navToImportTool() {
    this.dialogRef.close();
    this.router.navigate(['/administration/import']);
  }

  calculateTimeInBusiness(date: Date) {
    if(!date) {
      return '';
    }
    const currentDate = new Date();
    const yearsDiff = currentDate.getFullYear() - date.getFullYear();
    const monthsDiff = currentDate.getMonth() - date.getMonth();

    // Calculate the total number of months ago
    const monthsAgo = yearsDiff * 12 + monthsDiff;

    // payload expects a string
    return monthsAgo.toString();
  }

  // Autocomplete methods for NAICS search.
  search(): void {
    if (this.addBusinessForm.controls['industry_naics'].value.length >= 3) {
      this.searching = true;
      this.hasSearched = true;
      this.naicsSearch.searchByTerms(this.searchText).pipe(take(1)).subscribe({
        next: (res) => {
          this.industries = res.data;
          this.searching = false;
          this.errored = false;
        },
        error: (err) => {
          console.error(err);
          this.searching = false;
          this.errored = true;
        }
      });
    }
    }

  // Get examples of NAICS codes.
  getExamples(examples: any[]): string {
    if (!examples) {
      return '';
    }
    let response = 'Examples:\n\n';
    examples.forEach((example) => {
      response += example + '\n';
    });
    return response;
  }

  setCode(code: number, text: string): void {
    this.addBusinessForm.controls['industry_naics'].setValue(text);
    this.naicsCode = code;

    this.industries = [];
  }

  clearNaics() {
    this.addBusinessForm.controls['industry_naics'].setValue('');
    this.naicsCode = null;
  }

  assignFormValuesToObject() {
    this.borrowerToAdd.name = this.addBusinessForm.controls.name.value;
    this.borrowerToAdd.contact.email = this.addBusinessForm.controls.email.value;
    this.borrowerToAdd.contact.phoneNumber = this.addBusinessForm.controls.phoneNumber.value;
    this.borrowerToAdd.contact.phoneType = this.addBusinessForm.controls.phoneType.value;
    this.borrowerToAdd.contact.first = this.addBusinessForm.controls.first.value;
    this.borrowerToAdd.contact.last = this.addBusinessForm.controls.last.value;
    this.borrowerToAdd.contact.title = this.addBusinessForm.controls.title.value;
    this.borrowerToAdd.attributes.timeInBusiness = this.calculateTimeInBusiness(this.businessStartDate);
    this.borrowerToAdd.attributes.average_monthly_sales = this.addBusinessForm.controls.average_monthly_sales.value;
    this.borrowerToAdd.attributes.creditScore = Number(this.addBusinessForm.controls.creditScore.value);
    this.borrowerToAdd.attributes.industry_naics = this.naicsCode ? this.naicsCode : null;
  }

  cancel($event: Event) {
    if (this.addBusinessForm.dirty) {
      $event.stopPropagation();
      // Hide current modal to avoid modal-on-modal.
      this.hideEditModal = true;

      let confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        data: {
          title: "Are you sure you want to leave without saving?",
          description: "If you leave this page, any changes you've made will be lost.",
          cancelLabel: "Keep editing",
          confirmLabel:"Discard changes",
          width: "352px"
        }
      });

      // Cancel the "cancel" of the form dialog and resume.
      confirmDialogRef.componentInstance.onCancel.subscribe(() => {
        confirmDialogRef.close();
        this.hideEditModal = false;
      });
      // Proceed with cancelling the form dialog.
      confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
        confirmDialogRef.close();
        this.dialogRef.close();
      });
    } else {
      // If not dirty, just close.
      this.dialogRef.close();
    }
  }
}
