import { Component } from '@angular/core';
import { ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams } from "ag-grid-community";

@Component({
  templateUrl: './commissions-tooltip.component.html',
  styleUrls: ['../tooltips.scss'],
  standalone: false
})
export class CommissionsTooltipComponent implements ITooltipAngularComp {
  private _params: ITooltipParams;
  public hideToolTip: boolean | undefined = false;

  agInit(params: ITooltipParams): void {
    this._params = params;
    this.hideToolTip = params.value === 0 ||
      params.value === null ||
      params.value === undefined;
  }

}
