import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RemainingDocumentRequirement } from '@app/app/interfaces/document-requirements.model';
import { Store } from '@ngxs/store';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions'

@Component({
  templateUrl: './document-requirement-delete-dialog.component.html',
  styleUrl: './document-requirement-delete-dialog.component.scss',
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule
  ]
})
export class DeleteDocumentRequirementDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { requiredDocument: RemainingDocumentRequirement, dealId: number },
    public dialogRef: MatDialogRef<DeleteDocumentRequirementDialogComponent>,
    private store: Store
  ) { }

  close() {
    this.dialogRef.close();
  }

  deleteRequirement() {
    const { dealId, requiredDocument } = this.data;
    this.store.dispatch(new DA.DeleteDocumentRequirement(dealId, requiredDocument));
    this.close();
  }
}
