<div class="restricted-input" [class.loading]="loading">
  <mat-form-field>
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <span *ngIf="prefix" matPrefix>{{ prefix }}</span>
    <input matInput
      [type]="type"
      [min]="min"
      [max]="max"
      [step]="step"
      [(ngModel)]="value"
      [disabled]="disabled || loading"
      (focus)="selectAll($event)"
      (change)="updateValueFromInput($event)"
    >
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
