import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appGrowAnimation]'
})
export class GrowAnimationDirective implements OnChanges {
  @Input('appGrowAnimation') growAnimation!: boolean;
  state!: boolean;
  startHeight!: number;

  constructor(private _growElementRef: ElementRef) {}
  @HostBinding('@grow')
  get grow() {
    return { value: this.state, params: { startHeight: this.startHeight } };
  }

  setStartHeight() {
    this.startHeight = this._growElementRef?.nativeElement.clientHeight;
  }

  ngOnChanges() {
    this.setStartHeight();
    this.state = !this.state;
  }
}
