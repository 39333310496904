<!-- FACTORING OFFER -->
@if (loanProductCategory == 'Factoring' && !link) {
    <app-factoring-offer-form
        [loading]="loading$ | async"
        [localeCurrencySymbol]='localeCurrencySymbol'
        (newFactoringOffer)="createNewOffer($event)"
    />
}

<!-- REGULAR OFFER -->
@if (loanProductCategory !== 'Factoring' && !link) {
    <app-offer-form
        [loading]="loading$ | async"
        [localeCurrencySymbol]='localeCurrencySymbol'
        [isFundingInterstitial]="isFundingInterstitial"
        (newOffer)="createNewOffer($event)"
    />
}

<!-- OFFER LINK -->
@if (link) {
    <app-offer-link-form
        [loading]="loading$ | async"
        [localeCurrencySymbol]='localeCurrencySymbol'
        (newOfferLink)="createOfferLink($event)"
    />
}

