import { ErrorHandler, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { filter, map } from 'rxjs/operators';
import { AuthState } from '../store/auth/auth.state';

export const saasFeatureGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const router = inject(Router);
  const navigation = router.getCurrentNavigation();

  const saasFeature = route.data?.saasFeature;
  const permission = route.data?.permission;
  const affiliateMediumRequired = route.data?.affiliateMediumRequired;

  if (saasFeature === undefined) {
    inject(ErrorHandler).handleError(
      `Tried to permission guard route: ${route.toString()} without passing a saasFeature alias`
    );
    return of(false);
  }

  const store = inject(Store);

  if (affiliateMediumRequired) {
    const hasRequiredAffiliate = store.selectSnapshot(AuthState.user)?.getChildAffiliate(affiliateMediumRequired);

    if (!hasRequiredAffiliate) {
      return of(false);
    }
  }

  const requiredStateLoaded$ = store.select(SaasFeaturesState.initialLoadComplete);
  const hasAccess$ = store.select(SaasFeaturesState.saasPermitted(saasFeature, permission));

  return combineLatest([requiredStateLoaded$, hasAccess$]).pipe(
    filter(([requiredStateLoaded, hasAccess]) => !!requiredStateLoaded && !!hasAccess),
    map(([requiredStateLoaded, hasAccess]) => requiredStateLoaded && hasAccess)
  );
};
