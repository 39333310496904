import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { Document } from '@app/app/interfaces/document.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from 'ngx-quill';

@Component({
    selector: 'app-document-rejection-dialog',
    templateUrl: './document-rejection-dialog.component.html',
    standalone: true,
    imports: [
      CommonModule,
      MatButtonModule,
      MatDialogModule,
      MatIconModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatInputModule,
      QuillModule
    ]
})

export class DocumentRejectionDialogComponent {
  @Output() onSave = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  quillFormControl = new FormControl('',
    [
      Validators.required,
      Validators.minLength(17), // Really 10 chars, because 7 are the wrapping paragraph tags
      Validators.maxLength(25 * 1024 * 1024) // Roughly 25MB which is Gmail’s limit
    ]
  );
  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['image'],
    ]
  };

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: {
      document: Document,
      dealId: number
    },
    public selfDialogRef: MatDialogRef<DocumentRejectionDialogComponent>,
  ) {}

  save() {
    if (this.quillFormControl.value) {
      this.store.dispatch(new DocumentsActions.RejectDealDocument(this.data.dealId, this.data.document.id, this.quillFormControl.value))
        .subscribe({
          next: () => {
            this.selfDialogRef.close();
            this.onSave.emit();
            this.selfDialogRef.close();
        }
      });
    }
  }

  cancel($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }
}
