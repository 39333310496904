<app-alert
	*ngFor="let alert of alerts"
	[level]="alert.level"
	[message]="alert.message"
	[dismissible]="true">
</app-alert>

<div *ngIf="submitting" class="loading text-center" [@fadeIn] [@fadeOut]>
    <h2>Submitting Offer...</h2>
    <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<form [formGroup]="offerForm" [ngClass]="(submitting ? 'hidden' : '')">
    <div class="lendio-density-1 flex flex-col gap-8">
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Amount</mat-label>
                <span matPrefix class="!pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput
                       type="number"
                       formControlName="amount"
                       required data-cy="amount-input">
                <mat-error data-cy="amount-required"
                           *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched)">
                    Amount is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Payment amount</mat-label>
                <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="paymentAmount" required data-cy="payment-amount-input">
                <mat-error data-cy="payment-amount-required"
                           *ngIf="paymentAmountControl.invalid && (paymentAmountControl.dirty || paymentAmountControl.touched)">
                    Payment amount is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="width-50">
                <mat-label>Payback</mat-label>
                <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="payback" required data-cy="payback-input">
                <mat-error data-cy="payback-required"
                           *ngIf="paybackControl.invalid && (paybackControl.dirty || paybackControl.touched)">
                    Payback is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Term</mat-label>
                <input matInput type="number" formControlName="term" required data-cy="term-input">
                <mat-error data-cy="term-required"
                           *ngIf="termControl.invalid && (termControl.dirty || termControl.touched)">
                    Term is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Rate type</mat-label>
                <mat-select [(value)]="rateType" data-cy="rate-type-select">
                    <mat-option data-cy="buy-rate" [value]="'buy'">Buy Rate</mat-option>
                    <mat-option data-cy="sell-rate" [value]="'sell'">Sell Rate</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Cost type</mat-label>
                <mat-select [(value)]="costType" data-cy="cost-type">
                    <mat-option data-cy="cost-type-apr" [value]="'apr'">APR</mat-option>
                    <mat-option data-cy="cost-type-factor" [value]="'factor'">Factor</mat-option>
                    <mat-option data-cy="cost-type-interest" [value]="'interestRate'">Interest</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Cost</mat-label>
                <input matInput type="number" formControlName="cost" data-cy="cost-input">
                <span matSuffix class="pr-2">%</span>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Origination fee</mat-label>
                <input matInput type="number" formControlName="originationFee" data-cy="origination-fee-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Fee type</mat-label>
                <mat-select [(value)]="originationFeeType" data-cy="origination-fee-select">
                    <mat-option data-cy="origination-fee-dollar" [value]="'$'">{{ localeCurrencySymbol }}</mat-option>
                    <mat-option data-cy="origination-fee-percent" [value]="'%'">%</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Commission</mat-label>
                <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="commission" data-cy="commission-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Position</mat-label>
                <input matInput type="number" formControlName="position" data-cy="position-input">
            </mat-form-field>
        </div>

        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Num. payments</mat-label>
                <input matInput type="number" formControlName="numPayments" data-cy="num-payments-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Date received</mat-label>
                <input matInput
                       [matDatepicker]="dateReceivedPicker2"
                       formControlName="received"
                       required
                       data-cy="received-input"
                       [max]="today">
                <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker2"></mat-datepicker-toggle>
                <mat-datepicker #dateReceivedPicker2></mat-datepicker>
                <mat-error data-cy="received-required"
                           *ngIf="dateReceivedControl.invalid && (dateReceivedControl.dirty || dateReceivedControl.touched) ">
                    Date received is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Payment frequency</mat-label>
                <mat-select formControlName="paymentFrequency" required data-cy="payment-frequency-select">
                    <mat-option data-cy="payment-frequency-daily" [value]="'daily'">Daily</mat-option>
                    <mat-option data-cy="payment-frequency-semi-weekly" [value]="'semiweekly'">Semi-Weekly</mat-option>
                    <mat-option data-cy="payment-frequency-weekly" [value]="'weekly'">Weekly</mat-option>
                    <mat-option data-cy="payment-frequency-bi-weekly" [value]="'biweekly'">Bi-Weekly</mat-option>
                    <mat-option data-cy="payment-frequency-semi-monthly" [value]="'semimonthly'">Semi-Monthly</mat-option>
                    <mat-option data-cy="payment-frequency-select-monthly" [value]="'monthly'">Monthly</mat-option>
                    <mat-option data-cy="payment-frequency-select-bi-monthly" [value]="'bimonthly'">Bi-Monthly</mat-option>
                </mat-select>
                <mat-error data-cy="payment-frequency-required"
                           *ngIf="paymentFrequencyControl.invalid && (paymentFrequencyControl.dirty || paymentFrequencyControl.touched) ">
                    Payment frequency is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Expiration date</mat-label>
                <input matInput
                       [matDatepicker]="expirationDatePicker"
                       formControlName="expiration"
                       data-cy="expiration-input"
                       [min]="today">
                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Net funded amount</mat-label>
                <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="netFundedAmount" data-cy="net-funded-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Points</mat-label>
                <input matInput type="number" formControlName="points" data-cy="points-input">
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="additional-terms">
                <mat-label>Additional terms (optional)</mat-label>
                    <textarea
                        matInput
                        [(ngModel)]="additionalTerms"
                        [ngModelOptions]="{standalone: true}"
                        data-cy="additional-terms-input">
                    </textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="offer-form-actions lendio-density-1">
        <span class="mat-body-2"><span class="align-sub">*</span> required fields</span>
        <div>
            <button mat-button (click)="close(true)" class="mr-2">Cancel</button>
            <button mat-flat-button
                    color="primary"
                    (click)="submit()"
                    [disabled]="offerForm.invalid || submitting"
                    [class.spinner]="submitting"
                    data-cy="offer-submit-btn">
                {{ submitting ? '&nbsp;' : isFundingInterstitial ? 'Add offer and mark Funded' : 'Add offer' }}
            </button>
        </div>
    </div>
</form>

