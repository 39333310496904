<div clas="searchable-select-container">
    <ng-content select="[searchableSelectTrigger]"></ng-content>
    <div class="searchable-select fixed w-[360px] max-h-[368px] z-20">
        <mat-accordion class="borderless" [displayMode]="'flat'" hideToggle>
            <mat-expansion-panel [expanded]="expanded" hideToggle>
                @if (searchable) {
                    <div class="lendio-density-2 mt-4 mb-2">
                        <mat-form-field appearance="outline" class="lendio-no-hint-wrapper w-full">
                            <mat-icon matPrefix>search</mat-icon>
                            <input
                                matInput
                                type="text"
                                placeholder="Search"
                                [(ngModel)]="quickFilterValue"
                                (ngModelChange)="onQuickFilterChange()">
                        </mat-form-field>
                    </div>
                }
                <div class="searchable-select-grid-container mb-4" id="searchableSelectGridContainer">
                    <ag-grid-angular class="ag-theme-quartz searchable-select-grid w-full"
                                    [rowData]="items"
                                    [gridOptions]="gridOptions"
                                    [initialState]="gridInitialState"
                                    (gridReady)="onGridReady($event)"
                                    (selectionChanged)="onSelectionChanged($event)"
                                    (rowDataUpdated)="onRowDataUpdated($event)"
                    >
                    </ag-grid-angular>
                </div>
            <div class="text-sm text-lendio-warm-gray-300">
                {{ selectedRows() ? selectedRows().length : 0 }} selected
            </div>
            <mat-action-row class="lendio-density-2">
                <button (click)="cancel()" mat-stroked-button color="'accent'">Cancel</button>
                <button (click)="apply()" mat-flat-button color="primary" [disabled]="!selectedRows() || !selectedRows().length">Apply</button>
            </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>