import { Component, Input, computed } from '@angular/core';
import { Offer } from 'src/app/interfaces/offer.model';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import {
  NewOfferDialogComponent
} from "@app/app/components/new-offer-display/new-offer-dialog/new-offer-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NavPageTitleService } from "@app/app/services/nav-page-title.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  standalone: false
})
export class OffersComponent {

  @Input() offers: Offer[];
  @Input() sbaLoanNumber: number;
  @Input() dealId: number;
  @Input() loanProductCategory: string;
  @Input() tenantId: number;

  localeCurrencySymbol: string | null;
  displayOriginationToggle: boolean;
  originationDisplayDollars: boolean;
  borrowerName = computed(() => this._pageTitleService.pageTitle());

  emptyType = 'offers-contracts';
  emptyLabelContent = 'There are no offers or contracts here.';

  constructor(
    private localeSettingService: LocaleSettingService,
    private _dialog: MatDialog,
    private _pageTitleService: NavPageTitleService
  ) {
    this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
  }

  get hasOffers(): boolean {
    return this.offers && this.offers.length > 0;
  }

  get noOffers(): boolean {
    return !this.offers || this.offers.length === 0
  }

  get marketplaceTenant(): boolean {
    return this.tenantId === 1
  }

  setOriginationFeeToggleDisplay(toggle: boolean) {
    this.displayOriginationToggle = toggle;
  }

  toggleOriginationFeeDisplayDollars() {
    this.originationDisplayDollars = !this.originationDisplayDollars;
  }

  rateType(offer: Offer) {
    if (offer.factor) {
        return 'factor';
    } else if (offer.interestRate) {
        return 'interestRate';
    } else {
      return 'apr';
    }
  }

  rateTypeName(offer: Offer) {
    if (offer.factor) {
        return 'Factor';
    } else if (offer.interestRate) {
        return 'Interest Rate';
    } else {
        return 'APR';
    }
  }

  paymentFrequencyDisplay(offer: Offer) {
    const paymentFrequencyDisplays = {
      bimonthly: 'Bi-Monthly',
      biweekly: 'Bi-Weekly',
      daily: 'Daily',
      monthly: 'Monthly',
      semimonthly: 'Semi-Monthly',
      weekly: 'Weekly',
      semiweekly: 'Semi-Weekly'
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return paymentFrequencyDisplays[offer.paymentFrequency] || null;
  }

  openMakeOfferDialog(link: boolean = false): void {
    this._dialog.open(NewOfferDialogComponent, {
      data: {
        link,
        dealId: this.dealId,
        loanProductCategory: this.loanProductCategory,
        borrowerName: this.borrowerName()
      }
    })
  }

  fieldsToDisplayByType(offerType, offerLink) {
    if(offerLink) {
      return [
        'offerLink',
        'received'
      ];
    }
    switch(offerType){
      case 'ACH':
        return [
          'amount',
          'payback',
          'factor',
          'numPayments',
          'paymentFrequency',
          'paymentAmount',
          'term',
          'received',
          'expiration',
          'netFundedAmount',
          'notes'
        ];
      case 'Term':
      case 'SBA Standard':
        return [
          'amount',
          'payback',
          'interestRate',
          'numPayments',
          'paymentFrequency',
          'paymentAmount',
          'term',
          'received',
          'expiration',
          'netFundedAmount',
          'notes'
        ];
      case 'LOC':
        return [
          'amount',
          'interestRate',
          'paymentFrequency',
          'paymentAmount',
          'term',
          'received',
          'expiration',
          'netFundedAmount',
          'notes'
        ];
      case 'Factoring':
        return [
          'amount',
          'advanceRate',
          'minPurchaseFee',
          'term',
          'commission',
          'points',
          'paymentTerm',
          'received',
          'originationFee'
        ];
      default:
        return [
          'amount',
          'payback',
          'rateType',
          'variableRate',
          'commission',
          'points',
          'numPayments',
          'paymentFrequency',
          'paymentAmount',
          'term',
          'originationFee',
          'position',
          'received',
          'expiration',
          'netFundedAmount',
          'notes'
        ];
    }
  }

  variableFieldName(field, offerType) {
    switch(field){
      case 'amount':
        if(offerType == 'Factoring'){
          return 'Facility Amount';
        } else {
          return 'Amount'
        }
      case 'term':
        if(offerType == 'Factoring'){
          return 'Contract Duration';
        } else {
          return 'Term'
        }
      default:
        return field;
    }
  }
}
