import { Contact } from '@app/app/interfaces/contact.model';
import { BorrowerValues } from './borrower-value.model';
import { Deal } from './deal.model';

export enum BusinessAccessLevel {
  Modify = 'modify',
  View = 'view',
  None = 'none',
}

export interface Business {
  id: number; // Borrower ID
  // Lender ID tells us if this borrower is Lender Saas or Marketplace (44566).
  lenderId?: number;
  tenantId: number | null;
  activityType?: string | null;
  name: string;
  first?: string;
  last?: string;
  phone?: string;
  email?: string;
  street?: string;
  street2?: string;
  stateId?: string;
  zipId?: string;
  preapprovalId?: number;
  appLink: string | null;
  tmpPassword: string | null;
  maxLineAmount?: number;
  stage: string;
  status: string;
  dealStatus?: string;
  created?: string;
  lastActivity: {
    raw: number; // unix timestamp
    ago: string; // "2 hours ago", "Yesterday", "2 days ago", "Last week"
    formatted: string; // "July 1, 2023 at 9:00 am"
  };
  dealLastActivity?: {
    raw: number | null; // unix timestamp
    ago: string | null; // "2 hours ago", "Yesterday", "2 days ago", "Last week"
    formatted: string | null; // "July 1, 2023 at 9:00 am"
  };
  offerLastActivity?: {
    raw: number | null; // unix timestamp
    ago: string | null; // "2 hours ago", "Yesterday", "2 days ago", "Last week"
    formatted: string | null; // "July 1, 2023 at 9:00 am"
  };
  deals: Deal[];
  isPreapproved: boolean;
  preapprovalExpiration?: string; // ISO 8601 (YYYY-MM-DD HH:MM:SS) timestamp
  preapprovalGenerated?: string; // ISO 8601 (YYYY-MM-DD HH:MM:SS) timestamp
  assignedEmployeeEmail?: string;
  lendersBusinessId?: string;
  borrowerValues: BorrowerValues;
  primaryContact?: Partial<Contact>;
  accessLevel: BusinessAccessLevel;
  isEmbedded?: boolean;
  [key: string]:
    | string
    | boolean
    | number
    | BorrowerValues
    | object
    | null
    | undefined;
}

export interface ShareBusiness {
  borrowerId: number;
  sharedWithId: number;
}
