<div>
    <div class="mb-6">
        <div class="!mb-6">
            <h2 class="mat-headline-4 !m-0 !mb-4">Verify your account.</h2>
            <p class="mat-body-1">Enter the 6-digit code sent to your email.</p>
        </div>
        <div *ngIf="alert" class="mb-4">
            <app-alert
                [level]="alert.level"
                [message]="alert.message"
                [dismissible]="true"
                [showIcon]="false"
                [dismissDelay]="5000"
                (dismissed)="dismissAlert()">
            </app-alert>
        </div>
        <app-mfa-code-input
            [invalid]="codeInvalid"
            (code)="verifyCode($event)"
        ></app-mfa-code-input>
        <div *ngIf="skipChallengeMessage" class="checkbox-container !mt-1.5">
            <mat-checkbox color="primary" [(ngModel)]="skipChallenge" [ngModelOptions]="{standalone: true}">
                <span class="mat-body-1">Don't challenge me for {{skipChallengeMessage}}</span>
            </mat-checkbox>
        </div>
    </div>
    <div class="flex mt-6 mb-1 items-center justify-between align-items-center">
        <span class="mat-body-1 fw-700 !mb-0">Didn't receive your code?</span>
        <button
            data-cy="resend-mfa-code-button"
            id="resend-mfa-code-button"
            mat-button
            color="primary"
            (click)="resendCode()"
            [disabled]="countdown > 0"
        >
            <span class="mat-body-1">Resend code</span>
        </button>
    </div>
    <span *ngIf="countdown" class="mat-body-1">You can resend your code in {{ countdown }} seconds</span>
</div>
