/**
 * Namespace the actions so we can use succinct action names.
 */

import { DealDocument } from "@app/app/interfaces/deal-document.model";
import { DocumentRequirementItem } from "@app/app/interfaces/document-requirement-item.model";
import { DocumentRequirementRequest, RemainingDocumentRequirement } from "@app/app/interfaces/document-requirements.model";
import { Document } from "@app/app/interfaces/document.model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DocumentsActions {

  // Get a single document by ID.
  export class GetDocument {
    static readonly type = '[Documents] GetDocument';
    constructor(
      public documentId: number,
    ) { }
  }

  // Get documents by borrower.
  export class GetBorrowerDocuments{
    static readonly type = '[Documents] GetBorrowerDocuments';
    constructor(
      public borrowerId: number,
      public borrowerLenderId: number,
    ) { }
  }
  export class Post {
    static readonly type = '[Documents] Post';
    constructor(public file: File, public formData: FormData) { }
  }

  export class PostDocumentRequirements {
    static readonly type = '[Documents] PostDocumentRequirement';
    constructor(
      public dealId: number,
      public documentRequirements: DocumentRequirementRequest
    ) { }
  }

  export class DeleteDocumentRequirement {
    static readonly type = '[Documents] DeleteDocumentRequirement';
    constructor(
      public dealId: number,
      public documentRequirement: RemainingDocumentRequirement
    ) { }
  }
  export class Put {
    static readonly type = '[Documents] Put';
    constructor(public document: Document) { }
  }

  export class PatchDealDocument {
    static readonly type = '[Documents] PatchDealDocument';
    constructor(public dealId: number, public documentUpdates: Partial<Document>) { }
  }

  export class Delete {
    static readonly type = '[Documents] Delete';
    constructor(public id: number) { }
  }

  export class DeleteDealDocument {
    static readonly type = '[Documents] DeleteDealDocument';
    constructor(public dealId: number, public documentId: number) { }
  }

  // By Deal.
  export class GetDealDocuments {
    static readonly type = '[Documents] GetDealDocuments';
    constructor(
      public dealId: number,
      public borrowerLenderId: number,
    ) { }
  }

  export class GetAdvancedDealDocuments {
    static readonly type = '[Documents] GetAdvancedDealDocuments';
    constructor(
      public dealId: number,
      public borrowerLenderId: number
    ) { }
  }

  export class AcceptDealDocument {
    static readonly type = '[Documents] AcceptDealDocument';
    constructor(
      public dealId: number,
      public documentId: number
    ) { }
  }

  export class RejectDealDocument {
    static readonly type = '[Documents] RejectDealDocument';
    constructor(
      public dealId: number,
      public documentId: number,
      public reason: string
    ) { }
  }

  export class ClearDealDocumentStatus {
    static readonly type = '[Documents] ClearDealDocumentStatus';
    constructor(
      public dealId: number,
      public documentId: number
    ) { }
  }

  // Document categories for external lenders.
  export class GetDocumentCategories {
    static readonly type = '[Documents] GetDocumentCategories';
    constructor() { }
  }

  export class ClearDocumentsState {
    static readonly type = '[Documents] ClearDocuments';
    constructor() { }
  }

  export class SubscribeToDocumentStateUpdates {
    static readonly type = '[Documents] SubscribeToDocumentStateUpdates';
    constructor(
      public borrowerId: number,
      public dealId?: number
    ) { }
  }

  export class UnsubscribeFromDocumentStateUpdates {
    static readonly type = '[Documents] UnsubscribeFromDocumentStateUpdates';
    constructor(
      public borrowerId: number,
      public dealId?: number
    ) { }
  }

  export class PatchDocumentState {
    static readonly type = '[Documents] PatchDocumentState';
    constructor(public document: Document | null, public dealDocument?: DealDocument) { }
  }

  export class DeleteDocumentState {
    static readonly type = '[Documents] DeleteDocumentState';
    constructor(public documentId: number) { }
  }

  export class GetDocumentRequirements {
    static readonly type = '[Documents] GetDocumentRequirements';
    constructor(public dealId: number) { }
  }
}
