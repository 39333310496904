import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { LendioSnackBarOptions } from './lendio-snackbar-options'
import { CommonModule } from "@angular/common";
import { SentenceCasePipe } from "@app/app/pipes/sentence-case.pipe";
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lendio-snackbar',
  imports: [CommonModule, SentenceCasePipe, MatIconModule],
  templateUrl: './lendio-snackbar.component.html',
  styleUrls: ['./lendio-snackbar.component.scss']
})
export class LendioSnackbarComponent {
  @ViewChild('lendioSnackBar') lendioSnackBarRef!: ElementRef;
  canDismiss = false;
  title?: string;
  message?: string;
  actionLabel?: string;
  action?: () => void;
  prefixType?: string;
  prefixImgSrc?: string;
  prefixLabel?: string;
  prefixBackgroundColor?: string;
  prefixMatIconLabel?: string;
  prefixMatIconColor?: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: LendioSnackBarOptions,
    private _snackBarRef: MatSnackBarRef<LendioSnackbarComponent>
  ) {
    this.title = data.title;
    this.message = data.message;
    this.actionLabel = data.action?.label;
    this.action = data.action?.cb;
    this.canDismiss = typeof data.canDismiss === "boolean" ? data.canDismiss : false;
    this.prefixType = data.prefix?.type;
    this.prefixImgSrc = data.prefix?.imageSrc;
    this.prefixLabel = data.prefix?.label;
    this.prefixBackgroundColor = data.prefix?.backgroundColor;
    this.prefixMatIconLabel = data.prefix?.matIconLabel;
    this.prefixMatIconColor = data.prefix?.matIconColor;
  }

  snackBarAction(): void {
    if (this.action) {
      this.action();
    }
  }

  snackBarDismiss(): void {
    this._snackBarRef.dismiss();
  }

  get innerHeight(): number {
    if (this.lendioSnackBarRef) {
      return this.lendioSnackBarRef?.nativeElement.clientHeight;
    }
    return 0;
  }

  get tallSnack(): boolean {
    return this.innerHeight > 72;
  }


}
