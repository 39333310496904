import type { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import type { INoRowsOverlayParams } from 'ag-grid-community';
import { Component, signal } from '@angular/core';

@Component({
  templateUrl: './no-documents-overlay.component.html'
})
export class NoDocumentsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams;
  public noDocumentsMatchFilters = signal<boolean>(false);
  public filtersChecked = signal<boolean>(false);

  agInit(params: INoRowsOverlayParams): void {
    const filterModelKeys = Object.keys(params.api.getFilterModel());
    const quickFilter = params.api.getQuickFilter();
    if (filterModelKeys.length > 0 || quickFilter) {
      this.noDocumentsMatchFilters.set(true);
    }
    this.filtersChecked.set(true);
    this.refresh(params);
  }

  refresh(params: INoRowsOverlayParams): void {
    this.params = params;
  }
}
