import { Component, computed, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { catchError, take, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';
import { LoginUser } from 'src/app/interfaces/login-user.model';
import { AuthState } from '@app/app/store/auth/auth.state';
import { UpdateAuthUser } from '@app/app/store/auth/auth.actions';

@Component({
  selector: 'app-manage-sba-token',
  templateUrl: './manage-sba-token.component.html',
  styleUrls: ['./manage-sba-token.component.scss'],
  standalone: false
})
export class ManageSbaTokenComponent implements OnInit {

  submitting = false;
  currentSbaToken: string | undefined;
  sbaToken: string;
  sbaTokenForm: FormGroup;
  sbaTokenPatternRegex = /^\b[0-9A-Fa-f]{40}\b/;
  user?: LoginUser;

  constructor(
    private settingsService: SettingsService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.user = this.store.selectSnapshot(AuthState.user);
    this.currentSbaToken = this.user!.institution!.sbaTokenGuarded;
    this.sbaTokenForm = new FormGroup({
      sbaToken: new FormControl(this.sbaToken, [
        Validators.required,
        Validators.pattern(this.sbaTokenPatternRegex)
      ])
    });
  }

  submit() {
    this.submitting = true;
    this.settingsService.newSbaToken(this.sbaTokenForm.value, this.user!.institution!.id!).pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Failed to update your SBA Token. Please try again.'
      }));
      this.resetForm();
      return throwError(err);
    }), take(1), tap((res: any) => {
      if (res.data.sbaTokenGuarded) {
        this.store.dispatch(new CreateNewAlert({
          level: 'success',
          message: 'Your SBA Token has been updated!'
        }));
        const user = this.store.selectSnapshot(AuthState.user);
        if (user && user.institution) {
          user.institution.sbaTokenGuarded = res.data.sbaTokenGuarded;
          this.store.dispatch(new UpdateAuthUser(user));
        }
        this.currentSbaToken = res.data.sbaTokenGuarded;
      } else {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to update your SBA Token. Please try again.'
        }));
      }
      this.resetForm();
    })).subscribe();
  }

  checkError(controlName: string, errorName: string) {
    return this.sbaTokenForm.controls[controlName].hasError(errorName);
  }

  atLeastOneFieldFilledIn() {
    return this.sbaTokenForm.value.sbaToken !== null;
  }

  resetForm() {
    this.sbaTokenForm.reset();
    Object.keys(this.sbaTokenForm.controls).forEach(key => {
      this.sbaTokenForm.controls[key].setErrors(null);
    });
    this.submitting = false;
  }
}
