import { Affiliate } from "@app/app/interfaces/affiliate.model";
import { Institution } from "@app/app/interfaces/institution.model";
import { LoginUser } from "@app/app/interfaces/login-user.model";

const e2eUserEmails = [
  'e2e_bank+admin@lendio.com',
  'lendiotest+e2eplaywright@lendio.com'
];
export class AuthUser implements LoginUser {
  accessToken?: string;
  calculatedPermissions?: string;
  email?: string;
  first?: string;
  last?: string;
  fullName?: string;
  role?: string;
  id?: number;
  tenantId?: number;
  institution?: Institution;
  isSbaSpecialist?: boolean;
  affiliate?: {
    id?: number | null;
    name?: string | null;
    medium?: string | null;
  } | null;
  affiliate_children?: Affiliate[];
  password?: string;
  permissions?: {
    canHijack?: boolean;
    canManageLenderUsers?: boolean;
    canViewRequests?: boolean;
    canUploadLoanForgivenessCSV?: boolean;
    canFund?: boolean;
    hasWalleAccount?: boolean;
    manageSbaToken?: boolean;
  };
  permissionsList?: string[];
  ppp?: boolean;

  constructor(user: LoginUser) {
    this.accessToken = user.accessToken;
    this.calculatedPermissions = user.calculatedPermissions;
    this.email = user.email;
    this.first = user.first;
    this.last = user.last;
    this.fullName = user.fullName;
    this.role = user.role;
    this.id = user.id;
    this.tenantId = user.tenantId;
    this.institution = user.institution;
    this.affiliate = user.affiliate;
    this.affiliate_children = user.affiliate_children;
    this.password = user.password;
    this.permissions = user.permissions;
    this.permissionsList = user.permissionsList;
    this.ppp = user.ppp;
    this.isSbaSpecialist = !!user.isSbaSpecialist;
  }

  isPartner(): boolean {
    if (
      (!Array.isArray(this.permissionsList) || this.permissionsList.length > 0)
      && (this.permissionsList?.includes('affiliate.read') || this.permissionsList?.includes('affiliate.write'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  isLender(): boolean {
    return !!this.institution?.id;
  }

  hasEmbeddedChildAffiliate(): boolean {
    return !!this.getEmbeddedChildAffiliate();
  }

  getEmbeddedChildAffiliate(): Affiliate | undefined {
    return this.affiliate_children?.find(a => a.medium === "Embedded")
  }

  getIntelligentChildAffiliate(): Affiliate | undefined {
    return this.affiliate_children?.find(a => a.medium === "Intelligent Lending");
  }

  getChildAffiliate(medium: string): Affiliate | undefined {
    return this.affiliate_children?.find(a => a.medium === medium);
  }

  getChildAffiliateTypes(): string[] {
    return this.affiliate_children?.map(a => a.medium) ?? [];
  }

  hasIntelligentChildAffiliate(): boolean {
    return !!this.getIntelligentChildAffiliate();
  }

  isReferralPartner(): boolean {
    return !this.isLender() && this.affiliate != null;
  }

  permittedTo(permission: string): boolean {
    return this.permissionsList?.includes(permission) ?? false;
  }

  isE2EUser(): boolean {
    return e2eUserEmails.includes(this.email ?? '');
  }
}
