import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-note-dialog',
  templateUrl: './create-note-dialog.component.html',
  styleUrls: ['./create-note-dialog.component.scss'],
  standalone: false
})
export class CreateNoteDialogComponent implements OnInit {

  @Output() saveNote = new EventEmitter<string>();

  noteContent: string;

  constructor(public dialogRef: MatDialogRef<CreateNoteDialogComponent>) { }

  ngOnInit(): void {
  }

}
