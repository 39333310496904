import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DisplayValue } from '@app/app/components/businesses/business-values/business-values.component';
import { Store } from '@ngxs/store';
import { map, Observable, Subject } from 'rxjs';
import { CommercialCreditReport, ConsumerCreditReport } from '@app/app/interfaces/credit-reports/credit-report.model';
import { FinancesState } from '@app/app/store/finances/finances.state';
import {
  GetCommercialCreditReport,
  GetOwnerCreditReport,
  GetSavedOwnerReports,
} from '@app/app/store/finances/finances.actions';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { Business } from '@app/app/interfaces/business.model';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { BorrowerValue } from '@app/app/interfaces/borrower-value.model';
import { Contact } from '@app/app/interfaces/contact.model';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { ActivatedRoute } from '@angular/router';

interface OutstandingLoan {
  position: number;
  lenderName: string;
  balance: number;
  updatedDate: string;
  paidOff: boolean;
}

@Component({
  selector: 'app-finances-page',
  templateUrl: 'finances-page.component.html',
  styleUrls: ['finances-page.component.scss'],
  standalone: false
})
export class FinancesPageComponent implements OnInit, OnDestroy {

  performanceAliases = [
    'annualRevenue', // 72
    // 'MONTHLY REVENUE - PROBABLY CALCULATED',
    'averageMonthlyPayroll', // 862
    'annual_profits', // 4
    'average_monthly_sales', // 466
    'leasemortgage_payment', // 242
    // 'OUTSTANDING INVOICES',
    'acceptsCredit', // 723
    'monthlyCharges', // 76
    'businessDebtAmount', // 766
    'monthlyDebtPayments', // 769
  ];

  creditDetailsAliases = [
    'publicRecordsCount', // 753
    'installmentBalance', // 754
    'revolvingBalance', // 755
    'realEstateBalance', // 756
    'revolvingAvailablePercent', // 757
    'delinquenciesOver30Days', // 760
    'delinquenciesOver60Days', // 761
    'delinquenciesOver90Days', // 762
    'derogCounter',  // 763
    'inquiriesDuringLast6Months', // 758
    'monthlyPaymentOnInstallmentTradelines', // 1886
    'totalTradeItems', // 759
  ];

  personalCredit$: Observable<ConsumerCreditReport>;
  commercialCredit$: Observable<CommercialCreditReport> = this.store.select( FinancesState.commercialCredit );
  business$: Observable<Business | null> = this.store.select( BusinessState.business );
  outstandingLoans$: Observable<OutstandingLoan[]> = this.store.select( FinancesState.outstandingLoans );
  outstandingLoansDataSource$: Observable<MatTableDataSource<any>>;
  performanceValues$: Observable<DisplayValue[]>;
  ownerContacts$: Observable<Contact[]> = this.store.select( ContactsState.ownerContacts );
  primaryContact$: Observable<Contact | undefined> = this.store.select( ContactsState.primaryContact );
  ownersCreditReports$: Observable<ConsumerCreditReport[]> = this.store.select( FinancesState.ownersCreditReports );
  loading$: Observable<boolean> = this.store.select( FinancesState.loading );
  financesStateLoading$: Observable<boolean> = this.store.select( FinancesState.loading );
  destroyed$ = new Subject<boolean>();
  /**
   * Temporarily hidden until we can pass data about the Lender to determine
   * if they have Experian credentials.
   */
  showCommercial: boolean = false;

  outstandingLoansTableColumns = [
    'position',
    'lenderName',
    'balance',
    'updatedDate',
    'paidOff',
  ];

  businessId;
  borrowerValuePercentOwnership: BorrowerValue | undefined;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.borrowerValuePercentOwnership = this.store.selectSnapshot( BusinessState.borrowerValueByAlias('percentOwnership'));
    this.performanceValues$ = this.store.select( BusinessState.borrowerValuesByAliases(this.performanceAliases) ).pipe(
      takeUntilDestroyed(),
      map( values => {
        return values.map( ({shortName, type, value}) => ({
          label: shortName!,
          type,
          value,
        }));
      }),
    );
  }

  ngOnInit():void  {
    this.route.url.subscribe(segments => {
      this.businessId = segments[segments.length - 1].toString();
      this.store.dispatch(new DA.SubscribeToDocumentStateUpdates(parseInt(this.businessId)));
    });

    this.business$.pipe(
      takeUntil(this.destroyed$),
      filter(business => !!business?.id)
    ).subscribe( business => {
      business?.id
        ? this.store.dispatch( new GetSavedOwnerReports(business.id))
        : false;
    });

    this.ownersCreditReports$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe( report => {
    });
    this.outstandingLoansDataSource$ = this.outstandingLoans$.pipe(
      map(loans => {
        return new MatTableDataSource(loans);
      }),
    );
  }

  /**
   * Default value for a primaryContact created through Lead class /
   * application signup is NULL.  The initial value collected for
   * percentOwnership is a BorrowerValue.  In the case that a primaryContact
   * has no percentOwnership value, let's default to the BV.
   */
  percentOwnership(contact: Contact): number {
    // Let's respect the possibility of 0 and not default to the borrowerValue.
    if (typeof contact.percentOwnership === 'number') {
      return contact.percentOwnership;
    }

    const primaryContactPercentage = this.borrowerValuePercentOwnership?.value ?? '0';

    return contact.isPrimary ? parseInt(primaryContactPercentage) : 0;
  }

  pullCommercialCredit() {
    const dialogRef = this.dialog.open( ConfirmDialogComponent, {
      data: {
        title: 'Are you sure you want to pull credit?',
        description: 'Hard credit inquiries usually show on credit reports and impact credit scores.',
        confirmLabel: 'Pull credit'
      }
    });

    dialogRef.componentInstance.onCancel.pipe(
      take(1),
    ).subscribe( () => dialogRef.close() );
    dialogRef.componentInstance.onConfirm.pipe(
      take(1),
    ).subscribe( () => {
      dialogRef.close();
      this.business$.pipe(
        take(1),
      ).subscribe( business => {
        !!business?.id ? this.store.dispatch( new GetCommercialCreditReport(business.id) ) : false;
      });
    });
  }

  pullOwnerCredit(ownerContactId: number) {
    this.store.dispatch( new GetOwnerCreditReport(ownerContactId));
  }

  ngOnDestroy() {
    this.store.dispatch(new DA.UnsubscribeFromDocumentStateUpdates(this.businessId));
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
