<div *ngIf="signers.length > 0">
    <h2 data-cy="esign-status-title">E-signatures </h2>
    <div class="p-2 signer-border mb-2 grid grid-cols-3 gap-1"
         *ngFor="let signer of signers"
    >
        <div class="flex items-center">
            <mat-icon *ngIf="signer.hasSigned; else incomplete" class="material-symbols-outlined green-check ">done</mat-icon>
            <ng-template #incomplete>
                <mat-icon class="material-symbols-outlined gray-line">check_indeterminate_small</mat-icon>
            </ng-template>
        </div>

        <div class="grid grid-rows-2 styled-column">
            <div class="signer-name">{{signer.name}}</div>
            <div matTooltip="{{ signer.email }}" class="mat-caption gray-text hide-overflow">{{ signer.email }}</div>
        </div>
        <div *ngIf="signer.hasSigned; else notSigned" class="grid grid-rows-2 gap-0 styled-column">
                <div class="justify-self-end signer-name">Complete</div>
                <div matTooltip="Completed on {{signer.dateSigned | date:'MMMM d, y \'at\' h:mm a'}}"
                     class="mat-caption gray-text justify-self-end"
                >
                    {{timeAgo(signer.dateSigned)}}
                </div>
        </div>
        <ng-template #notSigned>
            <div class="grid grid-rows-2 gap-0 styled-column">
                <div class="justify-self-end signer-name" data-cy="signer-status">Incomplete</div>
                <div matTooltip="Sent on {{signer.dateSent | date:'MMMM d, y \'at\' h:mm a'}}"
                     class="mat-caption gray-text justify-self-end"
                >
                    {{timeAgo(signer.dateSent)}}
                </div>
            </div>
        </ng-template>
    </div>
</div>


