@if (!(importDetailsActive$ | async)) {
    <nav class="min-h-[48px]"
         mat-tab-nav-bar
         [tabPanel]="administrationNavTabPanelNew">
        @for (link of (featuresConfig$ | async); track link) {
                <a mat-tab-link
                   routerLinkActive
                   #rla="routerLinkActive"
                   *ngIf="link.permitted"
                   [active]="rla.isActive"
                   [routerLink]="link.link">
                    {{ link.label }}
                </a>
        }
        @if (userCanViewDemoTab) {
            <a mat-tab-link
               routerLinkActive
               #rla="routerLinkActive"
               [active]="rla.isActive"
               [routerLink]="'makeCsv'">
                Demo
            </a>
        }
    </nav>
}

<mat-tab-nav-panel #administrationNavTabPanelNew>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
