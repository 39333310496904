import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundingDeskSmartComponent } from './funding-desk-smart/funding-desk-smart.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { SingleRequestChainOldComponent } from './single-request-chain-old/single-request-chain-old.component';
import { SingleRequestChainComponent } from './single-request-chain/single-request-chain.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FundingDeskComponent } from './funding-desk/funding-desk.component';
import { MatIconModule } from '@angular/material/icon';
import {FocusOnInitDirective} from "@app/app/directives/focus-on-init.directive";
import {
  FundingDeskSidebarComponent
} from "@app/app/components/funding-desk/funding-desk-sidebar/funding-desk-sidebar.component";
import {EmptyStateComponent} from "@app/app/components/empty-state/empty-state.component";
import {TextareaAutoresizeDirective} from "@app/app/directives/textarea-autoresize.directive";

@NgModule({
  declarations: [
    FundingDeskSmartComponent,
    FundingDeskComponent,
    SingleRequestChainOldComponent,
    SingleRequestChainComponent,
    UserAvatarComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    FocusOnInitDirective,
    FundingDeskSidebarComponent,
    EmptyStateComponent,
    TextareaAutoresizeDirective,
  ],
  exports: [
    FundingDeskSmartComponent
  ]
})
export class FundingDeskModule { }
