<div data-cy="edit-document-dialog" *ngIf="editDocumentForm" [formGroup]="editDocumentForm">
    <div class="flex justify-between items-center">
        <h2 mat-dialog-title class="!pb-4" data-cy="cancel-edit-document-header">
            Edit details
        </h2>
        <button (click)="cancel($event)" class="pr-6">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="grid p-2">
            <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-8">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title" type="text" data-cy="title">
                <mat-error *ngIf="editDocumentForm.get('title')?.errors">
                    Please enter a document title
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-16">
                <mat-label>Type</mat-label>
                <mat-select formControlName="category" data-cy="category"
                    [(value)]="selected"
                >
                    <mat-option *ngFor="let option of (categories$ | async)" [value]="option.id">{{ option.name }}</mat-option>
                </mat-select>
                <mat-hint>Types include bank statement, signed contract, etc.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="editDocumentForm.get('category').value === 'bankStatement'" appearance="outline" floatLabel="auto" class="w-full mb-8">
                <mat-label>For month</mat-label>
                <mat-select formControlName="monthsString" 
                                data-cy="monthsString"
                                required
                    >
                    @for (month of last24Months; track month;) {
                        <mat-option [value]="month.value">
                            {{ month.display }}
                        </mat-option>
                    }
                    </mat-select>
                <mat-hint>Provide a month of the bank statement</mat-hint>
                <mat-error *ngIf="editDocumentForm.get('monthsString')?.errors">Enter a valid month and year (within last 13 months) as MM/YYYY</mat-error>
            </mat-form-field>
            @if (categoriesRequiringYear.includes(editDocumentForm.get('category').value)) {
                <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-8 -mt-8">
                    <mat-label>For year</mat-label>
                    <mat-select formControlName="yearsString" 
                                data-cy="yearsString"
                                required
                    >
                    @for (year of years; track year; let i = $index;) {
                        <!-- // Do not show current year when category is tax return -->
                        @if (!editDocumentForm.get('category').value.match('TaxReturn') || i > 0) {
                            <mat-option [value]="year">
                                {{ year }}
                            </mat-option>
                        }
                    }
                    </mat-select>
                <mat-error *ngIf="editDocumentForm.get('yearsString')?.errors">Enter a valid year</mat-error>
                </mat-form-field>
            }
        </div>
    </mat-dialog-content>

    <mat-divider class="!border-t-2"></mat-divider>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="accent" class="!mt-4" (click)="cancel($event)">
            Cancel
        </button>
        <button mat-flat-button color="primary" class="!ml-4 !mt-4"
            data-cy="business-details-document-save-btn"
            [disabled]="!editDocumentForm.dirty || !editDocumentForm.valid"
            (click)="save(editDocumentForm)"
        >
            Save
        </button>
    </mat-dialog-actions>
</div>
