import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('pulse', [
      state('start', style({ opacity: 1 })),
      state('end', style({ opacity: 0.8 })),
      transition('start <=> end', animate('500ms ease-in-out')),
    ]),
    trigger('slideInScaleFromBottomRight', [
      state('void', style({ transform: 'translate(30%, 30%) scale(0)', opacity: 0 })),
      state('in', style({ transform: 'translate(0, 0) scale(1)', opacity: 1 })),
      transition('void => in', animate('0.3s ease-in')),
      transition('in => void', animate('0.3s ease-out')),
    ]),
  ],
  standalone: false
})
export class LoadingComponent {

    pulseState = 'start';
    slideInState: 'in' | 'void' = 'void';

    constructor() {
      setInterval(() => {
        this.pulseState = this.pulseState === 'start' ? 'end' : 'start';
      }, 500);
      setTimeout(() => {
        this.slideInState = 'in';
      }, 100);
    }
}
