@if (filtersChecked()) {
    <div class="flex flex-col justify-center items-center my-auto h-full">
        @if (noItemsMatchFilters()) {
            <div class="mat-subtitle-2">No results were found.</div>
            <div class="mat-body-2">Please try adjusting your search.</div>

        } @else {
            <div class="mat-subtitle-2">Empty.</div>
        }
    </div>
}
