import { Business, ShareBusiness } from '../interfaces/business.model';
import { Injectable } from '@angular/core';
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { map } from 'rxjs';

/**
 * CRUD API requests for borrowers (called businesses in this UI).
 *
 * If you need anything besides what comes with the base class (create, get,
 * getIndex, update, delete), you can add it here.
 *
 * For the businesses-list (that would normally be getIndex), use that separate
 * service and store since it doesn't follow standard entity patterns.
 **/
@Injectable()
export class BusinessService {
  baseUrl = `${environment.apiUrl}/lender-portal/businesses`;

  constructor(private http: HttpClient) {}

  getBusiness(businessId: number) {
    return this.http.get<LendioResponse>(`${this.baseUrl}/${businessId}`)
      .pipe(map(response => response.data as Business));
  }

  updateBusiness(business: Business) {
    return this.http.put<LendioResponse>(`${this.baseUrl}/${business.id}`, business)
      .pipe(map((response) => response.data as Business));
  }

  shareBusiness(shareBusiness: ShareBusiness) {
    return this.http.post<LendioResponse>(`${this.baseUrl}/share-borrower`, shareBusiness);
  }

  deleteShareBusiness(shareBusiness: ShareBusiness) {
    return this.http.delete<LendioResponse>(`${this.baseUrl}/share-borrower`, {
      body: shareBusiness
    });
  }
}
