<div class="restricted-radio" [class.loading]="loading">
  <mat-radio-group [(ngModel)]="value" aria-label="Select an option">
      @for (option of options; track option) {
          <mat-radio-button
            color="primary"
            [value]="option.value"
            [disabled]="optionDisabled(option) || disabled || loading"
            (change)="valueChange.emit($event.value)">
            {{ option.label }}
          </mat-radio-button>
      }
  </mat-radio-group>
</div>
