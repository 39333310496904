import { Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
} from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExpirationDialogComponent } from './expiration-dialog/expiration-dialog.component';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { environment } from '@app/environments/environment';
import { Store } from '@ngxs/store';
import * as _ from 'underscore';
import { Alert } from '../interfaces/alert.model';
import { Logout } from '@app/app/store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  sessionTimeout = 120; // Session expiration, in minutes
  apiUrl: string = environment.apiUrl;
  userInactive: Subject<any> = new Subject<any>();
  sessionExpired: Subject<any> = new Subject<any>();
  alert: BehaviorSubject<Alert | null> = new BehaviorSubject(null);

  private userActivity: NodeJS.Timeout;
  private sessionExpiration: NodeJS.Timeout;
  private timeoutWarning = 5; // 5 minutes prior to a 30 minute expiration
  private timeoutDialogOpened = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    public store: Store,
  ) {
    this.timeoutDialogOpened = false;
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        localStorage.setItem('Session', (Math.round(Date.now() / 1000)).toString());
        if (sessionStorage.getItem('Forbidden')) {
          sessionStorage.removeItem('Forbidden');
        }
        if (this.outlet) {
          this.outlet.deactivate();
        }
      }
    });
    this.userInactive.subscribe(() => {
      const sessionDialogDismissed = localStorage.getItem('SessionWarningDismissed');
      if (sessionDialogDismissed === 'true') {
        return;
      }
      this.timeoutDialogOpened = true;
      const dialogRef = this.dialog.open(ExpirationDialogComponent, {
        width: '400px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((response: any) => {
        this.timeoutDialogOpened = false;
        if (response === true) {
          this.setTimeout();
          return;
        }
        if (response === false) {
          clearTimeout(this.sessionExpiration);
          this.store.dispatch(new Logout());
          return;
        }
      });
    });
    this.sessionExpired.subscribe(() => {
      const msg = 'Your session timed out due to inactivity. Please re-enter your email and password to sign in.';
      this.dialog.closeAll();
      this.snackBar.dismiss();
      localStorage.setItem('LoginAlert', msg);
      this.store.dispatch(new Logout());
    });
    this.setSession = _.debounce(this.setSession, 150);
  }

  public setTimeout(): void {
    if (this.timeoutDialogOpened || !this.store.selectSnapshot(AuthState.user)) {
      return;
    }
    const expireTime = (this.sessionTimeout * 60000) - 5000;
    const warningTime = expireTime - (this.timeoutWarning * 60000);
    if (this.userActivity) {
      clearTimeout(this.userActivity);
    }
    if (this.sessionExpiration) {
      clearTimeout(this.sessionExpiration);
    }
    this.setSession();
    this.userActivity = setTimeout(() => {
      if (this.store.selectSnapshot(AuthState.user)) {
        this.userInactive.next(undefined);
      }
    }, warningTime);
    this.sessionExpiration = setTimeout(() => {
      if (this.store.selectSnapshot(AuthState.user)) {
        this.sessionExpired.next(undefined);
      }
    }, expireTime);
  }

  public clearSession(): void {
    clearTimeout(this.userActivity);
    clearTimeout(this.sessionExpiration);
    localStorage.removeItem('Session');
  }

  private setSession(): void {
    localStorage.setItem('Session', (Math.round(Date.now() / 1000)).toString());
  }
}
