<div class="lendio-density-1">
    <mat-form-field appearance="outline"
                    color="accent">
         <mat-icon matPrefix
                   color="accent"
                   [matMenuTriggerFor]="partnersMenu"
                   #partnersTrigger="matMenuTrigger">
             <span>search</span>
        </mat-icon>
        <input
            [(ngModel)]="inputText"
            (keyup)="search()"
            data-cy="hijack-input"
            placeholder="Search for a partner"
            autocomplete="off"
            matInput
            type="text">
    </mat-form-field>

    <mat-menu #partnersMenu="matMenu"
              yPosition="below">
        <ng-container *ngIf="searchResults && searchResults.hasOwnProperty('data')">
            <button
                mat-menu-item
                [matMenuTriggerFor]="usersMenu"
                #usersTrigger="matMenuTrigger"
                (mouseenter)="partnerHovered({ id: result.id, type: result.type}); $event.stopPropagation(); $event.preventDefault()"
                *ngFor="let result of searchResults.data; let i = index"
                (click)="$event.stopPropagation(); $event.preventDefault()"
                attr.data-cy="partner-{{i}}">
                {{ result.name }}
            </button>
        </ng-container>
    </mat-menu>

    <mat-menu #usersMenu="matMenu"
              xPosition="before"
              [class.hidden]="!retrievingUsers && !retrievedUsers">
        <ng-container *ngIf="retrievingUsers">
            <div class="text-center pl-10 pt-3">
                <app-dot-loader-scale></app-dot-loader-scale>
            </div>
        </ng-container>
        <ng-container *ngIf="users && !retrievingUsers && retrievedUsers">
            <button
                mat-menu-item
                *ngFor="let user of users.data; let i = index"
                (click)="hijack(user.id)"
                attr.data-cy="partner-user-{{i}}">
                {{ user.first }} {{ user.last }}
            </button>
        </ng-container>
        <ng-container *ngIf="!retrievingUsers && (!users || !users.data || users.data.length === 0)">
            <button
                mat-menu-item
                disabled>
                No users found
            </button>
        </ng-container>
    </mat-menu>
</div>
