import { Business } from '../interfaces/business.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { ObsLendioResponse } from '../interfaces/lendio-response';
import { map } from 'rxjs';
import { Injectable } from '@angular/core';
//import { FilterService } from './filters.service';
import { BusinessesResponse } from '../interfaces/businesses-response.model';

/**
 * Get the business list.
 *
 * This varies from getting a regular entity list so it needs to be
 * its own service not extendinding EntityService (like business.service).
 **/
@Injectable()
export class BusinessesListService {
  private _lpBaseUrl = `${environment.apiUrl}/lender-portal`;
  private _lenderApiBaseUrl = `${environment.apiUrl}/l/v2/internal`;

  // private _filters = [
  //   'businessName',
  //   'businessStartDate',
  //   'businessSales',
  //   'businessIndustry',
  //   'businessOwnerFirstName',
  //   'businessOwnerLastName',
  //   'businessTitle',
  //   'businessCreditScore',
  //   'businessEmail'
  // ];

  constructor(
    private _http: HttpClient,
    //private _filtersService: FilterService
  ) {}

  getBusinesses({ sortBy = 'modified', sortDirection = 'desc', pageIndex = 0, pageSize = 25, filterBy = '', countRequest = 'exclude' }): ObsLendioResponse {
    const sort = sortBy + sortDirection;
    const path = window.location.pathname;

    //const filters = this._filtersService.getFiltersByPath(path);
    let queryStr = `?sort=${sort}&pageIndex=${pageIndex}&pageSize=${pageSize}&filterby=${filterBy}&count=${countRequest}`;

    // TODO: use store to get filters. For now just use the filterBy string above
    // if(filters) {
    //   filters.map(filter => {
    //     if(filter.value) {
    //       queryStr += `&${filter.name}=${filter.value}`;
    //     }
    //   });
    // }
    return this._http.get<BusinessesResponse>(`${this._lpBaseUrl}/businesses` + queryStr);
  }

  public addBusiness(borrower: object) {
    return this._http
      .post(`${this._lenderApiBaseUrl}/borrowers/add-single-borrower`, borrower)
      .pipe(
        map((response) => {
          return response as Business[];
        })
      );
  }
}
