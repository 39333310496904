import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StatusOption } from '@app/app/interfaces/status-option';
import { CamelToKebabPipe } from '@app/app/pipes/camel-to-kebab.pipe';
import { SentenceCasePipe } from '@app/app/pipes/sentence-case.pipe';
@Component({
  selector: 'lendio-status-display',
  templateUrl: './lendio-status-display.component.html',
  imports: [CommonModule, MatIconModule, CamelToKebabPipe, SentenceCasePipe]
})
export class LendioStatusDisplayComponent {
  @Input({ required: true }) public status: StatusOption;
  @Input() public showToggle = false;
}
