@if (noOffers) {
  <mat-card class="m-4" appearance="outlined">
    <mat-card-content>
      <div class="offers-actions lendio-density-2">
        <div class="flex flex-row items-center gap-4">
            @if (marketplaceTenant) {
                <button mat-button
                        class="add-offer-link"
                        (click)="openMakeOfferDialog(true)">
                    <span>Add offer link</span>
                </button>
            }
            <button mat-flat-button
                    color="primary"
                    (click)="openMakeOfferDialog(false)">
                <span>Add offer</span>
            </button>
        </div>
      </div>
      <app-empty-state [type]="emptyType" [label]="emptyLabelContent">
        <div class="text-center font-normal text-gray-900 mt-2" messageContent>
          Click <a class="text-blue-500 hover:text-blue-800 cursor-pointer" (click)="openMakeOfferDialog(false)">Add offer</a> or <a class="text-blue-500 hover:text-blue-800 cursor-pointer" (click)="openMakeOfferDialog(true)">Add offer link</a> to get started.
        </div>
      </app-empty-state>
    </mat-card-content>
  </mat-card>
}
@if (hasOffers) {
  <div class="offers-actions lendio-density-2">
      <div class="flex flex-row items-center gap-4">
          @if (marketplaceTenant) {
              <button mat-button
                      class="add-offer-link"
                      (click)="openMakeOfferDialog(true)">
                  <span>Add offer link</span>
              </button>
          }
          <button mat-flat-button
                  color="primary"
                  (click)="openMakeOfferDialog(false)">
              <span>Add offer</span>
          </button>
      </div>
  </div>
  @for (offer of offers; track offer; let last = $last; let i = $index;) {
      <mat-card appearance="outlined" [class.mb-4]="!last">
          <mat-card-header>
              <mat-card-title [matTooltip]="offer.name">
                  Offer
                  @if (offer.apiLogId) {
                      <span class="font-light">
                          - API Generated
                      </span>
                  }
                  @if (offer.status) {
                      <span class="font-light">
                          - ({{ offer.status }})
                      </span>
                  }
                  @if (offer.type === 'hard') {
                      <span class="text-lendio-red-400 font-light">
                          - Hard offer
                      </span>
                  }
              </mat-card-title>
          </mat-card-header>
          <div class="lendio-mat-card-divider"></div>
          <mat-card-content>
              @if (offer.borrowerDeclined) {
                  <span class="mat-body-1 font-bold text-uppercase !m-0">
                      Borrower decline reason:
                  </span>
                  <span class="mat-body-1 ml-2">{{ offer.declinedReason }}</span>
              }
              <div class="grid grid-cols-3 grid-flow-row gap-4 justify-between">
                  @for (field of fieldsToDisplayByType(offer.loanProductCategory, offer.offerLink); track field) {
                      <!-- Amount -->
                      @if (field === 'amount') {
                          <div [attr.data-cy]="'offer-amount-' + i">
                              <div class="col-header">{{ variableFieldName('amount', offer.loanProductCategory)}}</div>
                              <div class="mat-body-2">{{ (offer.amount | currency) || '-' }}</div>
                          </div>
                      }

                      <!-- Payback -->
                      @if (field === 'payback') {
                          <div>
                              <div class="col-header">Payback</div>
                              <div class="mat-body-2">{{ (offer.payback | currency) || '-' }}</div>
                          </div>
                      }

                      <!-- Factor Rate -->
                      @if (field === 'factor') {
                          <div>
                              <div class="col-header">Factor Rate</div>
                              <div class="mat-body-2">{{ (offer.factor | number:'1.2-2') || '-' }}</div>
                          </div>
                      }

                      <!-- Interest Rate -->
                      @if (field === 'interestRate') {
                          <div>
                              <div class="col-header">Interest Rate</div>
                              <div class="mat-body-2">{{ (offer.interestRate | number:'1.0-4') || '-' }}</div>
                          </div>

                      }

                      <!-- Num. Payments -->
                      @if (field === 'numPayments') {
                          <div>
                              <div class="col-header">Num. Payments</div>
                              <div class="mat-body-2">{{ offer.numPayments || '-' }}</div>
                          </div>
                      }

                      <!-- Payment Frequency -->
                      @if (field === 'paymentFrequency') {
                          <div [attr.data-cy]="'offer-payment-frequency-' + i">
                              <div class="col-header">Payment Frequency</div>
                              <div class="mat-body-2">
                                  @if (paymentFrequencyDisplay(offer) && offer.paymentFrequency) {
                                      {{ paymentFrequencyDisplay(offer) }}
                                  } @else {
                                      -
                                  }
                              </div>
                          </div>
                      }

                      <!-- Payment Amount -->
                      @if (field === 'paymentAmount') {
                          <div [attr.data-cy]="'offer-payment-amount-' + i">
                              <div class="col-header">Payment Amount</div>
                              <div class="mat-body-2">{{ (offer.paymentAmount | currency) || '-' }}</div>
                          </div>
                      }

                      <!-- Term -->
                      @if (field === 'term') {
                          <div  [attr.data-cy]="'offer-term-' + i">
                              <div class="col-header">{{ variableFieldName('term', offer.loanProductCategory)}}</div>
                              <div class="mat-body-2">{{ (offer.term + "mo.") || '-' }}</div>
                          </div>
                      }

                      <!-- Date Received -->
                      @if (field === 'received') {
                          <div>
                              <div class="col-header">Date Received</div>
                              <div class="mat-body-2">{{ (offer.received | toDateObj | date : "shortDate") || '-' }}</div>
                          </div>
                      }

                      <!-- Expiration Date -->
                      @if (field === 'expiration') {
                          <div>
                              <div class="col-header">Expiration Date</div>
                              <div class="mat-body-2">{{ (offer.expiration | toDateObj | date : "shortDate") || '-' }}</div>
                          </div>

                      }

                      <!-- Net Fund Amount -->
                      @if (field === 'netFundedAmount') {
                          <div>
                              <div class="col-header">Net Fund Amount</div>
                              <div class="mat-body-2">{{ (offer.netFundedAmount | currency) || '-' }}</div>
                          </div>
                      }

                      <!-- Offer Notes -->
                      @if (field === 'notes') {
                          <div>
                              <div class="col-header">Offer Notes</div>
                              <div class="mat-body-2">{{ offer.notes || '-' }}</div>
                          </div>
                      }

                      <!-- Advance Rate -->
                      @if (field === 'advanceRate') {
                          <div>
                              <div class="col-header">Advance Rate</div>
                              <div class="mat-body-2">{{ offer.advanceRate || '-' }}</div>
                          </div>
                      }

                      <!-- Min Purchase Fee -->
                      @if (field === 'minPurchaseFee') {
                          <div>
                              <div class="col-header">Min Purchase Fee</div>
                              <div class="mat-body-2">{{ (offer.minPurchaseFee + '%') || '-' }}</div>
                          </div>
                      }

                      <!-- Commission -->
                      @if (field === 'commission') {
                          <div>
                              <div class="col-header">Commission</div>
                              <div class="mat-body-2">{{ (offer.commission | currency) || '-' }}</div>
                          </div>
                      }

                      <!-- Points -->
                      @if (field === 'points') {
                          <div>
                              <div class="col-header">Points</div>
                              <div class="mat-body-2">{{ offer.points || '-' }}</div>
                          </div>
                      }

                      <!-- Payment Term -->
                      @if (field === 'paymentTerm') {
                          <div>
                              <div class="col-header">Payment Term</div>
                              <div class="mat-body-2">{{ (offer.paymentTerm | capitalize) || '-'}}</div>
                          </div>
                      }

                      <!-- Origination Fee -->
                      @if (field === 'originationFee') {
                          <div class="flex-1 p-1 origination-fee-display"
                                (mouseover)="setOriginationFeeToggleDisplay(true)"
                                (mouseleave)="setOriginationFeeToggleDisplay(false)">
                              <div class="col-header">Origination Fee</div>
                              @if (offer.originationFee) {
                                  <div class="origination-fee">
                                      <span class="mat-body-2">
                                          {{ originationDisplayDollars
                                              ? ((+offer.originationFee * +offer.amount!) / 100 | currency)
                                              : offer.originationFee + "%"
                                          }}
                                      </span>
                                      <a *ngIf="displayOriginationToggle"
                                          class="inline-table-toggle"
                                          (click)="toggleOriginationFeeDisplayDollars()">
                                          {{
                                              originationDisplayDollars
                                              ? "View %"
                                              : "View " +
                                              localeCurrencySymbol
                                          }}
                                      </a>
                                  </div>
                              } @else {
                                  <div>-</div>
                              }
                          </div>
                      }

                      <!-- Rate Type (based on buyRate) -->
                      @if (field === 'rateType') {
                          <div>
                              <div class="col-header">Rate Type</div>
                              <div class="mat-body-2">
                                  {{ offer.buyRate ? "Buy rate" : "Sell rate" }}
                              </div>
                          </div>
                      }

                      <!-- Variable Rate (rateTypeName based on buyRate) -->
                      @if (field === 'variableRate') {
                          <div>
                              <div class="col-header">{{ rateTypeName(offer) }}</div>
                              <div class="mat-body-2">
                                  @if (rateType(offer)) {
                                      @if (offer.buyRate !== null) {
                                          {{ offer.buyRate }}
                                      } @else {
                                          {{ offer[rateType(offer)] }}
                                      }
                                      @if (offer[rateType(offer)] !== null) {
                                          @if (rateType(offer) !== "factor") {
                                              %
                                          }
                                      }
                                  } @else {
                                      -
                                  }
                              </div>
                          </div>
                      }

                      <!-- Position -->
                      @if (field === 'position') {
                          <div>
                              <div class="col-header">Position</div>
                              <div class="mat-body-2">{{ offer.position || '-' }}</div>
                          </div>
                      }

                      <!-- Offer Link -->
                      @if (field === 'offerLink') {
                          <div>
                              <div class="col-header">Offer link</div>
                              <span
                                  class="icon-16-help"
                                  uib-tooltip="This link directs a Borrower to the Lender’s portal where they will perform final steps to accept their offer. This is a one-time use link, so do not visit this page!"
                              ></span>
                              <div class="long-word-wrap">
                                  <a data-cy="offerLink"
                                      target="_blank">
                                      {{ offer.offerLink }}
                                  </a>
                              </div>
                          </div>
                      }
                  }
              </div>
          </mat-card-content>
      </mat-card>
  }
}
