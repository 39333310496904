import { AfterViewInit, Component, computed, QueryList, signal, ViewChildren } from '@angular/core';
import { Store } from '@ngxs/store';
import { SnackbarState } from '@app/app/store/snackbar/snackbar.state';
import { Observable } from 'rxjs';
import { SnackbarNotification } from '@app/app/interfaces/snackbar-notification.model';
import { SnackbarCardComponent } from '@app/app/components/notifications/snackbar/snackbar-card.component';

@Component({
  selector: 'app-snackbar-manager',
  templateUrl: './snackbar-manager.component.html',
  standalone: false
})
export class SnackbarManagerComponent implements AfterViewInit {

  maxNotifications = 3;
  temporaryNotifications$: Observable<SnackbarNotification[]> = this.store.select( SnackbarState.temporaryNotifications );
  permanentNotifications$: Observable<SnackbarNotification[]> = this.store.select( SnackbarState.permanentNotifications )
  @ViewChildren(SnackbarCardComponent) snackbarCardsVC: QueryList<SnackbarCardComponent>;
  viewReady = signal(false);

  constructor(private store: Store) { }

  ngAfterViewInit() {
    this.setupCardsWatch();
  }

  setupCardsWatch(failures = 0) {
    if(failures > 3) {
      console.error('Failed to setup Snackbar/Notifications manager. @ViewChildren never initialized.');
      return;
    }

    if(!!this.snackbarCardsVC) {
      this.snackbarCardsVC.changes.subscribe( cards => {
        const excessCards = cards.length > this.maxNotifications
          && cards.filter( card => !card.notification.permanent && !card.expired ).length > this.maxNotifications;
        if(excessCards) {
          this.snackbarCardsVC.find(card => !card.expired)?.expire();
        }
      });
      this.viewReady.set(true);
    }

    setTimeout(() => this.setupCardsWatch(++failures))
  }

  track(index, item) {
    return item.identifier;
  }
}
