import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QualifierUnsaved } from '../../../interfaces/qualifier-unsaved.model';
import { Qualifier } from '../../../interfaces/qualifier.model';
import { Question } from '../../../interfaces/question.model';
import { State } from '../../../interfaces/state.model';

@Component({
  selector: 'app-add-base-qualifier',
  templateUrl: './add-base-qualifier.component.html',
  styleUrls: ['./add-base-qualifier.component.scss'],
  standalone: false
})
export class AddBaseQualifierComponent implements OnInit {

  @Input() questions: Question[];
  @Input() states: State[];
  @Input() loanProductId: number;
  @Input() qualifiers: (Qualifier | QualifierUnsaved)[];
  @Input() disabledBaseAttributeFields: string[];
  @Input() disabledBaseOperatorFilterTypes: Map<string, string>;
  @Input() hasWriteAccess: boolean;

  @Output() addQualifier = new EventEmitter<QualifierUnsaved>();

  addingBaseQualifier = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  save(qualifier: QualifierUnsaved) {
    this.addQualifier.emit(qualifier);
    this.addingBaseQualifier = false;
  }
}
