<div data-cy="document-rejection-dialog">
    <div class="flex justify-between content-center items-center">
        <h2 mat-dialog-title class="leading-none" data-cy="cancel-document-rejection-header">
            Document rejection confirmation
        </h2>
        <button class="py-6 pr-6"(click)="cancel($event)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div>
            <p>
                Are you sure you want to reject this document? Rejecting the document will notify the borrower, 
                prompting them to upload a new or revised version.
            </p>
            <p>
                Please add notes explaining the reasons for the rejection and/or instructions for proper completion 
                of this document requirement.
            </p>
        </div>

        <quill-editor
            class="w-full pt-6"
            [formControl]="quillFormControl"
            [modules]="quillModules"
            placeholder="Note to borrower&hellip;"
        />
        <!-- Validation error placeholder -->
        <div class="h-6">
            @if (quillFormControl.hasError('minlength')) {
                <mat-error>
                    Note must be at least 10 characters long.
                </mat-error>
            }
            @if (quillFormControl.hasError('maxlength')) {
                <mat-error>
                    Content exceeds maximum size of 25MB.
                </mat-error>
            }
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="primary" (click)="cancel($event)">
            Cancel
        </button>
        <button mat-flat-button color="primary" class="!ml-4"
            data-cy="document-rejection-confirm-btn"
            [disabled]="!quillFormControl.dirty || !quillFormControl.valid"
            (click)="save()"
        >
        Confirm rejection 
        </button>
    </mat-dialog-actions>
</div>
