import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lead-submission-dialog',
  templateUrl: './lead-submission-dialog.component.html',
  styleUrls: ['./lead-submission-dialog.component.scss'],
  standalone: false
})
export class LeadSubmissionDialogComponent {
  loading = false;
  submissionState = 'form';

  constructor(
    public dialogRef: MatDialogRef<LeadSubmissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { partnerId: number },
  ) { }

  submit() {
    this.loading = true;
    return;
  }

  setState(state: string): void {
    this.submissionState = state;
  }
}
