import {
    ColDef,
    GetRowIdParams,
    GridOptions,
    IColumnLimit,
    ModelUpdatedEvent,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridColumnLimits,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-community';
import { NoItemsOverlayComponent } from './no-items-overlay/no-items-overlay.component';


export const defaultMinWidth = 200;
export const sizeToFitColumnLimits: SizeColumnsToFitGridColumnLimits[] = [
    {
        colId: 'value',
        minWidth: 200,
    }
];

export const columnLimits: IColumnLimit[] = [
    {
        key: 'value',
        minWidth: 200,
        maxWidth: 328
    }
];

export const AUTO_SIZE_STRATEGY:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: 'fitGridWidth',
    defaultMinWidth: defaultMinWidth,
    columnLimits: sizeToFitColumnLimits
};

export const GRID_BASE_CONFIG: GridOptions = {
    noRowsOverlayComponent: NoItemsOverlayComponent,
    onModelUpdated: (event: ModelUpdatedEvent) => {
        event.api.getDisplayedRowCount() === 0 ?
        event.api.showNoRowsOverlay() :
        event.api.hideOverlay();
    },
    onFilterChanged: (event) => {
        event.api.sizeColumnsToFit(); 
    },
    headerHeight: 0,
    rowHeight: 32,
    domLayout: 'autoHeight',
    animateRows: true,
    autoSizeStrategy: AUTO_SIZE_STRATEGY,
    autoSizePadding: 0,
    cacheQuickFilter: true,
    columnMenu: 'new',
    enableCellTextSelection: true,
    popupParent: document.body,
    rowSelection: 'multiple',
    suppressAggFuncInHeader: true,
    suppressCellFocus: true,
    suppressRowClickSelection: false,
    rowMultiSelectWithClick: true
};

export const GRID_BASE_DEFAULT_COL_DEF: ColDef = {
    suppressMovable: true,
    checkboxSelection: true,
    suppressHeaderFilterButton: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    filterParams: {
        maxNumConditions: 1
    }
};

