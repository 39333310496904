import { Component, computed, OnInit, Signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import {
  EarlyAccessSelectionComponent
} from '@app/app/components/settings/early-access-selection/early-access-selection.component';
import { EarlyAccessFeatureService } from '@app/app/components/settings/early-access/early-access-feature.service';
import { GetSaasFeatures, UpdateEarlyAccessFeatureEnrollments } from '@app/app/store/saas-features/saas-features.actions';
import { EarlyAccessFeature } from '@app/app/interfaces/saas-feature.model';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';

@Component({
  standalone: true,
  selector: 'app-early-access',
  imports: [MatCardModule, EarlyAccessSelectionComponent, MatButtonModule, MatProgressSpinnerModule, CommonModule],
  templateUrl: './early-access.component.html',
  styleUrl: './early-access.component.scss'
})
export class EarlyAccessComponent implements OnInit {
  @Select(SaasFeaturesState.earlyAccessFeatures) earlyAccessFeatures$: Observable<EarlyAccessFeature[]>;
  features: Signal<EarlyAccessFeature[]> = computed(() => this._earlyAccessFeatureService.earlyAccessFeatures());
  featuresCopy: EarlyAccessFeature[] = this.features().map((feature => ({...feature})));
  autoEnrolled = false;
  private _savingChanges = false;

  constructor(
    private _earlyAccessFeatureService: EarlyAccessFeatureService,
    private _store: Store
  ) {
    this.earlyAccessFeatures$.pipe(takeUntilDestroyed()).subscribe((features: EarlyAccessFeature[]) => {
      this._earlyAccessFeatureService.setFeatures(features);
      this.featuresCopy = this.features().map((feature => ({...feature})));
      this.setAutoEnrolled();
    });
  }

  ngOnInit() {
    this._store.dispatch(new GetSaasFeatures());
    this.featuresCopy = this.features().map((feature => ({...feature})));
    this.setAutoEnrolled();
  }

  get noChangesToSave(): boolean {
    return JSON.stringify(this.features()) === JSON.stringify(this.featuresCopy);
  }

  get savingChanges(): boolean {
    return this._savingChanges;
  }

  set savingChanges(state: boolean) {
    this._savingChanges = state;
  }

  setAutoEnrolled() {
    const autoEnrolledFeature = this.features().find(feature => feature.alias === this._earlyAccessFeatureService.autoEnrollmentAlias);

    this.autoEnrolled = !!(autoEnrolledFeature && !autoEnrolledFeature.disabled);
  }

  saveFeatureChanges(): void {
    this._store.dispatch(new UpdateEarlyAccessFeatureEnrollments(this.features()));
  }
}
