<div class="restricted-slider" [ngClass]="{'is-restricted-min': minRestriction > 0, 'is-restricted-max': maxRestriction > 0, 'loading': loading}" style="display: flex;">
    <div class="restricted min-restriction" [style.width.%]="minRestriction">
        <div class="restricted-bar"></div>
    </div>
    <mat-slider
      #sliderEl
      color="primary"
      discrete="true"
      interval="auto"
      [max]="availableMax"
      [min]="roundedMin || availableMin"
      [step]="step"
      [disabled]="disabled || loading"
      [style.width.%]="width"
      [displayWith]="thumbLabelDisplay">
        <input matSliderThumb
            [(ngModel)]="value"
            (change)="!updateContinuously && emitChange({value: value})"
            (input)="updateContinuously && emitChange({value: value})" />
    </mat-slider>
    <div class="restricted max-restriction" [style.width.%]="maxRestriction">
        <div class="restricted-bar"></div>
    </div>
</div>

<div [style.opacity]="showValues ? 1 : 0" class="slider-min-max">
    <div>{{ minLabel }}</div>
    <div>{{ maxLabel }}</div>
</div>

