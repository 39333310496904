import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { AgGridAngular } from 'ag-grid-angular';
import { GetRowIdParams, GridOptions, GridReadyEvent, GridState, RowDataUpdatedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { GRID_BASE_CONFIG, GRID_BASE_DEFAULT_COL_DEF } from './grid-options';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'searchable-select',
    standalone: true,
    imports: [
        CommonModule,
        MatExpansionModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        AgGridAngular
    ],
    templateUrl: './searchable-select.component.html',
    styleUrl: './searchable-select.component.scss'
})

export class SearchableSelect implements OnInit, AfterViewInit, OnDestroy {
    @Input() items: any[];
    @Input() searchable: boolean = false;
    @Input() optionValueKey: string = 'name';
    @Input() optionIdKey: string = 'id';
    @Input() expanded: boolean = false;
    @Input() customCellStyle: {} = {};
    @Input() formatter?: (value: any) => string | number;
    @Output() onApply = new EventEmitter<any[]>();
    @Output() onCancel = new EventEmitter<void>();
    public  gridInitialState: GridState;
    public selectedRows = signal<any[]>([]);
    public openedSelectedStateIds: any[] = [];
    public selectedStateChanged: boolean = false;
    public quickFilterValue = '';
    private _gridApi: any;

    public gridOptions: GridOptions = Object.assign({}, {
        ...GRID_BASE_CONFIG,
        defaultColDef: GRID_BASE_DEFAULT_COL_DEF,
    });

    constructor(
        private eRef: ElementRef
    ) {}

    onDocumentClick(event: MouseEvent) {
        if (this.expanded && !this.eRef?.nativeElement.contains(event.target)) {
            this.cancel();
        }
    }

    ngOnInit(): void {
        this.gridOptions = {
            ...this.gridOptions,
            getRowId: (params: GetRowIdParams) => {
                return this.optionIdKey ? params.data[this.optionIdKey] : params.data.value;
            },
            columnDefs: [
                {
                    hide: false,
                    minWidth: 200,
                    headerName: 'Name',
                    field: this.optionValueKey,
                    colId: this.optionValueKey,
                    valueFormatter: ({ value }) => {
                        if (this.formatter) {
                            return this.formatter(value);
                        }

                        return value;
                    },
                    cellStyle: this.customCellStyle
                }
            ]
        }

    }

    ngAfterViewInit() {
        document.addEventListener('click', this.onDocumentClick.bind(this));
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.onDocumentClick.bind(this));
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
    }

    onRowDataUpdated(event: RowDataUpdatedEvent): void {
        event.api.refreshCells({ force: true });
    }

    clearAllSelected(): void {
        this._gridApi?.deselectAll();
    }

    onSelectionChanged(event: SelectionChangedEvent): void {
        this.selectedRows.set(event.api.getSelectedNodes());
    }

    onQuickFilterChange() {
        this._gridApi?.setGridOption('quickFilterText', this.quickFilterValue);
    }

    apply(){
        const selected = this.selectedRows().map(node => node.data);
        this.clearAllSelected();
        this.onApply.emit(selected);
    }

    cancel(){
        this.clearAllSelected();
        this.onCancel.emit();
    }
}
