<h2 mat-dialog-title>Confirm requirement removal</h2>
<mat-dialog-content>
	<div class="flex">
		<p>Are you sure you want to remove this requirement from the deal? This action will not remove any associated documents from the deal.</p>
	</div>
    <div class="py-2 flex flex-row-reverse">
        <button mat-stroked-button color="warn" class="mat-warn" (click)="deleteRequirement()">Confirm removal</button>
        <button class="ml-2" mat-button mat-dialog-close>Cancel</button>
    </div>
</mat-dialog-content>
