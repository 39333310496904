import { Component, Input } from '@angular/core';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';

@Component({
  selector: 'app-lendio-rep',
  templateUrl: './lendio-rep.component.html',
  styleUrls: ['./lendio-rep.component.scss'],
  standalone: false
})
export class LendioRepComponent {
  @Input() applicationDetails: ApplicationDetails;

  expanded = false;

  get buttonName() {
    return this.expanded ? "Hide" : "Show";
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
