import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  signal,
  WritableSignal,
  DestroyRef
} from '@angular/core';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import {Store} from "@ngxs/store";
import { Observable} from "rxjs";
import {FundingDeskRequestsState} from "@app/app/store/funding-desk/funding-desk-requests.state";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-funding-desk',
  templateUrl: './funding-desk.component.html',
  standalone: false
})
export class FundingDeskComponent implements OnInit {

  @ViewChild('fdPanel', { static: true })
  fdPanel: MatExpansionPanel;

  @ViewChild('requestTextArea', { static: false })
  requestTextArea: ElementRef<HTMLTextAreaElement>;

  /**
   * Why is this component also querying the store when the funding-desk-smart-component queries the store?
   *
   * There is an interesting bug when the store is updated any new requests will be rendered, and any
   * patched requests are re-rendered. When adding a new comment to a request this will rerender the single-request-chain
   * component and collapse the comments so that they are no longer showing.
   *
   * It is unusual that this patch request takes so long, about 4 seconds, and transmits so much data (but that is a
   * different bug.) To compensate for that bug a new comment is optimistically loaded into the front end before the
   * POST new comment request is fired, then after about 4 seconds the comment thread that the user was looking at
   * collapses itself.
   *
   * The fix for this is to change this component to read from the store independently of the smart component above it
   * otherwise any fix applied would also be applied to the old component that we want to remain unchanged. Then when
   * the store is updated this component can check to see if it is a new comment or a new request. Update the data only
   * when a new request is found and NOT when a new comment is found.
   *
   * The trade-off is that if there is an error while saving the comment to the database the comment will appear as if
   * it was saved successfully, about 4 seconds later an error toast will display the error while saving the comment.
   * Even though the comment looks successful after a refresh of the page, or a new request the failed comment will
   * disappear.
   *
   * A permanent solution will require refactoring the new comment endpoint to be much quicker, then refactoring the
   * single-request-chain component to read from the store, instead of optimistic loading the new comment before
   * calling the POST request.
   */

  fundingDeskRequests$: Observable<FundingDeskRequest[]> = this._store.select(FundingDeskRequestsState.fundingDeskRequests);

  @Output() createNew = new EventEmitter<{ description: string, type: string }>();
  @Output() newComment = new EventEmitter<{ description: string, requestId: number }>();

  newRequest = '';
  type = '';
  expandStatus = false;
  emptyState: WritableSignal<boolean> = signal(true);
  fundingDeskRequests: FundingDeskRequest[] = [];

  constructor(private _store: Store, private _destroyRef: DestroyRef) { }
  ngOnInit(): void {
    this.fundingDeskRequests$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe({
      next: updatedRequests => {
        //don't update the local fundingDeskRequests data if the only change is to the comments
        if (updatedRequests.length !== this.fundingDeskRequests.length) {
          this.fundingDeskRequests = updatedRequests;
        }
      }
    });
    this.checkEmptyState()
  }

  checkEmptyState():void {
    this.emptyState.set(this.fundingDeskRequests.length === 0);
  }

  addAMessage(): void {
    this.emptyState.set(false);
    this.expandStatus = true;
    this.handleAfterExpand();
  }

  handleAfterExpand(): void {
    // setTimeout allows the expansion panel animation to finish.
    setTimeout(() => {
      this.requestTextArea?.nativeElement.focus();
    });
  }

  createNewRequest(description: string) {
    this.newRequest = '';
    this.createNew.emit({description, type: this.type});
    this.expandStatus = false;
  }

  addNewComment(comment: { description: string, requestId: number }) {
    this.newComment.emit(comment);
  }

  cancelComment() {
    this.newRequest = '';
    this.expandStatus = false;
    this.checkEmptyState();
  }
}
