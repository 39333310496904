import {Alert} from '@app/app/interfaces/alert.model';
import {LoginUser} from '@app/app/interfaces/login-user.model';
import {AuthUser} from './auth-user';

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class RestoreLocalStorageUser {
  static readonly type = '[Auth] RestoreLocalStorageUser';
}

export class Hijack {
  static readonly type = '[Auth] Hijack';
  constructor(
    public userId: number,
  ) {}
}

export class SetAuthAlert {
  static readonly type = '[Auth] SetAuthAlert';
  constructor(
    public alert: Alert,
  ) {}
}

export class ClearAuthAlert {
  static readonly type = '[Auth] ClearAuthAlert';
}

export class SetAuthUser {
  static readonly type = '[Auth] SetAuthUser';
  constructor(
    public user: LoginUser,
    public isHijacked?: boolean,
  ) {
  }
}

export class GetAuthUser {
  static readonly type = '[Auth] GetAuthUser';
}

export class UpdateAuthUser {
  static readonly type = '[Auth] UpdateAuthUser';
  constructor(
    public updates: Partial<AuthUser | undefined>,
  ) {}
}

export class ForgotPassword {
  static readonly type = '[Auth] ForgotPassword';
  constructor(
    public email: string
  ) {}
}

export class ResetPassword {
  static readonly type = '[Auth] ResetPassword';
  constructor(
    public email: string,
    public token: string,
    public password: string,
  ) {}
}

export class ResetExpiredPassword {
  static readonly type = '[Auth] ResetExpiredPassword';
  constructor(
    public oldPassword: string,
    public newPassword: string,
    public confirmPassword: string,
    public transactionId: string,
  ) {}
}
