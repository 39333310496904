@if (isSidebar) {
    <app-funding-desk-sidebar
        [fundingDeskRequests]="fundingDeskRequests$ | async"
        (createNew)="createNewRequest($event)"
        (newComment)="createNewComment($event)"
    />
} @else {
    <app-funding-desk
        (createNew)="createNewRequest($event)"
        (newComment)="createNewComment($event)"
    />
}
