<div *ngIf="calculatedApprovals && calculatedApprovals.length else empty">
    <mat-accordion class="approvals-accordion">
        <ng-container *ngFor="let approval of calculatedApprovals; first as isFirst">
            <mat-expansion-panel class="w-full" [expanded]="isFirst">
                <mat-expansion-panel-header class="!h-[64px] !items-center">
                    <div class="approval-title w-full flex justify-between items-center !mr-12"
                        [ngClass]="'grid-cols-' + approval.descriptionValues.length">
                        <div *ngFor="let description of approval.descriptionValues" class="col-span-1" data-cy="offer-field">
                            <div class="!font-bold max-amount-header text-center mat-body-2"
                                [innerHTML]="description.label"></div>
                            <div class="!font-normal text-center mat-body-2">
                                <div *ngIf="description.pipe; else noPipe">
                                    <span *ngIf="description.pipe === 'currency'">
                                        {{description.value | currency: undefined: 'symbol': '1.0-0'}}
                                    </span>
                                    <span *ngIf="description.pipe === 'number'">
                                        {{description.value | number: '1.1-2'}}
                                    </span>
                                </div>
                                <ng-template #noPipe>
                                    {{description.value || '-'}}
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="grid grid-cols-2 grid-flow-row gap-4">

                    <!--Approval Details-->
                    <mat-card appearance="outlined" class="col-span-1 row-span-2">
                        <mat-card-header>
                            <mat-card-title>Approval</mat-card-title>
                        </mat-card-header>
                        <div class="lendio-mat-card-divider"></div>
                        <mat-card-content>
                            <div class="flex flex-col gap-2">
                                @for (detail of approval.approvalsDataSource; track detail) {
                                    <div data-cy="approval-details-field" class="!w-full flex flex-row justify-between items-center">
                                        <div class="mat-body-2" [innerHTML]="detail.label"></div>
                                        <div class="mat-body-2">{{ detail.value || '-' }}</div>
                                    </div>
                                }
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <!--Additional Details-->
                    <mat-card appearance="outlined" class="col-span-1 row-span-1">
                        <mat-card-header>
                            <mat-card-title>Additional details</mat-card-title>
                        </mat-card-header>
                        <div class="lendio-mat-card-divider"></div>
                        <mat-card-content>

                            <div class="flex flex-col gap-2">
                                <div data-cy="additional-details-field" class="!w-full flex flex-row justify-between items-center">
                                    <div class="mat-body-2">Net requirement</div>
                                    <div class="mat-body-2">{{ approval.position }}</div>
                                </div>
                                <div data-cy="additional-details-field" class="!w-full flex flex-row justify-between items-center">
                                    <div class="mat-body-2">Position</div>
                                    <div class="mat-body-2">{{ approval.netMinimum / 100 | percent }}</div>
                                </div>
                                <div data-cy="additional-details-field" class="!w-full flex flex-row justify-between items-center">
                                    <div class="mat-body-2">Expiration</div>

                                    @if (approval.expires && approval.expires !== '0000-00-00 00:00:00') {
                                        <div class="mat-body-2"> {{ approval.expires | date }}</div>
                                    }
                                </div>
                                @if (approval.offerLink) {
                                    <div data-cy="additional-details-field" class="!w-full flex flex-row justify-between items-center">
                                        <div class="mat-body-2">Offer link</div>
                                        <a class="font-bold text-lendio-blue-400 hover:text-lendio-blue-500"
                                           href="{{ approval.offerLink }}"
                                           target="_blank">
                                            View
                                        </a>
                                    </div>
                                }
                                @if (approval.calculationType === 'sba') {
                                  <div data-cy="additional-details-field" class="!w-full flex flex-row justify-between items-center">
                                      <div class="mat-body-2">Hourly Packaging Fee</div>
                                      <div class="mat-body-2">{{ isPackagingFeeHourly ? 'Yes' : 'No' }}</div>
                                  </div>
                                }
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <!--Stips and Notes-->
                    <mat-card appearance="outlined" class="col-span-1 row-span-1">
                        <mat-card-header>
                            <mat-card-title>Stipulations</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            @if(approval.stips.length) {
                                <mat-list>
                                    @for(stip of approval.stips; track stip; let i = $index) {
                                        <div data-cy="approval-stip" class="mb-1">
                                            <span class="mr-0.5">{{ i + 1 }}.</span>
                                            <span>{{ stip.name }}</span>
                                                @if(stip.type === 'conditional') {
                                                    <span class="ml-0.5">({{ stip.minAmount | currency }}+)</span>
                                                }
                                        </div>
                                    }
                                </mat-list>
                            } @else {
                                <ng-template [ngTemplateOutlet]="emptyState"></ng-template>
                            }
                        </mat-card-content>
                        <span>
                            <mat-card-header>
                                <mat-card-title>Notes</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div data-cy="approval-note" *ngIf="approval.notes else emptyState">
                                    <p class="mat-body-1">{{approval.notes}}</p>
                                </div>
                            </mat-card-content>
                        </span>
                    </mat-card>

                    <!--Payment Examples-->
                    <mat-card *ngIf="approval.paymentComparison" appearance="outlined" class="col-span-2 row-span-1">
                        <mat-card-header>
                            <mat-card-title>Payment examples</mat-card-title>
                        </mat-card-header>
                        <div class="lendio-mat-card-divider"></div>
                        <mat-card-content>
                            <table
                                mat-table
                                class="approvals-payment !w-full !ml-0 !mb-0 font-normal"
                                [dataSource]="approval.paymentComparison">

                                <mat-header-row *matHeaderRowDef="approval.paymentDisplayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: approval.paymentDisplayedColumns;"></mat-row>
                                <!--['type', 'payback', 'cost', 'centsOnDollar', 'payments']-->
                                <ng-container matColumnDef="type">
                                    <mat-header-cell class="!px-4 flex justify-start" *matHeaderCellDef>&nbsp;</mat-header-cell>
                                    <mat-cell class="!text-sm !font-bold !px-4 flex justify-start" *matCellDef="let payment">{{ payment.type }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="payback">
                                    <mat-header-cell class="!text-sm !px-4 flex justify-end" *matHeaderCellDef><span class="font-mono">~</span>&nbsp;Payback</mat-header-cell>
                                    <mat-cell class="!text-sm !font-normal !px-4 flex justify-end" *matCellDef="let payment">{{ payment.payback | currency }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="cost">
                                    <mat-header-cell class="!text-sm !px-4 flex justify-end" *matHeaderCellDef><span class="font-mono">~</span>&nbsp;Cost of cap.</mat-header-cell>
                                    <mat-cell class="!text-sm !font-normal !px-4 flex justify-end" *matCellDef="let payment">{{ payment.cost | currency }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="centsOnDollar">
                                    <mat-header-cell class="!text-sm !px-4 flex justify-end" *matHeaderCellDef><span class="font-mono">~</span>&nbsp;Cents/$</mat-header-cell>
                                    <mat-cell class="!text-sm !font-normal !px-4 flex justify-end" *matCellDef="let payment">{{ payment.centsOnDollar }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="payments">
                                    <mat-header-cell class="!text-sm !px-4 flex justify-end" *matHeaderCellDef># Payments</mat-header-cell>
                                    <mat-cell class="!text-sm !font-normal !px-4 flex justify-end" *matCellDef="let payment">{{ payment.payments }}</mat-cell>
                                </ng-container>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</div>
<ng-template #empty>
    <p class="no-offers pt-10">No Approvals Yet</p>
</ng-template>
<ng-template #emptyState>
    <p class="mat-body-1">-</p>
</ng-template>
