import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ThoughtspotLiveboardModel } from '@app/app/interfaces/thoughtspot-liveboard.model';
import { ThoughtspotReportsState } from '@app/app/store/thoughtspot-reports/thoughtspot-reports.state';
import { Select } from '@ngxs/store';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-reports-list-table',
  templateUrl: './reports-list-table.component.html',
  styleUrls: ['./reports-list-table.component.scss'],
  standalone: false
})
export class ReportsListTableComponent implements AfterViewInit {
  @Select(ThoughtspotReportsState.reportList) tsReports$: Observable<
    ThoughtspotLiveboardModel[]
  >;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['name', 'createdBy', 'created', 'modified'];
  tableDataSource$: Observable<MatTableDataSource<ThoughtspotLiveboardModel>>;

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._convertReportsToMatTableDataSource();
    })
  }

  /**
   * Map partner reports stream from state into a MatTableDataSource stream
   */
  private _convertReportsToMatTableDataSource(): void {
    this.tableDataSource$ = this.tsReports$.pipe(
      // Skip inital emission of default value from state
      filter((partnerReports) => partnerReports !== null),
      map((partnerReports) => {
        const tableDataSource =
          new MatTableDataSource<ThoughtspotLiveboardModel>(partnerReports);
        tableDataSource.sort = this.sort;

        return tableDataSource;
      })
    );
  }

  onRowClick(e: ThoughtspotLiveboardModel) {
    const reportSingleRoute = `/reports/${e.id}`;
    const reportName = e.name;
    this.router.navigateByUrl(reportSingleRoute, {
      state: { reportName },
    });
  }
}
