@if ((documents$ | async); as documents) {
    <app-documents [borrowerId]="borrowerId"
                   [dealId]="dealId"
                   [loanProductCategory]="loanProductCategory"
                   [canSeeAdvDocManagement]="canSeeAdvDocManagement" />
} @else {
  <mat-card appearance="outlined" class="mb-4">
    <h2 class="border-b">Documents</h2>
    <app-dot-loader-scale></app-dot-loader-scale>
  </mat-card>
}
