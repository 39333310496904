import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CsvState } from 'src/app/store/csv/csv.state';
import { GetForgivenessDocuments } from 'src/app/store/csv/csv.actions';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-forgiveness-import',
  templateUrl: './forgiveness-import.component.html',
  styleUrls: ['./forgiveness-import.component.scss'],
  standalone: false
})
export class ForgivenessImportPageComponent implements OnInit {
  // @Select(CsvState.forgivenessImportsPaginated) imports$: Observable<any>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;
  imports$: Observable<any> = this.store.select(CsvState.forgivenessImportsPaginated);

  constructor(private store: Store) {
    this.store.dispatch(new GetForgivenessDocuments());
  }

  ngOnInit(): void {
  }

}
