import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RowNode } from 'ag-grid-community';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthUser } from '@app/app/store/auth/auth-user';
@Component({
  selector: 'documents-header',
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    CommonModule
  ],
  templateUrl: './documents-header.component.html',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class DocumentsHeaderComponent implements OnInit {
  @Input() documents: Document[];
  @Input() selectedRows: RowNode[];
  @Input() currentUser: AuthUser;
  @Input() canUpload: boolean;
  @Input() canCrudMpDealDocs: boolean;
  @Input() hasModifyAccess: boolean;
  @Input() dealId?: number;
  @Output() handleUploadEvent = new EventEmitter<any>();
  @Output() handleDownloadEvent = new EventEmitter<any>();
  @Output() handleDeleteEvent = new EventEmitter<any>();
  public canDelete: boolean;

  handleUpload() {
    this.handleUploadEvent.emit();
  }

  handleDownload($event: Event) {
    this.handleDownloadEvent.emit($event);
  }

  handleDelete($event: Event) {
    this.handleDeleteEvent.emit($event);
  }

  ngOnInit(): void {
    this.canDelete = this.selectedRows.every((rowNode: RowNode) => {
      return (this.dealId && this.currentUser?.institution?.id === rowNode.data?.lenderId && this.canCrudMpDealDocs) ||
        this.hasModifyAccess
    });
  }
}
