import { Component, EventEmitter, OnInit, Input, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-restricted-radio',
  templateUrl: './restricted-radio.component.html',
  styleUrls: ['./restricted-radio.component.scss'],
  standalone: false
})
export class RestrictedRadioComponent implements OnInit, OnChanges {
  @Input() options: object[];
  @Input() availableOptions: any[];
  @Input() value: any;
  @Input() moveOnOptionChange: boolean; // If enabled: If an option is removed or disabled, select the farthest right option;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Output() valueChange = new EventEmitter<any>();

  ngOnInit() {
    if (!this.availableOptions) {
      this.availableOptions = [];
    }
  }

  ngOnChanges(changes: any) {
    const optionsChange = changes && changes.options && changes.options.currentValue;
    // const optionsPrevious = changes && changes.options && changes.options.previousValue;
    if (optionsChange !== undefined && optionsChange && this.value !== undefined) {
      const optionEnabled = this.availableOptions.includes(this.value);
      const optionExists = optionsChange.map((opt: any) => opt.value).includes(this.value);

      if (optionExists && optionEnabled) {
        return;
      }
      if (!optionExists && !this.moveOnOptionChange) {
        this.value = undefined;
        this.valueChange.emit(this.value.value);
        return;
      }
      if (!optionEnabled && !this.moveOnOptionChange) {
        return;
      }

      const availableValues = this.options
        .filter((option: any) => this.availableOptions.includes(option.value))
        .map((opt: any) => opt.value);
      this.value = this.options[availableValues.length - 1];
      this.valueChange.emit(this.value);
    }

    // When the disabled options change we need to make sure that a valid value gets selected
    const availableOptionsChange = changes && changes.availableOptions && changes.availableOptions.currentValue;
    // const disabledOptionsPrevious = changes && changes.disabledOptions && changes.disabledOptions.previousValue;
    if (availableOptionsChange !== undefined && availableOptionsChange && this.value !== undefined) {
      const actualAvailableChange = availableOptionsChange.filter(
        (option: any) => !changes.availableOptions.previousValue.includes(option)
      ).length > 0;
      const otherAvailableChange = changes.availableOptions.previousValue.filter(
        (option: any) => !availableOptionsChange.includes(option)
      ).length > 0;

      if (!actualAvailableChange && !otherAvailableChange) {
        return;
      }

      const valueEnabled = availableOptionsChange.includes(this.value);
      if (valueEnabled || !this.moveOnOptionChange) {
        return;
      }

      const availableValues = this.options
        .filter((option: any) => availableOptionsChange.includes(option.value))
        .map((opt: any) => opt.value);
      this.value = availableValues[availableValues.length - 1];
      this.valueChange.emit(this.value);
    }
  }

  optionDisabled(option: any) {
    return this.availableOptions.indexOf(option.value) === -1;
  }

}
