import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';

import { Notification } from '../../../../interfaces/notification.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss', '../../styles.temp.scss'],
  animations: [
    trigger('fadeInOutFloat', [
      transition(':enter', [
        animate('0.25s', keyframes([
          style({ position: 'absolute', 'z-index': -1, width: '100%', opacity: 0, offset: 0 }),
          style({ opacity: 0.667, offset: 0.5 }),
          style({ opacity: 1, offset: 0.75 }),
          style({ offset: 1 })
        ])),
      ]),
      transition(':leave', [
        animate('0.25s', keyframes([
          style({ position: 'absolute', 'z-index': -1, width: '100%', opacity: 1, offset: 0 }),
          style({ opacity: 0, offset: 1 }),
        ])),
      ])
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        animate('0.25s', keyframes([
          style({ transform: 'translateX(100%)', opacity: 0, offset: 0 }),
          style({ transform: 'translateX(50%)', opacity: 0.667, offset: 0.5 }),
          style({ transform: 'translateX(25%)', opacity: 1, offset: 0.75 }),
          style({ transform: 'translateX(0%)', offset: 1 })
        ])),
      ]),
      transition(':leave', [
        animate('0.25s', keyframes([
          style({ transform: 'translateX(0%)', opacity: 1, offset: 0 }),
          style({ transform: 'translateX(100%)', opacity: 0, offset: 1 }),
        ])),
      ])
    ])
  ],
  standalone: false
})
export class SidenavComponent implements OnInit {
    @Input() notifications: Notification[] | null;
    @Input() allRead = false;
    @Input() loading = true;
    @Input() togglingAllRead: boolean;
    @Input() deletingAll: boolean;

    @Output() toggle = new EventEmitter();
    @Output() bulkRead = new EventEmitter();
    @Output() bulkDelete = new EventEmitter();

    constructor(public router: Router) { }

    ngOnInit(): void {
    }

    toggleOpen() {
        this.toggle.emit();
    }

    deleteAll() {
        this.bulkDelete.emit();
    }

    markAllRead() {
        this.bulkRead.emit();
    }

    trackByFn(index: any, notification: { activityId: any; }) {
        return notification.activityId;
    }
}
