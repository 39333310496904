import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { StatusOption } from '../../interfaces/status-option';

export const clientApplicationStatusOptions: StatusOption[] = [
    {
        value: 'noAttempt',
        display: 'Lead Claimed',
        stage: 'active',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'attempted',
        display: 'Attempted',
        stage: 'active',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'inContact',
        display: 'In Contact',
        stage: 'active',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'docPrep',
        display: 'App Submitted',
        stage: 'active',
        hidden: true,
        disabledReason: null
    },
    {
        value: 'dealInProgress',
        display: 'Deal In Progress',
        stage: 'active',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'renewalsEligible',
        display: 'Renewals Eligible',
        stage: 'active',
        hidden: true,
        disabledReason: null,
    },
    {
        value: 'funded',
        display: 'Funded',
        stage: 'funded',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'notInterestedInRenewal',
        display: 'Not Interested In Renewal',
        stage: 'funded',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'onHold',
        display: 'On Hold',
        stage: 'inactive',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'doNotContact',
        display: 'Do Not Contact',
        stage: 'inactive',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'dead',
        display: 'Dead',
        stage: 'inactive',
        hidden: false,
        disabledReason: null,
    },
    {
        value: 'prospect',
        display: 'Prospect',
        stage: 'inactive',
        hidden: true,
        disabledReason: null,
    },
    {
        value: 'referral',
        display: 'Referral',
        stage: 'inactive',
        hidden: true,
        disabledReason: null,
    },
];

@Pipe({
    name: 'statusDisplay',
    standalone: false
})
export class StatusDisplayPipe implements PipeTransform {
    transform(value: string): string {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        const statusOption = clientApplicationStatusOptions.find(el => el.value === value);
        // could we just do this with CSS?
        return statusOption ? statusOption.display :  _.startCase(_.camelCase(value));
    }
}

