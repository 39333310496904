import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface CustomICellRendererParams extends ICellRendererParams {
  key: string;
  showInGroupRow: boolean;
  showInUngroupedRow: boolean;
}

@Component({
  selector: 'grid-boolean-renderer',
  templateUrl: './boolean-renderer.component.html',
  styleUrls: ['./boolean-renderer.component.scss'],
  standalone: false
})
export class BooleanRendererComponent implements ICellRendererAngularComp {
  public imageSource!: string;
  public imageAlt!: string;
  public shouldDisplay: boolean;
  public params: CustomICellRendererParams;

  agInit(params: CustomICellRendererParams): void {
    this.params = params;
    this.shouldDisplay = this.show();
    const imageFile = this.value()
      ? 'blue-checkmark.svg'
      : 'exit.svg';
    this.imageAlt = this.value()?.toString();
    this.imageSource = `assets/images/${imageFile}`;
  }

  value() {
    return !this.params?.node?.group
      ? this.params.value
      : this.params.node.allLeafChildren[0].data[this.params.key]
  }

  show() {
    return (
      this.params.node.group && this.params.showInGroupRow
    ) || (
      !this.params.node.group && this.params.showInUngroupedRow
    )
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
