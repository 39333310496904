import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Document } from '@app/app/interfaces/document.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LendioPaginatorComponent } from '@app/app/components/lendio-angular-material-theme/lendio-paginator/lendio-paginator.component';
import { cloneDeep, get } from 'lodash';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '@app/environments/environment';
import { Select, Store } from '@ngxs/store';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Observable, Subject, combineLatest, forkJoin, map, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { FileUploadDialogComponent } from '../../file-upload/file-upload-dialog/file-upload-dialog.component';
import { format, parse } from 'date-fns';
import { DocumentEditDialogComponent } from '../document-edit-dialog/document-edit-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { Business, BusinessAccessLevel } from '@app/app/interfaces/business.model';
import { HttpClient } from '@angular/common/http';
import { DocumentsService } from '@app/app/services/documents.service';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';
import * as JSZip from 'jszip';
import { documentsAllowedUploadFileTypes } from './documents-allowed-upload-file-types';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';

/**
 * Table/list of documents.
 *
 * This should be reusable for borrower or deal-centric documents depending
 * on the inputs.
 */
@Component({
  selector: 'app-documents-table',
  imports: [
    CommonModule,
    FileUploadModule,
    LendioPaginatorComponent,
    EmptyStateComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  // One of these (dealId, borrowerId) inputs will be undefined telling us
  // whether to get deal-specific docs or all for a borrower.
  @Input() borrowerId?: number;
  @Input() dealId?: number;

  @ViewChild(LendioPaginatorComponent) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Select(DocumentsState.documents) documents$: Observable<Document[]>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;
  @Select(BusinessState.business) business$: Observable<Business>;
  @Select(
    SaasFeaturesState.saasPermitted('funnelCrudMpDealDocs', 'lpxCanCrudMpDealDocs')
  ) canCrudMpDealDocs$: Observable<boolean>;

  canUpload$: Observable<boolean>;

  // TODO: Update these almost-same tableDataSource var names and add comments
  //       to clarify their different functions.
  tableDataSource$: Observable<MatTableDataSource<Document>>;
  private _tableDataSource = new MatTableDataSource<Document>();
  get tableDataSource(): MatTableDataSource<Document> {
    return this._tableDataSource;
  }
  destroyed$ = new Subject<void>();
  user: AuthUser | undefined;

  protected readonly allowedFileTypes = documentsAllowedUploadFileTypes;

  // Base columns used by any document list view (e.g. application-details).
  displayedColumns = [
    'checkbox',
    'title',
    'category',
    'monthsString',
    'created',
    'uploadedByName',
    'scanStatus',
    'menu',
  ];

  selection = new SelectionModel<Document>(true, []);

  emptyType = 'documents';
  emptyLabelContent = 'No documents have been uploaded for this business.';
  uploadText = 'to add relevant business documents'

  constructor(
    private store: Store,
    // Dialog for doc editing or confirming deletes.
    private dialog: MatDialog,
    private _snackbarService: LendioSnackbarService,
    private doc: DocumentsService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    if (this.dealId) {
      this.emptyLabelContent = 'No documents have been uploaded for this deal.';
      this.uploadText = 'to add relevant documents.'
    }

    if (this.borrowerId) {
      this.store.dispatch(new DocumentsActions.SubscribeToDocumentStateUpdates(this.borrowerId));
    }

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;
    });

    this.canUpload$ = combineLatest([
      this.business$,
      this.canCrudMpDealDocs$
    ]).pipe(
      map(([business, canCrudMpDealDocs]) => {
        return this._hasModifyAccess(business) ||
          // MP Deal, allow upload of deal docs only w/perm
          (this.dealId !== undefined && canCrudMpDealDocs);
      })
    )
  }

  ngAfterViewInit(): void {
    // Now that we have all the columns, bind the data to the table.
    this._convertDocumentsToMatTableDataSource();
  }

  ngOnDestroy(): void {
    if (this.borrowerId) {
      this.store.dispatch(new DocumentsActions.UnsubscribeFromDocumentStateUpdates(this.borrowerId));
    }
    this.destroyed$.next();
  }

  getDateAgo(timestamp: number): string {
    return moment.unix(timestamp).fromNow();
  }

  getDateString(timestamp: number): string {
    return moment.unix(timestamp).format("MMMM D, YYYY [at] h:mma")
  }

  // Convert MM/YYYY to MM YYYY.
  formatMonthYear(monthYear: string): string {
    return monthYear ? format(parse(monthYear, 'MM/yyyy', new Date()), 'MMM yyyy') : '';
  }

  // Differentiate between borrower and deal doc links since deal documents
  // log views while borrower docs (that aren't also deal docs) don't.
  viewDocument(documentId: number) {
    this.doc.openInNewTab(this.documentUrl(documentId), documentId);
  }

  deleteDocs($event: Event, document: Document|null = null) {
    // First, confirm.
    $event.stopPropagation();
    const documentIds: Array<number> = [];

    // Handle single doc from row menu or 1+ selected for bulk.
    if (document) {
        documentIds.push(document.id);
    } else {
      this.selection.selected.forEach((document) => {
        documentIds.push(document.id);
      });
    }

    const message = documentIds.length > 1
      ? ["documents", "Documents", "these", "were"]
      : ["document", "Document", "this", "was"];

    let confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete " + message[0] + "?",
        description: "Are you sure you want to delete " + message[2] + " " + message[0] + "?",
        cancelLabel: "Cancel",
        confirmLabel: "Delete",
        confirmStyles: "background-color: red;",
        width: "352px"
      }
    });

    // Cancel the "cancel" of the edit dialog.
    confirmDialogRef.componentInstance.onCancel.subscribe(() => {
      confirmDialogRef.close();
    });

    // Proceed with deleting the docs.
    confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
      documentIds.forEach((documentId) => {
        // Hit deal doc or borrower doc delete route
        const deleteAction = this.dealId !== undefined
          ? new DocumentsActions.DeleteDealDocument(this.dealId, documentId)
          : new DocumentsActions.Delete(documentId);

        this.store.dispatch(deleteAction).subscribe({
          error: () => {
            confirmDialogRef.close();
          },
          complete: () => {
            this.selection.clear();
            confirmDialogRef.close();
            this._snackbarService.open({
              message: message[1] + " " + message[3] + " deleted.",
              canDismiss: true,
              duration: 3000
            });
          }
        });
      });
    });
  }

  getDocType(type: string): string {
    if (!type) {
      return '';
    }
    return type.replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  }

  // If a document is provided, use it. Otherwise assume selections.
  downloadDocs(doc: Document|null = null): void {
    const zip = new JSZip();
    const downloadObservables: Observable<Blob>[] = [];
    if (doc) {
      downloadObservables.push(this.http.get(this.documentUrl(doc.id), { responseType: 'blob' }));
    } else {
      this.selection.selected.forEach((document) => {
        downloadObservables.push(this.http.get(this.documentUrl(document.id), { responseType: 'blob' }));
      });
    }
    forkJoin(downloadObservables).subscribe(responses => {
      responses.forEach((response, index) => {
        zip.file(`document_${doc ? doc.filename : this.selection.selected[index].filename ?? 'document.pdf'}`, response, { binary: true });
      });
      zip.generateAsync({ type: 'blob' }).then(content => {
        const fileURL = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `documents.zip`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      });
      this.selection.clear();
    });
  }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '372px',
      disableClose: true,
      // Props for file upload. All are optional and have defaults if not given.
      data: {
        // Specific to the dialog.
        title: 'Upload',
        closeLabel: 'Close',
        // Pass through to the file upload component.
        allowedFileTypes: documentsAllowedUploadFileTypes,
        // 20 MB - 1024 * 1024 * 20
        fileSizeLimit: 20971520,
        fileCount: 20,
        inline: false,
        fileSelectText: 'Drop files to upload',
        buttonText: 'or choose files'
      },
    });


    // Save documents and show snackbar confirming save success.
    dialogRef.componentInstance.fileInput.subscribe({
      next: (file: File) => {
        const formData = new FormData();
        if (this?.borrowerId) {
          formData.append('borrowerId', this.borrowerId.toString());
        }
        // Attach file to deal (in dealDocuments table) if this is a deal document upload.
        if (this?.dealId) {
          formData.append('attach', 'true');
          formData.append('dealId', this.dealId.toString());
        }
        this.store.dispatch(new DocumentsActions.Post(file, formData)).subscribe({
          next: () => {
            // TODO: This can be improved to only close after that last file
            //       upload is complete. Since they're posted asynchronously
            //       it will be rare for the timing to be long between first
            //       and final so leaving for now.
            setTimeout(() => {
              dialogRef.close();
              // TODO: show snackbar when all files are uploaded (instead of first completing).
              const message = "Upload was successful.";
              this._snackbarService.open({
                message,
                canDismiss: true,
                duration: 3000
              });
            }, 2000);
          }
        })
        // TODO: disable files from selection until scanStatus is clean.
      }
    });
  }

  openEditDialog(document: Document): void {
    const dialogRef = this.dialog.open(DocumentEditDialogComponent, {
      width: '372px',
      disableClose: true,
      // Props for file upload. All are optional and have defaults if not given.
      data: {
        document,
        dealId: this.dealId
      },
    });

    dialogRef.componentInstance.onSave.subscribe({
      next: () => {
        // TODO: show snackbar when all files are uploaded (instead of first completing).
        const message = "Changes have been saved.";
        this._snackbarService.open({
          message,
          canDismiss: true,
          duration: 3000
        });
      }
    });
  }


  zipAll(): void {
    // Select all rows/docs.
    this.tableDataSource.filteredData.forEach((document) => {
      if (document.scanStatus === 'CLEAN') {
        this.selection.select(document)
      }
    });
    this.downloadDocs();
  }

  private documentUrl(documentId: number): string {
    if (this.dealId) {
      return `${environment.apiUrl}/l/v2/internal/document/${documentId}/stream?dealId=${this.dealId}`;
    }
    if (this.borrowerId) {
      return `${environment.apiUrl}/document/${documentId}?stream=1`;
    }
    return ''
  }

  /**
   * Map documents$ into a MatTableDataSource.
   */
  private _convertDocumentsToMatTableDataSource(): void {
    this.tableDataSource$ = combineLatest([
      this.business$,
      this.documents$,
      this.currentUser$,
      this.canCrudMpDealDocs$
    ]).pipe(
      map(([business, documents, currentUser, canCrudMpDealDocs]) => {
        const tableDataSource = this._tableDataSource;
        // Calculate seconds since epoch for the "For month" property of each doc
        // This is used for sorting the table by the "For month" column
        const formattedDocuments = documents.map(doc => {
        const modifiedDoc = cloneDeep(doc);
          if (doc.months?.length) {
            const [month, year] = doc.months[0].split('/').map(x => parseInt(x) || 0);
            modifiedDoc.forMonthSinceEpoch = (new Date(year || 0, month || 0)).getTime();
          }

          modifiedDoc.isEditableAndDeletable = this._hasModifyAccess(business) ||
            // MP Deal, so only allow edit/delete of lender-owned deal doc w/perm
            (
              this.dealId !== undefined &&
              currentUser?.institution?.id == doc.lenderId &&
              canCrudMpDealDocs
            );

          return modifiedDoc;
        });

        tableDataSource.data = formattedDocuments;
        tableDataSource.sort = this.sort;
        tableDataSource.paginator = this.paginator;
        // Use lodash's get function to allow nested propery access when sorting.
        // See in template: mat-sort-header="updatedOn.raw".
        tableDataSource.sortingDataAccessor = get;
        // Define custom filter predicate to only filter by the visible row
        // data in a Document object.
        tableDataSource.filterPredicate = (data: Document, filter: string) => {
          return !filter
            || data.title.toLowerCase().includes(filter.toLowerCase())
            || data.category?.toLowerCase().includes(filter.toLowerCase())
            || data.monthsString?.toLowerCase().includes(filter.toLowerCase())
            || (this.getDateAgo(data.createdTimestamp).toLowerCase().includes(filter.toLowerCase()) ?? false)
            || (this.getDateString(data.createdTimestamp).toLowerCase().includes(filter.toLowerCase()) ?? false)
            || (data.uploadedByName?.toLowerCase().includes(filter.toLowerCase()) ?? false)
        }
        return tableDataSource;
      })
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  /** Select/clear all rows. */
  toggleAllRows() {
    if (this.selection.selected.length > 0) {
      this.selection.clear();
    } else {
      this.tableDataSource.filteredData.forEach((business) =>
        this.selection.select(business)
      );
    }
  }

  // Disable buttons that required 1+ docs to be selected to work.
  noneSelected() {
    return (this.tableDataSource && this.tableDataSource.filteredData.length === 0) || !this.selection.hasValue()
  }

  aDocumentInSelectionIsNotDeletable() {
    return this.selection.selected.some((doc) => !doc.isEditableAndDeletable)
  }

  uncleanDocsSelected() {
    return (
      this.selection.hasValue()
      && this.selection.selected.some(x => x.scanStatus !== "CLEAN")
    )
  }

  // Search by any value in document rows.
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  // Only show Zip All if we have documents.
  showZipAll(): boolean {
    return this.tableDataSource.data.length > 0
  }

  protected _hasModifyAccess(business?: Business) {
    return (
      business?.id == this.borrowerId &&
      business?.accessLevel === BusinessAccessLevel.Modify
    );
  }

  scanIsInconclusive(document: Document): boolean {
    if (!document) {
      return true;
    } else {
      return (
        document.scanStatus === null ||
        document.scanStatus === undefined ||
        document.scanStatus === 'PENDING'
      );
    }
  }
}
