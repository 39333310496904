import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from '../../environments/environment';

interface PusherChannelSpec {
  auth: boolean;
  name: string;
  handler: (eventName: string, data: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;

  constructor() {
    this.pusher = new Pusher(environment.PUSHER_APP_KEY);
  }

  /**
   * @param channel
   */
  subscribe(channel: PusherChannelSpec) {
    const channelName = this.channelName(channel);
    let pusherChannel = this.pusher.channel(channelName);
    if (pusherChannel) {
      return;
    } else {
      pusherChannel = this.pusher.subscribe(channelName);
    }

    pusherChannel.bind_global((eventName: string, data: any) => {
      if (eventName.startsWith('pusher:')) {
        return;
      }
      channel.handler(eventName, data);
    });
  }

  /**
   * @param channel
   */
  unsubscribe(channel: PusherChannelSpec) {
    this.pusher.unsubscribe(this.channelName(channel));
  }

  /**
   * @param channel
   */
  private channelName(channel: PusherChannelSpec) {
    const visibility = channel.auth ? 'private' : 'public';
    const app = 'partner';
    const env = environment.environment === 'production' ? 'LIVE' : environment.environment;
    return `${visibility}-${env}-${app}-${channel.name}`;
  }
}
