export enum DealStatus {
  AppComplete = 'appComplete',
  AppInProgress = 'appInProgress',
  AwaitingResponse = 'awaitingResponse',
  Behind = 'behind',
  ContractIn = 'contractIn',
  ContractOut = 'contractOut',
  ContractRequested = 'contractRequested',
  Current = 'current',
  Dead = 'dead',
  Declined = 'declined',
  Default = 'default',
  DoesNotQualify = 'doesNotQualify',
  ExpectationsCall = 'expectationsCall',
  Funding = 'funding',
  LostContact = 'lostContact',
  OfferAccepted = 'offerAccepted',
  OfferDeclined = 'offerDeclined',
  OfferReceived = 'offerReceived',
  OfferToBorrower = 'offerToBorrower',
  PaidOff = 'paidOff',
  PendingFundingManager = 'pendingFundingManager',
  Renewed = 'renewed',
  Stacked = 'stacked',
}
