@if (data.link) {
    <div mat-dialog-title>{{ data.loanProductCategory }} Offer Link</div>
} @else {
    <div mat-dialog-title data-cy="offer-dialog-loan-product-header">
        Add {{ data.loanProductCategory }} offer
        <span *ngIf="data.borrowerName">for {{ data.borrowerName }}</span>
        <span *ngIf="data.isFundingInterstitial"> to move to funded status</span>
        <button mat-icon-button
                class="!absolute !top-5 !right-4"
                (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <p *ngIf="data.isFundingInterstitial" class="font-normal pt-2 px-6">
        In order to change this deal status to Funded status, you must
        first specify the offer details you are marking as funded.
    </p>
}

<mat-dialog-content>
	<app-new-offer-smart
		[dealId]="data.dealId"
		[loanProductCategory]="data.loanProductCategory"
		[link]="data.link"
		[localeCurrencySymbol]="data.localeCurrencySymbol"
        [isFundingInterstitial]="data.isFundingInterstitial"
        (newOfferCreated)="_handleNewOfferCreated($event)"
    />

</mat-dialog-content>
