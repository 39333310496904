import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import {
  ClearFunnelViewsStore,
  GetFunnelViews,
  UpdateFunnelViews,
} from './funnel-views.actions';
import { ColumnFilterModel } from '@app/app/components/grid-components/grid-types';
import { FunnelViewFiltersService } from '@app/app/services/funnel-view-filters.service';
import { AuthState } from '@app/app/store/auth/auth.state';
import { SaasFeaturesState } from '../saas-features/saas-features.state';

const dateStringFormatter = (date: Date): string => date.toISOString().slice(0, 19).replace('T', ' ');
const gettingColdFilterDate = new Date();
gettingColdFilterDate.setDate(gettingColdFilterDate.getDate() - 2);
const formattedGettingColdDate = dateStringFormatter(gettingColdFilterDate);

export interface AgGridViewFilter {
  agGridViewId?: number;
  name: string;
  filterModel: ColumnFilterModel;
  isCreatedByUser: boolean;
  isHiddenByUser: boolean;
}

export class FunnelViewsStateModel {
  funnelViews: AgGridViewFilter[];
  activeTableName: string;
  loading: boolean;
}

@State<FunnelViewsStateModel>({
  name: 'funnelViews',
  defaults: {
    funnelViews: [],
    activeTableName: 'funnel',
    loading: false,
  },
})
@Injectable()
export class FunnelViewsState {
  @Selector()
  static funnelViews(state: FunnelViewsStateModel) {
    return state.funnelViews;
  }

  constructor(
    private _store: Store,
    private _viewFilterService: FunnelViewFiltersService
  ) { }

  @Action(GetFunnelViews)
  getFunnelViews({ patchState }: StateContext<FunnelViewsStateModel>) {
    const authUser = this._store.selectSnapshot(AuthState.user);
    const isSbaSpecialist = this._store.selectSnapshot(AuthState.isSbaSpecialist);
    const agGridTable = isSbaSpecialist ? 'sba-funnel' : 'funnel';

    return this._viewFilterService.getFunnelViews(agGridTable).pipe(
      catchError((error) => {
        patchState({ loading: false });
        return throwError(error);
      }),
      tap({
        next: (response) => {
          const funnelViews = response.data;
          const myDealsIndex = funnelViews?.findIndex(viewFilter => viewFilter.name === 'My deals');
          const gettingColdIndex = funnelViews?.findIndex(viewFilter => viewFilter.name === 'Getting cold');

          if (myDealsIndex !== - 1) {
            const userDealsFilter = {
              assignments: {
                filterType: 'text',
                type: 'includes',
                filter: `${authUser?.first} ${authUser?.last}`,
              }
            };
            funnelViews[myDealsIndex].filterModel = { ...userDealsFilter };
          }

          if (gettingColdIndex !== -1) {
            const gettingColdViewFilter = funnelViews[gettingColdIndex]?.filterModel;
            const gettingColdViewFilterModel = {
              modified: {
                filterType: 'date',
                type: 'lessThan',
                dateFrom: formattedGettingColdDate,
                dateTo: null,
              }
            }
            
            funnelViews[gettingColdIndex].filterModel = {
              ...gettingColdViewFilter,
              ...gettingColdViewFilterModel
            }
          }
          
          patchState({
            funnelViews,
            activeTableName: agGridTable,
            loading: false
          })
        },
      })
    )
  }

  @Action(UpdateFunnelViews)
  updateFunnelViews(
    { patchState, getState }: StateContext<FunnelViewsStateModel>,
    { funnelViews }: UpdateFunnelViews
  ) {
    const isSbaSpecialist = this._store.selectSnapshot(AuthState.isSbaSpecialist);
    const agGridTable = isSbaSpecialist ? 'sba-funnel' : 'funnel';
    const state = getState();

    // In the event that a gridTable does not match the state of our user, let's refetch views & set the activeTableName vs. saving to the wrong namespace
    if (agGridTable !== state.activeTableName) {
      return this._store.dispatch(new GetFunnelViews());
    }

    this._viewFilterService.updateFunnelViews(funnelViews, agGridTable).pipe(
      catchError((error) => throwError(() => error)),
      tap({ 
        next: () => patchState({ funnelViews }),
      })
    ).subscribe();
  }

  @Action(ClearFunnelViewsStore)
  clearFunnelViewsStore({ patchState }: StateContext<FunnelViewsStateModel>) {
    this._viewFilterService.updateFunnelViews([]);
    patchState({ funnelViews: [] });
  }
}
