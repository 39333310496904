import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Notification } from '../../../../interfaces/notification.model';
import { Store, Select, ofActionSuccessful, Actions } from '@ngxs/store';
import { DeleteNotification, PutNotificationRead, PutNotificationUnread } from 'src/app/store/notifications/notifications.actions';
import { NotificationsState } from 'src/app/store/notifications/notifications.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-item-smart',
  templateUrl: './item-smart.component.html',
  styleUrls: ['./item-smart.component.scss'],
  standalone: false
})
export class ItemSmartComponent implements OnInit, OnDestroy {
  // @Select(NotificationsState.deletingAll) deletingAll$: Observable<boolean>;
  deletingAll$: Observable<boolean> = this.store.select(NotificationsState.deletingAll);
  // @Select(NotificationsState.togglingAllRead) togglingAllRead$: Observable<boolean>;
  togglingAllRead$: Observable<boolean> = this.store.select(NotificationsState.togglingAllRead);
  // @Select(NotificationsState.togglingRead) togglingRead$: Observable<object>;
  togglingRead$: Observable<object> = this.store.select(NotificationsState.togglingRead);
  // @Select(NotificationsState.deleting) deleting$: Observable<object>;
  deleting$: Observable<object> = this.store.select(NotificationsState.deleting);

  @Input() notification: Notification;

  private ngUnsubscribe = new Subject();

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  redirect() {
    this.router.navigate([`/deal/${this.notification.dealId}`]);
  }

  toggleRead(redirect = false) {
    const activityId = this.notification.activityId;
    if (redirect && this.notification.read) {
      this.redirect();
      return;
    }
    if (redirect) {
      this.actions$.pipe(
          ofActionSuccessful(PutNotificationRead),
          takeUntil(this.ngUnsubscribe)
      ).subscribe(
        () => this.redirect()
      );
    }

    if (this.notification.read) {
      this.store.dispatch(new PutNotificationUnread(activityId));
      return;
    }
    this.store.dispatch(new PutNotificationRead(activityId));
  }

  delete() {
    const activityId = this.notification.activityId;
    this.store.dispatch(new DeleteNotification(activityId));
  }
}
