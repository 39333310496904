import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TermsComponent } from '../../components/terms/terms.component';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';
import { Alert } from '@app/app/interfaces/alert.model';
import { Observable } from 'rxjs';
import { ClearAuthAlert } from '@app/app/store/auth/auth.actions';

@Component({
  selector: 'app-auth-base',
  templateUrl: './auth-base.component.html',
  styleUrls: ['./auth-base.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ]),
  ],
  standalone: false
})
export class AuthBaseComponent {

  @Select(AuthState.alert) authAlert$: Observable<Alert>;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  get action(): string {
    return this.route.snapshot.params.action;
  }

  getYear() {
    return (new Date()).getFullYear();
  }

  openTC() {
    this.dialog.open(TermsComponent, {
      width: '80%',
      data: {}
    });
  }

  dismissAlert() {
    this.store.dispatch(new ClearAuthAlert());
  }
}
