<div (click)="$event.stopPropagation()" class="dynamic-document-wrapper">
@if (mouseOver && scanIsClean) {
    <div [ngClass]="{ 'flex-end': rejected, 'flex-start': !rejcted }" class="flex flex-row">
        <div class="flex items-center">
            <!-- Only show accept/reject actions if we are in a deal context -->
            @if (dealId) {
                <button (click)="handleAccept($event)" 
                        [disabled]="rejected"
                        class="flex items-center justify-center mr-3">
                    <mat-icon [matTooltip]="accepted ? 'Undo acceptance' : (rejected ? '' : 'Accept document')" 
                            matTooltipPosition="below"
                            matTooltipShowDelay="500"
                            [class]="accepted ? 'text-lendio-green-400': (rejected  ? 'invisible' : '')"
                            class="material-icons-outlined history-icon">
                            check
                    </mat-icon>
                </button>
                <button [matTooltip]="rejected ? '' : 'Reject document'" 
                        matTooltipPosition="below"
                        matTooltipShowDelay="500"
                        [disabled]="rejected"
                        (click)="handleReject($event)"
                        class="flex items-center mr-3">
                    <mat-icon [class]="rejected ? 'invisible' : ''" class="material-icons-outlined">close</mat-icon>
                </button>
            }
            <button [matTooltip]="scanIsClean ? 'Download' : 'Download disabled: virus scan pending.'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!scanIsClean"
                    (click)="handleDownload($event)"
                    class="flex items-center mr-3">
                <mat-icon [class]="!scanIsClean ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">file_download</mat-icon>
            </button>
            <button [matTooltip]="!canEditAndDelete ? 'You do not have edit permissions' : 'Edit'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!canEditAndDelete"
                    (click)="handleEdit($event)"
                    class="flex items-center mr-3">
                <mat-icon [class]="!canEditAndDelete ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">edit</mat-icon>
            </button>
            <button [matTooltip]="!canEditAndDelete ? 'You do not have delete permissions' : 'Delete document'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!canEditAndDelete"
                    (click)="handleDelete($event)"
                    class="flex items-center">
                <mat-icon [class]="!canEditAndDelete ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">delete</mat-icon>
            </button>
        </div>
    </div>
} @else {
    <!-- Only show accepted/rejected indicators if we are in a deal context -->
    @if (dealId) {
        <div *ngIf="accepted" >
            <span>Accepted</span>
        </div>
        <div *ngIf="rejected">
            <span>Rejected</span>
        </div>
    }
}
</div>
