import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, first } from 'rxjs';
import { GetLenderStatistics } from 'src/app/store/lender-statistics/lender-statistics.actions';
import { LenderStatisticsState } from 'src/app/store/lender-statistics/lender-statistics.state';
import { Router } from '@angular/router';
import { LENDER_LIVEBOARD_ID } from 'src/app/components/thoughtspot-liveboard/thoughtspot-boards';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';

@Component({
  selector: 'app-dashboard-smart',
  templateUrl: './dashboard-smart.component.html',
  styleUrls: ['./dashboard-smart.component.scss'],
  standalone: false
})
export class DashboardSmartComponent implements OnInit {
  protected readonly lenderLiveboardId = LENDER_LIVEBOARD_ID;
  @Select(SaasFeaturesState.initialLoadComplete) saasFeaturesLoaded$: Observable<boolean>
  @Select(SaasFeaturesState.saasPermitted('dashboardLenderBase')) hasLenderDashboardFeature$: Observable<boolean>;
  @Select(LenderStatisticsState.lenderStatistics) lenderStatistics$: Observable<any>;
  @Select(LenderStatisticsState.formattedStatistics) formattedStatistics$: Observable<any>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;

  user: AuthUser | undefined;
  constructor(
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$.pipe(
      first((user) => user !== null)
    ).subscribe(user => {
      this.user = user;
      if (!this.isSupportedForUser()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.router.navigateByUrl(localStorage.getItem('Home'));
        return;
      }
      this.store.dispatch(new GetLenderStatistics());
    });
  }

  isSupportedForUser(): boolean | number | null | undefined {
    const hasLenderTSDashboard = this.store.selectSnapshot(SaasFeaturesState.saasPermitted('dashboardLenderBase'));
    return this.user && this.user.institution?.id && !hasLenderTSDashboard && !this.user.ppp;
  }
}
