import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-awaiting-response-table',
  templateUrl: './awaiting-response-table.component.html',
  styleUrls: ['./awaiting-response-table.component.scss'],
  standalone: false
})
export class AwaitingResponseTableComponent implements OnInit {
  @Input() awaitingResponse: any;

  constructor() { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }
}
