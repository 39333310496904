import { Action, Actions, Selector, State, StateContext, Store, ofActionCompleted } from '@ngxs/store';
import {
  GetBorrowerDecisioningForProduct,
  GetLenderDecisioningBatches,
  AddNewDecisioningProgress,
  UpdateDecisioningProgress,
  SubscribeToDecisioningBatchPusherUpdates,
  UpdateDecisioningReRun,
  AddNewDecisioningReRun,
  PostDecisioningOverrides,
  SubscribeToDecisioningReRunResults,
  UnsubscribeFromDecisioningReRunResults,
  SubscribeToLenderDecisioning,
  UnsubscribeFromLenderDecisioning,
  GetDecisioningResultForBorrower,
  QueueBorrowerDecisioning,
  FetchWithUpdatedParams
} from '@app/app/store/decisioning/decisioning.actions';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { lastValueFrom, combineLatest, of } from 'rxjs';
import { AttributesMap, DecisioningService, PaginatedDecisioningBatchessResponse } from '../../services/decisioning.service';
import { Injectable } from '@angular/core';
import { Decisioning } from '@app/app/interfaces/decisioning.model';
import { DecisioningBatch } from "@app/app/interfaces/decisioning-batch.model";
import { DecisioningResult } from "@app/app/interfaces/decisioning-result.model";
import { QualifierValue } from '@app/app/interfaces/qualifier.model';
import { BorrowerValuesSource } from '@app/app/interfaces/borrower-values-source.model';
import { PusherService } from '@app/app/services/pusher.service';
import { keyBy } from 'lodash';
import { patch, updateItem } from '@ngxs/store/operators';
import { AuthState } from '../auth/auth.state';
import { RefreshBusinesses } from '../businesses/businesses-list.actions';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { Router } from '@angular/router';

export class DecisioningStateModel {
  attributesMap: AttributesMap;
  decisioning: any;
  decisioningBatch: DecisioningBatch[];
  decisioningProgress: DecisioningBatch[];
  decisioningReRun: DecisioningResult[];
  decisioningResults: DecisioningResult[];
  isDecisioningReRunQueued: boolean;
  isDecisioningRetryQueued: boolean;
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  filterBy: string;
  page: number;
  lastPage: number;
  total: number;
  next_page_url: string;
  prev_page_url: string;
}

@State<DecisioningStateModel>({
  name: 'decisioning',
  defaults: {
    attributesMap: {},
    decisioning: [],
    decisioningBatch: [],
    decisioningProgress: [],
    decisioningReRun: [],
    decisioningResults: [],
    isDecisioningReRunQueued: false,
    isDecisioningRetryQueued: false,
    loading: false,
    pageIndex: 0,
    pageSize: 20,
    sortBy: 'created',
    sortDirection: 'desc',
    filterBy: '',
    page: 1,
    lastPage: 0,
    total: 0,
    next_page_url: '',
    prev_page_url: '',
  }
})

@Injectable()
export class DecisioningState {
  /**
   * Begin pagination selectors
   */
  @Selector()
  static filterBy(state: DecisioningStateModel) {
      return state.filterBy;
  }

  @Selector()
  static nextPageUrl(state: DecisioningStateModel) {
      return state.next_page_url;
  }

  @Selector()
  static page(state: DecisioningStateModel) {
      return state.page;
  }

  @Selector()
  static pageIndex(state: DecisioningStateModel) {
      return state.pageIndex;
  }

  @Selector()
  static pageSize(state: DecisioningStateModel) {
      return state.pageSize;
  }

  @Selector()
  static prevPageUrl(state: DecisioningStateModel) {
      return state.prev_page_url;
  }

  @Selector()
  static sortBy(state: DecisioningStateModel) {
      return state.sortBy;
  }

  @Selector()
  static sortDirection(state: DecisioningStateModel) {
      return state.sortDirection;
  }

  @Selector()
  static total(state: DecisioningStateModel) {
      return state.total;
  }
  /**
   * End pagination selectors
   */

  @Selector()
  static attributesMap(state: DecisioningStateModel) {
    return state.attributesMap;
  }

  @Selector()
  static decisioning(state: DecisioningStateModel) {
    return state.decisioning;
  }

  @Selector()
  static decisioningBatch(state: DecisioningStateModel) {
    return state.decisioningBatch;
  }

  @Selector()
  static decisioningProgress(state: DecisioningStateModel) {
    return state.decisioningProgress;
  }

  @Selector()
  static decisioningReRun(state: DecisioningStateModel) {
    return state.decisioningReRun;
  }

  @Selector()
  static decisioningResults(state: DecisioningStateModel) {
    return state.decisioningResults;
  }

  @Selector()
  static isDecisioningReRunQueued(state: DecisioningStateModel) {
    return state.isDecisioningReRunQueued;
  }

  @Selector()
  static isDecisioningRetryQueued(state: DecisioningStateModel) {
    return state.isDecisioningRetryQueued;
  }

  @Selector()
  static loading(state: DecisioningStateModel) {
    return state.loading;
  }

  @Selector()
  static rejectedQualifiers(state: DecisioningStateModel) {
    let rejectedQualifierValues: QualifierValue[] = [];
    state.decisioning.forEach(decisioningRecord => {
      decisioningRecord?.preapprovalQualifierValues?.forEach(qualifierValue => {
        if (qualifierValue.status === 'rejected' || qualifierValue.override) {
          rejectedQualifierValues.push(qualifierValue);
        }
      });
      decisioningRecord?.approvalQualifierValues?.forEach(qualifierValue => {
        if (qualifierValue.status === 'rejected' || qualifierValue.override) {
          rejectedQualifierValues.push(qualifierValue);
        }
      });
    });

    return rejectedQualifierValues;
  }

  @Selector()
  static decisioningHasOverrides(state: DecisioningStateModel) {
    let decisioningHasOverrides = false;
    state.decisioning.forEach(decisioningRecord => {
      decisioningRecord.preapprovalQualifierValues?.forEach(preapprovalQualifierValue => {
        if (preapprovalQualifierValue.override) {
          decisioningHasOverrides = true;
          return;
        }
      })
      decisioningRecord.approvalQualifierValues?.forEach(approvalQualifierValue => {
        if (approvalQualifierValue.override) {
          decisioningHasOverrides = true;
          return;
        }
      })
    });

    return decisioningHasOverrides;
  }

  /**
   * Determine if the product is eligible for a decisioning retry
   * @param state
   * @returns boolean
   */
  @Selector()
  static isEligbleForDecisioningRetry(state: DecisioningStateModel) {
    const decisioningData = state.decisioning[0];
    if (!decisioningData) {
      return false;
    }

    // Decisioning finished, do we have at least one rejected/missing value?
    return decisioningData.matchData.resultsByQualifier.some(qualifier => {
      return (qualifier.matchValue == 'rejected' && qualifier.usedValue == null) ||
        (qualifier.field.includes('computed.isDscrQualifiedYear') && qualifier.usedValue == 'Missing');
    })
  }

  /**
   * Determine if decisioning failed and exited early
   * @param state
   * @returns boolean
   */
  @Selector()
  static decisioningFailed(state: DecisioningStateModel) {
    const decisioningData = state.decisioning[0];
    if (!decisioningData) {
      return false;
    }

    // Did decisioning fail and exit early?
    return (
      decisioningData.matchData.matchValue == 'rejected'
      && decisioningData.matchData.resultsByQualifier.length == 0
    );
  }

  /**
   * Determine if the loanProduct is qualified for decisioning override
   * @param state
   * @returns boolean
   */
  @Selector()
  static isOverrideable(state: DecisioningStateModel) {
    let decisioningData = state.decisioning[0];
    if (!decisioningData) {
      return false;
    }

    let qualifiersMatched = (decisioningData.preapprovalQualifierValues && decisioningData.preapprovalQualifierValues?.length > 0 ? decisioningData.preapprovalMatchPercentage === 100 : false)
      && (decisioningData.approvalQualifierValues && decisioningData.approvalQualifierValues?.length > 0 ? decisioningData.approvalMatchPercentage === 100 : false);
    let pricingQualifiers;
    let rejectedQualifierResults = decisioningData.matchData.resultsByQualifier.filter(result => {
      return result.matchValue === 'rejected';
    });
    rejectedQualifierResults?.forEach((rejectedQualifier) => {
      const decisioningQualifier = decisioningData.product.decisioningQualifiers.find(decisioningQualifier => decisioningQualifier.field === rejectedQualifier.field);
      if (decisioningQualifier && decisioningQualifier['loanProductPricingRuleSetId']) {
        pricingQualifiers.push(decisioningQualifier);
      }
    });
    let rejectedPricingQualifiers = pricingQualifiers?.length > 0;

    return !qualifiersMatched && !rejectedPricingQualifiers;
  }

  constructor(
    private decisioningService: DecisioningService,
    private store: Store,
    private pusherService: PusherService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private actions$: Actions
  ) { }

  /**
   * Fetch decisioning batches w/ updated params
   * @param paramChanges
   * @param shouldReloadOnFetch
   */
  @Action(FetchWithUpdatedParams)
  fetchWithUpdatedParams(
    { getState, patchState }: StateContext<DecisioningStateModel>,
    { paramChanges, shouldReloadOnFetch = false }: FetchWithUpdatedParams) {

    if (shouldReloadOnFetch) {
      patchState({ loading: true });
    } else {
      patchState({ loading: false });
    }
    patchState(paramChanges);

    const { pageIndex, pageSize, sortBy, sortDirection, filterBy } = getState();

    return this.decisioningService.getLenderDecisioningBatches(
      pageIndex,
      pageSize,
      sortBy,
      sortDirection,
      filterBy
    ).pipe(
      catchError(() => {
        return of()
      }),
      tap((res: PaginatedDecisioningBatchessResponse) => {
        patchState({
          loading: false,
          decisioningBatch: res.data.decisioningBatches,
          page: res.data.current_page,
          lastPage: res.data.last_page,
          total: res.data.total,
          next_page_url: res.data.next_page_url
        });
      })
    )
  }

  /**
   * Fetches borrower decisioning for loan product
   * @param ctx
   * @param borrowerId
   * @param loanProductId
   */
  @Action(GetBorrowerDecisioningForProduct)
  getBorrowerDecisioningForProduct(
    ctx: StateContext<DecisioningStateModel>,
    { borrowerId, loanProductId }: GetBorrowerDecisioningForProduct,
  ) {
    ctx.patchState({
      loading: true,
      decisioning: [],
      attributesMap: {}
    });

    return combineLatest([
        this.decisioningService.getBorrowerDecisioningForProduct(borrowerId, loanProductId),
        this.decisioningService.getTrustedBorrowerValuesSources(),
      ])
      .pipe(takeUntil(this.actions$.pipe(ofActionCompleted(GetBorrowerDecisioningForProduct))),
        catchError((err) => {
          /**
           * It's possible for Lenders to have both MP and IL deals
           * When this is the case and the loanProduct doesn't contain decisioningQualifiers
           * Let's gracefully return
           */
          /**
           * It's possible for decisioning to fail or pseudo complete
           * This leaves us w/ no snapshot data, but we do have failed decisioningResults
           * WHen this is the case, gracefully return
           */
          const decisioningResults = ctx.getState().decisioningResults;
          const didLoanProductFail = decisioningResults.some((result) => {
            return result.loanProductId === loanProductId && result.decisioningStatusId === 3; // failed
          })
          if (err.status !== 404 && !didLoanProductFail) {
            this.store.dispatch(new CreateNewAlert({
              level: 'error',
              message: 'Unable to fetch Decisioning'
            }));
          }
          ctx.patchState({
            loading: false,
          });
          return of();
        }),
        tap(([decisioningResponse, trustedSourcesResponse]) => {
          const trustedBorrowerValuesSources = trustedSourcesResponse?.data?.trustedSources || [];
          const attributesMap: AttributesMap = decisioningResponse?.data?.attributes || {};
          const decisioning: Decisioning[] = decisioningResponse?.data?.decisioning.map<Decisioning>(dec => {
            const preapprovalQualifierValues = this.formatDecisioningQualifierValues(dec, attributesMap, true, trustedBorrowerValuesSources);
            const approvalQualifierValues = this.formatDecisioningQualifierValues(dec, attributesMap, false, trustedBorrowerValuesSources);
            const preapprovalMatched = preapprovalQualifierValues.filter(qv => qv.status === 'accepted').length;
            const preapprovalMatchPercentage = Math.round((preapprovalMatched / preapprovalQualifierValues.length) * 100);
            const approvalMatched = approvalQualifierValues.filter(qv => qv.status === 'accepted').length;
            const approvalMatchPercentage = Math.round((approvalMatched / approvalQualifierValues.length) * 100);
            dec.preapprovalQualifierValues = preapprovalQualifierValues;
            dec.approvalQualifierValues = approvalQualifierValues;
            dec.preapprovalMatchPercentage = preapprovalMatchPercentage;
            dec.approvalMatchPercentage = approvalMatchPercentage;
            return {
              ...dec
            }
          });

          ctx.setState(
            patch<DecisioningStateModel>({
              loading: false,
              decisioning,
              attributesMap: decisioningResponse.data.attributes,
              isDecisioningRetryQueued: false
            })
          )
        })
    );
  }

  /**
   * @param ctx
   * @param borrowerId
   * @param loanProductId
   */
  @Action(GetDecisioningResultForBorrower)
  getDecisioningResultForBorrower(
    ctx: StateContext<DecisioningStateModel>,
    { borrowerId, loanProductId }: GetDecisioningResultForBorrower,
  ) {
    return this.decisioningService.getDecisioningResultsForBorrower(borrowerId, loanProductId).pipe(
      tap((response) => {
        ctx.setState(
          patch<DecisioningStateModel>({
            decisioningResults: response.data.decisioningResults,
          })
        )
      })
    );
  }

  /**
   * Fetches decisioning batches for lender
   */
  @Action(GetLenderDecisioningBatches)
  getLenderDecisioningBatches(
    { getState, setState }: StateContext<DecisioningStateModel>,
    { }: GetLenderDecisioningBatches,
  ) {
    const { pageIndex, pageSize, sortBy, sortDirection, filterBy } = getState();
    return lastValueFrom(this.decisioningService.getLenderDecisioningBatches(
      pageIndex,
      pageSize,
      sortBy,
      sortDirection,
      filterBy
    )
      .pipe(takeUntil(this.actions$.pipe(ofActionCompleted(GetLenderDecisioningBatches))),
        catchError(() => {
          return of()
        }),
        tap((res: PaginatedDecisioningBatchessResponse) => {
          setState(
            patch<DecisioningStateModel>({
              decisioningProgress: res.data.decisioningBatches,
              decisioningBatch: res.data.decisioningBatches
            })
          );
        })
      ), { defaultValue: [] })
  }

  /**
   * @param ctx
   * @param borrowerId
   * @param loanProductId
   */
  @Action(QueueBorrowerDecisioning)
  queueBorrowerDecisioning(ctx: StateContext<DecisioningStateModel>, { borrowerId, loanProductId }: QueueBorrowerDecisioning) {
    ctx.setState(
      patch<DecisioningStateModel>({
        decisioning: [],
        attributesMap: {},
        isDecisioningRetryQueued: true,
      })
    );

    return this.decisioningService.queueBorrowerDecisioning(borrowerId, loanProductId);
  }

  @Action(AddNewDecisioningProgress)
  addNewDecisioningProgress(ctx: StateContext<DecisioningStateModel>, { decisioningBatch }: AddNewDecisioningProgress) {
    ctx.setState(
      patch<DecisioningStateModel>({
        decisioningProgress: [...ctx.getState().decisioningProgress, decisioningBatch],
      })
    );
  }

  @Action(UpdateDecisioningProgress)
  updateDecisioningProgress(ctx: StateContext<DecisioningStateModel>, { decisioningBatch }: UpdateDecisioningProgress) {
    const batchExistsInState = ctx.getState().decisioningProgress.filter(d => d.id === decisioningBatch.id).length > 0;
    if (batchExistsInState === true) {
      // Check if batch is complete
      const completionPercent = Math.trunc(decisioningBatch.total ? ((decisioningBatch.totalSucceeded + decisioningBatch.totalFailed) / decisioningBatch.total) * 100 : 0);
      const complete = (completionPercent == 100 && decisioningBatch.totalFailed <= 0);
      ctx.setState(
        patch<DecisioningStateModel>({
          decisioningProgress: updateItem<DecisioningBatch>(x => x.id === decisioningBatch.id, decisioningBatch),
        })
      );

      if (complete) {
        // Refresh Businesses based on Decisioning being completed
        ctx.dispatch(new RefreshBusinesses());
      }
    } else {
      this.store.dispatch(new AddNewDecisioningProgress(decisioningBatch));
    }
  }

  /**
   * @param ctx
   * @param decisioningResult
   */
  @Action(AddNewDecisioningReRun)
  addNewDecisioningReRun(ctx: StateContext<DecisioningStateModel>, { decisioningResult }: AddNewDecisioningReRun) {
    ctx.setState(
      patch<DecisioningStateModel>({
        decisioningReRun: [...ctx.getState().decisioningReRun, decisioningResult],
        isDecisioningReRunQueued: true,
      })
    );
  }

  /**
   * @param ctx
   * @param decisioningResult
   */
  @Action(UpdateDecisioningReRun)
  updateDecisioningReRun(ctx: StateContext<DecisioningStateModel>, { decisioningResult }: UpdateDecisioningReRun) {
    ctx.setState(
      patch<DecisioningStateModel>({
        decisioningReRun: updateItem<DecisioningResult>(x => x.id === decisioningResult.id, decisioningResult),
      })
    );

    const currentReRun = ctx.getState().decisioningReRun;
    // Is the decisioning re-run complete?
    const done = currentReRun.every(result => {
      return result.decisioningStatusId == 2;
    });

    if (!done) {
      return;
    } else {
      // Clear state
      ctx.setState(
        patch<DecisioningStateModel>({
          decisioningReRun: [],
          isDecisioningReRunQueued: false,
          isDecisioningRetryQueued: false,
        })
      );
    }
  }

  /**
   * Post decisioning qualifier overrides for borrower loan product decisioning
   * @param ctx
   * @param borrowerId
   * @param dealId
   * @param overrides
   */
  @Action(PostDecisioningOverrides)
  postDecisioningOverrides(
    ctx: StateContext<DecisioningStateModel>,
    { borrowerId, dealId, overrides }: PostDecisioningOverrides,
  ) {
    ctx.setState(
      patch<DecisioningStateModel>({
        isDecisioningReRunQueued: true
      })
    );
    return this.decisioningService.postDecisioningOverrides(borrowerId, dealId, overrides);
  }

  /**
   * Lender decisioning
   * @param dealId
   * @param borrowerId
   * @param lenderId
   */
  @Action(SubscribeToLenderDecisioning)
  subscribeToLenderDecisioning({ }: StateContext<DecisioningStateModel>, { dealId, borrowerId, lenderId }: SubscribeToLenderDecisioning) {
    // No lender, get out
    if (!lenderId) {
      return;
    }

    // Make sure this is the active deal
    const activeDealId = this.getActiveDealId();

    // And only update if it's our state
    if (activeDealId !== dealId) {
      return;
    }

    this.pusherSubscribeToLenderDecisioning(dealId, borrowerId, lenderId);
  }

  /**
   * Lender decisioning
   * @param borrowerId
   * @param lenderId
   */
  @Action(UnsubscribeFromLenderDecisioning)
  unsubscribeFromLenderDecisioning({ }: StateContext<DecisioningStateModel>, { borrowerId, lenderId }: UnsubscribeFromLenderDecisioning) {
    // No lender, get out
    if (!lenderId) {
      return;
    }

    this.pusherUnsubscribeFromLenderDecisioning(borrowerId, lenderId);
  }

  /**
   * Subscribe lender to decisioning batches
   */
  @Action(SubscribeToDecisioningBatchPusherUpdates)
  subscribeToDecisioningBatchPusherUpdates({ }: StateContext<DecisioningStateModel>, { }: SubscribeToDecisioningBatchPusherUpdates) {
    this.pusherSubscribe();
  }

  /**
   * Subscribe lender to decisioning re-runs
   */
  @Action(SubscribeToDecisioningReRunResults)
  subscribeToDecisioningReRunResults({ }: StateContext<DecisioningStateModel>, { }: SubscribeToDecisioningReRunResults) {
    this.pusherSubscribeToDecisioningReRunResults();
  }

  /**
   * Subscribe lender to decisioning re-runs
   */
  @Action(UnsubscribeFromDecisioningReRunResults)
  unsubscribeFromDecisioningReRunResults({ }: StateContext<DecisioningStateModel>, { }: UnsubscribeFromDecisioningReRunResults) {
    this.pusherUnsubscribeFromDecisioningReRunResults();
  }

  /**
   * @param decisioning
   * @param attributesMap
   * @param preapproval
   * @return QualifierValue
   */
  formatDecisioningQualifierValues(decisioning: Decisioning, attributesMap: AttributesMap, preapproval: boolean, trustedBorrowerValuesSources: BorrowerValuesSource[]): Array<QualifierValue> {
    const groups = {
      accepted: <Array<QualifierValue>>[],
      rejected: <Array<QualifierValue>>[],
    };

    const mappedDecisioningQualifiers = keyBy(decisioning.product.decisioningQualifiers, 'id');

    decisioning.matchData.resultsByQualifier
      .filter(result => !!attributesMap[result?.field] && (mappedDecisioningQualifiers[result.id]?.isRequiredForPreapproval === preapproval))
      .forEach(result => {
        const dq = mappedDecisioningQualifiers[result.id];
        if (result) {
          groups[result.matchValue].push(this.decisioningService.getFormattedQualifierValue(dq, result, attributesMap, trustedBorrowerValuesSources, decisioning.matchData.additionalValues));
        }

      });

    return [
      ...groups.accepted,
      ...groups.rejected,
    ];
  }

  pusherSubscribe() {
    this.pusherService.subscribe({
      name: `decisioning-batch-${this.store.selectSnapshot(AuthState.user)?.institution?.id}`,
      auth: false,
      handler: (event: any, decisioningBatch: DecisioningBatch) => {
        if (event === 'decisioning-batch-created') {
          this.handlePusherMessage('created', decisioningBatch);
        }
        if (event === 'decisioning-batch-updated') {
          this.handlePusherMessage('updated', decisioningBatch);
        }
        return;
      }
    });
  }

  handlePusherMessage(type: string, decisioningBatch: DecisioningBatch) {
    if (type === 'created') {
      this.store.dispatch(new AddNewDecisioningProgress(decisioningBatch));
    }
    if (type === 'updated') {
      this.store.dispatch(new UpdateDecisioningProgress(decisioningBatch));
    }
  }

  /**
   * Subscibe to decisioning result (re-runs) pusher events
   * @return void
   */
  pusherSubscribeToDecisioningReRunResults(): void {
    const user = this.store.selectSnapshot(AuthState.user);
    this.pusherService.subscribe({
      name: `decisioning-re-run-${user?.institution?.id}-${user?.id}`,
      auth: false,
      handler: (event: any, decisioningResult: DecisioningResult) => {
        if (event == 'decisioning-created') {
          this.handleDecisioningResultMessage('created', decisioningResult);
        }
        if (event == 'decisioning-updated') {
          this.handleDecisioningResultMessage('updated', decisioningResult);
        }
        return;
      }
    });
  }

  /**
   * Subscibe to decisioning result (re-runs) pusher events
   * @return void
   */
  pusherUnsubscribeFromDecisioningReRunResults(): void {
    const user = this.store.selectSnapshot(AuthState.user);
    this.pusherService.unsubscribe({
      name: `decisioning-re-run-${user?.institution?.id}-${user?.id}`,
      auth: false,
      handler: () => {
        return;
      }
    });
  }

  /**
   * Subscribe to lender decisioning
   * @param dealId
   * @param borrowerId
   * @param lenderId
   * @return void
   */
  pusherSubscribeToLenderDecisioning(dealId: number, borrowerId: number, lenderId: number): void {
    this.pusherService.subscribe({
      name: `decisioning-${lenderId}-${borrowerId}`,
      auth: false,
      handler: (event: any, decisioningResult: DecisioningResult) => {
        this.handleLenderDecisioningUpdate(event, decisioningResult, dealId, borrowerId);
        return;
      }
    });
  }

  /**
   * Unsubscribe to lender decisioning
   * @param borrowerId
   * @param lenderId
   * @return void
   */
  pusherUnsubscribeFromLenderDecisioning(borrowerId: number, lenderId: number): void {
    this.pusherService.unsubscribe({
      name: `decisioning-${lenderId}-${borrowerId}`,
      auth: false,
      handler: () => {
        return;
      }
    });
  }

  /**
   * @param event
   * @param decisioningResult
   * @param dealId
   * @param borrowerId
   * @return void
   */
  handleLenderDecisioningUpdate(event: any, decisioningResult: DecisioningResult, dealId: number, borrowerId: number): void {
    // Is the decisioning run complete?
    if (decisioningResult && decisioningResult.decisioningStatusId == 2) {
      // Make sure this is the active deal
      const activeDealId = this.getActiveDealId();

      // No active deal
      // Or the active deal isn't what we're subscribed to
      // Or the borrowerId doesn't match, get out
      if (
        !activeDealId
        || activeDealId !== dealId
        || borrowerId !== decisioningResult.borrowerId
      ) {
        return;
      }

      // Allow results to come in
      setTimeout(() => {
        // Refresh borrower decisioning for product
        this.store.dispatch(new GetBorrowerDecisioningForProduct(decisioningResult.borrowerId, decisioningResult.loanProductId));
      }, 1000);
    }
  }

  /**
   * @param type
   * @param decisioningResult
   * @return void
   */
  handleDecisioningResultMessage(type: string, decisioningResult: DecisioningResult): void {
    if (type == 'created') {
      this.store.dispatch(new AddNewDecisioningReRun(decisioningResult));
    }
    if (type == 'updated') {
      this.store.dispatch(new UpdateDecisioningReRun(decisioningResult));
    }
  }

  /**
   * @returns number
   */
  private getActiveDealId(): number {
    const route = this.router.url;
    const parts = route.split('/');
    return parseInt(parts[2]);
  }
}
