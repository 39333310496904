import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  ChangeDetectorRef, ViewChild
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { LoanProductOperators } from 'src/app/interfaces/loan-product-operators.model';
import { QualifierUnsaved } from 'src/app/interfaces/qualifier-unsaved.model';
import { Qualifier } from 'src/app/interfaces/qualifier.model';
import { Question } from 'src/app/interfaces/question.model';
import { State } from 'src/app/interfaces/state.model';
import { QualifierExceptionGroup } from 'src/app/interfaces/qualifier-exception-group.model';

export interface QualifierChanged {
  isDirty?: boolean;
  data?: QualifierUnsaved;
  oldData?: Qualifier;
}

@Component({
  selector: 'app-qualifier',
  templateUrl: './qualifier.component.html',
  styleUrls: ['./qualifier.component.scss', './../loan-product.component.scss'],
  standalone: false
})
export class QualifierComponent implements OnInit, AfterViewInit {

  @Input() qualifier: Qualifier;
  @Input() questions: Question[];
  @Input() states: State[];
  @Input() loanProductId: number;
  @Input() disabledAttributeFields: string[];
  @Input() disabledOperatorFilterTypes: Map<string, string>;
  @Input() showUpdateButton: boolean;
  @Input() showAndButton: boolean;

  @Input() qualifiers: (Qualifier | QualifierUnsaved)[];
  @Input() exceptionGroup: QualifierExceptionGroup;
  @Input() baseQualifiers: (Qualifier | QualifierUnsaved)[];
  @Input() addingIfQualifier: boolean;
  @Input() labelColor: string;
  @Input() hasWriteAccess: boolean;

  @Output() cancel = new EventEmitter<void>();
  @Output() remove = new EventEmitter<Qualifier>();
  @Output() cancelUpdatesToQualifier = new EventEmitter<void>();
  @Output() update = new EventEmitter<QualifierChanged>();
  @Output() save = new EventEmitter<QualifierUnsaved>();
  @Output() dirty = new EventEmitter<QualifierChanged>();
  @Output() addingIfQualifierChange = new EventEmitter<boolean>();

  @ViewChild('attribute') attribute: MatSelect;
  @ViewChild('attrOperator') attrOperator: MatSelect;
  @ViewChild('attrValue') attrValue: MatSelect;

  initialized = false;
  /** @description to indicate that page has been initialized */
  selectedAttributeOption: Question;
  /** @description attribute option selected based on how the loan product questions were answered */
  selectedAttributeOptionId: number | string | null;
  /** @description the id of the selected attribute option */
  operatorOptions: { display: string, value: string }[] = [];
  /** @description an array of operator options */
  valueType: 'displayBlank' | 'boolean' | 'minMax' | 'dropdown';
  /** @description value option displayed based on attr. and op. selected */
  valueFieldOption: string | string[] | null = 'blank';
  /** @description used to display correct value field option */
  showConfirmDeleteButton = false;

  private operators;
  private fakeBlankId = 999888777;

  /** @description a hardcoded question id for blank "empty" state, do not search for this in the db */

  constructor(private cdRef: ChangeDetectorRef) {
    this.operators = new LoanProductOperators();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getSelectedAttribute();
    this.getOperatorOptions();
    this.initialized = true;
    this.cdRef.detectChanges();
    this.getValueFieldValue();
    this.cdRef.detectChanges();
  }

  getSelectedAttribute(): void | null {
    if (!this.questions) {
      return null;
    }

    if (!this.qualifier) {
      const index = this.questions.findIndex(x => x.alias === 'blank');
      if (index === -1) {
        this.questions.splice(0, 0, {
          id: this.fakeBlankId,
          shortName: 'Blank',
          type: 'text',
          alias: 'blank',
          options: [{label: '', value: ''}],
          allowedQualifiers: [],
          appSection: '',
          appSectionOrder: 0,
          contractAskable: false,
          created: '',
          createdTimestamp: 0,
          dbName: '',
          deleted: '',
          dependsExpression: '',
          dependsOn: '',
          filterable: false,
          group: '',
          groupOrder: 0,
          helpText: '',
          lenderId: 0,
          mask: '',
          maskOptions: [],
          maxLength: 0,
          minLength: 0,
          modified: '',
          modifiedTimestamp: 0,
          mustAllowNull: false,
          name: '',
          offerAskable: false,
          order: 0,
          pattern: '',
          patternError: '',
          placeholder: '',
          sectionId: 0,
          teamId: 0
        });
      }
      let attributeOption: any;

      attributeOption = this.questions[0];

      this.selectedAttributeOptionId = attributeOption.id;
      this.selectedAttributeOption = attributeOption;

      this.setValueType();
      return;
    }

    let selected: any = null;

    this.questions.forEach((question) => {
      if (this.qualifier.field === question.alias) {
        selected = question.id;
        this.selectedAttributeOptionId = selected;
        this.selectedAttributeOption = question;
      }
    });

    this.setValueType();
  }

  getOperatorDisabled(): boolean {
    return this.operatorOptions.length === 1;
  }

  getHasChangedAttribute(): boolean {
    if (!this.qualifier) {
      return true;
    }
    return this.qualifier.field !== this.selectedAttributeOption.alias;
  }

  getHasChangedOperator(): boolean {
    if (!this.qualifier) {
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.qualifier[this.attrOperator.value] === null;
  }

  getHasChangedValue(): boolean {
    if (!this.qualifier) {
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.qualifier[this.attrOperator.value] !== this.attrValue.value;
  }

  getSelectedOperator(): string {
    if (this.operatorOptions.length === 1 || this.getHasChangedAttribute()) {
      return this.operatorOptions[0].value;
    }

    return this.qualifier.filterType;
  }

  getValueIsDisplayBlank(): boolean {
    return this.selectedAttributeOption.alias === 'blank' &&
      this.selectedAttributeOption.allowedQualifiers.length === 0;
  }

  getValueIsBoolean(): boolean {
    return this.selectedAttributeOption.allowedQualifiers.length === 1 &&
      this.selectedAttributeOption.allowedQualifiers[0] === 'is';
  }

  getValueIsMinMax(): boolean {
    return this.selectedAttributeOption.allowedQualifiers.includes('min') &&
      this.selectedAttributeOption.allowedQualifiers.includes('max');
  }

  getValueIsDropdown(): boolean {
    return (this.selectedAttributeOption.allowedQualifiers.includes('contains') &&
        this.selectedAttributeOption.allowedQualifiers.includes('notContains')) ||
      (this.selectedAttributeOption.allowedQualifiers.includes('in') &&
        this.selectedAttributeOption.allowedQualifiers.includes('notIn'));
  }

  getValueFieldValue(): void {
    if (!this.initialized || !this.qualifier || this.getValueIsDisplayBlank()) {

      if (this.getValueIsDropdown()) {
        this.valueFieldOption = null;
      } else {
        this.valueFieldOption = 'blank';
      }
    } else if (this.getValueIsBoolean()) {
      if (this.getHasChangedAttribute()) {
        this.valueFieldOption = null;
      } else {
        this.valueFieldOption = this.qualifier.is;
      }
    } else if (this.getValueIsMinMax()) {
      if (this.getHasChangedAttribute()) {
        this.valueFieldOption = '';
      } else if (this.qualifier.min !== null || this.qualifier.max !== null) {
        this.valueFieldOption = (this.qualifier.min || this.qualifier.max).toString();
        this.commaFormattedValue(this.valueFieldOption);
      }
    } else if (this.getValueIsDropdown()) {
      if (this.getHasChangedAttribute()) {
        this.valueFieldOption = null;
      } else {
        this.valueFieldOption = this.qualifier.in || this.qualifier.notIn || this.qualifier.contains || this.qualifier.notContains;
      }
    }
  }

  getOperatorOptions(): void {
    const attributeOption = this.selectedAttributeOption;
    const returnOperators = [
      {
        display: 'Blank',
        value: 'blank'
      },
    ];

    if (!attributeOption) {
      this.operatorOptions = returnOperators;
      this.getValueFieldValue();
      return;
    }
    attributeOption.allowedQualifiers.forEach((opOption) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      returnOperators.push({display: this.operators.operatorDisplays[opOption], value: opOption});
    });
    this.operatorOptions = returnOperators;
    this.getValueFieldValue();
  }

  disableOperatorValue(operatorValue: string) {
    return this.disabledOperatorFilterTypes && this.disabledOperatorFilterTypes.size > 0 &&
      this.disabledOperatorFilterTypes.has(this.selectedAttributeOption.alias) &&
      this.disabledOperatorFilterTypes.get(this.selectedAttributeOption.alias) === operatorValue;
  }

  getIsDirty(): boolean {
    return this.getHasChangedAttribute() || this.getHasChangedOperator() || this.getHasChangedValue();
  }

  getCanSave(): boolean {
    if (!this.attribute || !this.attrOperator || !this.attrValue ||
      this.attribute.value === 'blank' || this.attrOperator.value === 'blank' || this.attrValue.value === 'blank') {
      return false;
    }

    return !!(this.attribute.value && this.attrOperator.value && this.attrValue.value);
  }

  getUnsavedQualifier(): QualifierUnsaved {
    const newQualifier: QualifierUnsaved | any = {
      field: this.selectedAttributeOption.alias,
      fieldType: this.selectedAttributeOption.type,
      filterType: this.attrOperator.value,
      loanProductId: this.loanProductId,
      is: null,
      min: null,
      max: null,
      contains: null,
      notContains: null,
      in: null,
      notIn: null,
      isExceptionCondition: false,
      qualifierExceptionGroupId: null
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newQualifier[this.attrOperator.value] = this.valueType === 'minMax' ? this.attrValue.value.replace(/,/g, '') :
      this.attrValue.value;

    return newQualifier;
  }

  emitDirty() {
    if (!this.qualifier) {
      return;
    }

    const newQualifier = this.getUnsavedQualifier();
    newQualifier.id = this.qualifier.id;

    this.dirty.emit({isDirty: true, data: newQualifier, oldData: this.qualifier});
    this.showConfirmDeleteButton = false;
    this.showUpdateButton = true;
  }

  emitClean() {
    if (!this.qualifier) {
      return;
    }

    this.dirty.emit({isDirty: false});
  }

  emitUpdate() {
    if (!this.qualifier) {
      return;
    }

    this.update.emit({oldData: this.qualifier});
  }

  emitCancelUpdate() {
    if (!this.qualifier) {
      return;
    }

    this.cancelUpdatesToQualifier.emit();
  }

  setSelectedAttribute(optionId: number): void {
    this.selectedAttributeOptionId = null;
    this.cdRef.detectChanges();
    const optionIndex = this.questions.findIndex((x) => x.id === optionId);
    this.selectedAttributeOptionId = optionId;
    this.selectedAttributeOption = this.questions[optionIndex];
    this.getOperatorOptions();
    this.setValueType();

    if (this.getHasChangedAttribute()) {

      // We HAVE to let Angular's current cycle finish before we can pull data. Hacky, I know :(
      setTimeout(() => {
        this.emitDirty();
      }, 0);
    } else {
      this.emitClean();
    }

    this.cdRef.detectChanges();
  }

  setSelectedOperator(): void {
    this.getValueFieldValue();
    if (this.getIsDirty()) {
      this.emitDirty();
    } else {
      this.emitClean();
    }
  }

  setValue(): void {
    if (this.getIsDirty()) {
      this.emitDirty();
    } else {
      this.emitClean();
    }
  }

  setValueType(): void {
    if (this.getValueIsBoolean()) {
      this.valueType = 'boolean';
    } else if (this.getValueIsMinMax()) {
      this.valueType = 'minMax';
    } else if (this.getValueIsDropdown()) {
      this.valueType = 'dropdown';
    } else {
      this.valueType = 'displayBlank';
    }
  }

  /**
   * @description on saving a new qualifier,
   * save is emitted up to the loan-product component which handles the addition.
   */
  addQualifier() {
    this.save.emit(this.getUnsavedQualifier());
  }

  /**
   * @description helper method to hide cancel button for empty state blank base qualifier
   * @returns boolean
   */
  showCancelButton(): boolean {
    return (this.qualifiers && this.qualifiers.length > 0);
  }

  /**
   * @description on canceling out of the "Confirm Delete" button, cancel is emitted
   *              setting addingGlobalQualifier to false.
   *              ConfirmDeleteButton is hidden.
   */
  cancelClicked(): void {
    this.cancel.emit();
    this.showConfirmDeleteButton = false;
  }

  /**
   * @description when "Confirm Delete" warning button is clicked, remove is emitted
   *              which removes the qualifier in the list of loanProduct.qualifiers in the
   *              loan-product component.
   */
  removeClicked(): void {
    this.remove.emit(this.qualifier);
    this.showConfirmDeleteButton = false;
  }

  handleAnd() {
    this.addingIfQualifier = true;
    this.addingIfQualifierChange.emit(this.addingIfQualifier);
  }

  onFocusValueFieldInput() {
    if (this.valueFieldOption === 'blank') {
      this.valueFieldOption = '';
      return this.attrValue.value = '';
    }
  }

  onKeyPressValidateKey(args: { key: number; }) {
    return !isNaN(args.key);
  }

  onKeyUpFormatValueFieldInput($event: KeyboardEvent) {
    // skip for arrow keys
    const pressedKey: number = $event.key.charCodeAt(0);
    if (pressedKey >= 37 && pressedKey <= 40) {
      $event?.preventDefault();
    }

    this.commaFormattedValue(this.attrValue.value);
  }

  commaFormattedValue(valueToFormat: string) {
    this.valueFieldOption = valueToFormat
      .replace(/\.[0-9][0-9]+$/g, '')
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  }
}
