import { DecisioningBatch } from "@app/app/interfaces/decisioning-batch.model";
import { DecisioningQualifierOverride } from "@app/app/interfaces/decisioning-qualifier-override.model";
import { DecisioningResult } from "@app/app/interfaces/decisioning-result.model";
import { DecisioningStateModel } from "./decisioning.state";

export class FetchWithUpdatedParams {
  static readonly type = '[Decisioning] FetchWithUpdatedParams';
  constructor(
    public paramChanges: Partial<DecisioningStateModel>,
    public shouldReloadOnFetch: boolean,
  ) {}
}

export class GetBorrowerDecisioningForProduct {
  static readonly type = '[Decisioning] GetBorrowerDecisioningForProduct';
  constructor(
    public borrowerId: number,
    public loanProductId: number,
  ) {}
}
export class GetLenderDecisioningBatches {
  static readonly type = '[Decisioning] GetLenderDecisioningBatches';
  constructor(
    public lenderId: number,
  ) {}
}

export class GetDecisioningResultForBorrower {
  static readonly type = '[Decisioning] GetDecisioningResultForBorrower';
  constructor(
    public borrowerId: number,
    public loanProductId: number,
  ) {}
}

/**
 * Lender decisioning
 */
export class SubscribeToLenderDecisioning {
  static readonly type = '[Decisioning] SubscribeToLenderDecisioning';
  constructor(
    public dealId: number,
    public borrowerId: number,
    public lenderId: number | undefined,
  ) {}
}

/**
 * Lender decisioning
 */
export class UnsubscribeFromLenderDecisioning {
  static readonly type = '[Decisioning] UnsubscribeFromLenderDecisioning';
  constructor(
    public borrowerId: number,
    public lenderId: number | undefined,
  ) {}
}

/**
 * Decisioning re-runs
 */
export class SubscribeToDecisioningReRunResults {
  static readonly type = '[Decisioning] SubscribeToDecisioningReRunResults';
}

/**
 * Decisioning re-runs
 */
export class UnsubscribeFromDecisioningReRunResults {
  static readonly type = '[Decisioning] UnsubscribeFromDecisioningReRunResults';
}

/**
 * Decisioning batches (imports)
 */
export class SubscribeToDecisioningBatchPusherUpdates {
  static readonly type = '[Decisioning] SubscribeToDecisioningBatchPusherUpdates';
}

export class AddNewDecisioningProgress {
  static readonly type = '[Decisioning] AddNewDecisioningProgress';
  constructor(
    public decisioningBatch: DecisioningBatch,
  ) {}
}

export class UpdateDecisioningProgress {
  static readonly type = '[Decisioning] UpdateDecisioningProgress';
  constructor(
    public decisioningBatch: DecisioningBatch,
  ) {}
}

export class AddNewDecisioningReRun {
  static readonly type = '[Decisioning] AddNewDecisioningReRun';
  constructor(
    public decisioningResult: DecisioningResult,
  ) {}
}

export class UpdateDecisioningReRun {
  static readonly type = '[Decisioning] UpdateDecisioningReRun';
  constructor(
    public decisioningResult: DecisioningResult,
  ) {}
}

export class PostDecisioningOverrides {
  static readonly type = '[Decisioning] PostDecisioningOverrides';
  constructor(
    public borrowerId: number,
    public dealId: number,
    public overrides: DecisioningQualifierOverride[],
  ) {}
}

export class QueueBorrowerDecisioning {
  static readonly type = '[Decisioning] QueueBorrowerDecisioning';
  constructor(
    public borrowerId: number,
    public loanProductId: number,
  ) {}
}

export class GetTrustedBorrowerValuesSources {
  static readonly type = '[Decisioning] GetTrustedBorrowerValuesSources';
}
