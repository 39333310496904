<div data-cy="file-upload-component"
    class="file-upload-container grid place-content-center dialog mx-6 mt-1 mb-6 p-8"
    [class.loading]="loading"
    [class.error]="errorMessage"
    [class.categorizing]="fileListWithCategories.length"
    fileDragNDrop
    (fileDropped)="handleFileDrop($event)"
>
    @if (!fileListWithCategories.length) {
        <input data-cy="file-upload-input"
            type="file"
            [accept]="allowedMimeTypes.join(',')"
            [multiple]="fileCount > 1"
            #fileInputRef
            id="fileInputRef"
            (change)="handleFileBrowse($event.target.files)"
        />
    }
    <div class="h-full w-full">
        <!-- Upload prompt -->
        @if (!fileListWithCategories.length) {
            <div class="grid grid-cols-1 gap-3.5 !font-medium file-details-text placeholder justify-items-center">
                <h3 class="!text-lg flex !my-0 !-mb-1.5">
                    <mat-icon class="material-symbols-outlined">cloud_upload</mat-icon>&nbsp;&nbsp;{{ fileSelectText }}
                </h3>
                <button data-cy="file-upload-btn" mat-flat-button color="primary" class="z-0">{{ buttonText }}</button>
                <div class="font-light instructions text-xs modal">
                    <div class="leading-5 text-center">
                        Upload up to {{ formatBytes(fileSizeLimit) }}<span *ngIf="fileCount <= 1">.</span> {{ fileCount > 1 ? "per file." : ""}}
                    </div>
                    @if (allowedExtensions.length < 5) {
                        <div class="leading-5 text-center">File types accepted: {{ allowedExtensions.join(', ') }}</div>
                    }
                </div>
            </div>
        }
        <!-- Categorizing step -->
        @if (fileListWithCategories.length) {
            @if (!isUploading) {
                <div class="pb-8">
                    Please assign a requirement type for each document:
                </div>
                <form #fileForm="ngForm" class="lendio-density-1">
                    @for (fileListItem of fileListWithCategories; track fileListItem.file.name; let i = $index) {
                        <div class="pb-4">{{ fileListItem.file.name }}</div>
                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-16">
                            <mat-label>Type</mat-label>
                            <mat-select [(ngModel)]="fileListItem.category"
                                        name="category-{{ i }}"
                                        required
                                        #category="ngModel"
                            >
                            @for (category of categories(); track category.id) {
                                <mat-option [value]="category.id">
                                    {{ category.name }}
                                </mat-option>
                            }
                            </mat-select>
                            <mat-hint>Types include bank statement, signed contract, etc.</mat-hint>
                        </mat-form-field>
                        <!-- Dynamically add in monthsString field when user chooses Bank Statment category -->
                        @if (fileListItem.category === 'bankStatement') {
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-8 -mt-8">
                                <mat-label>For month</mat-label>
                                <mat-select [(ngModel)]="fileListItem.monthsString" 
                                    name="monthsString-{{ i }}"
                                    data-cy="monthsString"
                                    required
                                >
                                @for (month of last24Months; track month;) {
                                    <mat-option [value]="month.value">
                                        {{ month.display }}
                                    </mat-option>
                                }
                                </mat-select>
                                <mat-hint>Provide a month of the bank statement.</mat-hint>
                            </mat-form-field>
                        }

                        @if (categoriesRequiringYear.includes(fileListItem.category)) {
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-full mb-8 -mt-8">
                                <mat-label>For year</mat-label>
                                <mat-select [(ngModel)]="fileListItem.yearsString"
                                        name="yearsString-{{ i }}"
                                        required
                                        #category="ngModel"
                            >
                                @for (year of years; track year; let i = $index;) {
                                    <!-- // Do not show current year when category is tax return -->
                                    @if (!fileListItem.category.match('TaxReturn') || i > 0) {
                                        <mat-option [value]="year">
                                            {{ year }}
                                        </mat-option>
                                    }
                                }
                                </mat-select>
                            </mat-form-field>
                        }
                    }
                </form>
            } @else {
                <app-dot-loader-scale></app-dot-loader-scale>
            }
        }
        @if (errorMessage) {
            <div class="error-message p-4 pb-0 text-center text-xs">
                {{ errorMessage }}
            </div>
        }
    </div>
</div>
