import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, signal, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DecisioningBatch } from '@app/app/interfaces/decisioning-batch.model';
import { FetchWithUpdatedParams } from '@app/app/store/decisioning/decisioning.actions';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { environment } from '@app/environments/environment';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-batch-reports',
  templateUrl: './batch-reports.component.html',
  styleUrls: ['./batch-reports.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class BatchReportsComponent implements OnInit, OnDestroy {
  @Select(DecisioningState.decisioningBatch) decisioningBatch$: Observable<DecisioningBatch[]>;
  @Select(DecisioningState.loading) loading$: Observable<boolean>;
  @Select(DecisioningState.pageIndex) pageIndex$: Observable<number>;
  @Select(DecisioningState.pageSize) pageSize$: Observable<number>;
  @Select(DecisioningState.sortBy) sortBy$: Observable<string>;
  @Select(DecisioningState.sortDirection) sortDirection$: Observable<SortDirection>;
  @Select(DecisioningState.total) total$: Observable<number>;
  @Select(SaasFeaturesState.saasPermitted('administrationImport', 'lpxAdministrationImport.write')) permittedToImport$: Observable<boolean>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  destroyed$ = new Subject<boolean>();
  tableDataSource$: Observable<MatTableDataSource<DecisioningBatch>>;

  pageSizeOptions: number[] = [20, 50];
  emptyTable = signal(false);
  emptyType = 'documents';
  emptyLabelContent = "You don't have any past decisioning batches.";
  activeTab: string = 'reports';
  columnsToDisplay = [
    'created',
    'generatedBy',
    'importMonth',
    'downloadStatus',
    'download'
  ];

  constructor(
    private store: Store,
    private http: HttpClient,
  ) { }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.store.dispatch(new FetchWithUpdatedParams({}, true));
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngAfterViewInit(): void {
    this.tableDataSource$ = this.decisioningBatch$.pipe(
      takeUntil(this.destroyed$),
      map((batches) => {
        const tableDataSource = new MatTableDataSource<DecisioningBatch>(batches);
        tableDataSource.paginator = this.paginator;
        tableDataSource.sort = this.sort;
        if (batches.length === 0) {
          this.emptyTable.set(true);
        }
        return tableDataSource;
      })
    );
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  /**
   * For table sorting/filtering
   * @param e
   */
  handleSortEvent(e: any) {
    const params = {
      pageIndex: 0,
      sortBy: e.active === 'generatedBy'
        ? 'importId'
        : (e.active === 'importMonth'
          ? 'created'
          : e.active),
      sortDirection: e.direction,
      filterBy: e.value,
    }
    this.store.dispatch(new FetchWithUpdatedParams(params, false));
  }

  /**
   * For the lendio paginator
   * @param e
   */
  handlePageEvent(e: PageEvent) {
    this.store.dispatch(new FetchWithUpdatedParams({
      pageIndex: e.pageIndex,
      pageSize: e.pageSize
    }, false
    ));
  }

  /**
   * Handler for streaming decisioning batch reports
   * @param reportId
   */
  async handleDownloadReport(reportId: number) {
    const url = `${environment.apiUrl}/lender-portal/decisioning-batch/${reportId}/stream`;

    try {
      // Get our report
      const response: Blob = await lastValueFrom(
        this.http.get(url, { responseType: 'blob' })
      );
      // Create URL for blob
      const csvUrl = window.URL.createObjectURL(response);

      // Create hidden link element
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = `batch_analytics_report_${reportId}.csv`;
      link.style.display = 'none';

      // Provide link to the document, click it, then forget it
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(csvUrl);

      // Bye bye
      document.body.removeChild(link);
    } catch (err) {
      console.error(`Error downloading batch analytics report: ${reportId}.`, err);
    }
  }
}
