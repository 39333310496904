import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';

@Component({
  selector: 'app-decline-dialog',
  templateUrl: './decline-dialog.component.html',
  styleUrls: ['./decline-dialog.component.scss'],
  standalone: false
})
export class DeclineDialogComponent implements OnInit {

  declineReasons: any;
  declineReasonSelect = new FormControl();
  selectedReasons: any[] = [];
  comment = '';
  loading = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    public dialogRef: MatDialogRef<DeclineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number }
  ) { }

  ngOnInit(): void {
    this.getDeclineReasons();
  }

  getDeclineReasons() {
    this.http.get(`${environment.apiUrl}/l/v2/internal/decline-reasons`).pipe(take(1)).subscribe((reasons: any) => {
      this.declineReasons = reasons.data;
    });
  }

  submit() {
    this.loading = true;
    const formattedReasons = this.selectedReasons.map(reason => {
      return { reason, lenderReason: 'No lender-specific reason' };
    });
    this.http.put(`${environment.apiUrl}/l/v2/internal/deals/${this.data.dealId}/status`, {
      status: 'doesNotQualify',
      reasons: formattedReasons,
      otherReason: this.comment && this.comment.length > 0 ? this.comment : null
    }).pipe(take(1)).subscribe((response: any) => {
      if (response.statusCode === 200) {
        window.location.reload();
      } else {
        this.store.dispatch(new CreateNewAlert({
            level: 'error',
            message: 'Failed to turn down this application. Please try again.'
        }));
      }
      this.dialogRef.close();
    });
  }
}
