import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { Offer } from '@app/app/interfaces/offer.model';

@Component({
  selector: 'app-new-offer-dialog',
  templateUrl: './new-offer-dialog.component.html',
  styleUrls: ['./new-offer-dialog.component.scss'],
  standalone: false
})
export class NewOfferDialogComponent {
  @Output() newOfferCreated = new EventEmitter<Partial<Offer>>();

  constructor(
    @Inject(LocaleSettingService) private localeSettingService: LocaleSettingService,
    @Inject(MAT_DIALOG_DATA) public data: {
      link: boolean;
      dealId: number;
      loanProductCategory: string;
      localeCurrencySymbol: string;
      borrowerName?: string;
      /**
       * Whether this dialog should be treated as an intermediary step towards
       * manually moving a deal to "Funded" stage.
       */
      isFundingInterstitial?: boolean;
    },
    public dialogRef: MatDialogRef<NewOfferDialogComponent>

  ) {
    this.data.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol()!;
  }

  protected _handleNewOfferCreated(newOffer: Partial<Offer>) {
    this.newOfferCreated.emit(newOffer);
  }

  public closeDialog(): void {
    this.dialogRef.close(true);
  }
}
