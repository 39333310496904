import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadersModule } from '../loaders/loaders.module';
import { OffersSmartComponent } from './offers-smart/offers-smart.component';
import { OffersComponent } from './offers/offers.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ResendContractButtonModule } from '../resend-contract-button/resend-contract-button.module';
import { ToDateObjPipeModule } from 'src/app/pipes/toDateObj/to-date-obj-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CapitalizePipePipeModule } from "@app/app/pipes/capitalize/capitalize-pipe.module";
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';

@NgModule({
  declarations: [
    OffersSmartComponent,
    OffersComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        ResendContractButtonModule,
        LoadersModule,
        ToDateObjPipeModule,
        MatTooltipModule,
        CapitalizePipePipeModule,
        EmptyStateComponent,
    ],
  exports: [
    OffersSmartComponent
  ]
})
export class OffersModule { }
