import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertModule } from '../../alert/alert.module';
import { LoadersModule } from '../../loaders/loaders.module';
import { ApplicationDetailsComponent } from './application-details.component';
import { ApplicationDetailsService } from '../../../services/application-details.service';
import { NaicsSearchComponent } from './ppp-status/naics-search/naics-search.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { MatCardModule } from '@angular/material/card';
import { LabelsModule } from '../../labels/labels.module';
import { DetailsComponent } from './details/details.component';
import { OldLendioRepComponent } from './old-lendio-rep/old-lendio-rep.component';
import { LendioRepComponent } from './lendio-rep/lendio-rep.component';
import { DynamicBorrowerValuesComponent } from './details/dynamic-borrower-values/dynamic-borrower-values.component';
import { FundingDeskRequestService } from 'src/app/services/funding-desk-request.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FundingDeskModule } from '../../funding-desk/funding-desk.module';
import { OffersModule } from '../../offers/offers.module';
import { MatButtonModule } from '@angular/material/button';
import { DeclineButtonModule } from '../../decline-button/decline-button.module';
import { NewOfferModule } from '../../new-offer-display/new-offer.module';
import { NewOfferButtonModule } from '../../new-offer-button/new-offer-button.module';
import { DocumentsService } from '../../../services/documents.service';
import { DocumentsModule } from '../../documents/documents.module';
import { PppDetailsComponent } from './ppp-details/ppp-details.component';
import { PhoneModule } from '../../../pipes/phone/phone-pipe.module';
import { ReplacePipeModule } from '../../../pipes/replace/replace-pipe.module';
import { TurnDownButtonModule } from '../../turn-down-button/turn-down-button.module';
import { UploadContractModule } from '../../upload-contract/upload-contract.module';
import { UploadContractButtonModule } from '../../upload-contract-button/upload-contract-button.module';
import { PppStatusComponent } from './ppp-status/ppp-status.component';
import { PppIncompleteComponent } from './ppp-status/ppp-incomplete/ppp-incomplete.component';
import { RouterModule, Routes } from '@angular/router';
import { OwnersModule } from '../../owners/owners.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { ExportApplicationSmartComponent } from './export-application-smart/export-application-smart.component';
import { ExportApplicationComponent } from './export-application/export-application.component';
import { ToDateObjPipeModule } from 'src/app/pipes/toDateObj/to-date-obj-pipe.module';
import { AssignmentsModule } from '../../assignments/assignments.module';
import {
  WithdrawFromSbaDialogComponent
} from './ppp-status/withdraw-from-sba-dialog/withdraw-from-sba-dialog.component';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { ContractRequestsModule } from 'src/app/components/contract-requests/contract-requests.module';
import { ApprovalsModule } from '../../approvals/approvals.module'
import { DecisioningModule } from '@app/app/components/decisioning/decisioning.module';
import { DealSidebarComponent } from '@app/app/components/deal-sidebar/deal-sidebar.component';
import { DynamicScreenHeightModule } from '@app/app/directives/dynamic-screen-height/dynamic-screen-height.module';
import { DealNotesComponent } from '@app/app/components/deal-notes/deal-notes.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  CreateNoteDialogComponent
} from '@app/app/components/deal-notes/create-note-dialog/create-note-dialog.component';
import { AssignmentDropdownModule } from '@app/app/components/assignment-dropdown/assignment-dropdown.module';
import { OldDynamicBorrowerValuesComponent } from './details/old-dynamic-borrower-values/old-dynamic-borrower-values.component';
import { OldDetailsComponent } from './details/old-details.component';
import { EsignStatusComponent } from '../../esign-status/esign-status.component';
import { EsignatureService } from '@app/app/services/esignature.service';
import { DealStatusChangeDropdownComponent } from '../../deal-status-change-dropdown/deal-status-change-dropdown.component';
import {
  LendioStatusDisplayComponent
} from '@app/app/components/lendio-angular-material-theme/lendio-status-display/lendio-status-display.component';
import { DealActivitiesComponent } from '../../deal-activities/deal-activities.component';
import { DealActivitiesQuickViewComponent } from '../../deal-activities/deal-activities-quick-view/deal-activities-quick-view.component';
import { LenderTasksHeaderComponent } from './lender-tasks-header/lender-tasks-header.component';
import { DealNotesTabComponent } from '../../deal-notes-tab/deal-notes-tab.component';
import { DocumentRequirementsComponent } from '../../document-requirements/document-requirements.component';
import {
  ApplicationDetailsSmartComponent
} from '@app/app/components/applications/application-details/application-details-smart.component';

const routes: Routes = [
  {
    path: ':id',
    component: ApplicationDetailsSmartComponent,
  }
];

@NgModule( {
  declarations: [
    ApplicationDetailsComponent,
    ApplicationDetailsSmartComponent,
    CreateNoteDialogComponent,
    DealNotesComponent,
    DealSidebarComponent,
    DetailsComponent,
    OldDetailsComponent,
    DynamicBorrowerValuesComponent,
    OldDynamicBorrowerValuesComponent,
    PppDetailsComponent,
    PppStatusComponent,
    PppIncompleteComponent,
    NaicsSearchComponent,
    ExportApplicationSmartComponent,
    ExportApplicationComponent,
    ExportApplicationSmartComponent,
    OldLendioRepComponent,
    LendioRepComponent,
    NaicsSearchComponent,
    PppDetailsComponent,
    PppIncompleteComponent,
    PppStatusComponent,
    SectionHeaderComponent,
    WithdrawFromSbaDialogComponent,
    EsignStatusComponent,
  ],
  imports: [
    AlertModule,
    ApprovalsModule,
    AssignmentDropdownModule,
    AssignmentsModule,
    CommonModule,
    ContractRequestsModule,
    DealStatusChangeDropdownComponent,
    DealActivitiesComponent,
    DealActivitiesQuickViewComponent,
    DealNotesTabComponent,
    DecisioningModule,
    DeclineButtonModule,
    DocumentsModule,
    DocumentRequirementsComponent,
    DynamicScreenHeightModule,
    FormsModule,
    FundingDeskModule,
    LabelsModule,
    LoadersModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    NewOfferButtonModule,
    NewOfferModule,
    OffersModule,
    OwnersModule,
    PhoneModule,
    ReactiveFormsModule,
    ReplacePipeModule,
    RouterModule.forChild(routes),
    ToDateObjPipeModule,
    TurnDownButtonModule,
    UploadContractButtonModule,
    UploadContractModule,
    LendioStatusDisplayComponent,
    LenderTasksHeaderComponent
  ],
  exports: [
    ApplicationDetailsComponent,
    RouterModule,
  ],
  providers: [
    ApplicationDetailsService,
    DocumentsService,
    EsignatureService,
    FundingDeskRequestService,
    LocaleSettingService,
  ]
} )
export class ApplicationDetailsModule {
}
