import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MakeCsvLiteService } from '@app/app/components/make-csv-lite/make-csv-lite.service';
import { MatButtonModule } from '@angular/material/button';
import { DEMO_BORROWERS, DEMO_CONTACTS } from '@app/app/components/make-csv-lite/make-csv-constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { growAnimation } from '@app/app/animations/grow/grow.animation';
import { GrowAnimationDirective } from '@app/app/animations/grow/grow-animation.directive';

@Component({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    GrowAnimationDirective
  ],
  providers: [MakeCsvLiteService],
  templateUrl: './make-csv-lite.component.html',
  styleUrls: ['./make-csv-lite.component.scss'],
  animations: [growAnimation]
})
export class MakeCsvLiteComponent {
  constructor(private _makeCsvService: MakeCsvLiteService) { }
  downloadIsDisabled = false;
  advancedOptionsVisible = false;

  demoFilesForm = new FormGroup({
    accountMin: new FormControl(1, [Validators.min(1), Validators.max(5)]),
    accountMax: new FormControl(1, [Validators.min(1), Validators.max(5)]),
    transactionMin: new FormControl(300, [Validators.min(1), Validators.max(300)]),
    transactionMax: new FormControl(300, [Validators.min(1), Validators.max(300)])
  });

  get transactionMinControl(): AbstractControl {
    return this.demoFilesForm.get('transactionMin')!;
  }

  get transactionMaxControl(): AbstractControl {
    return this.demoFilesForm.get('transactionMax')!;
  }

  get accountMinControl(): AbstractControl {
    return this.demoFilesForm.get('accountMin')!;
  }

  get accountMaxControl(): AbstractControl {
    return this.demoFilesForm.get('accountMax')!;
  }

  toggleAdvancedOptions(): void {
    this.advancedOptionsVisible = !this.advancedOptionsVisible;
  }

  validateAccounts() {
    const minVal = this.accountMinControl.value;
    const maxVal = this.accountMaxControl.value;

    if (minVal === null || minVal < 1 || minVal > 5) {
      this.accountMinControl.setValue(1);
    }

    if (maxVal === null || maxVal < 1 || maxVal > 5 || maxVal < minVal) {
      this.accountMaxControl.setValue(Math.max(1, minVal));
    }
  }

  validateTransactions() {
    const minVal = this.transactionMinControl.value;
    const maxVal = this.transactionMaxControl.value;

    if (minVal === null || minVal < 1 || minVal > 300) {
      this.transactionMinControl.setValue(300);
    }

    if (maxVal === null || maxVal < 1 || maxVal > 300 || maxVal < minVal) {
      this.transactionMaxControl.setValue(Math.max(300, minVal));
    }
  }

  generateContacts() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateContact,
      name: 'Contacts',
      dataSetId: null,
      parentRows: DEMO_CONTACTS,
      perParentMin: 10,
      perParentMax: 10
    });
  }

  generateBankAccounts() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateBankAccount,
      name: 'Bank Accounts',
      dataSetId: null,
      parentRows: DEMO_CONTACTS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  generateTransactions() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateTransaction,
      name: 'Transactions',
      dataSetId: null,
      parentRows: DEMO_BORROWERS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  generateBusinesses() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateBusiness,
      name: 'Business',
      dataSetId: null,
      parentRows: DEMO_BORROWERS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  async generateAll() {
    this.downloadIsDisabled = true;
    await this._makeCsvService.generate({
      prefix: 'demo',
      businessCount: 25,
      contactsPerBusinessMin: 1,
      contactsPerBusinessMax: 1,
      accountsPerBusinessMin: this.accountMinControl.value,
      accountsPerBusinessMax: this.accountMaxControl.value,
      transactionsPerAccountMin: this.transactionMinControl.value,
      transactionsPerAccountMax: this.transactionMaxControl.value
    }).finally(() => this.downloadIsDisabled = false);
  }

  async generate({count, rowGenerator, name, dataSetId, parentRows, perParentMin, perParentMax}) {
    this._makeCsvService.generateCsv({
      prefix: 'demo',
      count,
      rowGenerator,
      name,
      dataSetId,
      parentRows,
      perParentMin,
      perParentMax,
    })
  }

}
