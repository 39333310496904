import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { EarlyAccessFeatureService } from "@app/app/components/settings/early-access/early-access-feature.service";

@Component({
  selector: 'app-sidenav-user',
  templateUrl: './sidenav-user.component.html',
  styleUrls: ['./sidenav-user.component.scss'],
  standalone: false
})
export class SidenavUserComponent {
  @Input() collapsed = false;
  @Input() navMenuCollapsed = false;
  @Input() userFullName!: string;
  @Input() institutionName!: string;
  @Input() isSbaSpecialist: boolean;
  @Input() organizationName!: string;
  @Output() userLogout: EventEmitter<any> = new EventEmitter();
  @Output() userSettings: EventEmitter<any> = new EventEmitter();

  saasFeatureBadgeEnabled = computed(() => this._earlyAccessService.earlyAccessShowBadge());

  constructor(private _earlyAccessService: EarlyAccessFeatureService) {
  }

  selectLogout(): void {
    this.userLogout.emit();
  }

  selectSettings(): void {
    this.userSettings.emit();
  }
}
