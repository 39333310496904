import { ChangeDetectorRef, Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginator,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
  MatPaginatorModule
} from "@angular/material/paginator";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";

@Component({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatPaginatorModule
  ],
  selector: 'lendio-paginator',
  templateUrl: './lendio-paginator.component.html',
  styleUrls: ['./lendio-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LendioPaginatorComponent extends MatPaginator {
  constructor(
    intl: MatPaginatorIntl,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions
  ) {
    super(intl, changeDetectorRef, defaults);
  }

  get pages(): number[] {
    return [...Array(this.getNumberOfPages()).keys()];
  }

  get showBeforeEllipse(): boolean {
    return this.pageIndex >= 4 && this.pages.length > 5;
  }

  get showAfterEllipse(): boolean {
    return (
      this.pages.length > 5 &&
      this.pageIndex <= 3 ||
      this.pageIndex > 3 && this.pageIndex < this.pages.length - 3
    );
  }

  getPaginatorPages(): number[] {
    if (this.pages.length <= 5) {
      return this.pages;
    }

    if (this.pages.length > 5 && this.pageIndex <= 3) {
      return this.pages.slice(0, 5);
    }

    if (this.pages.length > 5 && this.pageIndex > 3 && this.pageIndex < this.pages.length - 3) {
      return [Number(this.pageIndex -1), Number(this.pageIndex), Number(this.pageIndex + 1)];
    }

    if (this.pages.length > 5 && this.pageIndex >= (this.pages.length - 3)) {
      const startIndex = Number(this.pages.length - 4);
      const stopIndex = Number(this.pages.length);
      return this.pages.slice(startIndex, stopIndex);
    }

    return [];
  }

  emitPageEvent(nextPage: number): void {
    this.pageIndex = Number(nextPage);
    this.page.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length
    })
  }
}
