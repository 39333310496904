import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'toDateObj',
    standalone: false
})
export class ToDateObjPipe implements PipeTransform {
    transform(value: string): any {
      if (value) {
        return moment.tz(value, 'America/Denver').toDate();
      }
      return null;
    }
}
