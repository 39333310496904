import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { extend, sortBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { Role } from '@app/app/interfaces/role.model';
import { Store } from '@ngxs/store';
import { ScopeState } from '@app/app/store/scopes/scope.state';
import { map, takeUntil } from 'rxjs/operators';
import { ScopeService } from '@app/app/services/scope.service';

@Component({
  selector: 'add-team-member-dialog',
  templateUrl: 'team-member.dialog.component.html',
  standalone: false
})
export class TeamMemberDialogComponent implements OnInit, OnDestroy {

  @ViewChild('rolesSelection') rolesSelection: MatSelectionList;
  @ViewChild('embeddedPermSelection') embeddedPermSelection: MatSelectionList;
  editing: boolean;

  form = new FormGroup({
    first: new FormControl<string>('', Validators.required ),
    last: new FormControl<string>('', Validators.required),
    email: new FormControl<string>('', [
      Validators.email,
      Validators.required,
    ]),
    roles: new FormControl<Array<number>>([]),
    manageStyles: new FormControl<boolean>(false),
    manageLegal: new FormControl<boolean>(false),
    manageWebhooks: new FormControl<boolean>(false),
  });

  roleOptions$: Observable<Array<Partial<Role>>>;
  destroyed$ = new Subject<boolean>();
  adminRoleId$: Observable<number | undefined>;

  saasRolesOrder = this.scopeService.getSaasRolesOrder();

  roleDescriptionsMap = {
    lpxClientAdministrator: 'Full access to all system features, typically reserved for account owners.',
    lpxClientExecutive: 'Primarily views dashboards and reports for high-level insights.',
    lpxClientOperationsLead: 'Manages key tasks and projects, with extended system privileges.',
    lpxClientSalesperson: 'Manages client relationships and pursues new business opportunities.',
    lpxClientProcessor: 'Oversees loan application, management, and closing processes.',
    lpxClientDeveloper: 'Handles API integrations and technical configurations.',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scopeService: ScopeService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if(!!this.data) {
      this.editing = true;
      const dataVal = extend(this.form.value, this.data);
      this.form.setValue(dataVal);
    }
    this.roleOptions$ = this.store.select( ScopeState.saasRoles ).pipe(
      map( roleOptions => {
        return sortBy( roleOptions, role => this.saasRolesOrder.indexOf(role.alias) )
          .map( role => {
            const description = [
              role.shortDescription,
              this.roleDescriptionsMap[role.alias],
              role.description,
            ].find( i => !!i ) || '';

            return {
              ...role,
              description
            };
          });
      })
    )
    this.adminRoleId$ =
    this.roleOptions$.pipe(
      takeUntil(this.destroyed$),
      map( roleOptions => {
        const adminRole = roleOptions.filter( role => role.alias === 'lpxClientAdministrator' )[0];
        return adminRole?.id;
      })
    )
  }

  handleSelectionChange($event, adminRoleId) {
    const option = $event?.options[0];
    const isAdminRole = option?.value === adminRoleId;
    if(isAdminRole) {
      option.selected ? $event.source.selectAll() : $event.source.deselectAll();
    }
    const rolesValue = this.rolesSelection.selectedOptions.selected.map( option => option.value);
    this.form.controls.roles.setValue(rolesValue);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
