<mat-card appearance="outlined" class="m-4">

    <div class="pt-2 pl-2">
        <div class="settings-email-notification">
            <div *ngFor="let setting of emailSettings$ | async" class="settings-row">
                <div>
                    <label id="settings-notification-label-{{setting.value}}">
                        <mat-checkbox
                            color="primary"
                            (change)="onSettingChange(setting)"
                            value="setting.status"
                            [checked]="setting.status"
                            [disabled]="(emailSettingsUpdateStatus$ | async) === 'pending'">
                            <span class="text-lg font-bold">{{ setting.label }}</span>
                        </mat-checkbox>
                    </label>
                    <div [ngClass]="{ 'collapsed': !setting.status }" class="pl-8 mt-1 mb-6 collapse-section flex-height-animated">
                        <div class="expand-in" *ngFor="let child of setting.children">
                            <label id="settings-notification-label-{{child.value}}">
                                <mat-radio-group>
                                    <mat-radio-button
                                        color="primary"
                                        (change)="onSettingChildChange(setting)"
                                        value="child.status"
                                        [checked]="child.status"
                                        [disabled]="(emailSettingsUpdateStatus$ | async) === 'pending'">
                                        {{ child.label }}
                                    </mat-radio-button><br />
                                </mat-radio-group>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
