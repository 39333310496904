import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Decisioning } from '@app/app/interfaces/decisioning.model';
import { Observable } from 'rxjs';
import { BorrowerAttribute } from '@app/app/interfaces/borrower-attribute.model';
import { DecisioningBatch } from "@app/app/interfaces/decisioning-batch.model";
import { BorrowerValuesSource } from "@app/app/interfaces/borrower-values-source.model";
import { Qualifier, QualifierOverride, QualifierValue, ResultsByQualifier } from '@app/app/interfaces/qualifier.model';
import { differenceInHours, format, parseISO } from 'date-fns';
import { AuthState } from '../store/auth/auth.state';
import { Store } from '@ngxs/store';
import { DecisioningResult } from '../interfaces/decisioning-result.model';
import { DecisioningQualifierOverride } from '../interfaces/decisioning-qualifier-override.model';
import { LendioResponse } from '../interfaces/lendio-response';

export interface AttributesMap {
  [alias: string]: BorrowerAttribute
}

export interface DecisioningResponse {
  data: {
    decisioning: Decisioning[];
    attributes: AttributesMap;
    decisioningResults: DecisioningResult[];
  }
}

export interface DecisioningBatchesResponse {
  data: {
    decisioningBatches: DecisioningBatch[];
  }
}

export interface PaginatedDecisioningBatchessResponse {
  data:{
    current_page: number,
    pageIndex: number;
    last_page: number;
    decisioningBatches: DecisioningBatch[];
    next_page_url: string;
    prev_page_url: string;
    last_page_url: string;
    total: number;
    per_page: number;
    nextPageUrl: string;
    sortBy: string;
    sortDirection: string;
    filterBy: string;
  }
}

export interface TrustedBorrowerValuesSourcesResponse {
  data: {
    trustedSources: BorrowerValuesSource[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class DecisioningService {

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {
  }

  /**
   * @param borrowerId
   * @param loanProductId
   */
  getBorrowerDecisioningForProduct(borrowerId: number, loanProductId: number): Observable<DecisioningResponse> {
    return this.httpClient.get<DecisioningResponse>(
      `${environment.apiUrl}/lender-portal/borrowers/${borrowerId}/decisioning-matches?loanProductIds[]=${loanProductId}`
    )
  }

  /**
   * @param pageIndex
   * @param pageSize
   * @param sortBy
   * @param sortDirection
   * @param filterBy
   */
  getLenderDecisioningBatches(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    filterBy: string
  ): Observable<PaginatedDecisioningBatchessResponse> {
    const params =
      {
        'page': pageIndex + 1,
        'limit': pageSize,
        'sortBy': sortBy,
        'sortDirection': sortDirection,
        'filterBy': filterBy
      };
    return this.httpClient.get<PaginatedDecisioningBatchessResponse>(`${environment.apiUrl}/lender-portal/decisioning-batches`, { params  })
  }

  getTrustedBorrowerValuesSources(): Observable<TrustedBorrowerValuesSourcesResponse> {
    return this.httpClient.get<TrustedBorrowerValuesSourcesResponse>(`${environment.apiUrl}/lender-portal/trusted-borrower-values-sources`)
  }

  /**
   * @param borrowerId
   * @param loanProductId
   */
  getDecisioningResultsForBorrower(borrowerId: number, loanProductId: number): Observable<DecisioningResponse> {
    return this.httpClient.get<DecisioningResponse>(
      `${environment.apiUrl}/lender-portal/borrower/${borrowerId}/decisioning-results?loanProductIds[]=${loanProductId}`
    )
  }

  /**
   * @param borrowerId
   * @param dealId
   * @param overrides
   * @return LendioResponse
   */
  postDecisioningOverrides(borrowerId: number, dealId: number, overrides: DecisioningQualifierOverride[]): Observable<LendioResponse> {
    return this.httpClient.post<LendioResponse>(`${environment.apiUrl}/lender-portal/borrower/${borrowerId}/overrides`,
      {
        dealId: dealId,
        overrides: overrides
      }
    )
  }

  /**
   * @param borrowerId
   * @param loanProductId
   * @return LendioResponse
   */
  queueBorrowerDecisioning(borrowerId: number, loanProductId: number): Observable<LendioResponse> {
    return this.httpClient.post<LendioResponse>(
      `${environment.apiUrl}/lender-portal/borrower/${borrowerId}/queue-decisioning?loanProductId=${loanProductId}`, {}
    );
  }

  /**
   * @param qualifier
   * @param resultData
   * @param attributesMap
   * @return QualifierValue
   */
  getFormattedQualifierValue(qualifier: Qualifier, resultData: ResultsByQualifier, attributesMap: AttributesMap, trustedSources: BorrowerValuesSource[], additionalValues: object = {}): QualifierValue {
    return {
      qualifierField: qualifier.field,
      qualifierLabel: this.formatQualifierLabel(qualifier, attributesMap[qualifier.field]?.name),
      requirementLabel: this.getRequirementLabel(qualifier, attributesMap[qualifier.field]),
      actualValue: this.formatQualifierValue(qualifier, resultData, attributesMap[qualifier.field], additionalValues),
      status: resultData.matchValue,
      sourcesMatch: resultData.usedSource === resultData.qualifierAllowedSource,
      usedVerifiedSources: resultData.usedVerifiedSources,
      fromVerifiedSource: this.isVerifiedSource(resultData.usedSource, trustedSources),
      requiredSource: qualifier.sourceId,
      usedSource: resultData.usedSource,
      verifiedSourceLabel: this.getVerifiedSourceLabel(qualifier, trustedSources, resultData),
      verifiedSourceDate: this.getVerifiedSourceDate(qualifier, trustedSources, resultData),
      override: this.formatOverrideQualifierValue(qualifier, attributesMap[qualifier.field], resultData.override),
      tooltip: this.formatQualifierTooltip(qualifier, resultData, attributesMap[qualifier.field], additionalValues)
    }
  }

  /**
   * @param qualifier
   * @param attribute
   * @return string
   */
  formatQualifierLabel(qualifier: Qualifier, qualifierName: any): string {
    if (qualifier.field === 'computed.prohibited_industry_lists') {
      return 'Industry is Prohibited?';
    }
    if (qualifier.field === 'computed.high_risk_industry_lists') {
      return 'Industry is High Risk?';
    }
    if (qualifier.field === 'computed.isDscrQualifiedYear1') {
      return 'DSCR Qualified (Year 1)';
    }
    if (qualifier.field === 'computed.isDscrQualifiedYear2') {
      return 'DSCR Qualified (Year 2)';
    }
    if (qualifier.field === 'computed.isDscrQualifiedYear3') {
      return 'DSCR Qualified (Year 3)';
    }
    return qualifierName;
  }

  /**
   * @param qualifier
   * @param resultData
   * @param attribute
   * @return string
   */
  formatQualifierValue(qualifier: Qualifier, resultData: ResultsByQualifier, attribute: any, additionalValues: object = {}): string {
    // enforce permissible use for experian_consumer source (id: 3)
    if (qualifier.sourceId === 3) {
      return resultData.matchValue === 'accepted' ? 'Within required limits' : 'Outside required limits';
    }
    if (qualifier.field === 'computed.prohibited_industry_lists' || qualifier.field === 'computed.high_risk_industry_lists') {
      if (additionalValues.hasOwnProperty('naics_code')) {
        return resultData.matchValue === 'accepted' ? 'No (NAICS: ' + additionalValues['naics_code'] + ')' : 'Yes (NAICS: ' + additionalValues['naics_code'] + ')';
      } else {
        return 'Missing';
      }
    }
    if (qualifier.field === 'computed.bankruptcyDischargeYears') {
      return resultData.usedValue == '999' ? 'No Bankruptcy'
        : (resultData.usedValue == '-1' ? 'Current Bankruptcy'
          : resultData.usedValue
        );
    }
    if (resultData.usedValue === null || (!resultData.override && resultData.qualifierAllowedSource && resultData.usedSource !== resultData.qualifierAllowedSource)) {
      return 'Missing';
    }
    if (qualifier.filterType === 'is' && (resultData.usedValue === '1' || resultData.usedValue === '0')) {
      return resultData.usedValue === '1' ? 'Yes' : 'No';
    }
    if (attribute.type === 'currency') {
      return this.formatNumber(parseFloat(resultData.usedValue), 'currency');
    }
    if (attribute.type === 'number') {
      return (parseFloat(resultData.usedValue) - Math.floor(parseFloat(resultData.usedValue)) !== 0)
        ? parseFloat(resultData.usedValue).toFixed(2).toString()
        : parseFloat(resultData.usedValue).toString()
    }
    return resultData.usedValue;
  }

  /**
   * @param qualifier
   * @param attribute
   * @param override
   * @return QualifierOverride | undefined
   */
  formatOverrideQualifierValue(qualifier: Qualifier, attribute: any, override: QualifierOverride | undefined): QualifierOverride | undefined {
    if (!override) {
      return undefined;
    } else if (override.oldBvValue === null || override.oldBvValue === 'Missing') {
      override.oldBvValue = 'Missing';
    }
    else if (qualifier.field === 'computed.bankruptcyDischargeYears') {
      override.oldBvValue = override.oldBvValue == '999' ? 'No Bankruptcy'
        : (override.oldBvValue == '-1' ? 'Current Bankruptcy'
          : override.oldBvValue
        );
    }
    else if (qualifier.filterType === 'is' && (override.oldBvValue === '1' || override.oldBvValue === '0')) {
      override.oldBvValue = override.oldBvValue === '1' ? 'Yes' : 'No';
    }
    else if (attribute.type === 'currency') {
      override.oldBvValue = this.formatNumber(parseFloat(override.oldBvValue), 'currency');
    }
    else if (attribute.type === 'number') {
      override.oldBvValue = (parseFloat(override.oldBvValue) - Math.floor(parseFloat(override.oldBvValue)) !== 0)
        ? parseFloat(override.oldBvValue).toFixed(2).toString()
        : parseFloat(override.oldBvValue).toString()
    }
    return override;
  }

  getRequirementLabel(qualifier: Qualifier, attribute: any): string {
    if (qualifier.field === 'computed.prohibited_industry_lists' || qualifier.field === 'computed.high_risk_industry_lists') {
      return `Must be 'No'`;
    } else {
      let readable, min, max;
      switch (qualifier.filterType) {
        case 'contains':
          readable = this.joinWithSeparator(qualifier.contains, ', ', 'or ');
          return `Must have one (or more) of ${readable}`;

        case 'notContains':
          readable = this.joinWithSeparator(qualifier.notContains, ', ', 'or ');
          return `Must have none of ${readable}`;

        case 'in':
          readable = this.joinWithSeparator(qualifier.in, ', ', 'or ');
          return `Must be ${readable}`;

        case 'notIn':
          readable = this.joinWithSeparator(qualifier.notIn, ', ', 'or ');
          return `Cannot be ${readable}`;

        case 'is':
          if (attribute.type === 'bool' || attribute.type === 'boolean') {
            return `Must be '${qualifier.is === '1' ? 'Yes' : 'No'}'`;
          }
          return `Must be ${qualifier.is}`;

        case 'range':
          min = this.formatNumber(qualifier.min, attribute.type);
          max = this.formatNumber(qualifier.max, attribute.type);
          return `Must be between ${min} and ${max}`;

        case 'min':
          min = this.formatNumber(qualifier.min, attribute.type);
          return `Must be at least ${min}`;

        case 'max':
          max = this.formatNumber(qualifier.max, attribute.type);
          return `Must be ${max} or less`;

        default:
          return '(INVALID FILTER)';
      }
    }
  }

  formatQualifierTooltip(qualifier: Qualifier, resultData: ResultsByQualifier, attribute: any, additionalValues: object = {}): string {
    let tooltip = '';

    if (qualifier.field === 'computed.prohibited_industry_lists' || qualifier.field === 'computed.high_risk_industry_lists') {
      if (additionalValues.hasOwnProperty('naics_code') && additionalValues.hasOwnProperty('naics_title')) {
        tooltip = additionalValues['naics_code'] + ": " + additionalValues['naics_title'];
      }
    }

    if (
      this.formatQualifierValue(qualifier, resultData, attribute, additionalValues) === 'Missing'
    ) {
      tooltip = 'This data was not available for decisioning.';
    }

    return tooltip;
  }

  joinWithSeparator(list: string[], separator: string, term: string): string {
    if (!Array.isArray(list) || list.length === 0) {
      return 'INVALID FILTER';
    }

    if (list.length === 1) {
      return list[0];
    }

    if (list.length === 2) {
      return `${list[0]} ${term} ${list[1]}`;
    }

    const last = list.pop();
    return `${list.join(separator)}${separator}${term}${last}`;
  }

  formatNumber(number: number, type: string): string {
    return (type === 'currency' ? '$' : '') + this.addCommas(number);
  }

  addCommas(number: number): string {
    const parts = (number * 1).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  isVerifiedSource(sourceId, trustedSources: BorrowerValuesSource[]): boolean {
    return !!trustedSources.find(trustedSource => trustedSource.id === sourceId);
  }

  getVerifiedSourceLabel(qualifier: Qualifier, trustedSources: BorrowerValuesSource[], resultData: ResultsByQualifier): string {
    let verifiedLabel = 'N/A';
    if(this.isVerifiedSource(resultData.usedSource, trustedSources)) {
      verifiedLabel = trustedSources.find(trustedSource => trustedSource.id === resultData.usedSource)?.displayName || 'N/A';
    } else if (resultData.usedVerifiedSources) {
      verifiedLabel = "a Verified Source"; // This is so we can show that data was not provided by 'a Verified Source' when appropriate
    }
    return verifiedLabel;
  }

  getVerifiedSourceDate(qualifier: Qualifier, trustedSources: BorrowerValuesSource[], resultData: ResultsByQualifier): string {
    let verifiedDate = '';
    if (this.isVerifiedSource(resultData.usedSource, trustedSources) && resultData.modified) {
      if (qualifier.sourceId || resultData.usedVerifiedSources) {
        const dateObject = parseISO(resultData.modified);
        const qualifierModifiedDate = format(dateObject, 'MM/dd/yyyy');
        verifiedDate = qualifierModifiedDate;
      }
    } else {
      verifiedDate = 'N/A';
    }
    return verifiedDate;
  }

  shouldShowDecisioningSnackbar(decisioningProgress: DecisioningBatch[]) {
    const decisioningProgressForSort = [...decisioningProgress];
    const mostRecentDecisioningBatch = decisioningProgressForSort.sort(({ id: a }, { id: b }) => b - a)[0];
    if (mostRecentDecisioningBatch) {
      const mostRecentBatchDate = new Date(mostRecentDecisioningBatch.created);
      const isWithinLast24Hours = differenceInHours(new Date(), mostRecentBatchDate) <= 24;
      const dismissDecisioningSnackbarList = JSON.parse(localStorage.getItem('DismissDecisioningSnackbarList') || '[]');
      const status = DecisioningService.getDecisioningBatchStatus(mostRecentDecisioningBatch)
      const currentUser = this.store.selectSnapshot(AuthState.user);
      return mostRecentDecisioningBatch.total > 0 &&
        status != 'inProgress' &&
        mostRecentDecisioningBatch.importId !== null &&
        mostRecentDecisioningBatch.generatedByUserId == currentUser?.id &&
        isWithinLast24Hours &&
        !dismissDecisioningSnackbarList?.includes(`${mostRecentDecisioningBatch.id}:${status}`);
    } else {
      return false;
    }
  }

  /**
   * @param decisioningReRun
   * @returns boolean
   */
  shouldShowReRunSnackbar(decisioningReRun: DecisioningResult[]): boolean {
    const currentReRun = [...decisioningReRun];
    // Is there a re-run?
    if (!currentReRun.length) {
      return false;
    }

    return true;
  }

  static getDecisioningBatchStatus(batch: DecisioningBatch): string {
    if (batch.totalSucceeded == 0 && batch.totalFailed == 0) {
      return 'started';
    }

    if (batch.totalFailed > 0) {
      return 'errors';
    }

    if (batch.downloadStatus) {
        return 'report_ready';
    }

    if (batch.totalSucceeded == batch.total) {
      return 'complete';
    }

    return 'inProgress';
  }
}
