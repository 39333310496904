import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DisplayValue,
  DisplayValuesMap,
} from '@app/app/components/businesses/business-values/business-values.component';
import { map, Observable, Subject } from 'rxjs';
import { FinancesState } from '@app/app/store/finances/finances.state';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-credit-report',
  templateUrl: 'credit-report.component.html',
  standalone: false
})
export class CreditReportComponent implements OnInit, OnDestroy {

  @Input() contactId!: number;
  @Input({required: true}) type!: 'commercial' | 'consumer';
  @Input() showEmptyState: boolean = false;

  creditReport$: Observable<{[key: string]: any}>;
  creditReportListValues$: Observable<DisplayValue[]>;
  destroyed$ = new Subject<boolean>();
  emptyStateVals: DisplayValue[];
  displayValuesMap: DisplayValuesMap;

  consumerDisplayableValuesMap: DisplayValuesMap = {
    publicRecordsCount: {
      label: '# of public records',
      value: (val) => val,
    },
    installmentBalance: {
      label: 'Installment balance',
      value: (val) => this.currencyPipe.transform(val),
    },
    revolvingBalance: {
      label: 'Revolving balance',
      value: (val) => this.currencyPipe.transform(val),
    },
    realEstateBalance: {
      label: 'Real estate balance',
      value: (val) => this.currencyPipe.transform(val),
    },
    revolvingAvailablePercent: {
      label: 'PCT revolving availability',
      value: (val) => `${val}%`,
    },
    inquiriesDuringLast6Months: {
      label: '6 month inquiries',
      value: (val) => val,
    },
    delinquenciesOver30Days: {
      label: '30 day delinquencies',
      value: (val) => val,
    },
    delinquenciesOver60Days: {
      label: '60 day delinquencies',
      value: (val) => val,
    },
    delinquenciesOver90Days: {
      label: '90 day delinquencies',
      value: (val) => val,
    },
    derogCounter: {
      label: '# of derogatory marks',
      value: (val) => val,
    }
  }

  commercialDisplayableValuesMap: DisplayValuesMap = {
    noPayRisk: {
      label: 'No pay risk',
      value: (val) => val?.toString(),
      tooltip: `This is the Financial Stability Risk Rating (FSR score) from Experian, which indicates risk of serious delinquency / default / bankruptcy. See Experian's definitions around this metric at https://smallbusiness.experian.com/pdp.aspx?pg=glossary&bm=FINANCIALSTABILITYRISKRATING`,
      labelIcon: 'info_outline',
    },
    slowPayRisk: {
      label: 'Slow pay risk',
      value: (val) => val?.toString(),
      tooltip: `This is the Intelliscore Plus V2 (IPv2 score) from Experian. See Experian's definitions around this metric at https://www.experian.com/assets/business-information/brochures/intelliscore-plus-v2-product-sheet.pdf`,
      labelIcon: 'info_outline',
    },
    currentVsIndustryDbt: {
      label: 'Current vs Industry DBT',
      value: (val) => val?.toString(),
      tooltip: `These are the number of days this business pays its bills past the invoice due date (days beyond terms) compared to the industry average. See more detail from Experian at https://smallbusiness.experian.com/pdp.aspx?pg=Helpglossary#dbt`,
      labelIcon: 'info_outline',
    },
    quarterlyScoreTrends: {
      label: 'Quarterly score trends',
      value: (val) => val?.toString(),
      tooltip: `Are they getting better? Worse? Staying the same?`,
    },
    tradelines: {
      label: 'Tradelines',
      value: (val) => val,
      tooltip: `This is the number of credit lines extended to this business. The more tradelines, the more debt this business has – also the more data to inform the scoring and therefore more accuracy.`,
    },
    totalBalanceAcrossTradelines: {
      label: 'Total balance across tradelines',
      value: (val) => this.currencyPipe.transform(val),
      tooltip: `The sum of the balances of all open tradelines for this business.`,
    },
    inquiriesInLastNineMonths: {
      label: 'Inquiries in last 9 mo',
      value: val => val,
      tooltip: `This is the number of companies making inquiries on this business in the last 9 months.`,
    },
    taxLiens: {
      label: 'Tax liens',
      value: val => val,
      tooltip: `The number of tax liens imposed upon this business. In this situation, the tax agency is taking security of the debt.`,
    },
    judgements: {
      label: 'Judgements',
      value: val => val,
      tooltip: `This is the number of legal judgments against this business. This number may include judgments that are satisfied.`,
    },
    collections: {
      label: 'Collections',
      value: val => val,
      tooltip: `This is the number of open or closed collection tradelines (from collection agencies).`,
    },
    totalUccFilings: {
      label: 'Total UCC filings',
      value: val => val,
      tooltip: `This is the number of tradelines with Uniform Commercial Code (UCC) filings. This includes original, continuation, addition, and supplemental filings.`,
      labelIcon: 'info_outline',
    },
    derogatoryRecords: {
      label: 'Derogatory records',
      value: val => val,
      tooltip: `This is the number of public records that include things like bankruptcy, tax liens (federal, state, and county), judgments, and UCC filings for this business.`,
      labelIcon: 'info_outline',
    }
  }

  constructor(
    private store: Store,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit():void {
    this.displayValuesMap = this.type === 'consumer'
      ? this.consumerDisplayableValuesMap
      : this.commercialDisplayableValuesMap;

    this.emptyStateVals = Object.keys(this.displayValuesMap).map( key => {
      return {
        label: this.displayValuesMap[key].label,
        value: 'N/A',
      }
    });

    const selector = this.type === 'consumer'
      ? FinancesState.creditReportByContactId(this.contactId)
      : FinancesState.commercialCredit;
    this.creditReport$ = this.store.select( selector );
    this.creditReportListValues$ = this.creditReport$.pipe(
      takeUntil(this.destroyed$),
      map( creditReport => {
        return !isEmpty(creditReport)
          ? this.formatValuesFromCreditReport(creditReport)
          : this.showEmptyState
            ? this.emptyStateVals
            : [];
      }),
    );

  }

  formatValuesFromCreditReport(creditReport): DisplayValue[] {
    return Object.keys(creditReport)
      .filter( crValueKey => !!this.displayValuesMap[crValueKey] )
      .map( crValueKey => {
        const mapConfig = this.displayValuesMap[crValueKey];
        return {
          label: mapConfig.label,
          value: mapConfig.value(creditReport[crValueKey]),
          tooltip: mapConfig.tooltip,
          labelIcon: mapConfig.labelIcon,
        };
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
