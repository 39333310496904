import { Component, computed, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetEmailSettings, UpdateEmailSetting, DismissEmailSettingsUpdateStatus } from '../../store/settings/settings.actions';
import { SettingsState } from 'src/app/store/settings/settings.state';
import { EmailSetting } from '../../interfaces/email-setting.model';
import { Observable } from 'rxjs';
import { PortalUsersState } from 'src/app/store/portal-users/portal-users-state.service';
import { PortalUser } from 'src/app/interfaces/portal-user.model';
import { GetPortalUsers } from 'src/app/store/portal-users/portal-users.actions';
import { LoginUser } from 'src/app/interfaces/login-user.model';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { AuthState } from '@app/app/store/auth/auth.state';
import { EarlyAccessFeatureService } from '@app/app/components/settings/early-access/early-access-feature.service';
import { SaasFeaturesService } from '@app/app/services/saas-features.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: false
})
export class SettingsComponent implements OnInit {
  emailSettings$: Observable<EmailSetting[]> = this._store.select(SettingsState.emailSettings);
  emailSettingsUpdateStatus$: Observable<any> = this._store.select(SettingsState.emailSettingsUpdateStatus);
  portalUsers$: Observable<PortalUser[] | null> = this._store.select(PortalUsersState.portalUsers);
  timeout: any;
  selectedIndex = 0;
  currentPortalUser: LoginUser;
  userCanManageSbaToken: boolean;

  tabs = [
    {
      name: 'notifications',
      label: 'Notifications',
      path: 'notifications',
      permitted: this.canManageNotifications
    },
    {
      name: 'update-password',
      label: 'Update Password',
      path: '/settings/update-password',
      permitted: true,
    },
    {
      name: 'users',
      label: 'Users',
      path: '/settings/users',
      permitted: this.canManageLenderUsers && this.notIntelligentLendingUser,
    },
    {
      name: 'sba-token',
      label: 'SBA Token',
      path: '/settings/sba-token',
      permitted: this.canManageSbaToken,
    },
    {
      name: 'early-access',
      label: 'Early access',
      path: '/settings/early-access',
      permitted: true,
    }
  ];
  showEarlyAccessBadge = computed(() => this._earlyAccessService.earlyAccessShowBadge());

  constructor(
    private _store: Store,
    private _pageService: NavPageTitleService,
    private _earlyAccessService: EarlyAccessFeatureService,
    private saasService: SaasFeaturesService,
  ) { }

  ngOnInit(): void {
      this._store.dispatch(new GetPortalUsers());
      const user = this._store.selectSnapshot(AuthState.user);
      if (!user?.isReferralPartner()) {
          this._store.dispatch(new GetEmailSettings());
      }
      this._pageService.setTitle('Settings');
  }

  get canManageNotifications(): boolean {
    return !this._store.selectSnapshot(AuthState.user)?.isReferralPartner();
  }

  get canManageLenderUsers(): boolean {
    return this._store.selectSnapshot(AuthState.user)?.permittedTo('lpxManageLenderUsers') ?? false;
  }

  get notIntelligentLendingUser(): boolean {
    return !this.saasService.saasPermitted('intelligentLending')
  }

  get canManageSbaToken(): boolean {
    const user = this._store.selectSnapshot(AuthState.user);
    return (user?.permittedTo('manageSbaToken') ?? false) && !user?.isReferralPartner();
  }

  updateSetting(setting: EmailSetting) {
    this._store.dispatch(new UpdateEmailSetting(setting));
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._store.dispatch(new DismissEmailSettingsUpdateStatus());
    }, 5000);
  }
}
