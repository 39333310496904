import { Pipe, PipeTransform } from '@angular/core';

// TODO: Rename this to something like SsnMaskPipe since we can have many
// kinds of masks (phone, ssn, ein, etc.).
@Pipe({
  name: 'defaultValue',
  standalone: false
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: any, defaultValue = '-'): any {
    return value || defaultValue;
  }
}
