import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DealNote } from '@app/app/interfaces/deal-note.model';
import { Select, Store } from '@ngxs/store';
import { DealNotesState } from '@app/app/store/deal-notes/deal-notes.state';
import { AddDealNote } from '@app/app/store/deal-notes/deal-notes.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CreateNoteDialogComponent
} from '@app/app/components/deal-notes/create-note-dialog/create-note-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-deal-notes',
  templateUrl: './deal-notes.component.html',
  styleUrls: ['./deal-notes.component.scss'],
  standalone: false
})
export class DealNotesComponent {
  @Select( DealNotesState.dealNotes ) notes$: Observable<DealNote[]>;
  @Input() dealId: number;

  constructor(
    public dialog: MatDialog,
    private store: Store
  ) { }

  openAddNote() {
     const dialogRef: MatDialogRef<CreateNoteDialogComponent> = this.dialog.open( CreateNoteDialogComponent, {
      width: '450px',
    });
     dialogRef.componentInstance.saveNote.pipe(
       take(1)
     ).subscribe( noteContent => {
       if(noteContent?.length) {
         this.store.dispatch( new AddDealNote(this.dealId, noteContent) );
       }

       dialogRef.close();
     })
  }

}
