import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-product-title',
  templateUrl: './loan-product-title.component.html',
  styleUrls: ['./loan-product-title.component.scss'],
  standalone: false
})
export class LoanProductTitleComponent implements OnInit {

  @Input() loanProduct: { lender: { name: string; }; name: string; };

  loading: any;

  constructor() { }

  ngOnInit(): void {}

  getText() {
    return this.loanProduct.lender.name + ' - ' + this.loanProduct.name;
  }

}
