<div class="mat-subtitle-1">
    {{ title }}
</div>
<table mat-table [dataSource]="data" class="w-full mat-elevation-z1">
    <!-- Lender Name Column -->
    <ng-container matColumnDef="lender">
        <th class="table-padding" mat-header-cell *matHeaderCellDef> Lender </th>
        <td class="table-padding" mat-cell *matCellDef="let item">
            {{ item.lenderName }}
        </td>
    </ng-container>

    <!-- File Name Column -->
    <ng-container matColumnDef="title">
        <th class="table-padding whitespace-no-wrap" mat-header-cell *matHeaderCellDef> File Name </th>
        <td class="table-padding" mat-cell *matCellDef="let item">
            <span *ngIf="!item.awsFileName; else titleDisplay">
                File Pending
            </span>
            <ng-template #titleDisplay>
                <span>
                    {{ item.originalFileName }}
                    <a [href]="getDownloadLink(item.id)" *ngIf="(currentUser$ | async)?.permittedTo('canManageLoanForgivenessCSV')">
                        <mat-icon class="!text-blue-400 !text-sm">cloud_download</mat-icon>
                    </a>
                </span>
            </ng-template>
        </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th class="table-padding" mat-header-cell *matHeaderCellDef> Status </th>
        <td class="table-padding" mat-cell *matCellDef="let item">
            {{ (item.accountsCreated === 0 ? 'Processing' : 'Accounts Created') }}
        </td>
    </ng-container>

    <!-- Requested Column -->
    <ng-container matColumnDef="created">
        <th class="table-padding whitespace-no-wrap" mat-header-cell *matHeaderCellDef> Uploaded Date </th>
        <td class="table-padding" mat-cell *matCellDef="let item">
            {{ item.created | toDateObj | date:'short' }} UTC
        </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th class="table-padding whitespace-no-wrap" mat-header-cell *matHeaderCellDef> Upload Type </th>
        <td class="table-padding" mat-cell *matCellDef="let item"> {{ item.csvType | titlecase }} </td>
    </ng-container>

    <!-- Borrowers Uploaded Column -->
    <ng-container matColumnDef="borrowersUploaded">
        <th class="table-padding whitespace-no-wrap" mat-header-cell *matHeaderCellDef> Number Of Borrowers </th>
        <td class="table-padding" mat-cell *matCellDef="let item"> {{ item.borrowersUploaded | number:'1.0':'en-US' }} </td>
    </ng-container>

    <!-- Uploaded By Column -->
    <ng-container matColumnDef="uploadedBy">
        <th class="table-padding whitespace-no-wrap" mat-header-cell *matHeaderCellDef> Uploaded By </th>
        <td class="table-padding" mat-cell *matCellDef="let item"> {{ item.userEmail }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<lendio-paginator #paginator
                  [disabled]="(status$ | async) !== 'success'"
                  [length]="totalCount$ | async"
                  [hidePageSize]="true"
                  [pageSize]="50"
                  [pageSizeOptions]="[10, 50, 100]"
                  (page)="paging($event)">
</lendio-paginator>
