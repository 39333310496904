import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImportEntity } from '@app/app/interfaces/import-details.model';
import { Import } from '@app/app/interfaces/import.model';
import { GetImportDetails, GetImportEntities, SubscribeToImportDetailsStateUpdates } from '@app/app/store/import-details/import-details.actions';
import { ImportDetailsState } from '@app/app/store/import-details/import-details.state';
import { ImportFailDialogComponent } from '../import-fail-dialog/import-fail-dialog.component';
import { ImportEntityDetailDialogComponent } from '../import-entity-detail-dialog/import-entity-detail-dialog.component';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map, take, tap } from 'rxjs';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';

@Component({
  selector: 'app-import-details',
  templateUrl: './import-details.component.html',
  styleUrls: ['./import-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ImportDetailsComponent implements OnInit, OnDestroy {

@Select(ImportDetailsState.filterBy) filterBy$: Observable<string>;
@Select(ImportDetailsState.importDetails) importDetails$: Observable<Import>;
@Select(ImportDetailsState.importEntities) importEntities$: Observable<ImportEntity[]>
@Select(ImportDetailsState.created) created$: Observable<Date>;
@Select(ImportDetailsState.importedBy) importedBy$: Observable<string>;
@Select(ImportDetailsState.status) status$: Observable<number>;
@Select(ImportDetailsState.type) type$: Observable<number>;
@Select(ImportDetailsState.totalRecords) totalRecords$: Observable<number>;
@Select(ImportDetailsState.updatedRecords) updatedRecords$: Observable<number>;
@Select(ImportDetailsState.importedRecords) importedRecords$: Observable<number>;
@Select(ImportDetailsState.skippedRecords) skippedRecords$: Observable<number>;
@Select(ImportDetailsState.failedRecords) failedRecords$: Observable<number>;
@Select(ImportDetailsState.description) description$: Observable<number>;
@Select(ImportDetailsState.pageIndex) pageIndex$: Observable<number>;
@Select(ImportDetailsState.pageSize) pageSize$: Observable<number>;
@Select(ImportDetailsState.sortBy) sortBy$: Observable<string>;
@Select(ImportDetailsState.sortDirection) sortDirection$: Observable<string>;
@Select(ImportDetailsState.total) total$: Observable<number>;
@Select(ImportDetailsState.loading) loading$: Observable<boolean>;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

  filterTypes = [
    {key: 'All Statuses', value: ''},
    {key: 'Imported', value: 'imported'},
    {key: 'Updated', value: 'updated'},
    {key: 'Skipped', value: 'skipped'},
    {key: 'Failed', value: 'failed'},
  ];

  pageSizeOptions: number[] = [20, 50];
  defaultPageSize: number = 20;
  tableDataSource$: Observable<MatTableDataSource<ImportEntity>>;
  importId: number;
  filterBy: string;
  componentTitle: string = 'Import Details';
  createdDate$: Observable<Date>;
  isLoaded: boolean;

  constructor(
    private pageTitleService: NavPageTitleService,
    private breadcrumbService: BreadcrumbService,
    private store: Store,
    private route: ActivatedRoute,
    private dialog: MatDialog) {
    this.importId = isNaN(Number(this.route.snapshot.paramMap.get('id'))) ? -1 : Number(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.isLoaded = false;
    this.store.dispatch(new GetImportDetails(Number(this.importId)));
    this.store.dispatch(new SubscribeToImportDetailsStateUpdates());

    this.created$.pipe(
      filter((created) => {
        return created !== undefined;
      })
      ,take(1)
      ,tap((created) => {
        const date = new Date(created);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
        });
        this.pageTitleService.setTitle(formattedDate);
    })).subscribe();

    this.breadcrumbService.setBreadcrumbs([
      {path: '/administration/import', label: 'Import'}
    ]);

    this.isLoaded = true;
    this.pageTitleService.setImportDetailsActive(true);
  }

  ngAfterViewInit(): void {
    this.tableDataSource$ = this.importEntities$.pipe(
      map((importEntities) => {
        const tableDataSource = new MatTableDataSource<ImportEntity>(importEntities);
        tableDataSource.paginator = this.paginator;
        tableDataSource.sort = this.sort;
        return tableDataSource;
      })
    );
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setBreadcrumbs([]);
    this.pageTitleService.setImportDetailsActive(false);
  }

  handlePageEvent(e: PageEvent) {
    const params = {
      pageIndex: e.pageIndex,
      pageSize: e.pageSize,
      filterBy: this.filterBy,
    }
    this.store.dispatch(new GetImportEntities(params, this.importId));
  }

  handleSortEvent(e: any) {
    if(e.direction === ""){
      e.direction = 'undefined';
    }
    const params = {
      pageIndex: 0,
      sortBy: e.active,
      sortDirection: e.direction,
      filterBy: e.value,
    }
    this.store.dispatch(new GetImportEntities(params, this.importId));
  }

  openEntityDialog(rowData: any) {
    const _dialog =  this.dialog.open(ImportEntityDetailDialogComponent,
      {
        width: '920px',
        data: rowData?.data
      });
  }

  openFailedEntityDialog(rowData: any) {
    const _dialog =  this.dialog.open(ImportFailDialogComponent,
      {
        panelClass: 'custom-dialog-container',
        maxWidth: '320px',
        data: {...rowData, page: 'details'}
      });
  }

  getColumnsToDisplay(type: string) {
    if(type === 'borrowers' || type === 'contacts') {
      return [
        'name',
        'externalId',
        'result',
        'errors',
        'entityId',
        'entityData'
      ];
    } else {
      return [
        'externalId',
        'result',
        'errors',
        'entityId',
        'entityData'
      ];
    }
  }

  getFormattedErrors(errorMsg: any) {
    let message = '';
    try {
      const importError = JSON.parse(errorMsg);
      message = Object.keys(importError).map((item) => importError[item]).join(" ");
    } catch {
      message = errorMsg;
    }

    return message;
  }
}
