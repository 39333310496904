import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Deal } from '@app/app/interfaces/deal.model';
import { GetSavedOwnerReports } from '@app/app/store/finances/finances.actions';
import { FinancesState } from '@app/app/store/finances/finances.state';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { Business } from '@app/app/interfaces/business.model';
import { PhonePipe } from '@app/app/pipes/phone/phone.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { GetEmailTemplates, SendEmailFromTemplate } from '@app/app/store/templates/template.actions';
import { Observable, of, take } from 'rxjs';
import { EmailTemplate } from '@app/app/interfaces/templates/EmailTemplate.model';
import { TemplateState } from '@app/app/store/templates/template.state';
import { futureDateResolver, logoResolver, TemplateResolvers } from '@app/app/store/templates/template-resolvers';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Contact } from '@app/app/interfaces/contact.model';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { MatSelectModule } from '@angular/material/select';
import { ContactsActions } from '@app/app/store/contacts/contacts.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConsumerCreditReport } from '@app/app/interfaces/credit-reports/credit-report.model';
import { combineLatest } from 'rxjs/internal/operators/combineLatest';

export interface TemplateDialogData {
  business: Business;
  deal: Deal;
  creditReport: ConsumerCreditReport;
  recipientEmails: string[];
}

@Component({
  selector: 'app-template-dialog',
  templateUrl: 'template.dialog.component.html',
  styleUrls: ['template.dialog.component.scss'],
  imports: [
    CommonModule,
    ClipboardModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatSelectionList,
    MatSelectModule,
    MatStepperModule,
    ReactiveFormsModule,
    QuillModule,
    MatFormField,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ]
})
export class TemplateDialogComponent {

  @ViewChild('reasonsList') reasonsList: MatSelectionList;
  @ViewChild('stepper') stepper: MatStepper;

  emailTemplates$: Observable<EmailTemplate[]> = this.store.select( TemplateState.emailTemplates );
  contacts$: Observable<Contact[]> = this.store.select( ContactsState.contacts );
  primaryContact$: Observable<Contact | undefined> = this.store.select( ContactsState.primaryContact );
  primaryContactCreditReport$: Observable<ConsumerCreditReport>;
  templatesLoading$: Observable<boolean> = this.store.select( TemplateState.loading );

  quillEditor: Quill;
  reasonsTemplateContent: string;
  selectedTemplate: EmailTemplate;
  editorContent: string;

  reasonsMap = {
    // CCBank NOAA - Denied Credit Application Template
    30945: [
      'Credit score too low',
      'Out of Lending Footprint',
      'Unfavorable 3rd Party Reporting',
      'Credit not granted to any applicants on terms or conditions requested',
    ],
    // CCBank Incomplete Credit Application Template
    30947: [
      'Missing Bank Statements',
      'Other Required Documents',
    ],
    // Corner Bank Demo AAN
    30992: [
      'Credit score too low',
      'Out of Lending Footprint',
      'Unfavorable 3rd Party Reporting',
      'Credit not granted to any applicants on terms or conditions requested',
    ],
    // Ridgeline Bank Demo AAN
    31000: [
      'Credit score too low',
      'Out of Lending Footprint',
      'Unfavorable 3rd Party Reporting',
      'Credit not granted to any applicants on terms or conditions requested',
    ]
  };
  reasons: string[] = [];
  selectedReasons: string[];

  formGroup = this.formBuilder.group({
    AANTemplateId: new FormControl(null, {validators: Validators.required}),
    subject: new FormControl(''),
    recipients: new FormControl<string[]>([], [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TemplateDialogData,
    public dialogRef: MatDialogRef<TemplateDialogComponent>,
    private formBuilder: FormBuilder,
    private phonePipe: PhonePipe,
    private store: Store,
  ) {
    this.store.dispatch( new GetEmailTemplates() );
    this.store.dispatch( new ContactsActions.GetIndex(this.data.business?.id) );

    this.primaryContact$.pipe(
      takeUntilDestroyed(),
    ).subscribe( primaryContact => {
      if(primaryContact?.email) {
        this.formGroup.controls.recipients.setValue([primaryContact.email]);
      }
    });

    !!this.data.business?.id && this.store.dispatch( new GetSavedOwnerReports(this.data.business?.id));
    const primaryContact = this.data.business?.primaryContact;
    if(!!primaryContact?.email) {
      this.formGroup.controls.recipients.setValue([primaryContact.email]);
    }

    if(!!primaryContact?.id) {
      this.primaryContactCreditReport$ = this.store.select( FinancesState.creditReportByContactId(primaryContact.id) );
      this.primaryContactCreditReport$.pipe(
        takeUntilDestroyed(),
      ).subscribe( primaryContactCreditReport => {
        this.data.creditReport = primaryContactCreditReport;
      });
    }
  }

  handleTemplateSelectionChange( { options } ) {
    const templateId = options[0]?.value;
    this.emailTemplates$.pipe(
      take(1),
    ).subscribe( emailTemplates => {
      const matchedSelection = emailTemplates.find( template => template.id === templateId );
      if(matchedSelection) {
        this.selectedTemplate = matchedSelection;
        this.formGroup.controls.AANTemplateId.setValue(templateId);
        this.formGroup.controls.subject.setValue(this.selectedTemplate.subject);
        this.selectedReasons = [];
        this.reasons = this.reasonsMap[templateId] || [];
        this.updateTemplateContent();
      }
    });
  }

  handleReasonSelectionChange( { source } ){
    this.selectedReasons = source.selectedOptions.selected.map( option => option.value );
    this.updateTemplateContent();
  }

  updateTemplateContent() {
    this.updateRecipients();
    const reasons = this.reasons.filter( reason => this.selectedReasons?.includes(reason) );
    this.reasonsTemplateContent = `<ul>${reasons.map( selectedReason => `<li>${selectedReason}</li>`).join('')}</ul>`;
    let templateContent = this.selectedTemplate.content.replace('[REASONS]', this.reasonsTemplateContent);
    const pipes = {
      phone: this.phonePipe,
    };
    Object.keys(TemplateResolvers).forEach( resolverAlias => {
      const resolver = TemplateResolvers[resolverAlias];
      if(typeof resolver === 'function') {
        templateContent = templateContent.replace(new RegExp(`{${resolverAlias}}`, 'g'), resolver(this.data, pipes) );
      }
    });
    templateContent = templateContent.replace(
      new RegExp('{daysInFuture:([\\d]+)}', 'g'),
      (match, $1) => futureDateResolver($1),
    );
    templateContent = templateContent.replace(
      new RegExp(`{logo:([^|\\s\\n<>]+)(?:\\|)?(\\d+)?(?:\\|)?(\\d+)?}`, 'g'),
      (match, $1, $2, $3) => logoResolver($1, $2, $3),
    );
    this.editorContent = templateContent;
  }

  updateRecipients() {
    this.data.recipientEmails = this.formGroup.controls.recipients.value || [];
  }

  onEditorCreated(quillEditor: any): void {
    if (quillEditor) {
      this.quillEditor = quillEditor;
    }
  }

  confirmAndSend() {
    this.store.dispatch( new SendEmailFromTemplate(
      this.data.business?.id,
      this.data.deal?.id,
      this.formGroup.controls.recipients.value!,
      this.selectedTemplate.id,
      this.editorContent,
      this.formGroup.controls.subject.value!,
    ));
    this.dialogRef.close('AAN sent successfully');
  }

  handleStepperNext(stepper) {
    return stepper.selectedIndex === 2 ? this.confirmAndSend() : stepper.next();
  }

}
