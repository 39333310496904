import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-saas-new-feature-badge',
  templateUrl: './saas-new-feature-badge.component.html',
  styleUrls: ['./saas-new-feature-badge.component.scss']
})
export class SaasNewFeatureBadgeComponent implements OnInit, AfterViewInit{
  @ViewChild('FeatureBadgePing') featureBadgePingElementRef!: ElementRef;
  @Input() intervals: number | undefined;
  @Input() intervalInMs = 15000;
  @Input() iterationCount = 5;

  private _remainingIntervals = 0;
  set remainingIntervals(val: number) {
    this._remainingIntervals = val;
  }
  get remainingIntervals(): number {
    return this._remainingIntervals;
  }

  ngOnInit(): void {
    if (this.intervals !== undefined && !isNaN(this.intervals)) {
      this.remainingIntervals = this.intervals < 11
        ? this.intervals + 1
        : 11;
    }
  }

  ngAfterViewInit(): void {
    this.featureBadgePingElementRef.nativeElement.animationIterationCount = this.iterationCount;
    this.featureBadgePingElementRef?.nativeElement.addEventListener('animationend', this._handleAnimationEnd.bind(this));
  }

  private _handleAnimationEnd() {
    this.featureBadgePingElementRef?.nativeElement.classList.remove('sass-feature-badge-ping-animation');
    if (this.intervals === undefined) {
      // always restart animation after interval time
      setTimeout(() => this.restartFeatureBadgeAnimation(), this.intervalInMs);
    } else {
      // restart a specific number of times then leave the animation in a completed state.
      if (this.remainingIntervals === 0) {
        return;
      } else {
        this.remainingIntervals -= 1;
        this.restartFeatureBadgeAnimation();
      }
    }
  }

  restartFeatureBadgeAnimation(): void {
    setTimeout(() => {
      this.featureBadgePingElementRef?.nativeElement.classList.add('sass-feature-badge-ping-animation');
    }, this.intervalInMs);
  }
}
