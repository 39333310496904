import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { ForgotPassword } from '@app/app/store/auth/auth.actions';
import { Store } from '@ngxs/store';

export interface ForgotPasswordDialogData {
  email: string;
}

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  standalone: false
})
export class ForgotPasswordDialogComponent implements OnInit {
  form: FormGroup;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData,
    private store: Store,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', {
        validators: [
          Validators.email,
          Validators.required,
        ],
        updateOn: 'change'
      }),
    });
  }

  canSubmit() {
    return (this.form.valid && !this.loading);
  }

  submit(): void {
    if (!this.canSubmit()) {
      return;
    }
    this.loading = true;
    this.store.dispatch(new ForgotPassword(this.form.value.email)).pipe(take(1)).subscribe(() => this.dialogRef.close(true));
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
