import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expiration-dialog',
  templateUrl: './expiration-dialog.component.html',
  styleUrls: ['./expiration-dialog.component.scss'],
  standalone: false
})
export class ExpirationDialogComponent {

  constructor(public dialogRef: MatDialogRef<ExpirationDialogComponent>) { }

  continueSession(): void {
    localStorage.setItem('SessionWarningDismissed', 'true')
    this.dialogRef.close(true);
  }

  logOut(): void {
    this.dialogRef.close(false);
  }
}
