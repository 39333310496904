import { Component, Input, OnInit } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { Approval } from "@app/app/interfaces/approval/approval.model";
import { ApprovalOption } from "@app/app/interfaces/approval/approval-option.model";
import { ApprovalCustomOption } from "@app/app/interfaces/approval/approval-custom-option.model";
import * as ApprovalCalculatorUtility from '@lendiodevs/lendio-js-utilities/lib/approval/ApprovalCalculator.utility.js';
import * as locTermSbaCalculatorGeneralFormulas from '@lendiodevs/lendio-js-utilities/dist/calculator/formulas/general-formulas';
import { LocTermSbaApprovalOption } from "@lendiodevs/lendio-js-utilities/dist/calculator/formulas/general-formulas"
import { CalculateScheduleParams } from "@lendiodevs/lendio-js-utilities/dist/calculator/payment-schedule";
import { configureCalculator } from '@lendiodevs/lendio-js-utilities/dist/calculator/approval-calculator';
import { forEach } from "lodash";

interface ApprovalDetails {
    label: string;
    value: string | number | null;
    pipe?: string;
}

interface StipsData {
    name: string,
    type: string,
    minAmount?: number,
    pipe?: string
}

interface PaymentTableData {
    type: string,
    payback: number,
    cost: number,
    centsOnDollar: number,
    payments: number
}

interface ApprovalDataDisplay {
    calculationType: string,
    descriptionValues: ApprovalDetails[],
    approvalBoundaries: { [key: string]: any; },
    approvalsDataSource: ApprovalDetails[],
    position: number,
    netMinimum: number,
    expires: string,
    offerLink?: string | undefined,
    stips: StipsData[] | [],
    notes?: string,
    paymentComparison?: PaymentTableData[],
    paymentDisplayedColumns?: string[]
}

/**
 * Map of frequency name to value
 */
const frequencyMap = new Map([
    ['daily', 1],
    ['weekly', 7],
    ['semimonthly', 14],
    ['monthly', 30],
]);

@Component({
    selector: 'app-approvals',
    templateUrl: './approvals.component.html',
    styleUrls: ['./approvals.component.scss'],
    standalone: false
})
export class ApprovalsComponent implements OnInit {
    @Input() approvals: Approval[];

    calculatedApprovals: ApprovalDataDisplay[] = [];
    approvalsDataSource: ApprovalDetails[];
    options: ApprovalOption[] = [];
    locTermSbaOptions: LocTermSbaApprovalOption[] = [];

    customApproval: ApprovalCustomOption;

    // Error state
    errored: boolean = false;

    // to indicate when all of the approval data has been initialized and is ready for display
    initializing: boolean = true;

    /*
    Param boundaries representing the min and max values for each param
    across all given approval options. Used to show that there is availability
    outside the limits of the currently selected option.
    */
    approvalBoundaries: { [key: string]: any } = {
        amount: { min: 0, max: 0 },
        points: { min: 0, max: 0 },
        term: { min: 0, max: 0 },
    };

    variablePercentOfRevenue: any;

    term: number;
    terms: number[];

    FREQ_OPTIONS = [
        { label: 'Daily', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Semi-monthly', value: 'semiMonthly' },
        { label: 'Monthly', value: 'monthly' },
    ];
    FREQ_MAP: { [key: string]: any } = {
        'daily': 1,
        'weekly': 7,
        'semiMonthly': 14,
        'monthly': 30,
    };

    /*
    Param specific limits for the currently selected approval option
   */
    curOriginParams = {
        originationAmount: {
            min:0,absMin:0,max:0,absMax:0
        },
        originationPercent: {
            min:0,absMin:0,max:0,absMax:0
        },
        originationThreshold: {
            min:0,max:0
        },
        originationLimitsAreEqual: false
    };

    // Loan Amounts
    amount: number;
    availableAmount: number;

    // Points
    points: number;
    availablePoints: number;

    // Payment and frequencies
    frequencyOptions: object[];

    // Origination
    originationPercent: number;
    originationAmount: number;

    // Stores the sum of stipulation requests. Only needs to be calculated once
    stips: StipsData[] | [] = [];
    stipulationCount: number;

    // More data display
    factor: number;
    requests: any;
    payment: number;
    payments: number;
    disbursement: number;
    commission: number;

    //Working values
    workingValues: any = {};

    paybackDataSource: any[] = [];

    //////////////
    // TODO: For now we will create Vars to store calculated values since we will eventually use these in changing calculations
    // The following are a set of calculated values we will need to store going forward
    //////////////

    // early payoff state
    isEarlyPayoff: boolean = false;

    //////////////
    // CALCULATOR
    //////////////
    calculator: any;

    //////////////
    // TOTAL INTEREST
    //////////////
    totalInterest: number = 0;

    //////////////
    // TERM VALUES
    //////////////
    minTerm: number = 0;
    maxTerm: number = 0;
    // the list of available term values pulled from the provided options
    availableTerms: number[] = [];
    selectedTerm: number = 0;

    ///////////////////////////
    // PAYMENT FREQUENCY VALUES
    ///////////////////////////
    paymentFrequencyDisplay: string = '';
    selectedPaymentFrequency: string = '';

    //////////////
    // DRAW AMOUNT
    //////////////
    selectedDrawAmount: number = 0;
    minDrawAmount: number = 0;
    maxDrawAmount: number = 0;

    ///////////
    // DRAW FEE
    ///////////
    drawFeePercent: number = 0.0;
    drawFee: number = 0;

    ////////////////
    // PAYOFF MONTHS
    ////////////////
    minPayoff: number = 0;
    maxPayoff: number = 0;
    // the list of available payoff values for the slider
    availablePayoffMonths: number[] = [];
    selectedPayoffMonths: number = 0;

    // the option with the max/highest values (based on drawAmount and paymentFrequency)
    maxOption: LocTermSbaApprovalOption | undefined;
    minOption: LocTermSbaApprovalOption | undefined;

    //////////
    // PAYBACK
    //////////
    payback: number = 0;
    paybackChanged: number = 0;
    paybackMax: number = 0;

    ///////////
    // APR
    ///////////
    apr: number = 0;

    ///////////
    // CENTS ON THE DOLLAR
    ///////////
    staticCentsOnTheDollar: number = 0;
    centsOnTheDollar: number = 0;
    centsOnTheDollarChanged: number = 0;
    centsOnTheDollarMax: number = 0;

    ///////////
    // PAYMENT AMOUNT
    ///////////
    paymentAmount: number = 0;
    paymentAmountMax: number = 0;
    paymentAmountMin: number = 0;

    ///////////
    // NUMBER OF PAYMENTS
    ///////////
    numberOfPayments: number = 0;
    numberOfPaymentsChanged: number = 0;
    effectivePaymentCount: number = 0;

    ///////////
    // CALCULATE SCHEDULE
    ///////////
    calculateScheduleParams: CalculateScheduleParams | undefined;

    ///////////
    // COST OF CAPITAL
    ///////////
    costOfCapital: number = 0;
    costOfCapitalChanged: number = 0;
    costOfCapitalMax: number = 0;

    ///////////
    // ANNUAL RATE
    ///////////
    annualRate: number = 0;
    annualRateMax: number = 0;
    annualRateMin: number = 0;

    ///////////
    // PAYMENT RATE
    ///////////
    paymentRate: number = 0;

    ///////////
    // PERIODIC RATE
    ///////////
    periodicRate: number = 0;
    periodicLabel: string = '';

    // SBA Specific
    isPackagingFeeHourly: boolean = false;

    constructor(private _currencyPipe: CurrencyPipe) { }

    ngOnInit(): void {
        this.approvals.map((approval) => {
            let data = this.initializeApproval(approval);
            if(data) {
                this.calculatedApprovals.push(data);
            }
        });
        this.initializing = false;
    }

    initializeApproval(approval: Approval): ApprovalDataDisplay | undefined {
        this.requests = approval.requestNames || [];
        this.stips = [];
        forEach(this.requests, (v, k) => {
            let result = v.map((stip) => {
                return {
                    name: stip.hasOwnProperty('name') ? stip.name : stip,
                    type: k,
                    minAmount: stip.hasOwnProperty('minAmount') ? stip.minAmount : null,
                    pipe: stip.hasOwnProperty('minAmount') ? 'currency' : null
                }
            });
            this.stips = this.stips.concat(result);
        });

        this.stipulationCount = this.stips.length;
        if (approval.calculationType === 'custom') {
            return this.customInitializeApproval(approval);
        }

        if (approval.calculationType === 'loc' || approval.calculationType === 'term' || approval.calculationType === 'sba') {
            return this.locTermSbaIntializeApproval(approval);
        }

        this.options = approval.options as ApprovalOption[];

        this.paymentFrequencyDisplay = this.setPaymentFrequencyDisplay(this.options);

        [
            'term',
            'points',
            'amount',
        ].forEach((param: any) => {
            this.approvalBoundaries[param] = this.getParamLimits(this.options, param);
        });


        this.options.forEach((o) => {
            if (o.variablePercentOfRevenue !== null) {
                this.variablePercentOfRevenue = o.variablePercentOfRevenue;
            }
        });

        this.maxTerm = this.options.reduce((max, o) => o.term > max ? o.term : max, 0);
        this.minTerm = this.options.reduce((min, o) => o.term < min ? o.term : min, 9999999999999);

        const maxPeriod = this.options
            .filter(option => option.term === this.maxTerm)
            .reduce((max, o) =>
                this.FREQ_MAP[o.paymentFrequency] > this.FREQ_MAP[max] ? o.paymentFrequency : max, 'daily'
            );

        const maxOption = this.options.find(o => o.term === this.maxTerm && o.paymentFrequency === maxPeriod);


        if (!maxOption) {
            return;
        }

        const maxOptionTerm = maxOption.term;
        const maxOptionMaxAmount = maxOption.limits.amount.max;
        const maxOptionMaxPoints = maxOption.limits.points.max;
        const maxOriginationPercent = maxOption.limits.originationPercent.max;

        this.availableAmount = maxOptionMaxAmount;
        this.availablePoints = maxOptionMaxPoints;

        const maxBuyrateConditional = maxOption.customCalculatorParams?.pointConditionalBuyrateThreshold;
        const maxBuyrateConditional2 = maxOption.customCalculatorParams?.pointConditionalBuyrateThreshold2;
        const maxBuyRate = (maxOption !== undefined && maxOptionMaxPoints !== undefined) || 0;

        const calculationType = approval.calculationType;
        const roundingStrategy = approval.loanProductRoundingStrategy;

        this.factor = ApprovalCalculatorUtility.getFactorRate({
            pointsTradable: approval.pointsTradable,
            buyRate: maxBuyRate,
            points: maxOptionMaxPoints,
            maxOriginationPercent: maxOriginationPercent,
        });

        const maxCalculatedOptionAmount =
            ApprovalCalculatorUtility.getOptionAvailableAmount({ option: maxOption, calculationType, factor: this.factor, roundingStrategy });

        this.term = maxOptionTerm;
        this.payments = maxOption.payments;
        this.amount = maxCalculatedOptionAmount;
        this.points = maxOptionMaxPoints;

        this.payback = ApprovalCalculatorUtility.getPaybackAmount({
            factor: this.factor,
            amount: this.amount,
        });

        const maxOptionPayback = this.payback;
        const maxOptionFactor = this.factor;

        this.terms = this.options.reduce(
            (terms: any, opt) => !terms.includes(opt.term) ? terms.concat([opt.term]) : terms,
            []);
        this.frequencyOptions = this.FREQ_OPTIONS.filter(
            (freqOpt) => this.options.some(opt => opt.paymentFrequency === freqOpt.value)
        );

        const descriptionValues = [
            { label: 'Amount', value: maxCalculatedOptionAmount, pipe: 'currency' },
            { label: 'Points', value: maxOptionMaxPoints },
            { label: 'Factor', value: maxOptionFactor },
            { label: 'Payback', value: maxOptionPayback, pipe: 'currency' },
            { label: 'Term', value: maxOptionTerm },
            { label: 'Stips', value: this.requests.conditional.length + this.requests.nonConditional.length }
        ];



        this.formatAndSetOptionData(approval);

        return {
            calculationType: approval.calculationType,
            descriptionValues,
            approvalBoundaries: this.approvalBoundaries,
            approvalsDataSource: this.approvalsDataSource,
            position: approval.position,
            netMinimum: approval.netMinimum,
            expires: approval.expires,
            offerLink: approval.offerLink,
            stips: this.stips,
            notes: approval.notes
        }
    }

    customInitializeApproval(approval: Approval): ApprovalDataDisplay | undefined {
        if (!approval.customOptions || !approval.customOptions.length) {
            return;
        }
        this.customApproval = approval.customOptions[0];

        if (!this.customApproval) {
            return;
        }

        // Term, payment, and frequency
        this.term = this.customApproval.term - 0; // Ensure term is a number to prevent re-calculation on initialization
        this.payment = this.customApproval.payment;
        this.payments = this.customApproval.payments;
        this.selectedPaymentFrequency = this.customApproval.frequency;
        this.paymentFrequencyDisplay = this.FREQ_OPTIONS.find(fo => fo.value === this.customApproval.frequency)?.label || 'Error';

        // Points
        this.points = this.toFixedNumber(this.customApproval.points, 1, 10);
        this.availablePoints = this.customApproval.pointsMax;
        this.approvalBoundaries.points.min = this.toFixedNumber(this.customApproval.pointsMin, 1, 10);
        this.approvalBoundaries.points.max = this.toFixedNumber(this.customApproval.absPointsMax, 1, 10);

        // Loan Amounts
        this.amount = Math.floor(this.customApproval.amount);
        this.approvalBoundaries.amount.min = this.customApproval.amountMin;
        this.approvalBoundaries.amount.max = this.customApproval.absAmountMax;
        this.availableAmount = this.customApproval.amountMax;
        this.factor = this.customApproval.factorRate;
        this.payback = this.customApproval.payback;
        this.disbursement = this.customApproval.disbursement;

        // Origination
        this.originationPercent = this.toFixedNumber(this.customApproval.origination, 1, 10);
        this.curOriginParams.originationPercent.min = this.toFixedNumber(this.customApproval.originationMin, 1, 10);
        this.curOriginParams.originationPercent.max = this.toFixedNumber(this.customApproval.absOriginationMax, 1, 10);

        // Assuming terms and frequency options are mapped to arrays
        this.terms = this.customApproval.absTerms || [];
        this.maxTerm = Math.max(...this.terms);
        this.minTerm = Math.min(...this.terms);

        this.frequencyOptions = this.customApproval.absFrequencyOptions.map(freqOpt => {
            const opt = this.FREQ_OPTIONS.find(fo => fo.value === freqOpt);
            if (opt) {
                return opt;
            }
            return { label: freqOpt, value: freqOpt };
        });

        const maxOptionTerm = this.customApproval.term;
        const maxOptionMaxAmount = this.customApproval.amountMax;
        const maxOptionMaxPoints = this.toFixedNumber(this.customApproval.pointsMax, 1, 10);
        const maxOptionFactor = this.toFixedNumber(this.customApproval.factorRate, 3, 10);
        const maxOptionPayback = this.customApproval.payback;

        this.formatAndSetOptionData(approval);

        const descriptionValues = [
            { label: 'Amount', value: maxOptionMaxAmount, pipe: 'currency' },
            { label: 'Points', value: maxOptionMaxPoints },
            { label: 'Factor', value: maxOptionFactor },
            { label: 'Payback', value: maxOptionPayback, pipe: 'currency' },
            { label: 'Term', value: maxOptionTerm },
            { label: 'Stips', value: this.requests.conditional.length + this.requests.nonConditional.length }
        ];

        return {
            calculationType: approval.calculationType,
            descriptionValues,
            approvalBoundaries: this.approvalBoundaries,
            approvalsDataSource: this.approvalsDataSource,
            position: approval.position,
            netMinimum: approval.netMinimum,
            expires: approval.expires,
            offerLink: approval.offerLink,
            stips: this.stips,
            notes: approval.notes
        }
    }

    locTermSbaIntializeApproval(approval: Approval): ApprovalDataDisplay | undefined {
        this.calculate(approval, true, true);

        this.paymentAmountMax = this.paymentAmount;
        this.annualRateMax = this.annualRate;

        const descriptionValues = [
            { label: (approval.calculationType === 'loc') ? 'Limit' : 'Amount', value: this.maxDrawAmount, pipe: 'currency' },
            { label: 'Term', value: this.maxTerm },
            { label: '<span class="font-mono">~&nbsp;</span>&nbsp;Cents/$', value: this.staticCentsOnTheDollar },
            { label: 'Interest rate', value: this.annualRate, pipe: 'number' },
            { label: 'Stips', value: this.stipulationCount },
            { label: 'Points', value: this.points }
        ];

        const paymentComparison = [
            { type: 'Maximum term:', payback: this.paybackMax, cost: this.costOfCapitalMax, centsOnDollar: this.centsOnTheDollarMax, payments: this.numberOfPayments }
        ];

        // TODO: Without the sliders we want to set the min term payoff to calculate a comparison to the initial max output from above
        this.setMinOptionValues();
        this.changePayoffMonths(approval, this.minTerm);

        this.paymentAmountMin = this.paymentAmount;
        this.annualRateMin = this.annualRate;

        this.formatAndSetOptionData(approval);

        paymentComparison.push({ type: 'Minimum term:', payback: this.payback, cost: this.costOfCapital, centsOnDollar: this.centsOnTheDollar, payments: this.effectivePaymentCount });

        return {
            calculationType: approval.calculationType,
            descriptionValues,
            approvalBoundaries: this.approvalBoundaries,
            approvalsDataSource: this.approvalsDataSource,
            position: approval.position,
            netMinimum: approval.netMinimum,
            expires: approval.expires,
            offerLink: approval.offerLink,
            stips: this.stips,
            notes: approval.notes,
            paymentComparison,
            paymentDisplayedColumns: ['type', 'payback', 'cost', 'centsOnDollar', 'payments']
        }
    }

    /**
     * Set/Calculate some needed values after the offer and options have been provided
     */
    calculate(approval: Approval, initialize: boolean = false, resetMaxValues: boolean = false) {
        if (initialize) {
            this.locTermSbaOptions = approval.options as LocTermSbaApprovalOption[];

            this.paymentFrequencyDisplay = this.setPaymentFrequencyDisplay(this.locTermSbaOptions);

            if (approval.calculationType === 'term') {
                this.locTermSbaOptions = this.locTermSbaOptions.map(item => {
                    return {
                        ...item,
                        drawFeePercent: item.originationFeePercent,
                        limits: {
                        ...item.limits,
                        drawAmount: item.limits.amount

                        }
                    }
                });
            }

            if (approval.calculationType === 'sba') {
              this.locTermSbaOptions = this.locTermSbaOptions.map(item => {
                  if(item.isPackagingFeeHourly){
                    this.isPackagingFeeHourly = item.isPackagingFeeHourly;
                  }
                  return {
                      ...item,
                      drawFeePercent: item.packagingFeePercentage,
                      limits: {
                      ...item.limits,
                      drawAmount: item.limits.amount,
                      isPackagingFeeHourly: item.isPackagingFeeHourly

                      }
                  }
              });
          }

            this.determineBoundaries();
            this.determineMaxOption();
            this.determineMinOption();
            this.setInitialAndMaxOptionValues();

            this.workingValues = this.maxOption;
        } else {
            // find the option associated with the selected term and frequency
            this.workingValues = this.locTermSbaOptions.find(
                (o: LocTermSbaApprovalOption) => o.term === this.selectedTerm && o.paymentFrequency === this.selectedPaymentFrequency
            );


            // Some frequencies aren't available with certain terms
            const newPaymentFrequency = locTermSbaCalculatorGeneralFormulas.findNewPaymentFrequency(
                this.selectedTerm, this.locTermSbaOptions, this.selectedPaymentFrequency);
            this.changePaymentFreq(approval, newPaymentFrequency);

            // Are we evaluating early payoff?
            this.isEarlyPayoff = this.selectedPayoffMonths < this.selectedTerm;
        }

        // Start Calculating
        this.periodicRate = locTermSbaCalculatorGeneralFormulas.toFixedNumber((this.workingValues?.periodicRate || 0.0), 10);
        this.periodicLabel = locTermSbaCalculatorGeneralFormulas.getPeriodicLabel(this.workingValues?.periodsPerYear || 0);

        this.paymentAmount =
        locTermSbaCalculatorGeneralFormulas.getPaymentAmount({
            drawAmount: this.selectedDrawAmount,
            periodicRate: this.periodicRate,
            paymentCount: this.workingValues?.payments || 0,
        }) || 0.0;

        this.annualRate =
        locTermSbaCalculatorGeneralFormulas.getAnnualRate({
            periodicRate: this.periodicRate,
            periodsPerYear: this.workingValues?.periodsPerYear || 0,
        }) || 0;

        const dailyRate = locTermSbaCalculatorGeneralFormulas.getDailyRate({ annualRate: this.annualRate }) || 0;
        this.paymentRate =
        locTermSbaCalculatorGeneralFormulas.getPaymentRate({
            dailyRate: dailyRate,
            daysInPeriod: Number(this.workingValues?.daysInPeriod) || 0,
        }) || 0;

        this.numberOfPayments = this.workingValues?.payments || 0;
        this.effectivePaymentCount = locTermSbaCalculatorGeneralFormulas.calculateEffectivePaymentCount(
        this.selectedPayoffMonths, this.numberOfPayments, this.selectedTerm);
        this.numberOfPaymentsChanged = this.numberOfPayments - this.effectivePaymentCount;

        this.calculateScheduleParams = {
            paymentCount: this.effectivePaymentCount,
            paymentRate: this.paymentRate,
            paymentAmount: this.paymentAmount,
            startBalance: this.selectedDrawAmount,
        };

        // configure the calculator
        try {
            this.calculator = this.initializeCalculator(approval);
            this.totalInterest = locTermSbaCalculatorGeneralFormulas.findTotalInterest(
                this.calculator.paymentSchedule, this.selectedPayoffMonths, this.numberOfPayments, this.selectedTerm
            );
            const finalPaymentAmount = locTermSbaCalculatorGeneralFormulas.findFinalPaymentAmount(this.calculator.paymentSchedule);

            // calculator functions
            this.drawFeePercent = this.workingValues?.drawFeePercent || this.drawFeePercent;
            this.drawFee = this.calculator.drawFeeAmount(this.selectedDrawAmount, this.drawFeePercent);
            this.disbursement = this.calculator.disbursement(this.selectedDrawAmount, this.drawFee);

            this.points = this.workingValues?.limits.points.max || this.points;
            this.commission = locTermSbaCalculatorGeneralFormulas.toFixedNumber((this.selectedDrawAmount * this.points) / 100, 2);

            this.payback = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);

            this.costOfCapital = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);

            this.centsOnTheDollar = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);

            this.apr = this.calculator.apr(
                this.workingValues?.limits.drawAmount.max || 0,
                this.drawFee,
                this.paymentAmount,
                this.effectivePaymentCount,
                this.workingValues?.periodsPerYear || 0,
                finalPaymentAmount
            );
            this.costOfCapitalMax = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);

            this.paybackMax = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
            this.centsOnTheDollarMax = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);
            this.staticCentsOnTheDollar = this.calculator.centsOnTheDollar(
                this.paybackMax,
                this.workingValues?.limits.drawAmount.max || 0
            );

            //reset max values when changeDrawAmount is called because new calculations need to be based of the slider adjusted amount
            if (resetMaxValues) {
                this.resetMax();
            }

            this.paybackChanged = locTermSbaCalculatorGeneralFormulas.toFixedNumber(this.paybackMax - this.payback, 2);
            this.costOfCapitalChanged = locTermSbaCalculatorGeneralFormulas.toFixedNumber(
                Math.round(this.costOfCapitalMax - this.costOfCapital),
                2
            );

            this.centsOnTheDollarChanged = locTermSbaCalculatorGeneralFormulas.toFixedNumber(
                this.centsOnTheDollarMax - this.centsOnTheDollar,
                2
            );

            if (initialize) {
                this.apr = this.calculator.apr(
                    this.workingValues?.limits.drawAmount.max || 0,
                    this.drawFee,
                    this.paymentAmount,
                    this.effectivePaymentCount,
                    this.workingValues?.periodsPerYear || 0,
                    finalPaymentAmount
                );
                this.costOfCapitalMax = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);
                this.paybackMax = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
                this.centsOnTheDollarMax = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);
                this.staticCentsOnTheDollar = this.calculator.centsOnTheDollar(
                    this.paybackMax,
                    this.workingValues?.limits.drawAmount.max || 0
                );
            } else {
                this.apr = this.calculator.apr(
                    this.selectedDrawAmount,
                    this.drawFee,
                    this.paymentAmount,
                    this.effectivePaymentCount,
                    this.workingValues?.periodsPerYear || 0,
                    finalPaymentAmount
                );
            }

            //reset datasource each time to reflect changes from early payoff slider.
            this.paybackDataSource = [];
            this.paybackDataSource.push({'payoffType': 'Full Term:', 'payments': this.numberOfPayments, 'cents': this.centsOnTheDollarMax, 'payback': this.paybackMax, 'costOfCapital': this.costOfCapitalMax })
            this.paybackDataSource.push({'payoffType': 'Selected Payoff:', 'payments': this.effectivePaymentCount, 'cents': this.centsOnTheDollar, 'payback': this.payback, 'costOfCapital': this.costOfCapital })

        } catch (e) {
            console.error('Error initializing LOC Calculator: ', e);
            this.errored = true;
            this.initializing = false;
            return;
        }
    }

    //reset max values when changeDrawAmount is called because new calculations need to be based of the slider adjusted amount
    resetMax() {
        this.costOfCapitalMax = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);
        this.paybackMax = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
        this.centsOnTheDollarMax = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);
    }

    setPaymentFrequencyDisplay(options: LocTermSbaApprovalOption[] | ApprovalOption[]): string {
        const frequencies = [...new Set(
            options
                .map(({ paymentFrequency }) => paymentFrequency.charAt(0).toUpperCase() + paymentFrequency.slice(1))
                .sort((p) => this.FREQ_MAP[p.toLowerCase()])
                .reverse()
        )];

        return frequencies.join(' / ');
    }

    /**
     * Initialize the Approval Calculator
     */
    private initializeCalculator(approval: Approval) {
        const { drawFeeAmount, disbursement, totalCostOfCapital, paymentSchedule } = approval.calculatorConfig;

        // configure calculator
        return configureCalculator({
            drawFeeAmount,
            disbursement,
            totalCostOfCapital,
            paymentSchedule,
            calculateScheduleParams: this.calculateScheduleParams,
        });
    }

    private setInitialAndMaxOptionValues() {
        // initialize to max option
        this.selectedDrawAmount = this.maxOption?.limits.drawAmount.max || 0;

        // default to max option term
        this.selectedTerm = this.maxOption?.term || 0;

        // initialize to max option
        this.selectedPaymentFrequency = this.maxOption?.paymentFrequency || '';

        // initialize to max option
        this.drawFeePercent = this.maxOption?.drawFeePercent || 0.0;

        // term values
        this.availableTerms = Array.from(
            this.locTermSbaOptions.reduce((acc: Set<number>, o: LocTermSbaApprovalOption) => acc.add(o.term), new Set())
        );

        // initialize to max option
        //this.paymentFrequencyDisplay = locTermSbaCalculatorGeneralFormulas.prettyFrequency(this.maxOption?.paymentFrequency || '');

        // initialize payoff months to max value
        this.selectedPayoffMonths = this.maxPayoff;

        // set the available payoff months
        this.availablePayoffMonths = Array.from(Array(this.maxTerm).keys()).map((x) => x + 1);

        // initialize number of payments
        this.numberOfPayments = this.maxOption?.payments || 0;
        this.effectivePaymentCount = this.numberOfPayments;
        this.numberOfPaymentsChanged = this.numberOfPayments;
    }

    private setMinOptionValues() {
        // initialize to min option
        this.selectedDrawAmount = this.minOption?.limits.drawAmount.min || 0;

        // default to min option term
        this.selectedTerm = this.minOption?.term || 0;

        // initialize to min option
        this.selectedPaymentFrequency = this.minOption?.paymentFrequency || '';

        // initialize to min option
        this.drawFeePercent = this.minOption?.drawFeePercent || 0.0;

        // term values
        this.availableTerms = Array.from(
            this.locTermSbaOptions.reduce((acc: Set<number>, o: LocTermSbaApprovalOption) => acc.add(o.term), new Set())
        );

        // initialize to min option
        //this.paymentFrequencyDisplay = locTermSbaCalculatorGeneralFormulas.prettyFrequency(this.minOption?.paymentFrequency || '');

        // initialize payoff months to min value
        this.selectedPayoffMonths = this.minPayoff;

        // set the available payoff months
        this.availablePayoffMonths = Array.from(Array(this.minTerm).keys()).map((x) => x + 1);

        // initialize number of payments
        this.numberOfPayments = this.minOption?.payments || 0;
        this.effectivePaymentCount = this.numberOfPayments;
        this.numberOfPaymentsChanged = this.numberOfPayments;
    }

    /**
   * Finds the min/max boundaries for the all options
   */
    private determineBoundaries() {
        // term
        this.maxTerm = this.locTermSbaOptions.reduce((max: number, o: LocTermSbaApprovalOption) => (o.term > max ? o.term : max), 0);
        this.minTerm = this.locTermSbaOptions.reduce((min: number, o: LocTermSbaApprovalOption) => (o.term < min ? o.term : min), 1000);

        // Draw Amount
        this.maxDrawAmount = this.locTermSbaOptions.reduce(
            (max: number, o: LocTermSbaApprovalOption) => (o.limits.drawAmount.max > max ? o.limits.drawAmount.max : max),
            0
        );
        this.minDrawAmount = this.locTermSbaOptions.reduce(
            (min: number, o: LocTermSbaApprovalOption) => (o.limits.drawAmount.min < min ? o.limits.drawAmount.min : min),
            9999999999
        );

        // Payoff Months
        this.maxPayoff = this.maxTerm;
        this.minPayoff = 1;
    }

    /**
     * Find the "max" of the available options by find the option with the highest term, drawAmount, and paymentFrequency
     */
    private determineMaxOption() {
        const maxPaymentFrequency = this.locTermSbaOptions
            .filter((o: LocTermSbaApprovalOption) => o.term === this.maxTerm)
            .reduce((max: string, o: LocTermSbaApprovalOption) => {
                const optionFreq = frequencyMap.get(o.paymentFrequency.toLowerCase()) || 0;
                const maxFreq = frequencyMap.get(max) || 0;
                return optionFreq > maxFreq ? o.paymentFrequency : max;
            }, 'daily');

        this.maxOption = this.locTermSbaOptions.find(
            (o: LocTermSbaApprovalOption) =>
                o.limits.drawAmount.max === this.maxDrawAmount &&
                o.paymentFrequency === maxPaymentFrequency &&
                o.term === this.maxTerm
            );
    }

    private determineMinOption() {
        const minPaymentFrequency = this.locTermSbaOptions
            .filter((o: LocTermSbaApprovalOption) => o.term === this.minTerm)
            .reduce((min: string, o: LocTermSbaApprovalOption) => {
                const optionFreq = frequencyMap.get(o.paymentFrequency.toLowerCase()) || 0;
                const minFreq = frequencyMap.get(min) || 0;
                return minFreq > optionFreq ? o.paymentFrequency : min;
            }, 'monthly');

        this.minOption = this.locTermSbaOptions.find(
            (o: LocTermSbaApprovalOption) =>
                o.limits.drawAmount.min === this.minDrawAmount &&
                o.paymentFrequency === minPaymentFrequency &&
                o.term === this.minTerm
            );
    }

    /**
     * change handler for payoff months
     */
    changePayoffMonths(approval: Approval, newTerm: number) {
        if (this.selectedPayoffMonths === newTerm) {
            return;
        }

        // find the closest available payoff month value
        this.selectedPayoffMonths = this.availablePayoffMonths.reduce((prev, curr) => {
            return Math.abs(curr - newTerm) < Math.abs(prev - newTerm) ? curr : prev;
        });

        this.calculate(approval);

    }

    /**
   * change handler for payment frequency values
   */
    changePaymentFreq(approval: Approval, freq: string) {
        if (this.selectedPaymentFrequency === freq) {
            return;
        }

        this.paymentFrequencyDisplay = locTermSbaCalculatorGeneralFormulas.prettyFrequency(freq);
        this.selectedPaymentFrequency = freq;

        this.calculate(approval);

        this.changeDrawAmount(approval, this.workingValues.limits.drawAmount.max, false, true);
        //}
    }

    /**
   * change handler for draw amount
   */
    changeDrawAmount(approval: Approval, newDrawAmount: number, termChanged: boolean = false, frequencyChanged: boolean = false) {
        if (this.selectedDrawAmount === newDrawAmount) {
            return;
        }

        if (termChanged || frequencyChanged) {
            this.maxDrawAmount = newDrawAmount;
        }

        this.selectedDrawAmount = newDrawAmount;

        // reset early payoff
        this.changePayoffMonths(approval, this.selectedTerm);

        this.calculate(approval, false, true);
    }

    getParamLimits(options: any, param: any) {
        return options.reduce((res: any, option: any) => {
            const optionParam = option[param] || option.limits[param];
            const optionMinValue = typeof optionParam === 'object' ?
                optionParam.min :
                optionParam;
            const optionMaxValue = typeof optionParam === 'object' ?
                optionParam.max :
                optionParam;
            return {
                min: Math.min(optionMinValue, res.min),
                max: Math.max(optionMaxValue, res.max)
            };
        }, {
            min: 9999999999999,
            max: 0
        });
    }

    formatAndSetOptionData(approval: Approval) {
        let optionData = {};

        if (approval.calculationType === 'loc') {
            optionData = {
                'Limit/Amount': this.currency(this.maxDrawAmount),
                'Draw amount': this.rangeValue(this.currency(this.minDrawAmount), this.currency(this.maxDrawAmount)),
                'Interest rate': this.rangeValue(this.toFixedNumber(this.annualRateMin, 3, 10), this.toFixedNumber(this.annualRateMax, 3, 10), '%'),
                'Term': this.rangeValue(this.minTerm, this.maxTerm),
                'Payoff months': this.rangeValue(this.minPayoff, this.maxPayoff),
                'Payment amount': this.rangeValue(this.currency(this.paymentAmountMin), this.currency(this.paymentAmountMax)),
                'Disbursement': this.currency(this.disbursement),
                'Draw fee': this.currency(this.drawFee),
                'Commission': this.currency(this.commission),
                '<span class="font-mono">~&nbsp;</span>APR': this.toFixedNumber(this.annualRate, 3, 10) + '%',
                'Frequency': this.paymentFrequencyDisplay,
            };
        } else if(approval.calculationType === 'term') {
          optionData = {
            'Limit/Amount': this.currency(this.maxDrawAmount),
            'Amount': this.rangeValue(this.currency(this.minDrawAmount), this.currency(this.maxDrawAmount)),
            'Interest rate': this.rangeValue(this.toFixedNumber(this.annualRateMin, 3, 10), this.toFixedNumber(this.annualRateMax, 3, 10), '%'),
            'Term': this.rangeValue(this.minTerm, this.maxTerm),
            'Payoff months': this.rangeValue(this.minPayoff, this.maxPayoff),
            'Payment amount': this.rangeValue(this.currency(this.paymentAmountMin), this.currency(this.paymentAmountMax)),
            'Disbursement': this.currency(this.disbursement),
            'Origination fee': this.currency(this.drawFee),
            'Commission': this.currency(this.commission),
            '<span class="font-mono">~&nbsp;</span>APR': this.toFixedNumber(this.annualRate, 3, 10) + '%',
            'Frequency': this.paymentFrequencyDisplay,
          };
        } else if(approval.calculationType === 'sba') {
          optionData = {
            'Limit/Amount': this.currency(this.maxDrawAmount),
            'Amount': this.rangeValue(this.currency(this.minDrawAmount), this.currency(this.maxDrawAmount)),
            'Interest rate': this.rangeValue(this.toFixedNumber(this.annualRateMin, 3, 10), this.toFixedNumber(this.annualRateMax, 3, 10), '%'),
            'Term': this.rangeValue(this.minTerm, this.maxTerm),
            'Payoff months': this.rangeValue(this.minPayoff, this.maxPayoff),
            'Payment amount': this.rangeValue(this.currency(this.paymentAmountMin), this.currency(this.paymentAmountMax)),
            'Disbursement': this.currency(this.disbursement),
            'Packaging fee': this.currency(this.drawFee),
            'Commission': this.currency(this.commission),
            '<span class="font-mono">~&nbsp;</span>APR': this.toFixedNumber(this.annualRate, 3, 10) + '%',
            'Frequency': this.paymentFrequencyDisplay,
          };
        } else {
            optionData = {
                'Amount': this.currency(this.amount),
                'Term': this.term,
                'Points': this.points,
                'Factor Rate': this.toFixedNumber(this.factor, 3, 10),
                'Payback': this.currency(this.payback),
                '# Payments': Math.floor(this.payments) || null,
                'Payment amount': this.currency(this.paymentAmount),
                'Origination Fee': this.currency(this.originationAmount),
                'Disbursement': this.currency(this.disbursement),
                'Commission': this.currency(this.commission),
                'Origination': this.originationPercent,
                'Frequency': this.paymentFrequencyDisplay,
                '% of Sales': this.toFixedNumber(this.variablePercentOfRevenue, 3, 10) + '%',
            };

            if (!approval.hasVariablePayment) {
                delete (optionData as any)['% of Sales'];
            }
        }

        this.setApprovalDetailsData(optionData);
        return Object.keys(optionData);
    }

    toFixedNumber(num: number, digits: number, base: number) {
        const pow = Math.pow(base || 10, digits);
        return Math.round(num * pow) / pow;
    }

    currency(value: any) {
        const floorValue = Math.floor(value);
        return this._currencyPipe.transform(floorValue, undefined, 'symbol', '1.0-0');
    }

    rangeValue(min: any, max: any, appendChar: string = '') {
        min = `${min}${appendChar}`;
        max = `${max}${appendChar}`;

        if (min === max) {
            return `${max}`;
        }

        return `${min} - ${max}`;
    }

    setApprovalDetailsData(optionData: object) {
        this.approvalsDataSource = [];
        for (const [ key, value ] of Object.entries(optionData)) {
            this.approvalsDataSource.push({
                label: key,
                value: value
            });
        }
    }
}
