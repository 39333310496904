<mat-card appearance="outlined" class="inline !p-0 m-4 lendio-density-1 max-w-xl">
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>Gain early access</mat-card-title>
            <mat-card-subtitle>
                Try out new and upcoming features by opting in with our early access switches.
                You can easily toggle new features on and off during the trial period.
            </mat-card-subtitle>
        </mat-card-title-group>
    </mat-card-header>
    <div class="lendio-mat-card-divider"></div>
    <mat-card-content>
        @for (feature of features(); track feature.id; let last = $last) {
            <app-early-access-selection [feature]="feature"
                                        [last]="last"
                                        [autoEnrolled]="autoEnrolled">
            </app-early-access-selection>
        }
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-flat-button
                color="primary"
                class="w-[140px]"
                [disabled]="noChangesToSave || savingChanges"
                (click)="saveFeatureChanges()">
            @if (savingChanges) {
                <mat-spinner color="accent" [strokeWidth]="2" [diameter]="20"></mat-spinner>
            } @else {
                <span>Save changes</span>
            }
        </button>
    </mat-card-actions>
</mat-card>
