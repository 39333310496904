<div class="mt-2">

    <!-- Divider -->
    <div class="divider my-3"></div>

    <!-- Name and time ago -->
    <div class="grid grid-cols-2">
        <div class="title-text">{{ requestChain.creator_user.first }} {{ requestChain.creator_user.last }}</div>
        <div class="time-ago justify-self-end">{{ timeAgo(requestChain.created) }}</div>
    </div>

    <!-- Comment text -->
    <div class="comment" attr.data-cy="fd-request-{{requestIndex}}">
        {{ requestChain.description }}
    </div>

    <!-- Request type, Status bubble, comment button -->
    <div class="grid grid-cols-[1fr_8fr_1fr] gap-x-1.5 items-center">
        <div *ngIf="addRequestTypeLabel"
             class="request"> {{ requestType }}
        </div>
        <div class="grid justify-start">
            <div *ngIf="!requestChain.completed" class="status-bubble status-in-progress">In Progress</div>
            <div *ngIf="requestChain.completed" class="status-bubble status-complete">Complete</div>
        </div>
        <div class="grid justify-end">
            <a
                (click)="addNewComment = true"
                mat-button
                color="primary"
                class="!ml-3"
                *ngIf="!addNewComment && !requestChain.completed"
                data-cy="fd-request-comment-btn"
            >Comment</a>
        </div>
    </div>

    <!-- Collapsible add comment form -->
    <div *ngIf="addNewComment" class="row mb-4" [@slideUpDown]>
        <div class="background-white">
            <h3 class="title-text">Add comment</h3>
                <mat-form-field appearance="outline" class="add-new-comment lendio-density-2">
                    <textarea
                        matInput
                        appFocusOnInit
                        data-cy="fd-request-comment-input"
                        class="form-control"
                        placeholder="Add comment here"
                        [(ngModel)]="newComment"
                        [ngModelOptions]="{standalone: true}"
                    ></textarea>
                </mat-form-field>
            <div class="mt-1 justify-end flex">
                <button mat-button color="primary" (click)="cancelComment()" class="cancel-comment">Cancel</button>
                <button
                    mat-flat-button
                    color="primary"
                    data-cy="submitComment"
                    [disabled]="newComment === ''"
                    type="button"
                    (click)="createNewComment(newComment)"
                    class="!ml-3"
                    data-cy="fd-request-comment-submit-btn"
                >Send</button>
            </div>
        </div>
    </div>

    <!-- Comments (#) and show/hide button -->
    <div *ngIf="comments.length > 0" class="grid grid-cols-2">
        <div class="title-text items-center flex">Comments ({{ comments.length }})</div>
        <button type="button" class="justify-self-end items-center flex" (click)="moreComments = !moreComments">
            <span class="comment-expand">{{moreComments? "Hide": "Show"}} </span><mat-icon class="material-symbols-outlined">{{moreComments? "keyboard_arrow_up": "keyboard_arrow_down"}}</mat-icon>
        </button>
    </div>

    <!-- Collapsible comment thread -->
    <div *ngIf="moreComments" [@slideUpDown]>
        <div *ngFor="let comment of comments; let i = index" class="request-comment">
            <div class="grid grid-cols-2">
                <div class="title-text">
                    {{ comment.creator_user.first }} {{ comment.creator_user.last }}
                </div>
                <div class="time-ago justify-self-end">{{ timeAgo(comment.created) }}</div>
            </div>
            <div class="comment" attr.data-cy="fd-request-comment-{{i}}">
                {{ comment.description }}
            </div>
        </div>
    </div>
</div>
