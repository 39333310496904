import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { ImportForgiveness } from 'src/app/store/csv/csv.actions';


@Component({
  selector: 'app-import-button-smart',
  templateUrl: './import-button-smart.component.html',
  styleUrls: ['./import-button-smart.component.scss'],
  standalone: false
})
export class ImportButtonSmartComponent implements OnInit {
  @Input() route: string;
  @Input() category: string;
  @Input() scope: string;

  @Output() fileSelected = new EventEmitter<any>();

  public uploading: boolean;
  public currentUploadDocumentIds: [];

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {

  }

  uploadDocuments(documents: { files: FileList, category: string }) {
    this.uploading = true;
    this.currentUploadDocumentIds = [];
    Array.from(documents.files).forEach((doc: any) => {
      if (this.scope === 'forgiveness') {
        this.store.dispatch(new ImportForgiveness(doc));
      }
    });
  }

}
