import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';
import { MatCardModule } from '@angular/material/card';
import { DynamicScreenHeightModule } from '@app/app/directives/dynamic-screen-height/dynamic-screen-height.module';

@Component({
  selector: 'app-thoughtspot-service-unavailable',
  imports: [CommonModule, EmptyStateComponent, MatCardModule, DynamicScreenHeightModule],
  templateUrl: './thoughtspot-service-unavailable.component.html',
  styleUrls: ['./thoughtspot-service-unavailable.component.scss']
})
export class ThoughtspotServiceUnavailableComponent {
  emptyType = 'dashboard';
  emptyLabelContent = 'Dashboard & reports will be right back.';
  emptyMessageContent = "We're improving things behind the scenes. In the meantime, feel free to use our other features.";
}
