import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-restricted-input',
  templateUrl: './restricted-input.component.html',
  styleUrls: ['./restricted-input.component.scss'],
  standalone: false
})
export class RestrictedInputComponent {
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() value: number;
  @Input() fixedDecimal: number;
  @Input() type: string;
  @Input() label: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() currency: boolean;
  @Input() selectAllOnFocus = true;
  @Output() valueChange = new EventEmitter<number>();

  selectAll(ctx: any) {
    if (this.selectAllOnFocus) {
      ctx.target.select();
    }
  }

  updateValueFromInput(event: any) {
    if (this.type !== 'number') {
      this.valueChange.emit(event.target.value);
      return;
    }
    let value = Number(event.target.value);
    if (this.fixedDecimal) {
      value = parseFloat(value.toFixed(this.fixedDecimal));
    }

    if (value !== undefined && value >= this.min && value <= this.max) {
      this.value = value;
      this.valueChange.emit(value);
      return true;
    }
    if (value > this.max) {
      this.valueChange.emit(this.max);
      this.value = this.max;
      return false;
    }
    if (value < this.min) {
      this.valueChange.emit(this.min);
      this.value = this.min;
      return false;
    }
  }

}
