import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IToolPanelAngularComp } from "ag-grid-angular";
import { IToolPanelParams, RowClickedEvent } from "ag-grid-community";
import { DrawerStepStatusService } from "src/app/services/drawer-step-status.services";
import { LeadStatusStep } from "../drawer-step-status/lead-status-step";
import { MilestonesData } from "../drawer-step-status/milestones-data";

@Component({
  selector: 'app-client-milestones',
  templateUrl: './client-milestones.component.html',
  styleUrls: ['./client-milestones.component.scss'],
  standalone: false
})
export class ClientMilestonesComponent implements IToolPanelAngularComp {
  @Input() set milestones(newMilestones: MilestonesData) {
    if (newMilestones) {
      this.clientName = newMilestones.clientName;
      this.statusSteps = this.drawerStepStatusService.getStatusStepsForClient(newMilestones);
    } else {
      this.statusSteps = [];
      this.clientName = null;
    }
  }

  @Output() close: EventEmitter<void> = new EventEmitter;
  clientName: string | null;
  statusSteps: LeadStatusStep[] = [];
  private _viewState: 'borrower' | 'deal' | undefined;
  get viewState(): 'borrower' | 'deal' | undefined {
    return this._viewState;
  }

  set viewState(state: 'borrower' | 'deal' | undefined) {
    this._viewState = state;
  }

  public constructor(
    private drawerStepStatusService: DrawerStepStatusService,
  ) { }

  agInit(params: IToolPanelParams<any>): void {
    const renderStats = (rowClicked: any) => {
      this.setNameAndStepsFromRow(rowClicked);
    };
    params.api.addEventListener('rowClicked', renderStats);
  }

  refresh(): void { }

  private setNameAndStepsFromRow(row: RowClickedEvent) {

    if (row?.node?.group &&
      row?.node?.key &&
      row?.node?.field &&
      (row?.node?.field === 'borrowerId' || row?.node?.field === 'borrowerName')) {
      // borrower row clicked
      const firstMilestones: MilestonesData = new MilestonesData(row.node.allLeafChildren[0].data);
      this.viewState = 'borrower';
      this.clientName = row.node.key;
      this.statusSteps = this.drawerStepStatusService.getStatusStepsForClient(firstMilestones, true);
    } else {
      // deal row clicked
      const firstMilestones: MilestonesData = new MilestonesData(row.data);
      this.viewState = 'deal';
      this.clientName = row.data.borrowerId;
      this.statusSteps = this.drawerStepStatusService.getStatusStepsForClient(firstMilestones);
    }
  }

}
