import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Deal } from '@app/app/interfaces/deal.model';
import { LendioDealStageDisplayComponent } from '@app/app/components/lendio-angular-material-theme/lendio-deal-stage-display/lendio-deal-stage-display.component';
import { Store } from '@ngxs/store';
import { PortalUser } from '@app/app/interfaces/portal-user.model';
import { Observable } from 'rxjs';
import { PortalUsersState } from '@app/app/store/portal-users/portal-users-state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'lender-tasks-header',
  imports: [
    CommonModule,
    LendioDealStageDisplayComponent
  ],
  templateUrl: './lender-tasks-header.component.html'
})
export class LenderTasksHeaderComponent implements OnInit {
  @Input() deal?: Deal;
  @Input() borrowerName?: string;
  portalUsers$: Observable<PortalUser[] | null> = this.store.select(PortalUsersState.portalUsers);
  portalUsers: PortalUser[] | null;
  public assignments: string = '';

  constructor(private store: Store) {
    this.portalUsers$.pipe(takeUntilDestroyed()).subscribe(lenderUsers => {
      this.portalUsers = lenderUsers;
    })
  }

  ngOnInit(): void {
    const assignedUserIds = this.deal?.assignments ? JSON.parse(this.deal.assignments) : [];
      if (this.portalUsers?.length && assignedUserIds?.length) {
        this.assignments = this.portalUsers
          .filter((user) => {
            return assignedUserIds.includes(user.id);
          })
          .map(user => `${user.first} ${user.last}`)
          .join(',');
      }
  }
}
