import { Component, OnInit, Input } from '@angular/core';
import { ContractRequest } from 'src/app/interfaces/contract-request.model';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { forEach } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, take } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';
import { EMPTY, Observable, throwError } from 'rxjs';
import { PhonePipe } from 'src/app/pipes/phone/phone.pipe';
import { EsignatureState } from '@app/app/store/esignature/esignature.state';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { LendioResponse } from '@app/app/interfaces/lendio-response';

@Component({
  selector: 'app-contract-requests',
  templateUrl: './contract-requests.component.html',
  styleUrls: ['./contract-requests.component.scss'],
  standalone: false
})

export class ContractRequestsComponent implements OnInit {
    @Input() contractRequest: ContractRequest;
    @Input() dealId: number;
    @Input() loanProductName: string;
    @Select(EsignatureState.contractSent) contractSent$: Observable<boolean>;
    @Select(EsignatureState.hasTemplate) hasTemplate$: Observable<boolean>;
    @Select(SaasFeaturesState.saasPermitted('instantContracts')) hasInstantContractsFeature$: Observable<boolean>;
    localeCurrencySymbol: string | null;
    email: string;
    sentDate: string | null | undefined;
    loading = false;
    disabled = false;
    buttonMessage = 'Mark as Contract Sent';
    esignButtonMessage = 'Send Contracts'
    clickableStatus = [
        'awaitingResponse',
        'pendingFundingManager',
        'declined',
        'offerReceived',
        'offerToBorrower',
        'offerAccepted',
        'offerDeclined',
        'contractRequested',
        'expectationsCall'
    ];

    emptyType = 'offers-contracts';
    emptyLabelContent = 'There is no contract request.';

    constructor(
        private http: HttpClient,
        private store: Store,
        private localeSettingService: LocaleSettingService,
        private phonePipe: PhonePipe,
        private _snackbarService: LendioSnackbarService
    ) {
        this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
    }

    ngOnInit(): void {
        if (
            this.contractRequest.deal?.status !== undefined &&
            this.clickableStatus.indexOf(this.contractRequest.deal.status) === -1
        ) {
            this.buttonMessage = 'Contract Sent';
            this.disabled = true;
        }

        if (
            this.contractRequest!.modified &&
            this.contractRequest!.sent &&
            this.contractRequest.modified > this.contractRequest.sent
        ) {
            this.sentDate = this.contractRequest.modified;
        } else {
            this.sentDate = this.contractRequest.sent;
        }
    }

    formatEmails(signers: string | any[]) {
        this.email = '';
        let i = 0;
        forEach((signers: any, value: any) => {
            i ++;
            this.email += signers.length === i ? value.email : value.email + ', ';
        });

        return this.email;
    }

    formatPhone(phone: string) {
        return this.phonePipe.transform(phone, '$1-$2-$3');
    }

  get requestedState(): 'Updated' | 'Requested' {
      if (!this.contractRequest ||
          !this.contractRequest.modified ||
          !this.contractRequest.sent) {
        return 'Requested';
      }
      return this.contractRequest.modified > this.contractRequest.sent
        ? 'Updated'
        : 'Requested';
  }


    markContractSent(contractRequest: ContractRequest) {
        this.loading = true;
        const payload = {
            status: 'contractOut',
            stage: 'closing',
            reasons: [{
                reason: 'no reason',
                lenderReason: 'No lender-specific reason'
            }]
        };

        this.http.put(`${environment.apiUrl}/l/v2/internal/deals/${contractRequest.dealId}/status`, payload).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Something went wrong, please try that again. If this issue persists, please contact funding desk.'
            }));
            this.loading = false;
            return throwError(() => err);
        }), take(1)).subscribe((response: any) => {
            if (response.statusCode === 200) {
                this.buttonMessage = 'Contract Sent';
                this.disabled = true;
            }
            this.loading = false;
        });
    }

    sendLenderContract() {
        this.loading = true;
        this.disabled = true;
        this.loading = true;
        const payload = { "dealId": this.dealId }
        this.http.post(`${environment.apiUrl}/sign-now-contract`, payload).pipe(catchError(err => {
            if (err.status === 423) {
                // Contracts are already being generated on the backend
                this.contractsAreSending()
                return EMPTY;
            }
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Something went wrong, please try that again. If this issue persists, please contact funding desk.'
            }));
            this.disabled = false;
            this.loading = false;
            return throwError(() => err);
        }), take(1)).subscribe((response: LendioResponse) => {
            if (response.statusCode === 200) {
                this.contractsAreSending()
            }
        });
    }

    contractsAreSending() {
        this.esignButtonMessage = 'Contracts sent';
        this.disabled = true;
        this._snackbarService.open({
            message: "Sending contracts",
            canDismiss: true,
            duration: 3000,
        });
        this.loading = false;
    }
}
