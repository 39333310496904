import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: `<app-application-details [dealId]="dealId" />`,
  standalone: false
})
export class ApplicationDetailsSmartComponent implements OnInit {
  dealId: number;
  constructor(private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const dealIdParam = this._activatedRoute.snapshot.paramMap.get('id');
    if (dealIdParam) {
      this.dealId = parseInt(dealIdParam);
    }
  }
}
