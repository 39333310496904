import { State, Action, StateContext, Selector, Store, Select } from '@ngxs/store';
import { tap, catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import {
    GetEmailSettings,
    ClearSettingsStore,
    UpdateEmailSetting,
    DismissEmailSettingsUpdateStatus,
} from './settings.actions';
import { SaasFeaturesState } from '../saas-features/saas-features.state';
import { AddSnackbarError } from '@app/app/store/snackbar/snackbar.actions';

export class SettingsStateModel {
    emailSettings: any;
    emailSettingsUpdateStatus: 'pending' | 'success' | 'error' | null;
}

@State<SettingsStateModel>({
    name: 'settings',
    defaults: {
        emailSettings: null,
        emailSettingsUpdateStatus: null,
    }
})
@Injectable()

export class SettingsState {

    @Selector() static emailSettings(state: SettingsStateModel) {
        return state.emailSettings;
    }

    @Selector() static emailSettingsUpdateStatus(state: SettingsStateModel) {
        return state.emailSettingsUpdateStatus;
    }

    constructor(
        private settingsService: SettingsService,
        private store: Store
    ) {}

    @Action(GetEmailSettings)
    getEmailSettings({ patchState }: StateContext<SettingsStateModel>, {}: GetEmailSettings) {
        return this.settingsService.getEmailNotificationSettings()
                  .pipe(
                    catchError(err => {
                      this.store.dispatch(new AddSnackbarError({
                          identifier: 'getEmailSettingsError',
                          subTitle: 'Unable to retrieve email settings. Please refresh the page to try again.'
                        }));
                    return throwError(err);
                  }),
                  tap((res: any) => {
                      if (res.data) {
                        const emailNotificationPermitted = this.store.selectSnapshot(SaasFeaturesState.saasPermitted('settingsIntelligentLendingEmailNotifications', 'lpxSettingsEmailNotifications.view'));
                        const formattedSettings = this.formatEmailSettingsResponse(res.data, emailNotificationPermitted);
                        patchState({ emailSettings: formattedSettings });
                      }
                  })
                )
    }

    @Action(UpdateEmailSetting)
    updateEmailSetting({ patchState }: StateContext<SettingsStateModel>, { setting }: UpdateEmailSetting) {
        patchState({ emailSettingsUpdateStatus: 'pending' });
        return this.settingsService.updateEmailSetting(setting).pipe(catchError(err => {
                patchState({ emailSettingsUpdateStatus: 'error' });
                return throwError(err);
            }), tap(() => {
                this.store.dispatch(new GetEmailSettings());
                patchState({ emailSettingsUpdateStatus: 'success' });
            })
        );
    }

    @Action(DismissEmailSettingsUpdateStatus)
    dismissEmailSettingsUpdateStatus({ patchState }: StateContext<SettingsStateModel>, {}: DismissEmailSettingsUpdateStatus) {
        patchState({ emailSettingsUpdateStatus: null });
    }

    @Action(ClearSettingsStore)
    clearSettingsStore({ patchState }: StateContext<SettingsStateModel>, {}: ClearSettingsStore) {
        patchState({
            emailSettings: [],
        });
    }

    formatEmailSettingsResponse(data: any, permitted: boolean = false) {
        const dataToArray = Object.keys(data).map(key => data[key]);
        const parentEmailSettings = dataToArray.filter(s => !s.dependsOnId);
        const childEmailSettings = dataToArray.filter(s => s.dependsOnId);
        childEmailSettings.forEach(setting => {
            const parentEmailIndex = parentEmailSettings.findIndex(s => s.id === setting.dependsOnId);
            if (!parentEmailSettings[parentEmailIndex].children) {
                parentEmailSettings[parentEmailIndex].children = [];
            }
            parentEmailSettings[parentEmailIndex].children.push(setting);
        });
        return this.filterEmailSettings(parentEmailSettings, permitted);
    }

    filterEmailSettings(settings: any, permitted: boolean = false) {
      const disabledEmailSettings = [
        'fundingDeskRequestCompleted',
        'newComment',
        'renewalDeal',
        'all'
      ];
        if(permitted) {
            return settings = settings.filter(setting => !disabledEmailSettings.includes(setting.value));
        }
        return settings;
    }
}
