import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentsService } from 'src/app/services/documents.service';
import { take, catchError, tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { ApplicationDetailsState } from 'src/app/store/application-details/application-details.state';
import { Observable, throwError } from 'rxjs';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';

@Component({
  selector: 'app-document-upload-smart',
  templateUrl: './document-upload-smart.component.html',
  styleUrls: ['./document-upload-smart.component.scss'],
  standalone: false
})
export class DocumentUploadSmartComponent implements OnInit {

  // @Select(ApplicationDetailsState.applicationDetails) applicationDetails$: Observable<ApplicationDetails>;
  applicationDetails$: Observable<ApplicationDetails | null> = this.store.select(ApplicationDetailsState.applicationDetails);
  @Output() documentError = new EventEmitter<{ level: string, message: string }>();
  @Output() documentUploaded = new EventEmitter<number>();
  @Output() documentDeleted = new EventEmitter<number>();

  dealId: number;
  uploadedDocs: any[] = [];
  currentUploadDocumentIds: any[] = [];
  uploading = false;

  constructor(private documentsService: DocumentsService, private store: Store) { }

  ngOnInit(): void {
    this.applicationDetails$.pipe(take(1)).subscribe(data => {
      if (data) {
        this.dealId = data.deal.id;
      }
    });
  }

  uploadDocuments(documents: { files: FileList, category: string }) {
    this.uploading = true;
    this.currentUploadDocumentIds = [];
    Array.from(documents.files).forEach((doc: any) => {
      const formData = new FormData();
      formData.append('file', doc);
      formData.append('name', doc.name);
      formData.append('category', documents.category);
      this.documentsService.postDealDocuments(this.dealId, formData).pipe(catchError(err => {
          this.documentError.emit({
              level: 'error',
              message: 'Unable to upload document. Please try again.'
          });
          this.uploading = false;
          return throwError(err);
        }), take(1), tap(res => {
        doc.id = res.data.id;
        this.uploadedDocs.push(doc);
        this.currentUploadDocumentIds.push(res.data.id);
        this.documentUploaded.emit(res.data.id);
        if (documents.files && documents.files.length === this.currentUploadDocumentIds.length) {
          this.uploading = false;
        }
      })).subscribe();
    });
  }

  deleteDocument(documentId: number) {
    this.documentsService.delete(documentId).pipe(catchError(err => {
      this.documentError.emit({
          level: 'error',
          message: 'Unable to delete document. Please try again.'
      });
      return throwError(err);
    }), take(1), tap(() => {
      this.uploadedDocs = this.uploadedDocs.filter(d => d.id !== documentId);
      this.documentDeleted.emit(documentId);
    })).subscribe();
  }
}
