<div data-cy="file-upload-dialog">
    <div class="flex justify-between content-center">
        <h2 mat-dialog-title class="!pb-4" data-cy="decline-modal-header">{{ dialogTitle }}</h2>
        <button (click)="close($event)" class="pr-6">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="!p-0">
        <advanced-file-upload
            [allowedFileTypes]="data?.allowedFileTypes"
            [fileSizeLimit]="data?.fileSizeLimit"
            [fileCount]="data?.fileCount"
            [label]="data?.label"
            [fileSelectText]="data?.fileSelectText"
            [buttonText]="data?.buttonText"
            [isUploading]="isUploading"
            (fileListInput)="fileList = $event; dialogTitle = 'Categorize documents';"
            (formIsValid)="handleFormIsValid($event)"
        />
    </mat-dialog-content>
    @if (fileList) {
        <mat-divider class="!border-t-2"></mat-divider>
        <mat-dialog-actions align="end">
            <button 
                data-cy="upload-files-button" 
                [disabled]="!formIsValid || isUploading"
                mat-stroked-button color="primary" 
                class="!mt-4" 
                (click)="uploadDocuments()"
            >
                Save &amp; finish upload
            </button>
        </mat-dialog-actions>
    }
</div>
