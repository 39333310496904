// config.js
declare const lendio: {
  environment: string;
  apiUrl: string;
  bpApiUrl: string;
  embeddedApiUrl: string;
  identityServiceEnabled: boolean;
  identityUrl: string;
  intercomAppId: string;
  rollbarAccessToken: string;
  locale: string;
  enableEmbeddedApi: boolean;
  PUSHER_APP_KEY: string;
  TENANT_NAME: string;
  zenDeskWidgetKey: string;
  disableNgxsConsoleLogging?: boolean;
  disableThoughtspot?: boolean;
  disableFullStory?: boolean;
}

// commit.js
declare const GIT_COMMIT: string;

export const environment = {
  production: lendio.environment === 'production',
  GIT_COMMIT,
  ...lendio,
};
