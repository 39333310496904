import { registerLocaleData, DatePipe } from '@angular/common';
import localeData from '@angular/common/locales/en';

registerLocaleData(localeData);
const datePipe = new DatePipe('en-US');

export const DOCUMENT_CATEGORIES_REQUIRING_YEAR_STRING = [
    'balanceSheet',
    'businessTaxReturns',
    'personalTaxReturns',
    'customerInvoice',
    'financials',
    'pLCurrentYear',
    'pLPreviousYear'
];

export const LAST_24_MONTHS_SELECT_OPTIONS = (() => {
    return Array.from({ length: 24 }, (v, i) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - i);

        return {
            display: `${datePipe.transform(currentDate, 'MMM yyyy')}`,
            value: `${datePipe.transform(currentDate, 'MM/yyyy')}`,
            parts: { month: parseInt(`${datePipe.transform(currentDate, 'MM')}`), year: parseInt(`${datePipe.transform(currentDate, 'yyyy')}`)} 
        }
    });
})();