import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, take } from 'rxjs';
import { DealDocumentResponse, DocumentResponse, DocumentsResponse } from '../interfaces/documents-response.model';
import { DocumentCategoryResponse } from '../interfaces/document-category-response.model';
import { Document } from '../interfaces/document.model';
import { LendioResponse } from '../interfaces/lendio-response';

/**
 * Document routes can be document or deal-centric. Documents are naturally
 * owned by borrowers, but some routes depend on documents also being specific
 * to one deal.
 * - Borrower document routes are in oauth-routes (apiUrl)
 * - Deal document routes are in lender-routes (lenderApiUrl)
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private apiUrl = environment.apiUrl;
  // TODO: determine whether to remove lenderPortalUrl.
  // private lenderPortalUrl = environment.apiUrl + '/lender-portal';
  private partnerPortalUrl = environment.apiUrl + '/partner-portal';
  private lenderApiUrl = environment.apiUrl + '/l/v1/internal';
  // private getUrl = environment.apiUrl + '/l/v1/internal';
  // private postUrl = `${environment.apiUrl}/l/v2/internal/deals`;

  constructor(private httpClient: HttpClient) { }

  // Get a single document by ID
  getDocument(documentId: number) {
    return this.httpClient.get<DocumentResponse>(`${this.apiUrl}/document/${documentId}`);
  }

  /**
   * *** Document/borrower-centric routes ***
   * These support borrower-based documents in business detail views.
   */

  // Get documents by borrower.
  getIndex(borrowerId: number): Observable<DocumentsResponse> {
    const query = {
      criteria: {
        'documents.borrowerId': { '==': borrowerId }
      },
      sort: { 'documents.created': 'DESC' }
    };
    const queryString = encodeURIComponent(JSON.stringify(query));
    return this.httpClient.get<DocumentsResponse>(`${this.apiUrl}/document/borrower/${borrowerId}/?query=${queryString}`);
  }

  // Get the public-facing subset of doc categories for external lender users.
  getDocumentCategories(): Observable<DocumentCategoryResponse> {
    return this.httpClient.get<DocumentCategoryResponse>(`${this.partnerPortalUrl}/document-categories`);
  }

  // Create/upload a new document.
  post(file: File, formData: FormData): Observable<DocumentResponse> {
    // remove all backslahes in a file name
    const fileNameSanitized = file && file.name && file.name.replace(/\\/g, '');
    // create a new instance of a file to pass with the name sanitized ( stripped slahes) since file is immutable
    const newFile = new File([file], fileNameSanitized);
    formData.append('file', fileNameSanitized ? newFile : file);
    formData.append('uploadSource', 'lenderPortal');

    // We hit different endpoints for Borrower docs vs Deal docs
    const dealId = formData.get('dealId');
    const postUrl = dealId !== null
      ? `${this.partnerPortalUrl}/deals/${dealId}/documents`
      : `${this.apiUrl}/document`;

    return this.httpClient.post<DocumentResponse>(postUrl, formData);
  }

  // Update a document's metadata.
  put(document: Document): Observable<DocumentResponse> {
    return this.httpClient.put<DocumentResponse>(`${this.apiUrl}/document/${document.id}`, document);
  }

  patchDealDocument(dealId: number, documentUpdates: Partial<Document>) {
    return this.httpClient.patch<DocumentResponse>(
      `${this.partnerPortalUrl}/deals/${dealId}/documents/${documentUpdates.id}/metadata`,
      documentUpdates
    );
  }

  // Delete a document. Server ensures the user has permission to delete docs
  // for this borrower. This replaces a previous /lender-portal endpoint that
  // only allowed deleting docs you created.
  delete(documentId: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.apiUrl}/document/${documentId}`);
  }

  deleteDealDocument(dealId: number, documentId: number) {
    return this.httpClient.delete<LendioResponse>(`${this.partnerPortalUrl}/deals/${dealId}/documents/${documentId}`);
  }

  /**
   * *** Deal-centric routes ***
   * These support deal-based documents in application-details views.
   */

  getDocuments(dealId: number): Observable<DocumentsResponse> {
    // return this.httpClient.get<DocumentsResponse>(`${this.getUrl}/deals/${dealId}/documents`);
    return this.httpClient.get<DocumentsResponse>(`${this.lenderApiUrl}/deals/${dealId}/documents`);
  }

  getAdvancedDealDocuments(dealId: number): Observable<DocumentsResponse> {
    return this.httpClient.get<DocumentsResponse>(`${this.partnerPortalUrl}/deals/${dealId}/documents`);
  }

  acceptDealDocument(dealId: number, documentId: number): Observable<DealDocumentResponse> {
    return this.httpClient.patch<DealDocumentResponse>(`${this.partnerPortalUrl}/deals/${dealId}/documents/${documentId}/accept`, null);
  }

  rejectDealDocument(dealId: number, documentId: number, reason: string): Observable<DealDocumentResponse> {
    return this.httpClient.patch<DealDocumentResponse>(`${this.partnerPortalUrl}/deals/${dealId}/documents/${documentId}/reject`, { reason });
  }

  clearDealDocumentStatus(dealId: number, documentId: number): Observable<DealDocumentResponse> {
    return this.httpClient.patch<DealDocumentResponse>(`${this.partnerPortalUrl}/deals/${dealId}/documents/${documentId}/clear-status`, null);
  }

  postDealDocuments(dealId: number, formData: FormData): Observable<any> {
    // return this.httpClient.post<any>(`${this.postUrl}/${dealId}/documents`, formData);
    return this.httpClient.post<any>(`${this.lenderApiUrl}/deals/${dealId}/documents`, formData);
  }

  openInNewTab(url: string, name: string | number): void {
    this.httpClient.get(url, { responseType: 'blob' }).pipe(take(1)).subscribe((response: Blob) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');
    });
  }
}
