import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Approval } from 'src/app/interfaces/approval/approval.model';
import { Label } from '../../../interfaces/label.model';
import { PortalUser } from '../../../interfaces/portal-user.model';
import {
  ClearApplicationDetailsStore,
  GetApplicationDetails,
  GetApplicationFields, UpdateDealStatus,
} from '../../../store/application-details/application-details.actions';
import { ApplicationDetailsState } from '../../../store/application-details/application-details.state';
import {BehaviorSubject, combineLatest, map, Observable, Subject, throwError} from 'rxjs';
import { ApplicationDetails } from '../../../interfaces/application-details.model';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { GetLabels } from '../../../store/labels/labels.actions';
import { LabelsState } from '../../../store/labels/labels.state';
import { GetPortalUsers } from '../../../store/portal-users/portal-users.actions';
import { PortalUsersState } from '../../../store/portal-users/portal-users-state.service';
import { EventBusService } from '../../../services/event-bus.service';
import { LaserProService } from '../../../services/laser-pro.service';
import { GetAdverseActionNotice } from '../../../store/adverse-action-notice/adverse-action-notice.actions';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { ActivateDealPageTabEvent, OpenOverridesEvent } from '@app/app/services/event-types';
import { ClearDealNotesStore, GetDealNotes } from '@app/app/store/deal-notes/deal-notes.actions';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { ClearApprovalsStore, GetApprovals } from '@app/app/store/offers/approvals.actions';
import { GetDealOffers } from '@app/app/store/offers/offers.actions';
import { Offer } from '@app/app/interfaces/offer.model';
import { OffersState } from '@app/app/store/offers/offers.state';
import { ApprovalsState } from '@app/app/store/offers/approvals.state';
import { DealActivitiesState } from '@app/app/store/deal-activities/deal-activities.state';
import { GetDealActivities } from '@app/app/store/deal-activities/deal-activities.actions';
import { Document } from '@app/app/interfaces/document.model';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { BusinessActions } from '@app/app/store/businesses/business.actions';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { ContractRequestsState } from '@app/app/store/contract-requests/contract-requests.state';
import { ContractRequest } from '@app/app/interfaces/contract-request.model';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from '@app/app/store/auth/auth.state';
import {
  GetBorrowerDecisioningForProduct,
  GetDecisioningResultForBorrower,
  QueueBorrowerDecisioning,
} from '@app/app/store/decisioning/decisioning.actions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GetContractRequestOffers } from '@app/app/store/contract-requests/contract-requests.actions';
import {LendioSnackbarService} from "@app/app/services/lendio-snackbar.service";
import {PushToLaserPro, SubscribeToLaserPro, UnsubscribeFromLaserPro} from "@app/app/store/laser-pro/laser-pro.actions";
import {FundingDeskRequest} from "@app/app/interfaces/funding-desk-request.model";
import {FundingDeskRequestsState} from "@app/app/store/funding-desk/funding-desk-requests.state";
import { GetFundingDeskRequests } from '@app/app/store/funding-desk/funding-desk-requests.actions';
import { Deal } from '@app/app/interfaces/deal.model';
import { Business } from '@app/app/interfaces/business.model';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss'],
  standalone: false
})
export class ApplicationDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) dealId: number;
  @Input() tabToActivate: string;
  @Input() inLenderTasksContext = false;

  @Select(ContractRequestsState.contractRequests) contractRequests$: Observable<ContractRequest>;
  @Select(DealActivitiesState.activities) dealActivities$: Observable<DealActivity[]>;
  @Select(SaasFeaturesState.saasPermitted('decisioning')) hasDecisioningFeature$: Observable<boolean>;

  canPushToLaserPro = toSignal( this.store.select( AuthState.userHasPermission('canPushToLaserPro') ) );
  canSeeNewFunnel = this.store.selectSignal<boolean>(SaasFeaturesState.saasPermitted('funnel2.0'));
  // TODO: Remove canSeeMessagesTab$ once this goes live for everyone
  canSeeMessagesTab$: Observable<boolean> = this.store.select(AuthState.userHasPermission('lpxCanSeeMessagesTab'));
  canSeeAdvDocMgmt = this.store.selectSignal<boolean>(SaasFeaturesState.saasPermitted('advDocMgmt', 'lpxCanSeeAdvDocMgmt'));
  isMarketplaceOrEmbeddedDeal$: Observable<boolean>;
  applicationDetails$: Observable<ApplicationDetails | null> = this.store.select(ApplicationDetailsState.applicationDetails);
  applicationFields$: Observable<any> = this.store.select(ApplicationDetailsState.applicationFields);
  portalUsers$: Observable<PortalUser[] | null> = this.store.select(PortalUsersState.portalUsers);
  lenderLabels$: Observable<Label[] | null> = this.store.select(LabelsState.labels);
  businessTenantId$: Observable<number | null> = this.store.select(BusinessState.businessTenantId);
  business$ = this.store.select(BusinessState.business);
  businessLenderId$: Observable<number | undefined> = this.store.select(BusinessState.businessLenderId);
  documents$: Observable<Document[] | null> = this.store.select(DocumentsState.documents);
  offers$: Observable<Offer[] | null> = this.store.select(OffersState.offers);
  approvals$: Observable<Approval[] | null> = this.store.select(ApprovalsState.approvals);
  fundingDeskRequests$: Observable<FundingDeskRequest[]> = this.store.select(FundingDeskRequestsState.fundingDeskRequests);
  private destroyed$ = new Subject<void>();

  portalUser: AuthUser | undefined;
  borrowerId: number;
  loading = true;
  loanProductCategory: string;
  loanProductId: number;
  loanProductType: string;
  loanProductTypeName: string;
  hasOffers: boolean;
  isRenewal = false;
  selectedIndex = 0;
  page: string;
  link: string;
  renewals: number[] = [0];
  tenantId: number | null;
  assignmentArray = [];
  tabs : string[];
  shouldAutoPopOverrides = false;
  hasContractRequest = false;
  businessName: string;
  borrowerName: string;
  deal: Deal;

  constructor(
    private store: Store,
    private eventBusService: EventBusService,
    private router: Router,
    private _route: ActivatedRoute,
    private _titleService: NavPageTitleService,
    private _breadcrumbService: BreadcrumbService,
    private laserProService: LaserProService,
    private _snackbarService: LendioSnackbarService,
  ) {
    if (localStorage.getItem('app.renewals')) {
      this.renewals = JSON.parse(localStorage.getItem('app.renewals') || '');
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.renewals = this.router.getCurrentNavigation()?.extras.state?.renewals
        ? this.router.getCurrentNavigation()?.extras.state?.renewals
        : [];
      localStorage.setItem('app.renewals', JSON.stringify(this.renewals));
    }
    this.portalUser = this.store.selectSnapshot(AuthState.user);
  }

  get showContractRequestTab(): boolean {
    return (
      // TODO: Go back in and delete this CCBank-specific band-aid
      (
        // Always show for CCBank for now
        this.portalUser?.institution?.id === 47800 &&
        this.tenantId !== 19
      )
      ||
      (this.tenantId === 1 && (
        this.loanProductType === 'ach' ||
        this.loanProductType === 'loc' ||
        this.loanProductType === 'term'
      ))
    );
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.isMarketplaceOrEmbeddedDeal$ = this.business$.pipe(
      map((business: Business) => {
        return (business?.tenantId === 1 || (business?.isEmbedded ?? false));
      }),
      tap((isMarketplaceOrEmbeddedDeal) => {
        if (isMarketplaceOrEmbeddedDeal) {
          this.store.dispatch(new GetFundingDeskRequests(this.dealId));
        }
      })
    );

    this.eventBusService
      .subscribe(this.eventBusService.types.NewOfferEvent)
      .pipe(takeUntil(this.destroyed$))
      // TODO: This needs to be changed from a magic number to instead
      // use the activateTabFromName method. Not even sure what 2 is.
      // Is it Offers?
      .subscribe(() => (this.selectedIndex = 2));

    this.eventBusService
      .subscribe(this.eventBusService.types.ActivateDealPageTabEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ tabName }: ActivateDealPageTabEvent) =>
        this.activateTabFromName(tabName)
      );

    this.isRenewal = this.renewals.includes(Number(this.dealId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dealId) {
      this.loading = true;

      this.fetchData();

      // Wait for both application details and portal users to be ready
      combineLatest([this.applicationDetails$, this.portalUsers$, this.businessTenantId$, this.hasDecisioningFeature$, this.contractRequests$, this.dealActivities$])
        .pipe(
          takeUntil(this.destroyed$),
          // Filter out initial emissions
          filter(([applicationDetails, portalUsers, businessTenantId]) => {
            return applicationDetails !== undefined && portalUsers !== null && businessTenantId !== undefined;
          })
        )
        .subscribe(([applicationDetails, portalUsers, businessTenantId, hasDecisioningFeature, contractRequests, dealActivities]) => {
          if (applicationDetails && portalUsers && businessTenantId) {
            this.tenantId = businessTenantId;
            this.hasOffers = applicationDetails.deal.offers_desc.length > 0;
            this.loanProductCategory =
              applicationDetails.deal.loan_product.category.shortName;
            this.loanProductType = applicationDetails.deal.loan_product.type;
            this.loanProductTypeName = applicationDetails.deal.loan_product.loanProductTypeName;
            this.loanProductId = applicationDetails.deal.loan_product.id;
            this.assignmentArray = JSON.parse(
              applicationDetails.deal.assignments
            );
            this.deal = applicationDetails.deal;
            this.borrowerId = applicationDetails?.deal?.borrowerId;
            this._setPageTitle(applicationDetails.deal.borrower.name, applicationDetails.deal.loan_product.name);
            this.loading = false;
            this.hasContractRequest = Object.keys(contractRequests).length > 0;
            this.businessName = applicationDetails.deal.borrower.name + ': ' + applicationDetails.deal.loan_product.name || 'Unknown';
            this.borrowerName = applicationDetails.deal.borrower.name;

            if (!this.page) {
              this.page = this.titleCase(applicationDetails.page);
              this.link = applicationDetails.page;
              /* This breadcrumb is mislabeled if the user is coming from the Businesses page
                  it should say Businesses > Business-Name, but it always says Funnel > Funded
                  the long term fix for this is to update the breadcrumbs to use the router instead
                  of manually setting them here.
               */
              if (this.canSeeNewFunnel()) {
                this._breadcrumbService.setBreadcrumbs([
                  {label: 'Funnel', path: '/funnel'},
                ]);
              } else {
                this._breadcrumbService.setBreadcrumbs([
                  {label: 'Funnel', path: '/applications'},
                  {label: this.page, path: `/applications/${this.link}`},
                ]);
              }
            }
          }

          if (hasDecisioningFeature) {
            this.store.dispatch(new GetDecisioningResultForBorrower(this.borrowerId, this.loanProductId));
            this.store.dispatch(new GetBorrowerDecisioningForProduct(this.borrowerId, this.loanProductId));
          }

          // subscribe to laser pro updates
          if (this.canPushToLaserPro()) {
            this.store.dispatch(new SubscribeToLaserPro(this.dealId));
          }

          // This list powers the "activateTabFromName", but not all tabs are always present,
          // so we need to check permissions and other conditions to figure out what tabs will
          // be displayed. Order must match the tabs in the tab group
          this.tabs = ['details', 'documents', 'offers', 'approvals'];
          if (hasDecisioningFeature) {
            this.tabs.push('decisioning');
          }
          if (this.showContractRequestTab) {
            this.tabs.push('contractRequests');
          }
          // Keep history at the tail
          this.tabs.push('history')

          if (this.tabToActivate) {
            this.activateTabFromName(this.tabToActivate);
          }
        });
    }
  }

  activateTabFromName(tabName: string): void {
    const index = this.tabs.indexOf(tabName.toLowerCase());
    if (index !== -1) {
      this.selectedIndex = index;
    }
  }

  fetchData() {
    if (this.portalUser == null) {
      return;
    }
    this.store.dispatch(new GetContractRequestOffers(this.dealId));
    this.store.dispatch(new GetDealActivities(this.dealId));
    this.store.dispatch(new ClearApplicationDetailsStore());
    this.store.dispatch(new BusinessActions.ClearBusinessState());
    this.store.dispatch(new GetPortalUsers());
    this.store.dispatch(new GetApplicationDetails(this.dealId)).pipe(
      catchError((error) => {
        this.loading = false;
        return throwError(() => error);
      })
    ).subscribe({
      // Ensure we have the borrower's lender ID before we get documents.
      next: () => {
        const business = this.store.selectSnapshot(BusinessState.business);
        const appDetails = this.store.selectSnapshot(ApplicationDetailsState.applicationDetails);

        // Clear docs and reload based on switching deals, borrowers, etc.
        this.store.dispatch(new DA.ClearDocumentsState());
        this.store.dispatch(new DA.GetDocumentRequirements(this.dealId));
        if (!business?.id || business.id !== appDetails?.deal?.borrowerId) {
          if (appDetails?.deal?.borrowerId) {
            this.store.dispatch(new BusinessActions.GetBusiness(appDetails.deal.borrowerId)).subscribe({
              // Get document once we're sure we have a business' lenderId.
              next: () => {
                // TODO: remove this while killing EntityState pattern
                const lenderId = business?.lenderId;
                if (this.canSeeAdvDocMgmt()) {
                  this.store.dispatch(new DA.GetAdvancedDealDocuments(this.dealId, lenderId!));
                } else {
                  this.store.dispatch(new DA.GetDealDocuments(this.dealId, lenderId!));
                }
              }
            });
          }
        } else {
          if (this.canSeeAdvDocMgmt()) {
            this.store.dispatch(new DA.GetAdvancedDealDocuments(this.dealId, business.lenderId as number));
          } else {
            this.store.dispatch(new DA.GetDealDocuments(this.dealId, business.lenderId as number))
          }
        }
      },
    });

    this.store.dispatch(new GetDealOffers(this.dealId))
    this.store.dispatch(new GetApplicationFields(this.dealId));
    this.store.dispatch(new GetLabels());
    this.store.dispatch(new GetAdverseActionNotice(this.dealId));
    this.store.dispatch(new GetDealNotes(this.dealId));
    this.store.dispatch(new GetApprovals(this.dealId));
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.store.dispatch(new ClearApplicationDetailsStore());
    this.store.dispatch(new ClearApprovalsStore());
    this.store.dispatch(new ClearDealNotesStore());
    if (this.canPushToLaserPro()) {
      this.store.dispatch(new UnsubscribeFromLaserPro(this.dealId));
    }
    this.destroyed$.next();
    this._breadcrumbService.setBreadcrumbs([]);
  }

  goBack() {
    if (this.isRenewal) {
      this.router.navigate([`/renewals/${this.link}`]);
    } else {
      this.router.navigate([`/applications/${this.link}`]);
    }
  }

  titleCase(s: string) {
    return s
      .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
      .replace(/[_-]+/g, ' ') // split snake_case and lisp-case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, ' ') // collapse repeated whitespace
      .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
  }

  private _setPageTitle(borrowerName: string, loanProductName: string): void {
    return this._titleService.setTitle(borrowerName, loanProductName);
  }

  // If user navigates away from Decisioning tab, make sure
  // we turn off the autopop flag so we don't automatically pop it
  // when we don't mean to.
  handleSelectedTabChange(tabChange: MatTabChangeEvent) {
    if (this.tabs[tabChange.index] !== 'decisioning') {
      this.shouldAutoPopOverrides = false;
    }
  }

  handleOverrideButtonClicked() {
    if (this.tabs[this.selectedIndex] === 'decisioning') {
      // Fire event so Decisioning tab knows to open Overrides flow
      this.eventBusService.publish(new OpenOverridesEvent());
    } else {
      // Activate Decisioning tab and trigger autopop via input
      // since it's not yet subscribed to events.
      this.shouldAutoPopOverrides = true;
      this.activateTabFromName('decisioning');
    }
  }

  handlePushToLaserProButtonClicked() {
    this.store.dispatch(new PushToLaserPro(this.dealId, this.businessName));
  }

  handleOpenDealHistoryClicked() {
    this.activateTabFromName('history');
  }

  /**
   * Move the users tab to decisioning if/when retry decisioning is selected
   */
  handleRetryDecisioningClicked() {
    if (this.tabs[this.selectedIndex] === 'decisioning') {
    } else {
      this.activateTabFromName('decisioning');
    }

    this.store.dispatch(new QueueBorrowerDecisioning(this.borrowerId, this.loanProductId));
  }

}
