import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-import-button',
  templateUrl: './import-button.component.html',
  styleUrls: ['./import-button.component.scss'],
  standalone: false
})
export class ImportButtonComponent implements OnInit, OnChanges {
  @Input() isUploading: boolean;
  @Output() uploadDocs = new EventEmitter<{ files: FileList, category: string }>();
  @Input() category: any;

  @ViewChild('fileInput') public fileInput: ElementRef;

  constructor() {}

  ngOnInit(): void {
  }

  upload(documents: FileList) {
    this.isUploading = true;
    this.uploadDocs.emit({ files: documents, category: this.category });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('isUploading') && !changes.isUploading.currentValue && this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
