import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: false
})
export class PhonePipe implements PipeTransform {
    transform(num: string, formatting?: string): string {
        if (num) {
            return num.toString().replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, formatting || '($1) $2-$3');
        } else {
            return num;
        }
    }
}
