import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {QualifierUnsaved} from 'src/app/interfaces/qualifier-unsaved.model';
import {Qualifier} from 'src/app/interfaces/qualifier.model';
import {Question} from 'src/app/interfaces/question.model';
import {State} from 'src/app/interfaces/state.model';
import {QualifierChanged} from 'src/app/components/loan-product/qualifier/qualifier.component';
import {QualifierExceptionGroup} from 'src/app/interfaces/qualifier-exception-group.model';
import {DeleteGroupDialogComponent} from 'src/app/components/loan-product/delete-group-dialog/delete-group-dialog.component';
import {BaseQualificationReqWarningComponent} from 'src/app/components/loan-product/base-qualification-req-warning/base-qualification-req-warning.component';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-qualifier-exception-groups',
  templateUrl: './qualifier-exception-groups.component.html',
  styleUrls: ['./qualifier-exception-groups.component.scss', './../loan-product.component.scss'],
  standalone: false
})
export class QualifierExceptionGroupsComponent implements OnInit {

  @Input() qualifiers: (Qualifier | QualifierUnsaved)[];
  @Input() exceptionGroup: QualifierExceptionGroup;
  @Input() exceptionGroupIndex: number;
  @Input() qualifierExceptionGroups: QualifierExceptionGroup[];
  @Input() questions: Question[];
  @Input() states: State[];
  @Input() loanProductId: number;
  @Input() dirtyQualifierId: number;
  @Input() modifiedQualifier: QualifierUnsaved;
  @Input() baseQualifiers: (Qualifier | QualifierUnsaved)[];
  @Input() showBaseQualificationRequirementWarning: boolean;
  @Input() hasWriteAccess: boolean;

  @Output() addQualifier = new EventEmitter<QualifierUnsaved>();
  @Output() dirtyQualifier = new EventEmitter<QualifierChanged>();
  @Output() updateQualifier = new EventEmitter<QualifierUnsaved>();
  @Output() removeQualifier = new EventEmitter<Qualifier>();
  @Output() cancelUpdatesToQualifier = new EventEmitter<Qualifier>();
  @Output() showBaseQualificationRequirementWarningChange = new EventEmitter<boolean>();
  @Output() renameGroup = new EventEmitter<QualifierExceptionGroup>();
  @Output() removeExceptionGroup = new EventEmitter<QualifierExceptionGroup>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('groupName') groupName: MatSelect;

  addingIfQualifier = false;
  addingThenQualifier = false;
  showIfStatementRequirementWarning = false;
  showRenamingInput = false;
  disabledAttributeFieldsInExceptions: string[];
  disabledOperatorFilterTypesInExceptions: Map<string, string>;

  constructor(private cdRef: ChangeDetectorRef, public dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.exceptionGroup && this.exceptionGroup.id !== null &&
      this.qualifierExceptionGroups && this.qualifierExceptionGroups.length > 0) {
      this.exceptionGroup.index = this.exceptionGroupIndex;
      this.setDisabledValuesForExceptions();
    }
  }

  /**
   * @description modal to provide additional warning before deleting a group
   */
  openDeleteGroupDialog() {
    const dialogRef = this.dialog.open(DeleteGroupDialogComponent, {
      width: '370px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteGroup();
      }
    });
  }

  /**
   * @description modal to provide additional warning that a base qualifier is required before a
   * conditional (IF) qualifier can be added
   */
  openBaseQualifierReqWarning() {
    const dialogRef = this.dialog.open(BaseQualificationReqWarningComponent, {
      width: '370px',
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  /**
   * @description method responsible for adding a conditional (IF) qualifier and emitting change to loan-product parent component
   * @param qualifierToAdd: a qualifier that hasn't been put in the qualifiers table yet (no ID yet)
   * @param isCondition: whether we are adding a condition or not
   */
  addQualifierToGroup(qualifierToAdd: QualifierUnsaved | any, isCondition: boolean): void {
    if (this.baseQualifiers && this.baseQualifiers.length === 0) {
      this.openBaseQualifierReqWarning();
      return;
    }
    this.addingIfQualifier = true;
    this.addingThenQualifier = true;

    qualifierToAdd.isExceptionCondition = isCondition;
    qualifierToAdd.qualifierExceptionGroupId = this.exceptionGroup?.id;

    if (!isCondition && this.exceptionGroup?.conditions && this.exceptionGroup.conditions.length === 0) {
      this.showIfStatementRequirementWarning = true;
    } else {
      if (isCondition){
        this.exceptionGroup?.conditions?.push(qualifierToAdd);
      } else {
        this.exceptionGroup?.exceptions?.push(qualifierToAdd);
      }

      this.setDisabledValuesForExceptions();
      this.addQualifier.emit(qualifierToAdd);
      this.showIfStatementRequirementWarning = false;
      this.addingIfQualifier = false;
      this.addingThenQualifier = false;
    }
  }

  /**
   * @description method to emit a dirty qualifier
   * @param changedQualifierPayload: [QualifierChanged] -
   * isDirty: [boolean] flag, true if qualifier is dirty
   * data: [QualifierUnsaved] new qualifier data
   * oldData: [Qualifier] old qualifier data
   * @param isCondition: [boolean] true if qualifier is a condition (IF) qualifier,
   * false if an exception (THEN) qualifier
   */
  dirty(changedQualifierPayload: QualifierChanged | any, isCondition: boolean): void {
    if (isCondition) {
      changedQualifierPayload.data.qualifierExceptionGroupId = this.exceptionGroup?.id;
      changedQualifierPayload.data.isExceptionCondition = true;
    }

    else {
      changedQualifierPayload.data.qualifierExceptionGroupId = this.exceptionGroup?.id;
      changedQualifierPayload.data.isExceptionCondition = false;
    }

    this.dirtyQualifier.emit(changedQualifierPayload);
  }

  /**
   * @description method to update a dirty qualifier
   * @param changedQualifier: [QualifierChanged] -
   * isDirty: [boolean] flag, true if qualifier is dirty
   * data: [QualifierUnsaved] new qualifier data
   * oldData: [Qualifier] old qualifier data
   * false if an exception (THEN) qualifier
   */
  update(changedQualifier: QualifierChanged): void {
    if (changedQualifier.oldData?.isExceptionCondition) {
      this.modifiedQualifier.qualifierExceptionGroupId = this.exceptionGroup.id;
      this.modifiedQualifier.isExceptionCondition = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const ifConditions = [ ...this.exceptionGroup.conditions ];
      const ifExceptionsIndex = ifConditions.findIndex((x) => x.id === this.modifiedQualifier.id);

      if (ifExceptionsIndex === -1) {
        return;
      }

      ifConditions.splice(ifExceptionsIndex, 1, this.modifiedQualifier);
      this.exceptionGroup.conditions = ifConditions;
    } else {
      this.modifiedQualifier.qualifierExceptionGroupId = this.exceptionGroup.id;
      this.modifiedQualifier.isExceptionCondition = false;

      const thenExceptions = [ ...this.exceptionGroup.exceptions ];
      const thenExceptionsIndex = thenExceptions.findIndex((x) => x.id === this.modifiedQualifier.id);

      if (thenExceptionsIndex === -1) {
        return;
      }

      thenExceptions.splice(thenExceptionsIndex, 1, this.modifiedQualifier);
      this.exceptionGroup.exceptions = thenExceptions;
    }

    this.setDisabledValuesForExceptions();
    this.updateQualifier.emit(this.modifiedQualifier);
  }

  /**
   * @description method to rename an exception group
   * @param nameInput: [string] renaming group input
   */
  rename(nameInput: string) {
    if (nameInput === '') {
      this.showRenamingInput = false;
      return;
    } else {
      this.exceptionGroup.name = nameInput;
    }
    this.renameGroup.emit(this.exceptionGroup);
    this.showRenamingInput = false;
  }

  /**
   * @description method to remove a dirty qualifier
   * @param qualifierToRemove: qualifier to remove
   */
  remove(qualifierToRemove: Qualifier): void {
    if (qualifierToRemove.isExceptionCondition) {
      this.exceptionGroup.conditions = this.exceptionGroup.conditions?.filter(condition => condition.id !== qualifierToRemove.id);
    } else {
      this.exceptionGroup.exceptions = this.exceptionGroup.exceptions?.filter(exception => exception.id !== qualifierToRemove.id);
    }

    this.removeQualifier.emit(qualifierToRemove);
  }

  /**
   * @description method to show update and cancel buttons by current qualifier that needs to be updated in a group
   */
  showUpdateButtonByCurrentQualifier(qualifierId: number): boolean {
    return this.dirtyQualifierId === qualifierId;
  }

  /**
   * @description method to cancel any updates made to a conditional or exception qualifier in a group
   * @param currentQualifier: [Qualifier] qualifier that needs modifications canceled, reverted
   * @param type: [string] type of qualifier, 'if' or 'then'
   */
  cancelQualifierUpdates(currentQualifier: Qualifier, type: string): void {
    this.showBaseQualificationRequirementWarning = false;
    this.showBaseQualificationRequirementWarningChange.emit(this.showBaseQualificationRequirementWarning);

    if (type === 'if') {
      const ifConditions = [ ...this.exceptionGroup.conditions ];
      const ifExceptionsIndex = ifConditions.findIndex((x) => x.id === currentQualifier.id);
      const ifQualifier = ifConditions[ifExceptionsIndex];
      this.exceptionGroup.conditions?.splice(ifExceptionsIndex, 1);
      this.cdRef.detectChanges();
      this.exceptionGroup.conditions?.splice(ifExceptionsIndex, 0, ifQualifier);
      this.cdRef.detectChanges();
    }

    else if (type === 'then') {
      const thenExceptions = [ ...this.exceptionGroup.exceptions ];
      const thenExceptionsIndex = thenExceptions.findIndex((x) => x.id === currentQualifier.id);
      const thenQualifier = thenExceptions[thenExceptionsIndex];
      this.exceptionGroup.exceptions?.splice(thenExceptionsIndex, 1);
      this.cdRef.detectChanges();
      this.exceptionGroup.exceptions?.splice(thenExceptionsIndex, 0, thenQualifier);
      this.cdRef.detectChanges();
    }

    this.cancelUpdatesToQualifier.emit(currentQualifier);
  }

  /**
   * @description method to hide new, unsaved line of conditional (IF) qualifier
   */
  cancelSavingIfQualifier() {
    if (this.addingIfQualifier) {
      this.addingIfQualifier = false;
      return;
    }

    else {
     this.cancel.emit();
    }
  }

  /**
   * @description method to hide new, unsaved line of exception (THEN) qualifier
   */
  cancelSavingThenQualifier() {
    if (this.addingThenQualifier) {
      this.addingThenQualifier = false;
      return;
    }

    else {
      this.cancel.emit();
    }
  }

  /**
   * @description method to remove an exception group
   */
  deleteGroup(): void {
    this.removeExceptionGroup.emit(this.exceptionGroup);
  }

  /**
   * @description method responsible for disabling attribute and operator values in exceptions
   */
  setDisabledValuesForExceptions(): void {
    this.disabledAttributeFieldsInExceptions = [];
    this.disabledOperatorFilterTypesInExceptions = new Map<string, string>();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setDisabledValuesBasedOnQualifierType(this.exceptionGroup.conditions);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setDisabledValuesBasedOnQualifierType(this.exceptionGroup.exceptions);
  }

  /**
   * @description method to set disabled values based on whether the array of qualifiers to
   *              iterate through is the exception group's conditions or exceptions
   *              Both need to be iterated through because the disabled attribute and operators
   *              are dependent on both the conditions and exceptions within a group.
   * @param exceptionQualifiersBasedOnType : Qualifier|QualifierUnsaved)[] an array of qualifiers
   *        either the conditions (IF) or exceptions (THEN) within an exceptionGroup
   */
  setDisabledValuesBasedOnQualifierType(exceptionQualifiersBasedOnType: (Qualifier|QualifierUnsaved)[]): void {
    exceptionQualifiersBasedOnType.forEach(qualifier => {
      if (qualifier.filterType !== 'min' && qualifier.filterType !== 'max') {
        this.disabledAttributeFieldsInExceptions.push(qualifier.field);
      }

      if (this.disabledOperatorFilterTypesInExceptions && this.disabledOperatorFilterTypesInExceptions.size > 0 &&
          this.disabledOperatorFilterTypesInExceptions.has(qualifier.field)) {
        this.disabledAttributeFieldsInExceptions.push(qualifier.field);
        this.disabledOperatorFilterTypesInExceptions.delete(qualifier.field);
      }

      else if (qualifier.filterType === 'min') {
        this.disabledOperatorFilterTypesInExceptions.set(qualifier.field, 'min');
      }

      else if (qualifier.filterType === 'max') {
        this.disabledOperatorFilterTypesInExceptions.set(qualifier.field, 'max');
      }
    });
  }
}
