import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, inject, AfterViewChecked } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UploadFileType } from '@app/app/interfaces/upload-file-type.model';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Store } from '@ngxs/store';
import { DOCUMENT_CATEGORIES_REQUIRING_YEAR_STRING, LAST_24_MONTHS_SELECT_OPTIONS } from '../../document-upload/document-upload-constants';

export interface FileWithCategory {
  file: File;
  category: string;
  // Only used in conjunction with certain categories. e.g. 'bankStatements'
  monthsString?: string;
  // Only used in conjunction with certain categories. e.g. '...TaxReturns'
  yearsString?: string;
}

/**
 * Generic file input that supports a file-upload button or drag-n-drop
 * for one or multiple files.
 *
 * Use the inputs to customize for the context (e.g. uploading images,
 * documents, etc.). Client code should then handle the selected files,
 * processing them according to the file type, situation, etc.
 */
@Component({
  selector: 'advanced-file-upload',
  templateUrl: './advanced-file-upload.component.html',
  styleUrls: ['./advanced-file-upload.component.scss'],
  standalone: false
})
export class AdvancedFileUploadComponent implements AfterViewChecked {
  // This ref is the html input of type file that allows 1+ uploads depending
  // on fileCount input.
  @ViewChild('fileInputRef') fileInputRef: ElementRef;
  @ViewChild('fileForm') fileFormRef: NgForm;
  @Output() fileInput: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileListInput: EventEmitter<FileWithCategory[]> = new EventEmitter<FileWithCategory[]>();
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<any> = new EventEmitter<any>();
  @Input() set allowedFileTypes(fileTypes: UploadFileType[]) {
    this.allowedMimeTypes = fileTypes.map((fileType) => fileType.mimeType);
    this.allowedExtensions = fileTypes.map((fileType) => fileType.extension);
  }
  // Max size of each file in bytes. Defaults to 50KB.
  @Input() fileSizeLimit: number = 51200;
  // Number of files the user can drop/select/upload at one time. This will
  // determine whether we handle chosen files as File or FileList.
  @Input() fileCount: number = 1;
  // Optional label to treat this like a form input. If not provided, we're
  // probably showing it in a modal.
  @Input() label?: string = '';
  // Text at top of drop area.
  @Input() fileSelectText: string = "Click to choose, or drag file";
  // Text on blue button.
  @Input() buttonText: string = "Select file";
  @Input({ required: true }) isUploading: boolean;

  // Selected files.
  // TODO: Gut selection?
  selection?: FileList;
  fileListWithCategories: FileWithCategory[] = [];

  allowedMimeTypes: string[];
  allowedExtensions: string[];

  // By default, users can select/upload one file at a time. Using the
  // fileCount input, client code can specify up to 25. If client code tries
  // to specify more or if a user drops more than this maxFileCount, we'll
  // prevent it and let them know.
  // Note: API enforces max doc uploads of 50 per user per 5 minutes.
  maxFileCount: number = 25;
  errorMessage = '';
  loading: boolean = false;
  categoriesRequiringYear: string[] = DOCUMENT_CATEGORIES_REQUIRING_YEAR_STRING;
  last24Months: { display: string, value: string }[] = LAST_24_MONTHS_SELECT_OPTIONS;

  categories = inject(Store).selectSignal(DocumentsState.categories);
  years: string[] = [];
  protected _isAlreadySubscribed = false;

  constructor() {
    this.years = Array.from({ length: 10 }, (v, i) => {
      const year = new Date().getFullYear() - i;
      return year.toString();
    });
  }

  // We have to wait for the form to be initialized before we can subscribe to its changes.
  ngAfterViewChecked(): void {
    if (this.fileFormRef && !this._isAlreadySubscribed) {
      this._isAlreadySubscribed = true
      this.fileFormRef.statusChanges?.subscribe(() => {
        if (this.fileFormRef && this.fileFormRef.valid) {
          this.formIsValid.emit(true);
        } else {
          this.formIsValid.emit(false);
        }
      });
    }
  }

  // React to dropped files.
  handleFileDrop(selection: FileList) {
    this.loading = true;
    this.prepareSelection(selection);
  }

  // React to dialog selected files. Fires once per selected file if multi.
  handleFileBrowse(selection: FileList) {
    this.loading = true;
    this.prepareSelection(selection);
  }

  // This only works for single file inputs. FileList is readonly and we can't
  // "unselect" one item.
  removeFile(f: File | undefined | null = null) {
    this.fileInputRef.nativeElement.value = '';
    this.selection = undefined;
    this.loading = false;
    this.fileRemoved.emit();
  }

  prepareSelection(selection: FileList) {
    let errorFound = false;
    const validFiles: FileWithCategory[] = [];
    // Check and prepare 1+ files.
    // Enforce file count and max file count.
    Array.from(selection).forEach((f) => {
      if (selection?.length > this.fileCount || selection?.length > this.maxFileCount) {
        this.cycleError('fileCount');
        this.removeFile();
        errorFound = true;
        return;
      }
      if (f.size && f.size > this.fileSizeLimit) {
        this.cycleError('fileSize');
        this.removeFile(f);
        errorFound = true;
        return;
      }
      if (this.allowedMimeTypes.indexOf(f.type) < 0) {
        this.cycleError('fileType');
        this.removeFile(f);
        errorFound = true;
        return;
      }
      validFiles.push({ file: f, category: '' });
    });

    // TODO: Will this still upload files if some failed above? Yes. Fix it.
    if (!errorFound) {
      this.fileListWithCategories = validFiles;
      this.fileListInput.emit(this.fileListWithCategories);
    }
  }

  formatBytes(bytes: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + sizes[i];
  }

  cycleError(errorType: 'fileType' | 'fileSize' | 'fileCount'): void {
    this.loading = false;
    if (errorType === 'fileType') {
      // Make user-friendly display of accepted file extensions.
      this.errorMessage = 'File type must be ' + this.allowedExtensions.join(', ');
    } else if (errorType === 'fileSize') {
      this.errorMessage = 'File cannot exceed ' + this.formatBytes(this.fileSizeLimit);
    } else if (errorType === 'fileCount') {
      if (this.fileCount > 1) {
        this.errorMessage = 'You may upload up to ' + this.fileCount + ' files at a time';
      } else {
        this.errorMessage = 'You may upload 1 file at a time';
      }
    } else {
      this.errorMessage = 'There was a problem with the selected file(s)';
    }

    setTimeout(() => {
      this.errorMessage = '';
    }, 5000);
  }
}

