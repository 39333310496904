import { Component } from '@angular/core';
import { Logout } from '@app/app/store/auth/auth.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  standalone: false
})
export class LogoutComponent {

  constructor(
    private store: Store,
  ) {
    this.store.dispatch(new Logout());
  }

}
