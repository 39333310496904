import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mfa-code-input',
  templateUrl: './mfa-code-input.component.html',
  styleUrls: ['./mfa-code-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('jiggle', [
      transition('* => start', [
        animate('0.5s', keyframes([
          style({ transform: 'translateX(0)' }),
          style({ transform: 'translateX(-5px)' }),
          style({ transform: 'translateX(5px)' }),
          style({ transform: 'translateX(-5px)' }),
          style({ transform: 'translateX(5px)' }),
          style({ transform: 'translateX(0)' })
        ]))
      ]),
    ]),
  ],
  standalone: false
})
export class MfaCodeInputComponent implements OnInit, OnChanges {
  codeForm: FormGroup;
  jiggleState: 'start' | 'end' | null = null;

  @Input() invalid = false;
  @Output() code = new EventEmitter<string>();
  @ViewChildren('codeInput') codeInputs: QueryList<ElementRef>;

  constructor() { }

  ngOnInit() {
    this.codeForm = new FormGroup<any>({});
    for (let i = 0; i < 6; i++) {
      this.codeForm.addControl(`code${i}`, new FormControl('', {
        validators: [
          Validators.min(0),
          Validators.max(9),
        ],
        updateOn: 'change'
      }));
    }
    this._focusInput(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invalid && !changes.invalid.previousValue && changes.invalid.currentValue) {
      this._formInvalid();
    }
  }

  get fields() {
    return Object.values(this.codeForm.controls) ?? [];
  }

  onFocus(event: FocusEvent, index: number) {
    this.codeForm.get(`code${index}`)?.setValue('');
  }

  onKeydown(event: KeyboardEvent, index: number) {
    if (
      (event.key === 'Backspace' || event.key === 'Delete')
      && (index > 0 && index < this.fields.length)
      && !this.fields[index].value
    ) {
      this._focusInput(index - 1);
      return;
    }
  }

  onInput(event: InputEvent, index: number) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (isNaN(parseInt(inputValue))) {
      this.codeForm.get('code' + index)?.setValue('');
      event?.preventDefault();
      return;
    }
    if (inputValue.length > 1) {
      const digits = inputValue.split('');
      for (let i = 0; i < digits.length; i++) {
        this.codeForm.get('code' + i)?.setValue(digits[i]);
      }
      if (this.fields.every(field => field.value) && this.codeForm.valid) {
        this.emitCode();
      }
      return;
    }

    if (inputValue && (inputValue < '0' || inputValue > '9')) {
      event?.preventDefault();
      return;
    }

    if (
      index < this.fields.length - 1
      && inputValue
      && !this.fields[index + 1].value
    ) {
      this._focusInput(index + 1);
      return;
    }
  }

  onKeyUp(event: KeyboardEvent, index: number) {
    if (event.key !== 'Enter' && isNaN(parseInt(event.key))) {
      return;
    }
    if (
      (index === this.fields.length - 1 || event.key === 'Enter')
      && this.fields.every(field => field.value !== '')
      && this.codeForm.valid
    ) {
      this.codeInputs.toArray()[index]?.nativeElement.blur();
      this.emitCode();
      return;
    }
  }

  emitCode() {
    this.code.emit(this.fields.map(field => field.value).join(''));
  }

  private _formInvalid() {
    this.codeForm.reset();
    Object.values(this.codeForm.controls).forEach(control => {
      control.markAsTouched();
    });
    this._jiggleInputs();
    this._focusInput(0);
  }

  private _focusInput(index: number) {
    setTimeout(() => {
      this.codeInputs.toArray()[index]?.nativeElement.focus();
    });
  }

  private _jiggleInputs() {
    this.jiggleState = 'start';
    setTimeout(() => {
      this.jiggleState = 'end';
    }, 3000);
  }
}
