import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Question } from 'src/app/interfaces/question.model';

@Component({
  selector: 'app-add-question-dialog',
  templateUrl: './add-question-dialog.component.html',
  styleUrls: ['./add-question-dialog.component.scss'],
  standalone: false
})
export class AddQuestionDialogComponent implements OnInit {
  selection = new SelectionModel<OptionObject>(true, []);

  constructor(
    public dialogRef: MatDialogRef<AddQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionDataObject
  ) { }

  ngOnInit(): void {}

  addQuestions(): void {
    const selectedData: OptionObject[] = [];

    this.selection.selected.forEach((selectedQuestion) => {
      let existingField = selectedData.find(data => data.field == selectedQuestion.field);
      let existingIndex = selectedData.findIndex(data => data.field == selectedQuestion.field);
      if (selectedQuestion.isCompound){
        selectedQuestion.fields?.forEach((compoundField) => {
          const qIndex = this.data.questions.findIndex((x) => x.alias === compoundField);
          const question = this.data.questions[qIndex];

          const compoundOption: OptionObject = {
            display: question.shortName || question.name,
            section: selectedQuestion.section,
            isCompound: false,
            field: compoundField,
            requiredForContract: selectedQuestion.requiredForContract,
            requiredForOffer: selectedQuestion.requiredForOffer,
            mustAllowNull: selectedQuestion.mustAllowNull
          };

          selectedData.push(compoundOption);
        });
      } else if (existingField) {
        if(selectedQuestion.requiredForOffer){
          selectedData[existingIndex].requiredForOffer = true;
        }
        if(selectedQuestion.requiredForContract){
          selectedData[existingIndex].requiredForContract = true;
        }
      } else {
        selectedData.push(selectedQuestion);
      }
    });
    this.dialogRef.close(selectedData);
  }

  generateArray(n: number, startFrom: number = 1): any[] {
    return [...Array(n).keys()].map(i => i + startFrom);
  }

}

export interface AddQuestionDataObject {
  dialogTitle: string;
  sections: SectionObject[];
  questions: Question[];
}

export interface SectionObject {
  title: string;
  options: OptionObject[];
}

export interface OptionObject {
  display: string;
  section: string;
  isCompound: boolean;
  fields?: string[] | null;
  field?: string;
  documentQuantity?: number;
  requiredForOffer?: boolean;
  requiredForContract?: boolean;
  mustAllowNull?: boolean;
  fieldIds?: string[] | null;
  fieldId?: string | null;
}
