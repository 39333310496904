import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LenderUser } from '@app/app/interfaces/lender-user.model';
import { MatDialog } from '@angular/material/dialog';
import {
  TeamMemberDialogComponent
} from '@app/app/components/administration/team/team-member-dialog/team-member.dialog.component';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Store } from '@ngxs/store';
import { combineLatest, filter, fromEvent, map, Observable, Subject, take, takeUntil, } from 'rxjs';
import { LenderUserState } from '@app/app/store/lender-user/lender-user.state';
import {
  CreateLenderUser,
  DeleteLenderUser,
  GetLenderUsers,
  UpdateLenderUser
} from '@app/app/store/lender-user/lender-user.actions';
import { MatSort } from '@angular/material/sort';
import { get, sortBy } from 'lodash';
import { ScopeState } from '@app/app/store/scopes/scope.state';
import { Role } from '@app/app/interfaces/role.model';
import { debounceTime } from 'rxjs/operators';
import { GetScope } from '@app/app/store/scopes/scope.actions';
import { ScopeService } from '@app/app/services/scope.service';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-team-members-table',
  templateUrl: 'team-members-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TeamMembersTableComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('containerRef') containerRef: ElementRef;
  saasRoles$: Observable<Role[]> = this.store.select( ScopeState.saasRoles );
  adminRoleId$: Observable<number>;

  destroyed$ = new Subject<boolean>();
  teamMembers$: Observable<LenderUser[]>;
  teamMembersLoading$: Observable<boolean>;

  defaultColumns: Array<string> = [
    'name',
    'email',
    'roles',
    'lastActive',
    'actions',
  ];
  displayedColumns = this.defaultColumns;
  saasRolesOrder = this.scopeService.getSaasRolesOrder();

  dataSource$: Observable<MatTableDataSource<LenderUser>>;

  sortingAccessorMap: any = {
    name: 'fullName',
    email: 'user.email',
    lastActive: 'user.last_login',
  }

  constructor(
    private dialog: MatDialog,
    private scopeService: ScopeService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch( new GetScope('lenderSaas'));
    this.store.dispatch( new GetLenderUsers() );
    this.teamMembers$ = this.store.select( LenderUserState.teamMembers );
    this.teamMembersLoading$ = this.store.select( LenderUserState.loading );
    this.adminRoleId$ =
      this.saasRoles$.pipe(
        filter( roles => roles !== undefined),
        takeUntil(this.destroyed$),
        map( saasRoles => {
          const adminRole = saasRoles.filter( role => role.alias === 'lpxClientAdministrator' )[0];
          return adminRole?.id;
        })
      );

  }

  updateDisplayedColumns(): void {
    //@media-lg
    const breakPoint = 1024;
    this.displayedColumns = this.containerRef?.nativeElement.clientWidth < breakPoint
                            ? this.defaultColumns.filter( col => col !== 'lastActive')
                            : this.defaultColumns;
  }

  ngAfterViewInit(): void {
    this.dataSource$ = combineLatest([this.teamMembers$, this.saasRoles$, this.adminRoleId$]).pipe(
      filter(([teamMembers, saasRoles]) => teamMembers !== undefined && saasRoles !== undefined),
      takeUntil(this.destroyed$),
      map( ([teamMembers, saasRoles, adminRoleId]) => {
        const saasRoleIds = saasRoles?.map( role => role.id );
        teamMembers = teamMembers.map( teamMember => {
          const saasRoles = teamMember.user?.roles.filter( role => saasRoleIds.includes(role.id)),
            orderedSaasRoles = sortBy(saasRoles, role => this.saasRolesOrder.indexOf(role.alias) )
              .map( role => {
                return {
                  ...role,
                  name: role.alias === 'lpxClientDeveloper' ? 'Developer' : role.name
                }
            });
          const rolesCsv = orderedSaasRoles.map( role => role.name).join(',');
          return {
          ...teamMember,
            hasAdminRole: teamMember.user.roles.map( role => role.id ).includes(adminRoleId),
            saasRoles: orderedSaasRoles,
            rolesCsv,
          };
        });

        const dataSource = new MatTableDataSource<LenderUser>(teamMembers);
        dataSource.sort = this.sort;
        dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
        return dataSource;
      })
    );

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200)
      ).subscribe( () => this.updateDisplayedColumns() );
    this.updateDisplayedColumns();
  }

  addTeamMember(): void {
    const currentUser = this.store.selectSnapshot(AuthState.user)
    this.dialog.open( TeamMemberDialogComponent, {
      width: '740px',
    }).afterClosed()
      .pipe(take(1))
      .subscribe((newLenderUser) => {
        !!newLenderUser ? this.store.dispatch( new CreateLenderUser({
          institutionId: currentUser?.institution?.id,
          manageLenderUsers: false,
          ...newLenderUser
        })) : false;
      });
  }

  editUser( teamMember: any ): void {
    this.dialog.open( TeamMemberDialogComponent, {
      width: '740px',
      data: {
        first: teamMember?.first,
        last: teamMember?.last,
        email: teamMember?.user?.email,
        roles: teamMember?.user?.roles.map( (role: any) => role.id ),
        manageStyles: teamMember.permissions.includes('embeddedConfig.write'),
        manageLegal: teamMember.permissions.includes('embeddedLegal.write'),
        manageWebhooks: teamMember.permissions.includes('embeddedWebhooks.write'),
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe((lenderUser) => {
        !!lenderUser ? this.store.dispatch( new UpdateLenderUser(teamMember.id, {
          manageLenderUsers: false,
          ...lenderUser
        })) : false;
      });
  }

  deleteUser( user: any): void {
    const customContent = `
      <div class="mb-4">Are you sure you want to delete the team member <strong>${user.fullName}</strong>?</div>
      They will no longer be able to sign in and businesses and deals they managed will become unassigned.
    `
    const dialogRef = this.dialog.open( ConfirmDialogComponent, {
      data: {
        title: 'Delete Team Member',
        customContent,
        confirmLabel: 'Delete',
        confirmButtonColor: 'warn',
      }
    });

    dialogRef.componentInstance
      .onConfirm
      .pipe(
        take(1)
      ).subscribe(() => {
        this.store.dispatch( new DeleteLenderUser( user.id ) );
        dialogRef.close();
      });

    dialogRef.componentInstance
      .onCancel
      .pipe(
        take(1),
      ).subscribe( () => dialogRef.close() );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  sortingDataAccessor(data: any, sortHeaderId: string) {
    if(sortHeaderId === 'roles') {
      // push users with no roles to end of sort instead of beginning
      return data?.rolesCsv || 'zz';
    }
    const mappedAccessor = this.sortingAccessorMap[sortHeaderId],
      accessor = mappedAccessor ?? sortHeaderId;
    return get(data, accessor);
  }

}
