<app-alert
	*ngFor="let alert of alerts"
	[level]="alert.level"
	[message]="alert.message"
	[dismissible]="true">
</app-alert>

<div *ngIf="submitting" class="loading text-center" [@fadeIn] [@fadeOut]>
    <h2>Submitting Offer...</h2>
    <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<form [formGroup]="offerForm" [ngClass]="(submitting ? 'hidden' : '')">
    <div class="lendio-density-1 flex flex-col gap-8">
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Facility amount</mat-label>
                <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="amount" required data-cy="amount-input">
                <mat-error data-cy="amount-required"
                           *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched)">
                    Amount is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Advance rate</mat-label>
                <input type="number" matInput formControlName="advanceRate" required data-cy="advance-rate-input">
                <span matSuffix class="pr-2">%</span>
                <mat-error data-cy="advance-rate-required"
                           *ngIf="advanceRateControl.invalid && (advanceRateControl.dirty || advanceRateControl.touched)">
                    Advance rate is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Min. purchase fee</mat-label>
                <input matInput type="number" formControlName="minPurchaseFee" required data-cy="min-purchase-fee-input">
                <span matSuffix class="pr-2">%</span>
                <mat-error data-cy="min-purchase-fee-required"
                           *ngIf="minPurchaseFeeControl.invalid && (minPurchaseFeeControl.dirty || minPurchaseFeeControl.touched)">
                    Min purchase fee is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Contract duration</mat-label>
                <input matInput type="number" formControlName="term" required data-cy="term-input">
                <span matSuffix>Months</span>
                <mat-error data-cy="term-required"
                           *ngIf="termControl.invalid && (termControl.dirty || termControl.touched)">
                    Term is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Commission</mat-label>
                <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                <input matInput type="number" formControlName="commission" data-cy="commission-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Points</mat-label>
                <input matInput type="number" formControlName="points" data-cy="points-input">
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Payment term</mat-label>
                <mat-select formControlName="paymentTerm" data-cy="payment-term-select">
                    <mat-option *ngFor="let term of paymentTerms; let i = index" [value]="term.value" attr.data-cy="payment-term-{{i}}">{{ term.display }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Date received</mat-label>
                <input matInput [matDatepicker]="dateReceivedPicker1" formControlName="received" required data-cy="received-input" [max]="today">
                <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker1"></mat-datepicker-toggle>
                <mat-datepicker #dateReceivedPicker1></mat-datepicker>
                <mat-error data-cy="received-required"
                           *ngIf="receivedControl.invalid && (receivedControl.dirty || receivedControl.touched)">
                    Date received is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="width-75">
                <mat-label>Origination fee</mat-label>
                <input matInput type="number" formControlName="originationFee" data-cy="origination-fee-input">
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-25">
                <mat-label>Fee type</mat-label>
                <mat-select [(value)]="originationFeeType">
                    <mat-option [value]="localeCurrencySymbol">{{ localeCurrencySymbol }}</mat-option>
                    <mat-option [value]="'%'">%</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center gap-4">
            <mat-form-field appearance="outline" class="additional-terms">
                <mat-label>Additional terms (optional)</mat-label>
                <textarea
                    matInput
                    [(ngModel)]="additionalTerms"
                    [ngModelOptions]="{standalone: true}"
                    data-cy="additional-terms-input">
                </textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-row justify-between items-center mt-4">
        <span class="mat-body-2"><span class="align-sub">*</span> required fields</span>
        <div>
            <button mat-button class="cancel-new-offer" (click)="close()" class="mr-2">Cancel</button>
            <button mat-flat-button
                    color="primary"
                    class="send-offer"
                    (click)="submit()"
                    data-cy="factoring-offer-submit-btn"
                    [class.spinner]="submitting"
                    [disabled]="offerForm.invalid || submitting">
                {{ submitting ? '&nbsp;' : 'Add offer' }}
            </button>
        </div>
    </div>
</form>

