import { Component, Input } from '@angular/core';
import { EarlyAccessFeature } from '@app/app/interfaces/saas-feature.model';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { EarlyAccessFeatureService } from '@app/app/components/settings/early-access/early-access-feature.service';
import {
  EarlyAccessLabelComponent
} from '@app/app/components/settings/early-access/early-access-label/early-access-label.component';

@Component({
  standalone: true,
  selector: 'app-early-access-selection',
  imports: [
    MatCheckbox,
    FormsModule,
    EarlyAccessLabelComponent
  ],
  templateUrl: './early-access-selection.component.html',
  styleUrl: './early-access-selection.component.scss'
})
export class EarlyAccessSelectionComponent {
  @Input() autoEnrolled: boolean = false;
  @Input() feature: EarlyAccessFeature;
  @Input() last: boolean;
  public autoEnrollmentAlias: string;

  constructor(private _earlyAccessService: EarlyAccessFeatureService) {
    this.autoEnrollmentAlias = this._earlyAccessService.autoEnrollmentAlias;
  }

  handleFeatureChange(event: MatCheckboxChange): void {
    if (this.autoEnrolled && this.feature.alias !== this.autoEnrollmentAlias) return;
    this.feature.disabled = !event.checked;
  }

  handleClick(featureToggle) {
    if (this.autoEnrolled && this.feature.alias !== this.autoEnrollmentAlias) return;
    featureToggle.toggle();
    this.feature.disabled = !featureToggle._checked
  }
}
