import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@app/environments/environment';
import { TrackingService } from '@app/app/services/tracking.service';
import { LogEvent } from './tracking.actions';

export class TrackingStateModel {
    loggedEvents: {};
}

@State<TrackingStateModel>({
    name: 'tracking',
    defaults: {
        loggedEvents: {},
    },
})
@Injectable()
export class TrackingState {
    constructor(
        private trackingService: TrackingService,
        private store: Store
    ) { }

    @Selector()
    static loggedEvents(state: TrackingStateModel) {
        return state.loggedEvents;
    }

    @Action(LogEvent)
    LogEvent(
        { patchState, getState }: StateContext<TrackingStateModel>,
        { event, payload }: LogEvent
    ) {
        const currentTime = new Date().getTime();
        let loggedEvents = {};
        loggedEvents[event] = 0;

        // pre-set the event timing to 0
        patchState({
            loggedEvents: {
                ...loggedEvents,
                ...getState().loggedEvents
            }
        })

        // TBD: cap events to 1 per minute per event
        // if (getState().loggedEvents[event] > (currentTime - (1000 * 60))) return;

        return this.trackingService.logEvent(event, payload).pipe(
            catchError(e => {
                if (!environment.production) {
                    console.warn(`Event ${event} log failed to post.`, e?.message);
                }

                return of(); // Let it go, let it go...
            }),
            tap((response: TrackingStateModel[]) => {
                const loggedEvents = { ...getState().loggedEvents }
                loggedEvents[event] = currentTime

                patchState({
                    loggedEvents
                });
            })
        );
    }
}
