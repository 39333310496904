import * as moment from 'moment-timezone';
import { Component } from '@angular/core';
import localeData from '@angular/common/locales/en';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'grid-activity-cell',
    imports: [
        MatIconModule
    ],
    templateUrl: './date-cell.component.html',
    styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent implements ICellRendererAngularComp {
    public date?: string;
    public timePast?: string;
    public params: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.date = this.formatTimestamp(this.params.value);
      this.timePast = this.timeAgo(parseInt(this.params.value ?? '') ?? 0);
     
    }

    timeAgo(dateStamp: number) {
      return moment.unix(dateStamp).fromNow();
    }

    formatTimestamp(dateStamp: number) {
        registerLocaleData(localeData);
        const datePipe = new DatePipe('en-US');
        const date = dateStamp ? datePipe.transform((dateStamp * 1000), 'longDate')! : '';
        const time = dateStamp ? datePipe.transform((dateStamp * 1000), 'shortTime')! : '';

        return `${date} at ${time}`;
    }
  
    refresh(params: ICellRendererParams) {
      return false;
    }
}
