import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngxs/store';
import { Hijack } from '@app/app/store/auth/auth.actions';

@Component({
  selector: 'app-hijack',
  templateUrl: './hijack.component.html',
  styleUrls: ['./hijack.component.scss'],
  standalone: false
})
export class HijackComponent implements OnInit, OnChanges {

  @Input() searchResults: any;
  @Input() users: any;
  @Output() searchTerm = new EventEmitter<string>();
  @Output() partnerSelect = new EventEmitter<{ id: number, type: string }>();

  @ViewChild('partnersTrigger') partnersTrigger: MatMenuTrigger;
  @ViewChild('usersTrigger') usersTrigger: MatMenuTrigger;

  inputText = '';
  retrievingUsers = false;
  retrievedUsers = false;

  constructor(
    private store: Store,
  ) {}

  ngOnInit(): void {
  }

  search() {
    this.searchTerm.emit(this.inputText);
  }

  partnerHovered(partner: { id: number, type: string }) {
    this.retrievedUsers = false;
    this.getUsers(partner);
  }

  getUsers(partner: { id: number, type: string }) {
    this.retrievingUsers = true;
    this.partnerSelect.emit(partner);
  }

  hijack(userId: number) {
    this.store.dispatch(new Hijack(userId))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('searchResults')) {
      if (this.inputText !== '') {
        this.partnersTrigger.openMenu();
      }
    }

    if (changes.users && changes.users.currentValue !== null) {
      this.retrievingUsers = false;
      this.retrievedUsers = true;
    }
  }

}
