<div>
    <div mat-dialog-title>
        <button mat-icon-button
                class="!absolute !top-5 !right-4"
                (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>

        <ng-container *ngIf="_dialogData.acceptedOffer; else nonFundingTitle">
            Confirm accepted offer details and <br> status change
        </ng-container>
        <ng-template #nonFundingTitle>
            Confirm status change<span
                *ngIf="(_dialogData.inactiveReasons$ | async)?.length"
            >
                and reason(s)</span
            >
        </ng-template>
    </div>

    <div mat-dialog-content>

        <!-- Accepted offer -->
        <ng-container *ngIf="_dialogData.acceptedOffer">
            <p>This funding will be marked with the following offer details:</p>
            <!-- TODO: Turn this non-selectable offer list item into a theme component? -->
            <div class="lendio-data-detail-list">
                <div class="non-selectable">
                    <div class="lendio-data-detail-list-item-title mb-2">Offer</div>
                    <div class="lendio-data-detail-list-row">
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">Amount</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !_dialogData.acceptedOffer.amount}">
                                {{ (_dialogData.acceptedOffer.amount | currency) || '-' }}
                            </div>
                        </div>
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">APR</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !_dialogData.acceptedOffer.apr}">
                                {{ (_dialogData.acceptedOffer.apr | number: '1.0-2') || '-' }}<span *ngIf="_dialogData.acceptedOffer.apr">%</span>
                            </div>
                        </div>
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">Payment Freq.</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !_dialogData.acceptedOffer.paymentFrequency}">
                                {{ _dialogData.acceptedOffer.paymentFrequency || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p>
            <ng-container *ngIf="_dialogData.acceptedOffer; else nonFundingSubtitle">
                By clicking “Confirm funding” you will be changing this Deal
            </ng-container>
            <ng-template #nonFundingSubtitle>
                By clicking “Confirm status change” you will be changing this Deal from:
            </ng-template>
        </p>

        <!-- Deal status indicators -->
        <div class="flex flex-row gap-4 pb-6">
            <div>
                <div class="py-2 font-bold">From</div>
                <lendio-status-display
                    [status]="_dialogData.currentStatus"
                ></lendio-status-display>
            </div>
            <div>
                <div class="py-2 font-bold">To</div>
                <lendio-status-display
                    [status]="_dialogData.newStatus"
                ></lendio-status-display>
            </div>
        </div>

        <!-- Inactive stage - Reason select & Other Reason form field -->
        <ng-container *ngIf="(_dialogData.inactiveReasons$ | async)?.length">
            <p class="mt-4 pb-1 font-semibold">
                Please provide the reason(s) for moving this deal to an inactive
                status:
            </p>
            <form class="lendio-density-1 mb-8" [formGroup]="inactiveStatusChangeForm">
                <div class="mb-2">
                    <mat-form-field
                        class="mb-4 inactive-form-content"
                        appearance="outline"
                    >
                        <mat-select
                            formControlName="inactiveReason"
                            placeholder="Choose reason(s)"
                            [multiple]="true"
                        >
                            <mat-option
                                *ngFor="
                                    let reason of _dialogData.inactiveReasons$ | async
                                "
                                [value]="reason.reason"
                            >
                                {{ reason.reason }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="inactiveReasonControl.invalid">
                            Reason is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="inactive-form-content" appearance="outline">
                        <input
                            formControlName="inactiveReasonDetail"
                            matInput
                            placeholder="Comments"
                        />
                        <mat-hint>
                            Please disclose the details for the reason(s) selected above.
                        </mat-hint>
                        <mat-error *ngIf="inactiveReasonDetailControl.invalid">
                            Comment is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div class="mat-body-2 inactive-form-content">
                Please note: Setting this deal to '{{
                    _dialogData.newStatus.stage
                }}: {{ _dialogData.newStatus.display }}' will finalize this action
                and close any pending offers for this opportunity. Please ensure all
                details are correct before proceeding.
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions
         [align]="'end'"
         class="deal-status-change-controls lendio-density-1">
        @if (_dialogData?.newStatus?.value === 'dead' && (showAAN$ | async)) {
            <div class="mt-4">
                <button
                    (click)="_handleSaveChanges()"
                    [disabled]="_isSaving || (_dialogData.inactiveReasons$ | async)?.length > 0 && inactiveStatusChangeForm.invalid"
                    mat-button
                    color="primary"
                >
                    Change status
                </button>
                <button
                    (click)="_handleSaveAndSendAAN()"
                    [disabled]="_isSaving || (_dialogData.inactiveReasons$ | async)?.length > 0 && inactiveStatusChangeForm.invalid"
                    mat-flat-button
                    color="primary"
                >
                    Change status & send AAN
                </button>
            </div>
        } @else {
            <div class="mt-4">
                <button mat-button [mat-dialog-close]="true" color="primary">Cancel</button>
                <button
                    (click)="_handleSaveChanges()"
                    [disabled]="_isSaving || (_dialogData.inactiveReasons$ | async)?.length > 0 && inactiveStatusChangeForm.invalid"
                    mat-flat-button
                    color="primary"
                >
                    {{ _dialogData.acceptedOffer ? 'Confirm funding' : 'Confirm status change' }}
                </button>
            </div>
        }
    </div>
</div>
