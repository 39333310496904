import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { BusinessActions } from '@app/app/store/businesses/business.actions';
import { ContactsActions } from '@app/app/store/contacts/contacts.actions';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { filter } from 'rxjs/operators';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';

/**
 * Business details.
 *
 * CRUD Business details and relations.
 */
@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss'],
  standalone: false
})
export class BusinessDetailsComponent implements OnInit, OnDestroy {

  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;
  loading$ = this.store.select(BusinessState.loading);
  business$ = this.store.select(BusinessState.business);
  businessLenderId$: Observable<number | undefined> = this.store.select(BusinessState.businessLenderId);
  contacts$ = this.store.select(ContactsState.contacts);
  documents$ = this.store.select(DocumentsState.documents);
  canViewFinancesTab$: Observable<boolean> = this.store.select(AuthState.userHasPermission('lpxViewBusinessFinancesTab'));
  canSeeAdvDocMgmt = this.store.selectSignal(SaasFeaturesState.saasPermitted('advDocMgmt', 'lpxCanSeeAdvDocMgmt'));

  // Currently selected tab.
  public selectedTab: string = 'overview';
  // Borrower/business ID from route param in constructor.
  emptyType: string = 'deals';
  emptyLabelContent: string = 'There are no deals here—yet.';
  emptyMessageContent: string = 'Send them an application to see if they qualify for business capital.';
  private destroyed$ = new Subject<void>();

  constructor(
    private store: Store,
    private titleService: NavPageTitleService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { label: 'Businesses', path: '/businesses' },
    ]);

    this.business$.pipe(
      takeUntil(this.destroyed$),
      filter(business => !!business?.id && !!business?.lenderId && !!business?.name),
    ).subscribe(business => {
      this.titleService.setTitle(business.name);
      this.store.dispatch(new DocumentsActions.GetBorrowerDocuments(business.id, business.lenderId!));
      this.store.dispatch(new ContactsActions.GetIndex(business.id));
    });
  }

  setTab(tab: string) {
    this.selectedTab = tab;
  }

  getShortenedTabCount(count: number): string | number {
    return (count >= 1000) ? Math.floor(count / 1000) + 'k+' : count;
  }

  getHumanName(tab: string): string {
    return this.toSentenceCase(tab.replace('-', ' '));
  }

  toSentenceCase(str: string) {
    return str.length === 0
      ? ''
      : `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setBreadcrumbs([]);
    this.destroyed$.next();
    this.store.dispatch(new ContactsActions.ClearContactsState());
    this.store.dispatch(new DocumentsActions.ClearDocumentsState());
    this.store.dispatch(new BusinessActions.ClearBusinessState());
  }
}
