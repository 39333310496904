import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Document } from '@app/app/interfaces/document.model';
import {
  ColDef,
  ColumnRowGroupChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridState,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
  ToolPanelVisibleChangedEvent,
  RowNode,
  RowDataUpdatedEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { MatButtonModule } from '@angular/material/button';
import {
  ColumnFilterModel
} from '@app/app/components/grid-components/grid-types';
import {
  documentsGridConfig,
  documentsGridDefaultColDef,
  documentsSizeColsToFit,
} from './documents-grid-config-helpers';
import {
  COL_DEF_FILE_NAME,
  COL_DEF_TYPE,
  COL_DEF_UPLOADED,
  COL_DEF_DYNAMIC_ACTIONS,
  COL_DEF_DYNAMIC_BORROWER_ACTIONS
} from './documents-grid-column-defs';
import { DocumentsHeaderComponent } from './documents-header/documents-header.component';
import { MatDialog } from '@angular/material/dialog';
import { documentsAllowedUploadFileTypes } from '../documents-allowed-upload-file-types';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { environment } from '@app/environments/environment';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import { FileWithCategory } from '@app/app/components/file-upload/advanced-file-upload/advanced-file-upload.component';
import { AdvancedFileUploadDialogComponent } from '@app/app/components/file-upload/advanced-file-upload-dialog/advanced-file-upload-dialog.component';
import { Business, BusinessAccessLevel } from '@app/app/interfaces/business.model';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { AuthState } from '@app/app/store/auth/auth.state';
import { DOCUMENT_CATEGORIES_REQUIRING_YEAR_STRING } from '@app/app/components/document-upload/document-upload-constants';

@Component({
  selector: 'app-documents-grid',
  imports: [
    AgGridAngular,
    MatButtonModule,
    DocumentsHeaderComponent
  ],
  templateUrl: './documents-grid.component.html',
  styleUrl: './documents-grid.component.scss'
})
export class DocumentsGridComponent implements OnInit, OnDestroy {
  @Input({required: true}) authUser: AuthUser | undefined;
  @Input({required: true}) documents: Document[];
  @Input() dealId: number;
  @Input() borrowerId: number;
  @Input() loanProductCategory: string;
  protected readonly documentsGridConfig = documentsGridConfig;
  protected readonly colDefDefault = documentsGridDefaultColDef;
  private _documentsGridApi: GridApi | undefined;
  public canUpload: boolean;
  public hasModifyAccess: boolean;
  public canCrudMpDealDocs = this._store.selectSignal<boolean>(SaasFeaturesState.saasPermitted('funnelCrudMpDealDocs', 'lpxCanCrudMpDealDocs'));
  public business = this._store.selectSignal<Business>(BusinessState.business);
  public currentUser = this._store.selectSignal<AuthUser | undefined>(AuthState.user);
  public documentsGridInitialState: GridState;
  public selectedRows = signal<any[]>([]);
  public isModifiedViewFilter: WritableSignal<boolean> = signal(false);
  public currentFilterModel: WritableSignal<ColumnFilterModel> = signal<ColumnFilterModel>([]);
  public colDefFileName: ColDef = COL_DEF_FILE_NAME;
  public colDefType: ColDef = COL_DEF_TYPE;
  public colDefUploadedOn: ColDef = COL_DEF_UPLOADED;
  public colDefDynamicActions: ColDef = COL_DEF_DYNAMIC_ACTIONS;
  public colDefDynamicBorrowerActions: ColDef = COL_DEF_DYNAMIC_BORROWER_ACTIONS;
  public documentsGridOptions: GridOptions = Object.assign({}, {
    ...this.documentsGridConfig,
    defaultColDef: this.colDefDefault
  });

  constructor(
    private _store: Store,
    private dialog: MatDialog,
    private _snackbarService: LendioSnackbarService,
    private http: HttpClient
  ) {
    fromEvent(window, 'resize')
      .pipe(debounceTime(250), takeUntilDestroyed())
      .subscribe(() => documentsSizeColsToFit(this._documentsGridApi!));
  }

  ngOnInit(): void {
    this.hasModifyAccess = this.business()?.accessLevel === BusinessAccessLevel.Modify;
    this.canUpload = (!!this.dealId && this.canCrudMpDealDocs()) || this.hasModifyAccess;
    this.documentsGridOptions.columnDefs = [
      {
        ...this.colDefFileName,
        cellRendererParams: {
          dealId: this.dealId
        }
      },
      this.colDefType,
      this.colDefUploadedOn,
      {
        ...this.colDefDynamicActions,
        cellRendererParams: {
          dealId: this.dealId,
          borrowerId: this.borrowerId,
          loanProductCategory: this.loanProductCategory,
          business: this.business(),
          canCrudMpDealDocs: this.canCrudMpDealDocs(),
          currentUserInstitutionId: this.currentUser()?.institution?.id
        }
      },
      {
        ...this.colDefDynamicBorrowerActions,
        cellRendererParams: {
          dealId: this.dealId,
          borrowerId: this.borrowerId,
          loanProductCategory: this.loanProductCategory,
          business: this.business(),
          canCrudMpDealDocs: this.canCrudMpDealDocs(),
          currentUserInstitutionId: this.currentUser()?.institution?.id
        }
      }
    ]
    if (this.borrowerId) {
      this._store.dispatch(new DocumentsActions.SubscribeToDocumentStateUpdates(this.borrowerId, this.dealId));
    }
  }

  ngOnDestroy(): void {
    if (this.borrowerId) {
      this._store.dispatch(new DocumentsActions.UnsubscribeFromDocumentStateUpdates(this.borrowerId, this.dealId));
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this._documentsGridApi = event.api;
    this.calculateDefaultColumns();
  }

  onFirstDataRendered(event: FirstDataRenderedEvent): void {
    setTimeout(() => documentsSizeColsToFit(event.api), 500);
  }

  onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent): void {
    documentsSizeColsToFit(event.api);
  }

  calculateDefaultColumns() {
    const columnsState: { colId: string, hide: boolean | undefined }[] = [];

    if (!this.dealId) {
      columnsState.push({
          colId: 'status',
          hide: true
      });
    } else {
      columnsState.push({
        colId: 'actions',
        hide: true
      })
    }

      this._documentsGridApi?.applyColumnState({ state: columnsState });
  }

  onToolPanelVisibilityChanged(event: ToolPanelVisibleChangedEvent): void {
    setTimeout(() => documentsSizeColsToFit(event.api), 150);
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    this.selectedRows.set(event.api.getSelectedNodes());
  }

  onRowDataUpdated(event: RowDataUpdatedEvent): void {
    event.api.refreshCells({ force: true });
  }

  clearAllSelected(): void {
    this._documentsGridApi?.deselectAll();
  }

  handleUpload(event): void {
    this.openUploadDialog();
  }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(AdvancedFileUploadDialogComponent, {
      width: '400px',
      disableClose: true,
      restoreFocus: false,
      // Props for file upload. All are optional and have defaults if not given.
      data: {
        // Specific to the dialog.
        title: 'Upload',
        closeLabel: 'Close',
        // Pass through to the file upload component.
        allowedFileTypes: documentsAllowedUploadFileTypes,
        // 20 MB - 1024 * 1024 * 20
        fileSizeLimit: 20971520,
        fileCount: 20,
        fileSelectText: 'Drop files to upload',
        buttonText: 'or choose files'
      },
    });


    // Save documents and show snackbar confirming save success.
    dialogRef.componentInstance.fileListInput.subscribe({
      next: (fileListWithCategories: FileWithCategory[]) => {
        const numberOfFiles = fileListWithCategories.length;
        let numberOfFilesUploaded = 0;
        fileListWithCategories.forEach((fileListItem) => {
          const formData = new FormData();
          if (this?.borrowerId) {
            formData.append('borrowerId', this.borrowerId.toString());
            formData.append('category', fileListItem.category);
            if (fileListItem.category === 'bankStatement') {
              formData.append('monthsString', fileListItem.monthsString!);
            }
            if (DOCUMENT_CATEGORIES_REQUIRING_YEAR_STRING.includes(fileListItem.category)) {
              formData.append('yearsString', fileListItem.yearsString!);
            }
          }
          // Attach file to deal (in dealDocuments table) if this is a deal document upload.
          if (this?.dealId) {
            formData.append('attach', 'true');
            formData.append('dealId', this.dealId.toString());
            formData.append('category', fileListItem.category);
            if (fileListItem.category === 'bankStatement') {
              formData.append('monthsString', fileListItem.monthsString!);
            }
          }
          this._store.dispatch(new DocumentsActions.Post(fileListItem.file, formData)).subscribe({
            next: () => {
              numberOfFilesUploaded++;
              if (numberOfFilesUploaded >= numberOfFiles) {
                dialogRef.close();
                this._snackbarService.open({
                  prefix: {
                    type: 'image',
                    imageSrc: 'assets/images/green-circle-check.png',
                  },
                  title: 'Document(s) categorized & uploaded',
                  message: 'Documents will become available pending a virus scan.',
                  canDismiss: true,
                  duration: 3000
                });
              }
            }
          })
        });
      }
    });
  }

  handleDelete($event: Event): void {
    $event.stopPropagation();
    this.deleteSelected()
  }

  deleteSelected(): void {
    // First, confirm.
    const documentIds: Array<number> = [];

    // Handle single doc from row menu or 1+ selected for bulk.
    this.selectedRows().forEach((row: RowNode) => {
      documentIds.push(row.data.id);
    });

    const message = ["documents", "Documents", "these", "were"]

    let confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete " + message[0] + "?",
        description: "Are you sure you want to delete " + message[2] + " " + message[0] + "?",
        cancelLabel: "Cancel",
        confirmLabel: "Delete",
        confirmStyles: "background-color: red;",
        width: "352px"
      }
    });

    // Cancel the "cancel" of the edit dialog.
    confirmDialogRef.componentInstance.onCancel.subscribe(() => {
      confirmDialogRef.close();
    });

    // Proceed with deleting the docs.
    confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
      documentIds.forEach((documentId) => {
        // Hit deal doc or borrower doc delete route
        const deleteAction = this.dealId !== undefined
          ? new DocumentsActions.DeleteDealDocument(this.dealId, documentId)
          : new DocumentsActions.Delete(documentId);

        this._store.dispatch(deleteAction).subscribe({
          error: () => {
            confirmDialogRef.close();
          },
          complete: () => {
            this.clearAllSelected();
            confirmDialogRef.close();
            this._snackbarService.open({
              message: message[1] + " " + message[3] + " deleted.",
              canDismiss: true,
              duration: 3000
            });
          }
        });
      });
    });
  }

  handleDownload($event: Event): void {
    $event.stopPropagation();
    this.downloadSelected()
  }

  downloadSelected(): void {
    const zip = new JSZip();
    const downloadObservables: Observable<Blob>[] = [];

    this.selectedRows().forEach((rowNode: RowNode) => {
      downloadObservables.push(this.http.get(this.documentUrl(rowNode.data.id), { responseType: 'blob' }));
    });

    forkJoin(downloadObservables).subscribe(responses => {
      responses.forEach((response, index) => {
        zip.file(`document_${this.selectedRows()[index].filename ?? 'document.pdf'}`, response, { binary: true });
      });

      zip.generateAsync({ type: 'blob' }).then(content => {
        const fileURL = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `documents.zip`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      });
      this.clearAllSelected();
    });
  }

  onRowClicked(event: RowClickedEvent): void {
  }

  private documentUrl(docId: number): string {
      if (this.dealId) {
          return `${environment.apiUrl}/l/v2/internal/document/${docId}/stream?dealId=${this.dealId}`;
      } else {
          return `${environment.apiUrl}/document/${docId}?stream=1`;
      }
  }
}
