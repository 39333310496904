import { Component, Input } from '@angular/core';
import { EarlyAccessLabel } from 'src/app/components/settings/early-access/early-access-feature'
import { CapitalizePipePipeModule } from '@app/app/pipes/capitalize/capitalize-pipe.module';

@Component({
  standalone: true,
  selector: 'app-early-access-label',
  imports: [CapitalizePipePipeModule],
  templateUrl: './early-access-label.component.html',
  styleUrl: './early-access-label.component.scss'
})
export class EarlyAccessLabelComponent {
  @Input({required: true}) featureType: EarlyAccessLabel;
}
