import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PortalUser } from 'src/app/interfaces/portal-user.model';
import { Store } from '@ngxs/store';
import { DeletePortalUser } from 'src/app/store/portal-users/portal-users.actions';

@Component({
  templateUrl: './delete-user-dialog.component.html',
  standalone: false
})
export class DeleteUserDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { portalUser: PortalUser },
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    private store: Store
  ) { }

  close() {
    this.dialogRef.close();
  }

  deleteUser() {
    this.store.dispatch(new DeletePortalUser(this.data.portalUser.id));
    this.close();
  }
}
