import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { Store } from '@ngxs/store';
import { complexityValidator, equalToValidator } from '../reset-password/reset-password.component';
import { ResetExpiredPassword } from '@app/app/store/auth/auth.actions';
import { take } from 'rxjs';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired.component.html',
  styleUrls: ['./password-expired.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ])
  ],
  standalone: false
})
export class PasswordExpiredComponent implements OnInit {

  submitted = false;
  submitting = false;
  transactionId: string;
  showPassword = false;
  showConfirmPassword = false;
  showOldPassword = false;
  expiredPasswordForm: FormGroup = this.formBuilder.group({
    oldPassword: new FormControl('', {
      validators: [
        Validators.required,
      ],
      updateOn: 'blur'
    }),
    newPassword: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(14),
        complexityValidator(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/)
      ],
      updateOn: 'blur'
    }),
  });

  get newPassword() {
    return this.expiredPasswordForm.get('newPassword');
  }

  get oldPassword() {
    return this.expiredPasswordForm.get('oldPassword');
  }

  get confirmPassword() {
    return this.expiredPasswordForm.get('confirmPassword');
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.transactionId = this.route.snapshot.queryParamMap.get('transactionId') ?? '';
    if (!this.transactionId) {
      this.router.navigate(['/auth/login']);
    }
    this.expiredPasswordForm.addControl('confirmPassword', new FormControl('', {
      validators: [
        Validators.required,
        equalToValidator(this.newPassword!)
      ],
      updateOn: 'change'
    }));
  }

  canSubmit(): boolean {
    return this.expiredPasswordForm.valid && !this.submitting && !this.submitted;
  }

  submit() {
    if (!this.canSubmit()) {
      return;
    }
    this.submitting = true;
    this.store.dispatch(new ResetExpiredPassword(
      this.oldPassword!.value,
      this.newPassword!.value,
      this.confirmPassword!.value,
      this.transactionId
    )).pipe(take(1)).subscribe({
      error: () => {
        this.submitting = false;
      }
    });
  }
}
